import { takeLeading, select, call, put } from 'redux-saga/effects';

import { fetchValidSkillsActions, FETCH_VALID_SKILLS } from 'store/reducers/validSkills';
import { getAllSkills } from 'services/skillsClassification';

// we don't want this to run more than once, since the response is so large
// and shouldn't change very often

const getIsLoaded = ({ validSkills: { isLoaded } }) => isLoaded;

export function* fetchValidSkillsOnce() {
  const hasRequested = yield select(getIsLoaded);
  if (!hasRequested) {
    yield put(fetchValidSkillsActions.fetchData());
    try {
      // These are valid skills across different modelVersions
      let skillsResponse = yield call(getAllSkills);

      let skills = skillsResponse.reduce((obj, skill) => {
        obj[skill.id] = skill;
        return obj;
      }, {});
      yield put(fetchValidSkillsActions.success(skills));
    } catch (e) {
      yield put(fetchValidSkillsActions.failure(e.message));
    }
  }
}

export default function* watch() {
  yield takeLeading(FETCH_VALID_SKILLS.REQUEST_ONCE, fetchValidSkillsOnce);
}
