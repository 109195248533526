import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { updateProfileMiddleware } from './middleware/updateProfile';

import reducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, updateProfileMiddleware];

export default function configureStore(initialState = {}) {
  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  // Expose store when running cypress to allow action invoking
  if (window.Cypress) {
    window.store = store;
  }

  sagaMiddleware.run(sagas);

  return store;
}
