import React, { useEffect, useState, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import camelCase from 'lodash.camelcase';

import FloatingTooltip from 'components/atoms/FloatingTooltip';
import ClusterResults from 'components/organisms/ClusterResults';
import EducationResults from 'components/organisms/EducationResults';
import JobResults from 'components/organisms/JobResults';
import PriorLearningCreditResults from 'components/organisms/PriorLearningCreditResults';
import InternshipResults from 'components/organisms/InternshipResults';
import CoursesProgramsToggle from 'components/molecules/CoursesProgramsToggle';
import ResultsSidebar from 'components/molecules/ResultsSidebar';
import SkillFilter from 'components/molecules/SkillFilter';
import CompanyFilter from 'components/molecules/CompanyFilter';
import ProgramFilter from 'components/molecules/ProgramFilter';
import CheckboxFilter from 'components/molecules/CheckboxFilter';

import ResultsNav from 'containers/ResultsNav';
import SavedViews from 'containers/SavedViews';
import LocationAutocomplete from 'containers/LocationAutocomplete';

import withFetchValidSkills from 'hocs/withFetchValidSkills';

import getClusterResults from 'helpers/getClusterResults';
import useResultsPagination from 'helpers/useResultsPagination';
import { initialState as currentViewsInitialState } from 'store/reducers/currentViews';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GlobalStyle = createGlobalStyle`
  // prevents Chrome from anchoring scrollbar during pagination
  body {
    overflow-anchor: none;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-grow: 1;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const ResultsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 3rem 0.7rem;
`;

const initialResultState = {
  matches: [],
  others: [],
  isLoading: true,
  error: false
};

const Results = ({
  closeSignUpPrompt,
  currentViews,
  site,
  profile,
  fetchValidSkills,
  location,
  updateView,
  validSkills
}) => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  // Education and Jobs are more similar (pagination/filtering through API)
  // whereas Clusters is different (pagination/filtering in FE)
  const [clusterResults, setClusterResults] = useState(initialResultState);

  useEffect(() => {
    // validSkills won't be loaded if the user came to this page directly
    if (!Object.keys(validSkills).length) {
      fetchValidSkills();
    }
  }, [validSkills, fetchValidSkills]);

  // Initialize cluster results
  useEffect(() => {
    const load = async () => {
      setClusterResults(prevState => ({ ...prevState, isLoading: true }));
      try {
        const [matches, others] = await getClusterResults(profile.selectedSkills);
        setClusterResults(prevState => ({ ...prevState, isLoading: false, matches, others }));
      } catch (e) {
        setClusterResults(prevState => ({ ...prevState, isLoading: false, error: true }));
      }
    };
    if (!profile.isProfileLoading) {
      load();
    }
  }, [profile.isProfileLoading, profile.selectedSkills]);

  const { careerAreas: careerView, education: eduView, jobs: jobsView } = currentViews;

  // useMemo used to avoid an infinite loop caused by passing dependency values to useResultsPagination
  // The memoized value is recomputed only when a dependency changes
  const searchedSites = useMemo(() => {
    const validSites = eduView.filterSites.filter(filterSite =>
      Object.keys(site.institutions).includes(filterSite)
    );
    return validSites.length ? validSites : Object.keys(site.institutions);
  }, [eduView.filterSites, site.institutions]);

  const eduOptions = {
    filterSkills: eduView.filterSkills,
    isProfileLoading: profile.isProfileLoading,
    selectedSkills: profile.selectedSkills,
    validSkills,
    edType: eduView.filterEdType,
    educationLevels: eduView.filterLevels,
    programIds: eduView.filterProgramIds,
    searchedSites,
    // Used to update results when navigating between PLC to Education page
    location
  };

  // Remove this if we ever get rid of plc
  if (location.pathname === '/results/plc') {
    eduOptions.edType = 'Courses';
  }

  const [educationResults, loadMoreEducation] = useResultsPagination(eduView, eduOptions);

  const [jobResults, loadMoreJobs] = useResultsPagination('jobs', {
    filterSkills: jobsView.filterSkills,
    companyIds: jobsView.filterCompanyIds.length
      ? jobsView.filterCompanyIds
      : site.jobPostingOrgIds,
    isProfileLoading: profile.isProfileLoading,
    selectedSkills: profile.selectedSkills,
    validSkills,
    cities: jobsView.filterCities,
    // Used to update results when navigating between Internships to Jobs page
    location
  });

  const navOptions = [
    {
      component: EducationResults,
      path: '/results/education',
      props: {
        clearFilters: () =>
          updateView('education', {
            ...currentViewsInitialState.education,
            filterEdType: site.defaultEduType
          }),
        edType: currentViews.education.filterEdType,
        selectedSkills: profile.selectedSkills,
        institutions: site.institutions,
        educationResults,
        setMobileSidebarOpen,
        loadMoreEducation
      },
      title: 'Education'
    },
    {
      component: PriorLearningCreditResults,
      path: '/results/plc',
      props: {
        clearFilters: () =>
          updateView('education', {
            ...currentViewsInitialState.education,
            filterEdType: site.defaultEduType
          }),
        edType: 'Courses',
        selectedSkills: profile.selectedSkills,
        institutions: site.institutions,
        educationResults,
        plcMenuLabel: site.plcMenuLabel,
        plcTitle: site.plcTitle,
        plcDescription: site.plcDescription,
        plcLinks: site.plcLinks,
        setMobileSidebarOpen,
        loadMoreEducation
      },
      title: 'Prior Learning Credit'
    },
    {
      component: JobResults,
      path: '/results/jobs',
      props: {
        clearFilters: () =>
          updateView('jobs', {
            ...currentViewsInitialState.jobs,
            filterCities: [site.defaultCity]
          }),
        jobResults,
        selectedSkills: profile.selectedSkills,
        setMobileSidebarOpen,
        loadMoreJobs
      },
      title: 'Jobs'
    },
    {
      component: InternshipResults,
      path: '/results/internships',
      props: {
        clearFilters: () =>
          updateView('jobs', {
            ...currentViewsInitialState.jobs,
            filterCities: [site.defaultCity]
          }),
        jobResults,
        selectedSkills: profile.selectedSkills,
        setMobileSidebarOpen,
        loadMoreJobs
      },
      title: 'Jobs'
    },
    {
      component: ClusterResults,
      path: '/results/career-areas',
      props: {
        clearFilters: () => updateView('careerAreas', currentViewsInitialState.careerAreas),
        filterSkills: careerView.filterSkills,
        clusterResults,
        selectedSkills: profile.selectedSkills,
        isProfileLoading: profile.isProfileLoading,
        validSkills,
        setMobileSidebarOpen
      },
      title: 'Career Areas'
    }
  ];

  if (location.pathname === '/results') {
    let goalPathRedirect = '/career-areas';
    if (profile.selectedGoal === 'Find a job') {
      goalPathRedirect = '/jobs';
    } else if (profile.selectedGoal === 'Learn & Explore') {
      goalPathRedirect = '/education';
    }
    return <Redirect to={`/results${goalPathRedirect}`} />;
  }

  const currentPath = navOptions.find(option => location.pathname.startsWith(option.path));

  // If current path is not valid, redirect back to /results
  if (!currentPath) {
    return <Redirect to="/results" />;
  }

  // If the current path is only semi-valid, e.g./results/jobs/not-found, we will redirect back to /results/jobs
  if (currentPath && location.pathname !== currentPath.path) {
    return <Redirect to={currentPath.path} />;
  }

  let section = camelCase(currentPath.title);

  // This ensures that we use the same currentView as Education, since Prior Learning Credit is not a real view in the rest of the tool
  // Remove this if we ever get rid of plc
  let isPlc = location.pathname === '/results/plc';
  if (isPlc) {
    section = 'education';
  }
  // Remove this if we ever get rid of internships
  if (location.pathname === '/results/internships') {
    section = 'jobs';
  }

  let selectedFilterCount = Object.values(currentViews[section]).filter(filter => filter.length)
    .length;

  if (section === 'education' && site.hideEdType) {
    selectedFilterCount--;
  }

  return (
    <Wrapper>
      {!profile.isLoggedIn && Object.keys(profile.selectedSkills).length ? (
        <FloatingTooltip
          hasSignUpPromptClosed={profile.hasSignUpPromptClosed}
          closeSignUpPrompt={closeSignUpPrompt}
          helpText="Sign up to save your skills for next time!"
        />
      ) : null}
      <GlobalStyle />
      <ResultsNav />
      <Flex>
        <ResultsSidebar
          type={currentPath.title}
          mobileOpen={mobileSidebarOpen}
          selectedFilterCount={selectedFilterCount}
          setMobileOpen={setMobileSidebarOpen}
        >
          {currentPath.title === 'Career Areas' && <SavedViews section={section} />}
          {currentPath.title === 'Education' && (
            <>
              <SavedViews section={section} />
              {!site.hideEdType && (
                <CoursesProgramsToggle
                  edType={currentViews.education.filterEdType}
                  setEdType={eduType => updateView('education', { filterEdType: eduType })}
                />
              )}

              {Object.keys(site.institutions).length > 1 && (
                <CheckboxFilter
                  section={section}
                  title="Institutions"
                  selectedItemIds={eduView.filterSites}
                  items={Object.entries(site.institutions).map(i => ({ id: i[0], name: i[1] }))}
                  onChange={updates => updateView('education', { filterSites: updates })}
                  // key allows the component to remount based on the activeViewId
                  // The main reason for remounting is to re-sort itemList in CheckboxFilter
                  key={`filterSites-${profile.activeViewId}`}
                />
              )}
              <CheckboxFilter
                section={section}
                isHidden={eduView.filterEdType === 'Courses'}
                title="Education Levels"
                selectedItemIds={eduView.filterLevels}
                items={Object.values(site.groupTypeClasses)}
                onChange={updates => updateView('education', { filterLevels: updates })}
                // key allows the component to remount based on the activeViewId
                // The main reason for remounting is to re-sort itemList in CheckboxFilter
                key={`filterLevels-${profile.activeViewId}`}
              />
              {eduView.filterEdType === 'Courses' && (
                <ProgramFilter
                  view={eduView}
                  updateView={updates => updateView('education', updates)}
                  sites={Object.keys(site.institutions)}
                />
              )}
            </>
          )}
          {isPlc && (
            <>
              {Object.keys(site.institutions).length > 1 && (
                <CheckboxFilter
                  section={section}
                  title="Institutions"
                  selectedItemIds={eduView.filterSites}
                  items={Object.entries(site.institutions).map(i => ({ id: i[0], name: i[1] }))}
                  onChange={updates => updateView('education', { filterSites: updates })}
                />
              )}
              <ProgramFilter
                view={eduView}
                updateView={updates => updateView('education', updates)}
                sites={Object.keys(site.institutions)}
              />
            </>
          )}
          {currentPath.title === 'Jobs' && (
            <>
              {currentPath.path === '/results/jobs' && <SavedViews section={section} />}
              <LocationAutocomplete />
              {/* The CompanyFilter will only display when there are 0 or more than 1 jobPostingOrgIds.
                In the case of 1 jobPostingOrgId, the results will display job postings for that 1 company.
              */
              site.jobPostingOrgIds.length !== 1 && (
                <CompanyFilter
                  view={jobsView}
                  updateView={updates => updateView('jobs', updates)}
                  jobPostingOrgIds={site.jobPostingOrgIds}
                />
              )}
            </>
          )}
          <SkillFilter
            view={currentViews[section]}
            validSkills={validSkills}
            updateView={updates => updateView(section, updates)}
          />
        </ResultsSidebar>
        <ResultsWrapper>
          <Switch>
            {navOptions.map(nav => (
              <Route
                key={`nav-${nav.path}`}
                path={nav.path}
                render={() => <nav.component {...nav.props} />}
              />
            ))}
          </Switch>
        </ResultsWrapper>
      </Flex>
    </Wrapper>
  );
};

const StringArray = PropTypes.arrayOf(PropTypes.string).isRequired;

Results.propTypes = {
  closeSignUpPrompt: PropTypes.func.isRequired,
  currentViews: PropTypes.shape({
    careerAreas: PropTypes.shape({ filterSkills: StringArray }).isRequired,
    education: PropTypes.shape({
      filterSkills: StringArray,
      filterSites: StringArray,
      filterLevels: StringArray,
      filterEdType: PropTypes.string.isRequired,
      filterProgramIds: StringArray
    }).isRequired,
    jobs: PropTypes.shape({
      filterSkills: StringArray,
      filterCompanyIds: StringArray,
      filterCities: StringArray
    }).isRequired
  }).isRequired,
  updateView: PropTypes.func.isRequired,
  validSkills: PropTypes.object,
  fetchValidSkills: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired, // from react-router
  site: PropTypes.shape({
    institutions: PropTypes.objectOf(PropTypes.string).isRequired,
    groupTypeClasses: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    defaultCity: PropTypes.string.isRequired,
    defaultEduType: PropTypes.string.isRequired,
    hideEdType: PropTypes.bool,
    plcMenuLabel: PropTypes.string,
    plcTitle: PropTypes.string,
    plcDescription: PropTypes.string,
    plcLinks: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ),
    jobPostingOrgIds: PropTypes.arrayOf(PropTypes.string).isRequired
  }),
  profile: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
    hasSignUpPromptClosed: PropTypes.bool.isRequired,
    isProfileLoading: PropTypes.bool.isRequired,
    selectedGoal: PropTypes.string,
    selectedSkills: PropTypes.objectOf(PropTypes.string).isRequired,
    subdomain: PropTypes.string,
    activeViewId: PropTypes.string
  })
};
export default withFetchValidSkills(Results);
