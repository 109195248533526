const {
  REACT_APP_ALGOLIA_PROJECTID,
  REACT_APP_ALGOLIA_KEY,
  REACT_APP_KEEN_PROJECTID,
  REACT_APP_KEEN_WRITEKEY,
  REACT_APP_KEEN_READKEY,
  REACT_APP_KEEN_NEW_HORIZONS_PROJECTID,
  REACT_APP_KEEN_NEW_HORIZONS_WRITEKEY,
  REACT_APP_KEEN_PURDUE_GLOBAL_PROJECTID,
  REACT_APP_KEEN_PURDUE_GLOBAL_WRITEKEY,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_OPTIMIZELY_SDK_KEY,
  REACT_APP_SKILLABI_SITES_API_URL,
  REACT_APP_EMSI_SERVICES_URL
} = process.env;

const config = {
  modelVersion: 'national.2021.6_v7.44',
  ukModelVersion: 'uk_national.2020.10_v7.22',
  algoliaProjectId: REACT_APP_ALGOLIA_PROJECTID,
  algoliaKey: REACT_APP_ALGOLIA_KEY,
  skillabiSitesUrl: REACT_APP_SKILLABI_SITES_API_URL,
  emsiServicesUrl: REACT_APP_EMSI_SERVICES_URL,
  keen: {
    projectId: REACT_APP_KEEN_PROJECTID,
    writeKey: REACT_APP_KEEN_WRITEKEY,
    readKey: REACT_APP_KEEN_READKEY
  },
  keenNewHorizons: {
    projectId: REACT_APP_KEEN_NEW_HORIZONS_PROJECTID,
    writeKey: REACT_APP_KEEN_NEW_HORIZONS_WRITEKEY
  },
  keenPurdueGlobal: {
    projectId: REACT_APP_KEEN_PURDUE_GLOBAL_PROJECTID,
    writeKey: REACT_APP_KEEN_PURDUE_GLOBAL_WRITEKEY
  },
  awsCognitoAuth: {
    region: 'us-west-2',
    userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
  },
  optimizelySDKKey: REACT_APP_OPTIMIZELY_SDK_KEY
};

export default config;
