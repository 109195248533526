import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SearchAndFilterInput from 'components/molecules/SearchAndFilterInput';

import { searchCompanies, searchCompaniesbyId } from 'services/jpa';
import withKeen from 'hocs/withKeen';

const CompanyFilter = ({ view, updateView, trackEvent, jobPostingOrgIds }) => {
  const { companyNames, filterCompanyIds } = view;
  // allowedCompanies stores the companies that are indicated by jobPostingOrgIds
  const [allowedCompanies, setAllowedCompanies] = useState([]);

  useEffect(async () => {
    if (jobPostingOrgIds.length > 1) {
      const resp = await searchCompaniesbyId(jobPostingOrgIds);
      setAllowedCompanies(resp.data);
    }
  }, []);

  const makeSelection = suggestion => {
    trackEvent('Company Searched', {
      companyId: suggestion.id
    });
    updateView({
      companyNames: { [suggestion.id]: suggestion.name, ...companyNames },
      filterCompanyIds: [suggestion.id, ...filterCompanyIds]
    });
  };

  const removeSuggestion = async companyId => {
    const newFilterCompanyIds = filterCompanyIds.filter(itemId => itemId !== companyId);
    const newCompanyNames = { ...companyNames };
    delete newCompanyNames[companyId];
    updateView({
      filterCompanyIds: newFilterCompanyIds,
      companyNames: newCompanyNames
    });
    return newCompanyNames;
  };

  const fetchRequested = async e => {
    if (jobPostingOrgIds.length > 1) {
      return allowedCompanies.filter(company =>
        company.name.toLowerCase().startsWith(e.value.toLowerCase())
      );
    } else {
      const resp = e.value ? await searchCompanies(e.value) : [];
      return resp.data ? resp.data : [];
    }
  };

  return (
    <SearchAndFilterInput
      type="Company"
      removeSuggestion={removeSuggestion}
      makeSelection={makeSelection}
      fetchRequested={fetchRequested}
      pillData={Object.entries(companyNames)}
    />
  );
};

export default withKeen(CompanyFilter);

CompanyFilter.propTypes = {
  view: PropTypes.shape({
    filterCompanyIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    companyNames: PropTypes.objectOf(PropTypes.string).isRequired
  }).isRequired,
  updateView: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  jobPostingOrgIds: PropTypes.arrayOf(PropTypes.string).isRequired
};
