import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Button';
import overviewData from 'Admin/helpers/ExcelWorkbookHelpers/overviewData';
import skillsData from 'Admin/helpers/ExcelWorkbookHelpers/skillsData';
import educationData from 'Admin/helpers/ExcelWorkbookHelpers/educationData';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const ExcelSiteWorkbook = ({ profiles, skills, subdomain }) => {
  const today = new Date().toISOString().slice(0, 10);
  const sortedProfiles = profiles.sort((a, b) => (a.email < b.email ? -1 : 1));
  const overviewInformation = overviewData(sortedProfiles, skills);
  const educationInformation = educationData(sortedProfiles);
  const skillsInformation = skillsData(sortedProfiles, skills);

  return (
    <ExcelFile
      element={<Button inverseColor>Export</Button>}
      filename={`${today} ${subdomain} SkillsMatch Profiles Export`}
    >
      <ExcelSheet
        dataSet={[{ columns: overviewInformation.columns, data: overviewInformation.data }]}
        name="Overview"
      />
      <ExcelSheet
        dataSet={[{ columns: educationInformation.columns, data: educationInformation.data }]}
        name="Education"
      />
      <ExcelSheet
        dataSet={[{ columns: skillsInformation.haveColumns, data: skillsInformation.haveData }]}
        name="Have Skills"
      />
      <ExcelSheet
        dataSet={[{ columns: skillsInformation.wantColumns, data: skillsInformation.wantData }]}
        name="Want Skills"
      />
      <ExcelSheet name="Explanation" data={[]}>
        <ExcelColumn label="This is a snapshot of the data as of" />
        <ExcelColumn label={`${today}`} />
        <ExcelColumn
          label="The Profiles Export excel file consists of 4 tabs.
            The first “Overview” tab lists information of all users who have signed up an account on your institution’s Skills Match website, if there are any.
            Each user might also have a list of education history, and a list of skills they have selected as “Have” or “Want”.
            Because there is not enough space in the first tab, we have created 3 more tabs (“Education”, “Have Skills” and “Want Skills”) to show all user selections.
            You can get all information about one user by matching up their email across these tabs, and combining the information."
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

ExcelSiteWorkbook.propTypes = {
  profiles: PropTypes.array,
  skills: PropTypes.array,
  subdomain: PropTypes.string
};

export default ExcelSiteWorkbook;
