import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from 'components/atoms/Card';
import Modal from 'components/atoms/Modal';
import { grayLight } from 'helpers/colors';

const StyledCard = styled(Card)`
  padding: 3rem 1.5rem;
  @media (min-width: 800px) {
    width: 62rem;
    max-height: 62rem;
    padding: 3rem;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const ModalHeader = styled.h1`
  font-size: 2.8rem;
  margin: 0 0 0 0.5rem;
  font-weight: normal;
  @media (max-width: 799px) {
    font-size: 2.2rem;
  }
`;

const SkillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  overflow: auto;
`;

const Pill = styled.span`
  max-width: 100%;
  background: ${grayLight};
  padding: 0.2rem 1rem;
  margin: ${props => (props.rounded ? ' 0 0 0 1rem' : '0.5rem')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: ${props => (props.rounded ? '1.3rem' : '0.5rem')};
  font-size: ${props => (props.rounded ? '1.2rem' : '1.4rem')};
`;

const SkillsModal = ({ type, skills, closeModal, isModalOpen }) => {
  return (
    <Modal isOpen={isModalOpen} close={closeModal}>
      <StyledCard>
        <TopSection>
          <ModalHeader>{type === 'All' ? 'All Skills Offered' : `Skills You ${type}`}</ModalHeader>
          <Pill rounded>{skills.length}</Pill>
        </TopSection>

        {skills.length && (
          <SkillsList>
            {skills.map(skill => (
              <Pill key={skill.id}>{skill.name}</Pill>
            ))}
          </SkillsList>
        )}
      </StyledCard>
    </Modal>
  );
};

SkillsModal.propTypes = {
  type: PropTypes.oneOf(['Want', 'Have', 'All']).isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired })
  ).isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired
};

export default SkillsModal;
