import { getClustersBySkills, getAllClusters } from 'services/skillsClusters';
import { getClustersMeta } from 'services/clustersMeta';

const getClusterResults = async selectedSkills => {
  let [matchingClusters, otherClusters] = await Promise.all([
    getClustersBySkills(Object.keys(selectedSkills)),
    getAllClusters()
  ]);

  otherClusters = otherClusters
    // Randomly shuffle other clusters, so not all neighboring cards appear the same
    .sort(() => 0.5 - Math.random())
    // Filter out matchingClusters from otherClusters
    .filter(c1 => matchingClusters.every(c2 => c1.id !== c2.id));

  // Restructure clusters in otherClusters for consistency with matchingClusters
  otherClusters = otherClusters.map(({ skillids, subgroup, ...rest }) => ({
    primarySkills: skillids.map(id => ({ id })),
    subgroup_id: subgroup,
    ...rest
  }));

  // Combine cluster data from Skills Clusters and Clusters Meta API
  const clustersMeta = await getClustersMeta();
  matchingClusters = matchingClusters.map(cluster => ({
    ...clustersMeta.find(item => item.clusterId === cluster.id),
    ...cluster
  }));
  otherClusters = otherClusters.map(cluster => ({
    ...clustersMeta.find(item => item.clusterId === cluster.id),
    ...cluster
  }));
  return [matchingClusters, otherClusters];
};

export default getClusterResults;
