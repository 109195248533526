import React from 'react';
import styled from 'styled-components';

import AriaAlert from 'components/atoms/AriaAlert';
import Link from 'components/atoms/Link';
import SvgIcon from 'components/atoms/SvgIcon';
import SkillsChooser from 'components/molecules/SkillsChooser';
import TitledCard from 'components/organisms/TitledCard';

import { ReactComponent as EducationIcon } from 'images/education.svg';
import { getSkillsByCluster } from '../../../services/skillsClusters';
import { getClustersByEducation } from '../../../services/clustersMeta';
import { blue, red } from 'helpers/colors';
import sortItemsInArrays from '../../../helpers/sortItemsInArrays';

import { ReactComponent as PencilIcon } from 'images/pencil.svg';

const StyledSvg = styled(SvgIcon)`
  height: auto;
  width: 1.4rem;
  margin-right: 0.5rem;
  fill: ${blue};
`;

const StyledLink = styled(Link)`
  height: 1.7rem;
  font-size: 1.4rem;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  :hover {
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid ${blue};
  }
`;

const Icon = styled(SvgIcon)`
  width: 1.5rem;
  margin-right: 1rem;
`;

const ErrorMessage = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${red};
`;

const StyledDiv = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const EditLink = () => {
  return (
    <StyledDiv>
      <StyledLink to="/degrees">
        <StyledSvg Svg={PencilIcon} />
        Edit Education
      </StyledLink>
    </StyledDiv>
  );
};

class SkillsByEducation extends React.Component {
  state = {
    skills: [],
    areSkillsLoading: false,
    errorMessage: ''
  };

  componentDidMount() {
    this.getSkills();
  }

  async getSkills() {
    let { education } = this.props;
    // High School doesn't get matches, so we only try call API if there is at least one non-High-School education
    education = education.filter(item => !item.level.label.includes('High School'));
    if (education && education.length) {
      this.setState({ areSkillsLoading: true });
      let clusters;
      clusters = await getClustersByEducation(
        education.map(ed => `${ed.level.id.toString()}|${ed.area.pl3}`)
      );
      const validClusters = clusters
        .filter(cluster => cluster.probability > 0.05)
        .sort((a, b) => a.probability < b.probability);
      if (validClusters && validClusters.length) {
        const clusterPromises = validClusters.map(cluster => getSkillsByCluster(cluster.clusterId));
        const skillsByCluster = await Promise.all(clusterPromises);
        const skills = sortItemsInArrays(skillsByCluster).map(skill => skill.id);
        this.setState({ skills, areSkillsLoading: false });
      } else {
        this.setState({
          areSkillsLoading: false
        });
      }
    }
  }

  render() {
    const { skills, areSkillsLoading, errorMessage } = this.state;
    const { education } = this.props;
    return (
      <TitledCard
        helpText="Select skills based on the education you provided in the 'My Education' section."
        icon={<Icon Svg={EducationIcon} />}
        isEducation
        title="Skills By Education"
        titleLink={<EditLink />}
      >
        <AriaAlert>
          {!areSkillsLoading && skills.length > 0 && 'Tab to select skills matching your search.'}
        </AriaAlert>
        {errorMessage && <ErrorMessage aria-live="polite">{errorMessage}</ErrorMessage>}
        <SkillsChooser
          skillIds={skills}
          isLoading={areSkillsLoading}
          emptyText="Message coming soon to a PR near you."
        >
          {!skills.length &&
            (!education.length ? (
              <>
                No education specified. Please <Link to="/degrees">add your education</Link> and try
                again.
              </>
            ) : (
              <>
                No skill suggestions found for your education. Please try{' '}
                <Link to="/skills">another method for adding skills</Link>.
              </>
            ))}
        </SkillsChooser>
      </TitledCard>
    );
  }
}

export default SkillsByEducation;
