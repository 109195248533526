import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';

const Heading = styled.h1`
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
`;

const Text = styled.p`
  margin-bottom: 2.7rem;
  margin-top: 1.8rem;
  a {
    font-size: 1.4rem;
  }
`;

const StyledButton = styled(Button)`
  padding: 1.2rem 4.6rem;
`;

const ViewAuthContents = ({ close }) => (
  <>
    <Heading>
      Please <Link to="/login">login</Link> or <Link to="/signup">signup</Link> to save filters.
    </Heading>
    <Text>
      You need to <Link to="/login">login</Link> or <Link to="/signup">signup</Link> before you can
      save filters.
    </Text>
    <StyledButton onClick={close}>Return to Page</StyledButton>
  </>
);

ViewAuthContents.propTypes = {
  close: PropTypes.func.isRequired
};

export default ViewAuthContents;
