import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from 'components/atoms/Card';
import Modal from 'components/atoms/Modal';
import ExternalLink from 'components/atoms/ExternalLink';
import VerticalScrollGradient from 'components/atoms/VerticalScrollGradient';
import AdminToolTip from 'Admin/components/AdminToolTip';

import { grayMedium } from 'helpers/colors';

const StyledCard = styled(Card)`
  padding: 3rem 1.5rem;
  @media (min-width: 800px) {
    padding: 3rem;
    width: 45rem;
  }
`;

const TopSection = styled.div`
  padding: 0 1rem 1.5rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  margin: 0.5rem 0;
`;

const SubTitleSection = styled.h2`
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0 0 1rem 0;
`;

const SubTitleText = styled.span`
  font-weight: 500;
`;

const StyledHr = styled.hr`
  width: 100%;
  margin-top: 2rem;
  border: solid 1px ${grayMedium};
`;

const List = styled.ol`
  padding: 0;
  margin: 0;
`;

const ListItemWrapper = styled.li`
  display: flex;
  font-size: 1.4rem;
  padding: 0 1rem;
`;

const StyledToolTipChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ListItem = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.3rem;
`;

const ItemResult = styled.div`
  margin-left: 1rem;
`;

const StyledLink = styled(ExternalLink)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.3rem;
`;

const KeenListDataModal = ({ title, subtitle, listData, formatText, isOpen, close }) => {
  return (
    <Modal isOpen={isOpen} close={close}>
      <StyledCard>
        <TopSection>
          <Title>{title}</Title>
          {subtitle && (
            <SubTitleSection>
              Showing <SubTitleText>{subtitle}</SubTitleText>
            </SubTitleSection>
          )}
          <StyledHr />
        </TopSection>
        <VerticalScrollGradient takesFullHeight gradientColor="#FFFFFF" maxHeight="40rem">
          <List>
            {listData.map((item, i) => (
              <ListItemWrapper key={`${formatText(item)} - ${i}`}>
                {/* The first two items using the AdminToolTip, noted by index, will
                "appearBelow" an item so as to not crop or get cutoff by parent containers.
                The rest will appear above the item. */}
                <AdminToolTip
                  hideToolTip={formatText(item).length <= 49}
                  appearBelow={i <= 1}
                  text={formatText(item)}
                >
                  <StyledToolTipChildren>
                    {item.url ? (
                      <StyledLink url={item.url}>{formatText(item)}</StyledLink>
                    ) : (
                      <ListItem>{formatText(item)}</ListItem>
                    )}
                    <ItemResult>{item.result}</ItemResult>
                  </StyledToolTipChildren>
                </AdminToolTip>
              </ListItemWrapper>
            ))}
          </List>
        </VerticalScrollGradient>
      </StyledCard>
    </Modal>
  );
};

KeenListDataModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      result: PropTypes.number.isRequired,
      url: PropTypes.string,
      skillName: PropTypes.string,
      skillId: PropTypes.string
    })
  ).isRequired,
  formatText: PropTypes.func.isRequired
};

export default KeenListDataModal;
