import React from 'react';
import styled from 'styled-components';

import SvgIcon from 'components/atoms/SvgIcon';
import SkillNameWrapper from 'components/molecules/SkillNameWrapper';
import TitledCard from 'components/organisms/TitledCard';
import { ReactComponent as SkillsIcon } from 'images/screwdriver-and-wrench.svg';

const Icon = styled(SvgIcon)`
  height: auto;
  width: 1.3rem;
  margin-right: 1rem;
  fill: black;
`;

const SkillsByName = () => {
  return (
    <TitledCard
      helpText="Select skills by entering a specific name or a broader keyword."
      icon={<Icon Svg={SkillsIcon} />}
      title="Skills By Name"
    >
      <SkillNameWrapper />
    </TitledCard>
  );
};

export default SkillsByName;
