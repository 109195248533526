import { connect } from 'react-redux';
import SidebarNav from 'components/organisms/SidebarNav';
import { profileActions } from 'store/reducers/profile';

const { logout } = profileActions;

export const mapStateToProps = ({ profile, site }) => ({
  isLoggedIn: profile.isLoggedIn,
  userRole: profile.userRole,
  plcMenuLabel: site.data.plcMenuLabel,
  isPlcEnabled: site.data.isPlcEnabled,
  menuBackgroundColor: site.data.menuBackgroundColor
});

export default connect(mapStateToProps, { logout })(SidebarNav);
