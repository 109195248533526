import fetchWithRetry from './helpers/fetchWithRetry';

const headers = {
  'Content-Type': 'application/json'
};

/**
 * Get Job Titles from the Analyst Aggregate Profiles Data API.
 *
 * @param   {string}  query  The query string to be sent to the API.
 * @return  {Array}   An array of job titles matching the query (limited to 50).
 */
export const getJobTitles = async query => {
  const response = await fetchWithRetry(
    `/api/emsi-services/${
      window.nation === 'UK' ? 'uk-' : ''
    }profiles/taxonomies/title?q=${query}&limit=50`
  );

  const data = await response.json();
  if (!response.ok || !data.data) {
    throw Error(data.message || 'Unknown error requesting job titles');
  }
  // UK data does not have singular_name, so we look for item.name first instead. US still uses singular_name
  return data.data.map(item => ({ name: item.name || item.properties.singular_name, id: item.id }));
};

/**
 * Get a list of skill IDs from the Analyst Aggregate Profiles Data API related
 * to  provided Job ID.
 *
 * @param   {string}  jobId  A Skill ID (string).
 * @return  {Array}   An array of skill IDs.
 */
export const getSkillsForJob = async jobId => {
  // Get Skill IDs with job ID
  const body = {
    filter: {
      title: [jobId]
    },
    rank: {
      by: 'profiles',
      limit: 60,
      min_profiles: 1
    }
  };
  const response = await fetchWithRetry(
    `/api/emsi-services/${window.nation === 'UK' ? 'uk-' : ''}profiles/rankings/skills`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    }
  );
  let data = await response.json();
  if (!response.ok || !data.data) {
    throw Error(data.message || 'Error requesting skills for jobId.');
  }

  return data.data.ranking.buckets.map(({ name }) => name);
};

export const getSkillsByTitle = async query => {
  const response = await fetchWithRetry(
    `/api/emsi-services/${
      window.nation === 'UK' ? 'uk-' : ''
    }profiles/taxonomies/skills?q=${query}&limit=100`
  );
  let data = await response.json();
  if (!data.data) {
    if (data.message) {
      throw data.message;
    }
    throw new Error('Error requesting skills by title.');
  }

  return data.data;
};
