import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/atoms/Button';

const Wrapper = styled.div`
  width: 95%;
`;

const Heading = styled.h1`
  font-size: 1.6rem;
  margin: 0;
  overflow-wrap: break-word;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  @media (max-width: 599px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  width: 50%;
  font-size: 1.4rem;
  margin: 0 2rem;
  @media (max-width: 599px) {
    width: 100%;
    margin: 0 0 1rem 0;
  }
`;

const ViewOptionsContents = ({ close, selectedFilterObj, confirmationFunc, optionType }) => {
  return (
    selectedFilterObj && (
      <Wrapper>
        <Heading>
          {optionType} &quot;{selectedFilterObj.name}&quot;?
        </Heading>
        <ButtonWrapper>
          <StyledButton onClick={close} variant="secondary">
            Cancel
          </StyledButton>
          <StyledButton
            onClick={() => {
              confirmationFunc(selectedFilterObj);
              close();
            }}
            variant={`${optionType === 'Overwrite' ? 'primary' : 'danger'}`}
          >
            {optionType}
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    )
  );
};

ViewOptionsContents.propTypes = {
  close: PropTypes.func.isRequired,
  selectedFilterObj: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  confirmationFunc: PropTypes.func.isRequired,
  optionType: PropTypes.string.isRequired
};

export default ViewOptionsContents;
