import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DirectionalCaret from 'components/atoms/DirectionalCaret';
import Checkbox from 'components/atoms/Checkbox';

import { blue, grayLight } from 'helpers/colors.js';

const Wrapper = styled.div`
  background-color: white;
  border: 1px solid ${grayLight};
  border-radius: 0.5rem;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  padding: 2rem 1.5rem;
  position: relative;
  margin-bottom: 3rem;
`;

const Label = styled.label`
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
`;

const SelectAllBtn = styled.button`
  background: none;
  border: none;
  color: ${blue};
  cursor: pointer;
  font-size: 1.4rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
`;

const Count = styled.span`
  font-weight: 400;
  padding-left: 0.3rem;
`;

const StyledCheckbox = styled(Checkbox)`
  :not(:last-child) {
    margin-bottom: 1rem;
  }
  label {
    ${({ isChecked }) => isChecked && 'font-weight: 600'}
  }
  margin-top: 0.5rem;
`;

const ShowAllBtn = styled.button`
  color: ${blue};
  border: none;
  background-color: white;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  cursor: pointer;
`;

const StyledCaret = styled(DirectionalCaret)`
  margin-left: 1rem;
`;

const CheckboxFilter = ({ section, title, isHidden, selectedItemIds, items, onChange }) => {
  const [showAll, setShowAll] = useState(false);

  const computeSelectedItemIds = itemId => {
    if (!selectedItemIds.includes(itemId)) {
      selectedItemIds = [...selectedItemIds, itemId];
    } else {
      selectedItemIds = selectedItemIds.filter(item => item !== itemId);
    }
    return selectedItemIds;
  };

  const allSelected = selectedItemIds.length === items.length;

  // itemList is the same list as items, re-sorted into the order they will be displayed.
  // When showAll is false, then checked checkboxes show up first
  const itemList = useMemo(() => {
    let iterableItems = [...items];
    if (!showAll) {
      const selectedItems = iterableItems.filter(item => selectedItemIds.includes(item.id));
      if (selectedItems.some(item => !iterableItems.slice(0, 4).includes(item))) {
        iterableItems.unshift(...selectedItems);      // Selected items are added to the beginning of iterableItems
        iterableItems = [...new Set(iterableItems)];  // Deduplication via Set(), then spread to convert to an array
      }
    }
    return iterableItems;
  }, [showAll]);

  let checkboxes = itemList.map(item => (
    <StyledCheckbox
      dataCy={`${title.replaceAll(' ', '-')}-checkbox-${item.name.replaceAll(' ', '-')}`}
      name={item.name}
      label={item.name}
      key={item.id}
      isChecked={selectedItemIds.includes(item.id)}
      onChange={() => onChange(computeSelectedItemIds(item.id))}
      ariaLabel={`Select to display ${section} results of ${item.name}`}
    />
  ));

  return (
    <Wrapper hidden={isHidden}>
      <Label aria-label={`${title}. There are ${items.length} total ${title}.`}>
        {title} <Count>({items.length})</Count>
      </Label>
      <SelectAllBtn
        onClick={() => (allSelected ? onChange([]) : onChange(items.map(i => i.id)))}
        aria-label={`${allSelected ? 'deselect all' : 'select all'} ${title}`}
      >
        {allSelected ? 'Deselect all' : 'Select all'}
      </SelectAllBtn>
      {showAll ? checkboxes : checkboxes.slice(0, 4)}
      {items.length > 4 && (
        <ShowAllBtn
          onClick={() => setShowAll(!showAll)}
          aria-label={showAll ? `hide ${title}` : `expand ${title}`}
        >
          {showAll ? 'Hide' : 'Show All'}
          <StyledCaret fillColor={blue} position={showAll ? 'up' : 'down'} />{' '}
        </ShowAllBtn>
      )}
    </Wrapper>
  );
};

CheckboxFilter.propTypes = {
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  selectedItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default CheckboxFilter;
