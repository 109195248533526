import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { grayLight } from 'helpers/colors';

const StyledButton = styled(({ component, ...props }) => React.createElement(component, props))`
  width: ${props => props.size};
  height: ${props => props.size};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid ${grayLight};
  border-radius: 50%;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.1);
  /* give shadow enough space to render */
  margin: 0 0.3rem;
  transition: all 1s ease-out;
  transition: box-shadow 0.1s, transform 0.1s ease-out;

  * {
    top: 0 !important;
    ${({ disabled }) => disabled && 'opacity: .5;'}
  }

  transform: scale(1) translate(0%, -20%);
  :active {
    ${props => !props.disabled && 'transform: scale(0.9) translate(0%, -20%);'}
  }
`;

const CircleButton = ({ disabled, children, size = '3rem', component = 'button', ...props }) => {
  return (
    <StyledButton
      size={size}
      component={component}
      disabled={disabled}
      aria-disabled={disabled}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

CircleButton.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  component: PropTypes.elementType,
  disabled: PropTypes.bool
};

export default CircleButton;
