import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import Loading from 'images/loading-white.gif';

const StyledButton = styled(Button)`
  background-image: url("${Loading}");
  background-size: 3.5rem;
  background-repeat: no-repeat;
  background-position: center;
`;

const VisibleText = styled.div`
  visibility: visible;
`;

const HiddenText = styled.div`
  visibility: hidden;
`;

const LoadingButton = ({ children, isLoading, ...rest }) => {
  return isLoading ? (
    <StyledButton disabled role="alert" aria-live="assertive" aria-label="Loading" {...rest}>
      <HiddenText>{children}</HiddenText>
    </StyledButton>
  ) : (
    <Button {...rest}>
      <VisibleText>{children}</VisibleText>
    </Button>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool
};

export default LoadingButton;
