import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';

import LoadingButton from 'components/molecules/LoadingButton';
import Checkbox from 'components/atoms/Checkbox';
import FormInput from 'components/atoms/FormInput';
import ExternalLink from 'components/atoms/ExternalLink';
import withKeen from 'hocs/withKeen';
import { red } from 'helpers/colors';
import { required, isEmail, isValidPassword, composeValidators } from 'helpers/validateForm';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Label = styled.label`
  font-size: 1.3rem;
  margin-left: 1.2rem;
  margin-bottom: 2.7rem;
  line-height: 1.7rem;
`;

const Error = styled.span`
  font-size: 1.4rem;
  color: ${red};
  margin-top: 1rem;
`;

const SignupForm = ({ signUp, isLoading, error, clearProfileError, trackEvent }) => {
  const [hasReadPrivacy, setPrivacy] = useState(false);

  // This effect clears the profile error state so that it does not persist into another form
  useEffect(() => () => clearProfileError(), [clearProfileError]);

  return (
    <Form
      onSubmit={values => {
        const data = {
          ...values,
          email: values.email.toLowerCase()
        };
        signUp(data);
        trackEvent('Attempted sign up');
      }}
      validate={values => {
        const errors = {};
        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = 'Passwords must match';
        }
        return errors;
      }}
    >
      {({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Field name="email" validate={composeValidators(required, isEmail)}>
            {({ input, meta }) => (
              <FormInput
                label="email"
                autoComplete="email"
                error={meta.error && meta.touched ? meta.error : ''}
                {...input}
              />
            )}
          </Field>
          <Field
            name="password"
            type="password"
            validate={composeValidators(required, isValidPassword)}
          >
            {({ input, meta }) => (
              <FormInput
                label="password"
                autoComplete="new-password"
                error={meta.error && meta.touched ? meta.error : ''}
                {...input}
              />
            )}
          </Field>
          <Field name="confirmPassword" type="password" validate={required}>
            {({ input, meta }) => {
              return (
                <FormInput
                  label="re-enter password"
                  autoComplete="new-password"
                  error={meta.error && meta.touched ? meta.error : ''}
                  {...input}
                />
              );
            }}
          </Field>
          <Checkbox
            ariaLabel="I acknowledge that I have read the privacy policy"
            isChecked={hasReadPrivacy}
            name="acknowledgement"
            onChange={() => {
              setPrivacy(!hasReadPrivacy);
            }}
            required
            labelComponent={
              <Label htmlFor="acknowledgement">
                I acknowledge that I have read the{' '}
                <ExternalLink url="https://www.lightcast.io/privacy-policy/">
                  Privacy Policy
                </ExternalLink>
              </Label>
            }
          />
          <LoadingButton disabled={!hasReadPrivacy} isLoading={isLoading}>
            Create Account
          </LoadingButton>
          {error && <Error aria-live="polite">{error}</Error>}
        </StyledForm>
      )}
    </Form>
  );
};

SignupForm.propTypes = {
  clearProfileError: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  signUp: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(withRouter(SignupForm));
