import { createAction, handleActions } from 'redux-actions';

export const FETCH_VALID_SKILLS = {
  REQUEST_ONCE: 'validSkills/FETCH_VALID_SKILLS.REQUEST_ONCE',
  FETCH_DATA: 'validSkills/FETCH_VALID_SKILLS.FETCH_DATA',
  SUCCESS: 'validSkills/FETCH_VALID_SKILLS.SUCCESS',
  FAILURE: 'validSkills/FETCH_VALID_SKILLS.FAILURE'
};

export const fetchValidSkillsActions = {
  requestOnce: createAction(FETCH_VALID_SKILLS.REQUEST_ONCE),
  fetchData: createAction(FETCH_VALID_SKILLS.FETCH_DATA),
  success: createAction(FETCH_VALID_SKILLS.SUCCESS),
  failure: createAction(FETCH_VALID_SKILLS.FAILURE)
};

const initialState = {
  data: {},
  isLoading: false,
  isLoaded: false,
  error: null
};

export default handleActions(
  {
    [FETCH_VALID_SKILLS.FETCH_DATA]: state => ({
      ...state,
      isLoading: true,
      isLoaded: false
    }),
    [FETCH_VALID_SKILLS.SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      error: null,
      isLoading: false,
      isLoaded: true
    }),
    [FETCH_VALID_SKILLS.FAILURE]: (state, action) => ({
      ...state,
      error: action.payload || 'Unknown error loading valid skills.',
      isLoading: false,
      isLoaded: false
    })
  },
  initialState
);
