import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgramCourseCard from 'containers/ProgramCourseCard';
import LoadMoreButton from 'components/molecules/LoadMoreButton';
import VisuallyHidden from 'components/atoms/VisuallyHidden';
import FlexCardContainer from 'components/molecules/FlexCardContainer';
import NoResultsFilterCard from 'components/molecules/NoResultsFilterCard';
import NoResultsSkillCard from 'components/molecules/NoResultsSkillCard';
import ExternalLink from 'components/atoms/ExternalLink';

import { blueText, blue } from 'helpers/colors';
import { ReactComponent as EmptyIcon } from 'images/empty-list.svg';
import loadingGif from 'images/loading.gif';

const MatchesHeader = styled.h2`
  font-size: 1.8rem;
  font-weight: normal;
  margin: 0 0 0 1rem;
`;

const Loader = styled.img`
  width: 5rem;
  margin: 6rem auto;
`;

// center-aligned messages for error and loading
const ErrorMessage = styled.div`
  align-items: center;
  color: ${blueText};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  > svg {
    margin-bottom: 1.5rem;
  }
`;

const PlcContentWrapper = styled.div`
  margin: 0 1rem 4.8rem 1rem;
`;

const PlcTitle = styled.h2`
  font-size: 1.8rem;
  margin: 0;
`;

const PlcDescription = styled.p`
  font-size: 1.4rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const LinkContainer = styled.div`
  display: flex;
  margin-top: 0.6rem;
  @media (min-width: 600px) and (max-width: 799px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 599px) {
    flex-direction: column;
    text-align: center;
  }
`;

const StyledLink = styled(ExternalLink)`
  display: flex;
  font-size: 1.4rem;
  border: 1px solid ${blue};
  border-radius: 0.4rem;
  margin: 1.5rem 1.5rem 0 0;
  padding: 1rem 1.5rem;
  text-decoration: none !important;
  @media (max-width: 799px) {
    margin-right: 0;
  }
  @media (max-width: 599px) {
    justify-content: center;
  }
`;

// This component is a blend of EducationResults, ResultsDisplay, and ResultsScreenReader.
// It's done this way so if we don't like PriorLearningCredits as an experiment, it's easy to
// remove without changing other components
const PriorLearningCreditResults = ({
  edType,
  educationResults,
  selectedSkills,
  setMobileSidebarOpen,
  loadMoreEducation,
  institutions,
  plcMenuLabel,
  plcTitle,
  plcDescription,
  plcLinks
}) => {
  const { matches, error, isLoading, isLoadingMore, endOfList } = educationResults;

  useEffect(() => {
    document.title = `Lightcast SkillsMatch - ${plcMenuLabel || 'Get Credit'}`;
  }, []);

  if (isLoading) {
    return <Loader src={loadingGif} alt="loading" />;
  }

  if (error) {
    return (
      <ErrorMessage aria-live="polite">
        <EmptyIcon />
        There was an error loading courses.
      </ErrorMessage>
    );
  }

  const matchedCards = matches
    // TODO: Remove filtering once we fix courses API returning dups
    .filter(
      (currentItem, currentIndex) =>
        // if currentItem is the first item with the same id, then keep it. Filter out the rest
        matches.findIndex(duplicate => duplicate.id === currentItem.id) === currentIndex
    )
    .map(edItem => (
      <ProgramCourseCard
        key={edItem.id}
        edItem={edItem}
        edType={edType}
        shouldShowInstitution={Object.keys(institutions).length > 1}
      />
    ));

  const hasSelectedSkills = !!Object.keys(selectedSkills).length;
  const noMatchesCard = hasSelectedSkills ? (
    <NoResultsFilterCard
      section="matched"
      contentName="Courses"
      openMobileSidebar={() => setMobileSidebarOpen(true)}
    />
  ) : (
    <NoResultsSkillCard />
  );

  return (
    <>
      {(plcTitle || plcDescription || (plcLinks && plcLinks.length > 0)) && (
        <PlcContentWrapper>
          {plcTitle && <PlcTitle>{plcTitle}</PlcTitle>}
          {plcDescription && <PlcDescription>{plcDescription}</PlcDescription>}
          {plcLinks && (
            <LinkContainer data-cy="plc-link-container">
              {plcLinks.map((link, index) => (
                <StyledLink url={link.url} key={index}>
                  {link.text}
                </StyledLink>
              ))}
            </LinkContainer>
          )}
        </PlcContentWrapper>
      )}
      <MatchesHeader>Courses you may be able to get credit for based on your skills</MatchesHeader>
      {matchedCards.length ? (
        <FlexCardContainer data-cy="matched-to-you">{matchedCards}</FlexCardContainer>
      ) : (
        noMatchesCard
      )}
      <VisuallyHidden>
        {hasSelectedSkills && (
          <div aria-atomic="true" aria-live="polite">
            {matchedCards.length
              ? `displaying ${matchedCards.length} Courses that match your skills`
              : 'all Courses matched to your skills have been filtered out'}
          </div>
        )}
      </VisuallyHidden>
      {matches.length >= 12 && !isLoading && !error && (
        <LoadMoreButton
          isLoading={isLoadingMore}
          loadMore={loadMoreEducation}
          endOfList={endOfList}
        />
      )}
    </>
  );
};

PriorLearningCreditResults.propTypes = {
  edType: PropTypes.oneOf(['Courses', 'Programs']).isRequired,
  educationResults: PropTypes.shape({
    matches: PropTypes.arrayOf(PropTypes.object).isRequired,
    others: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool.isRequired,
    isLoadingMore: PropTypes.bool.isRequired,
    endOfList: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  selectedSkills: PropTypes.objectOf(PropTypes.string).isRequired,
  institutions: PropTypes.objectOf(PropTypes.string).isRequired,
  setMobileSidebarOpen: PropTypes.func.isRequired,
  loadMoreEducation: PropTypes.func.isRequired,
  plcMenuLabel: PropTypes.string,
  plcTitle: PropTypes.string,
  plcDescription: PropTypes.string,
  plcLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  )
};

export default PriorLearningCreditResults;
