import camelCase from 'camelcase-keys-deep';
import fetchWithRetry from './helpers/fetchWithRetry';

let accessToken = null;
let expireTime = 0;

export const getSite = async subdomain => {
  const response = await fetchWithRetry(`/api/emsi-services/sm-sites/${subdomain}`);
  if (response.status === 404) {
    return;
  }
  if (!response.ok && response.status) {
    throw new Error('Error retrieving site from sites-api');
  }
  const site = await response.json();
  return camelCase(site.data.attributes);
};

const requestToken = async subdomain => {
  const response = await fetchWithRetry(`/api/emsi-services/sm-sites/token?subdomain=${subdomain}`);
  const json = await response.json();
  return json;
};

const refreshToken = async subdomain => {
  const response = await requestToken(subdomain);
  const { access_token, expires_in } = response;
  // subtract a 60 second buffer and multiply by 1000 to convert to ms
  const expires_in_ms = (expires_in - 60) * 1000;

  expireTime = Date.now() + expires_in_ms;
  accessToken = access_token;
};

export const getSiteToken = async subdomain => {
  if (!accessToken || Date.now() >= expireTime) {
    await refreshToken(subdomain);
  }

  return accessToken;
};
