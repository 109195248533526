import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ClickOutsideWrapper from 'components/atoms/ClickOutsideWrapper';
import DirectionalCaret from 'components/atoms/DirectionalCaret';
import { blue, blueText, grayLight, grayMedium } from 'helpers/colors.js';

const Wrapper = styled.div`
  position: relative;
`;

const DateRangePill = styled.button`
  display: flex;
  font-size: 1.4rem;
  cursor: pointer;
  border: 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 3rem;
  background-color: white;
  color: ${blue};
  width: 16rem;
  padding: 0.5rem 1.5rem;
`;

const DateRangeList = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 0.8rem;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  top: 3.5rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 30rem;
`;

const DateRangeItem = styled.button`
  cursor: pointer;
  font-size: 1.4rem;
  background-color: ${({ selected }) => (selected ? grayLight : 'transparent')};
  color: ${blueText};
  font-weight: ${({ selected }) => selected && 500};
  text-align: left;
  margin: 0.25rem 0;
  padding: 0.6rem 1rem;
  border: 0;
  border-radius: 0.4rem;
  :hover {
    background-color: ${grayLight};
  }
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;

const HiddenRangeItem = styled.button`
  position: fixed;
  height: 0;
  width: 0;
  opacity: 0;
`;

const StyledHr = styled.hr`
  border: 1px solid ${grayMedium};
  width: 100%;
`;

const DateRangePicker = ({ setActiveTimeFrame, activeTimeframe, options }) => {
  const [isDateOpen, setIsDateOpen] = useState(false);

  useEffect(() => {
    isDateOpen && document.getElementById('date-range-list').children[1].focus();
  }, [isDateOpen]);

  return (
    <Wrapper>
      <ClickOutsideWrapper clickOutsideHandler={() => setIsDateOpen(false)}>
        <DateRangePill
          onClick={() => setIsDateOpen(!isDateOpen)}
          aria-label={`Click to open the timeframe selectors for analytics. ${activeTimeframe.name} is currently selected`}
          aria-expanded={isDateOpen}
        >
          {activeTimeframe.name}
          <DirectionalCaret fillColor={blue} position={isDateOpen ? 'up' : 'down'} />
        </DateRangePill>
      </ClickOutsideWrapper>
      {isDateOpen && (
        <DateRangeList id="date-range-list" className="ignore-react-onclickoutside">
          <HiddenRangeItem onFocus={() => setIsDateOpen(false)} />
          {options.map(dateOption => {
            return (
              <Fragment key={dateOption.name}>
                <DateRangeItem
                  onClick={() => {
                    setActiveTimeFrame(dateOption);
                    setIsDateOpen(false);
                  }}
                  aria-label={
                    activeTimeframe.name === dateOption.name
                      ? `${dateOption.name} is your active timeframe.`
                      : `Select ${dateOption.name} as your current timeframe.`
                  }
                  selected={activeTimeframe.name === dateOption.name}
                >
                  {dateOption.name}
                </DateRangeItem>
                {dateOption.name === '12 Months' && <StyledHr />}
              </Fragment>
            );
          })}
          <HiddenRangeItem onFocus={() => setIsDateOpen(false)} />
        </DateRangeList>
      )}
    </Wrapper>
  );
};

const activeTimeFrameShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  timeFrame: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired,
  interval: PropTypes.string.isRequired
});

DateRangePicker.propTypes = {
  options: PropTypes.arrayOf(activeTimeFrameShape).isRequired,
  setActiveTimeFrame: PropTypes.func.isRequired,
  activeTimeframe: activeTimeFrameShape.isRequired
};

export default DateRangePicker;
