import React from 'react';
import styled from 'styled-components';

const Header = styled(({ component: Component, isCentered, children, ...rest }) => {
  return <Component {...rest}>{children}</Component>;
}).withConfig({ displayName: 'Header' })`
  font-size: 2.8rem;
  font-weight: normal;
  text-align: ${props => props.isCentered && 'center'};
  @media (max-width: 1023px) {
    text-align: center;
  }
`;

Header.defaultProps = {
  component: 'h1',
  isCentered: false
};

export default Header;
