import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from 'components/atoms/SvgIcon';
import { ReactComponent as RadioSelected } from 'images/radio-selected.svg';
import { ReactComponent as RadioUnselected } from 'images/radio-unselected.svg';

const Wrapper = styled.div`
  align-items: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  ${props => props.disabled && 'opacity:0.5; >svg { cursor:not-allowed } '};
`;

const StyledLabel = styled.label`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 1.4rem;
  margin-left: 1rem;
  user-select: none;
`;

const Radio = React.forwardRef(
  (
    { checked, onChange, className, handleUpArrow, handleDownArrow, disabled, label, ...props },
    ref
  ) => {
    return (
      <Wrapper
        className={className}
        onClick={() => !disabled && onChange()}
        tabIndex={checked ? 0 : -1}
        aria-checked={checked}
        aria-disabled={disabled}
        role="radio"
        disabled={disabled}
        ref={ref}
        onKeyDown={e => {
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            !disabled && onChange();
          }
          if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
            e.preventDefault();
            handleDownArrow();
          }
          if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
            e.preventDefault();
            handleUpArrow();
          }
        }}
        {...props}
      >
        <SvgIcon Svg={checked ? RadioSelected : RadioUnselected} />
        {label && <StyledLabel disabled={disabled}>{label}</StyledLabel>}
      </Wrapper>
    );
  }
);

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // The arrow handlers should be required but doing so throws a warning b/c of how RadioGroup is set up
  // https://github.com/facebook/react/issues/4494
  handleDownArrow: PropTypes.func,
  handleUpArrow: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

export default Radio;
