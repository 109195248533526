import fetchWithRetry from './helpers/fetchWithRetry';
import { getSiteToken } from './sites';
import getSubdomain from 'helpers/getSubdomain';
import config from 'config';

const URL = `${config.emsiServicesUrl}/curricular-skills`;
const subdomain = getSubdomain();
const getToken = () => getSiteToken(subdomain);

/** *********************** Curricular Skills API ****************************/

/**
 * Get courses that contain any of the skillScoreMappings skills, AND contain any of the filterSkills.
 * @param {Object} skillScoreMappings A JSON object with skillIds as keys, and their corresponding scores as values
 * @param {string[]} filterSkills An array of skillIds to further filter education by. If empty, no filter will be applied
 * @param {string[]} sites The skillabi sites of the current subdomain
 * @param {string[]} programIds An array of selected program IDs to filter courses by. If empty, no filter will be applied
 * @param {number} limit The number of results returned
 * @param {number} [offset=0] The number of results to skip in the DB
 * @return {Object[]} An array of course objects
 */
export const getCoursesBySkills = async (
  skillScoreMappings,
  filterSkills,
  sites,
  programIds,
  limit,
  offset = 0
) => {
  const response = await fetchWithRetry(
    `${URL}/courses-by-skills?sites=${sites.join(',')}&limit=${limit}&offset=${offset}`,
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          attributes: { skills: skillScoreMappings, filterSkills, filterGroups: programIds }
        }
      }),
      headers: {
        Authorization: `Bearer ${await getToken()}`
      }
    }
  );
  if (!response.ok) {
    throw new Error('Error retrieving courses from API.');
  }
  const json = await response.json();
  json.data = json.data.map(course => ({ ...course.attributes, id: course.id }));
  return json;
};

/**
 * Get groups that contain any of the skillScoreMappings skills, AND contain any of the filterSkills.
 * @param {Object} skillScoreMappings An object keyed by skill IDs with numeric scores as values
 * @param {string[]} filterSkills An array of skillIds to further filter education by. If empty, no filter will be applied
 * @param {string[]} sites An array of selected skillabi sites to filter education by. If empty, no filter will be applied
 * @param {string[]} groupTypeClasses An array of selected groupTypeClass IDs to filter education by. If empty, no filter will be applied
 * @param {number} limit The number of results returned
 * @param {number} [offset=0] The number of results to skip in the DB
 * @return {Object[]} An array of group objects
 */
export const getGroupsBySkills = async (
  skillScoreMappings,
  filterSkills,
  sites,
  groupTypeClasses,
  limit,
  offset = 0
) => {
  const response = await fetchWithRetry(
    `${URL}/groups-by-skills?sites=${sites.join(',')}&groupTypeClasses=${groupTypeClasses.join(
      ','
    )}&limit=${limit}&offset=${offset}`,
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          attributes: { skills: skillScoreMappings, filterSkills }
        }
      }),
      headers: {
        Authorization: `Bearer ${await getToken()}`
      }
    }
  );
  if (!response.ok) {
    throw new Error('Error retrieving programs from API.');
  }
  const json = await response.json();
  json.data = json.data.map(group => ({ ...group.attributes, id: group.id }));
  return json;
};

/**
 * First get a group by its ID. Also take all of its courses, and run through Search endpoint to get all the skills.
 * @param {String} id Id of the group
 * @param {String} site The site to fetch from
 */
export const getGroupById = async (id, site) => {
  const [group, associatedCourses] = await Promise.all([
    fetchWithRetry(`${URL}/groups/${id}`, {
      headers: {
        Authorization: `Bearer ${await getToken()}`
      }
    })
      .then(response => response.json())
      .then(json => ({ ...json.data.attributes, id: json.data.id })),
    fetchWithRetry(`${URL}/courses/search`, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'courseSearch',
          attributes: {
            filter: { site: { in: [site] }, associatedGroup: id, isPublished: true },
            limit: 100
          }
        }
      }),
      headers: {
        Authorization: `Bearer ${await getToken()}`
      }
    })
      .then(response => response.json())
      .then(json => json.data.map(course => ({ ...course.attributes, id: course.id })))
  ]).catch(() => {
    throw new Error(`Error retrieving programs from API.`);
  });
  let groupSkills = [];
  associatedCourses.forEach(course => (groupSkills = groupSkills.concat(course.skills)));
  group.courses = associatedCourses;
  group.skills = [...new Set(groupSkills)]; // Deduplication via Set(), then spread to convert to an array
  return group;
};

/**
 * First get a course by its ID. Also take all the groups that contain the course ID, and run through Search endpoint to get group data.
 * @param {String} id Id of the course
 * @param {String} site The site to fetch from
 */
export const getCourseById = async (id, site) => {
  const [course, associatedGroups] = await Promise.all([
    fetchWithRetry(`${URL}/courses/${id}`, {
      headers: {
        Authorization: `Bearer ${await getToken()}`
      }
    })
      .then(response => response.json())
      .then(json => ({ ...json.data.attributes, id: json.data.id })),
    fetchWithRetry(`${URL}/groups/search`, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'groupSearch',
          attributes: {
            filter: { site: { in: [site] }, courses: { all: [id] }, isPublished: true },
            limit: 100
          }
        }
      }),
      headers: {
        Authorization: `Bearer ${await getToken()}`
      }
    })
      .then(response => response.json())
      .then(json => json.data.map(group => ({ ...group.attributes, id: group.id })))
  ]).catch(() => {
    throw new Error(`Error retrieving courses from API.`);
  });
  let groupIds = associatedGroups.map(group => group.id);
  course.groups = await Promise.all(groupIds.map(groupId => getGroupById(groupId, site)));
  return course;
};

/**
 * Get all education items of the specified type for the given subdomains.
 * @param {string[]} filterSkills An array of skillIds to filter education by. If empty, no filter will be applied
 * @param {string[]} sites An array of selected skillabi sites to filter education by. If empty, no filter will be applied
 * @param {string[]} groupTypeClasses An array of selected groupTypeClass IDs to filter programs by. If empty, no filter will be applied
 * @param {string[]} programIds An array of selected program IDs to filter courses by. If empty, no filter will be applied
 * @param {'course' | 'group'} edType The type of education item being retrieved
 * @param {number} limit The number of results returned
 * @param {number} offset The number of results to skip in the DB
 * @return {Object[]} An array of either course or program objects
 */
export const getAllOfEdType = async (
  filterSkills,
  sites,
  groupTypeClasses,
  programIds,
  edType,
  limit,
  offset
) => {
  let filter = { site: { in: sites }, isPublished: true };
  if (filterSkills.length) {
    filter.skills = { in: filterSkills };
  }
  if (groupTypeClasses && groupTypeClasses.length) {
    filter.groupTypeClass = { in: groupTypeClasses };
  }
  if (programIds && programIds.length) {
    filter.associatedGroups = { in: programIds };
  }
  const response = await fetchWithRetry(`${URL}/${edType}s/search`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: `${edType}Search`,
        attributes: {
          filter,
          sort: [['title', 'ascending']],
          limit,
          offset
        }
      }
    }),
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  });
  if (!response.ok) {
    throw new Error(`Error retrieving ${edType}s from API.`);
  }
  const json = await response.json();
  json.data = json.data.map(edItem => ({ ...edItem.attributes, id: edItem.id }));
  return json;
};

/**
 * Search programs based on the user-entered input string
 * @param {string[]} filterProgramIds An array of program ids to search programs by. If empty, no filter will be applied
 * @param {string} searchString An input string to search by. If empty, no filter will be applied
 * @param {string[]} sites An array of selected skillabi sites to filter education by. If empty, no filter will be applied
 * @param {number} limit The number of results returned
 * @return {Object[]} An array of matched program objects
 */
export const searchPrograms = async (filterProgramIds, searchString, sites, limit) => {
  let filter = { site: { in: sites }, isPublished: true };
  if (filterProgramIds && filterProgramIds.length) {
    filter.ids = { in: filterProgramIds };
  }
  if (searchString) {
    filter.text = searchString;
  }
  const response = await fetchWithRetry(`${URL}/groups/search`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'groupSearch',
        attributes: {
          filter,
          limit
        }
      }
    }),
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  });
  if (!response.ok) {
    throw new Error(`Error retrieving programs from API.`);
  }
  const json = await response.json();
  json.data = json.data.map(edItem => ({ ...edItem.attributes, id: edItem.id }));
  return json;
};

/**
 * Get all groupTypeClasses.
 */
export const getAllGroupTypeClasses = async () => {
  const response = await fetchWithRetry(`${URL}/group-type-class`, {
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  });
  if (!response.ok) {
    throw new Error(`Error retrieving education levels from API.`);
  }
  const json = await response.json();
  const result = {};
  for (let groupTypeClass of json.data) {
    const { id, attributes } = groupTypeClass;
    result[id] = { ...attributes, id };
  }
  return result;
};
