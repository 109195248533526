import React from 'react';
import PropTypes from 'prop-types';

import AriaAlert from 'components/atoms/AriaAlert';
import SkillsChooser from 'components/molecules/SkillsChooser';
import { getSkillsForJob } from 'services/analystProfiles';

export default class JobSkillsChooser extends React.Component {
  state = {
    jobSkillIds: [],
    isRequesting: false
  };

  componentDidUpdate(prevProps) {
    let { jobId } = this.props;

    if (jobId !== prevProps.jobId) {
      this.updateJobSkills(jobId);
    }
  }

  async updateJobSkills(jobId) {
    if (!jobId) {
      return this.setState({
        isRequesting: false,
        jobSkillIds: []
      });
    }

    this.setState({
      isRequesting: true,
      jobSkillsIds: []
    });

    try {
      let jobSkillIds = await getSkillsForJob(jobId);
      if (jobId === this.props.jobId) {
        this.props.onJobSkillsErrorUpdate();
        this.setState({
          jobSkillIds,
          isRequesting: false
        });
      }
    } catch (e) {
      this.props.onJobSkillsErrorUpdate(e.message || e);
      this.setState({
        isRequesting: false
      });
    }
  }

  render() {
    const { isRequesting, jobSkillIds } = this.state;
    return (
      <>
        <AriaAlert>
          {!isRequesting && jobSkillIds.length > 0 && 'Tab to select skills matching your search.'}
        </AriaAlert>
        <SkillsChooser
          skillIds={jobSkillIds}
          isLoading={isRequesting}
          emptyText="Enter a job title to see related skills here."
        />
      </>
    );
  }
}

JobSkillsChooser.propTypes = {
  jobId: PropTypes.string,
  onJobSkillsErrorUpdate: PropTypes.func.isRequired
};
