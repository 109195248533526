import { select, call, put, takeLatest } from 'redux-saga/effects';

import { currentViewActions, initialState } from 'store/reducers/currentViews';
import {
  ADD_PROFILE_VIEW,
  DELETE_PROFILE_VIEW,
  GET_PROFILE_VIEW,
  UPDATE_PROFILE_VIEW,
  profileActions
} from 'store/reducers/profile';

import {
  getProfileView,
  createProfileView,
  deleteProfileView,
  updateProfileView
} from 'services/profiles';
import { searchCompaniesbyId } from 'services/jpa';
import { searchPrograms } from 'services/courses';

import { flashError, flashSuccess } from 'helpers/flashMessage';

const formatDataForProfileView = response => {
  const { attributes, id } = response.data;
  const { name, type, updatedAt } = attributes;
  return { id, updatedAt, name, type };
};

const formatDataForCurrentView = response => {
  const { attributes } = response.data;
  const { profileId, subdomain, name, updatedAt, createdAt, ...rest } = attributes;
  return rest;
};

export function* addProfileViewSaga(action) {
  const { section, newFilterName } = action.payload;
  const { subdomain, profileId } = yield select(state => state.profile);

  let { companyNames, programNames, ...currentFilters } = yield select(
    state => state.currentViews[section]
  );
  // filterLevels is not applicable when filterEdType is 'Courses', therefore setting it to an empty array when adding a view to a profile
  if (currentFilters.filterEdType === 'Courses') {
    currentFilters.filterLevels = [];
  }
  try {
    const data = {
      profileId,
      subdomain,
      type: section,
      name: newFilterName,
      ...currentFilters
    };
    const addedProfileView = yield call(createProfileView, data);
    yield put(profileActions.addProfileView.success(formatDataForProfileView(addedProfileView)));
    flashSuccess('New filter created!', {
      position: 'bottom-left'
    });
  } catch (e) {
    flashError('A problem occurred, please try again.');
  }
}

export function* getProfileViewSaga(action) {
  try {
    const getViewResponse = yield call(getProfileView, action.payload);
    const { type, ...rest } = formatDataForCurrentView(getViewResponse);

    // Occasionally skills are removed from validSkills list, so we need to filter out invalid skills
    const validSkills = yield select(state => state.validSkills.data);
    // This isn't completely safe, because there is a small chance validSkills haven't finished loading yet
    // A better way would be to not load page until validSkills are loaded
    if (Object.keys(validSkills).length) {
      rest.filterSkills = rest.filterSkills.filter(skillId => validSkills[skillId]);
    }

    if (type === 'jobs') {
      const { filterCompanyIds } = rest;
      rest.companyNames = {};
      if (filterCompanyIds.length) {
        let companies = yield call(searchCompaniesbyId, filterCompanyIds);
        // only keep valid companyIds
        rest.filterCompanyIds = companies.data.map(company => company.id);
        companies.data.forEach(item => {
          rest.companyNames[item.id] = item.name;
        });
      }
    }

    if (type === 'education') {
      const { filterProgramIds, filterSites } = rest;
      rest.programNames = {};
      if (filterProgramIds && filterProgramIds.length) {
        let sites = yield select(state => state.site.data.skillabiSites);
        if (filterSites.length) {
          sites = filterSites;
        }
        const programs = yield call(searchPrograms, filterProgramIds, '', sites, 100);
        // only keep valid programIds
        rest.filterProgramIds = programs.data.map(program => program.id);
        programs.data.forEach(program => {
          rest.programNames[program.id] = program.title;
        });
      }
    }

    yield put(currentViewActions.updateView(type, rest));
    // The below profileAction is needed because updateView func above resets 'viewId' in redux
    yield put(profileActions.getProfileView.success(action.payload));
  } catch (e) {
    flashError('A problem occurred, please try again.');
  }
}

export function* deleteProfileViewSaga(action) {
  try {
    const { id, type } = action.payload;
    const activeViewId = yield select(state => state.profile.activeViewId);
    if (activeViewId === id) {
      if (type === 'education') {
        const defaultEduType = yield select(state => state.site.data.defaultEduTab);
        yield put(
          currentViewActions.updateView(type, {
            ...initialState[type],
            filterEdType: defaultEduType
          })
        );
      } else if (type === 'jobs') {
        const defaultCity = yield select(state => state.site.data.defaultCity);
        yield put(
          currentViewActions.updateView(type, {
            ...initialState[type],
            filterCities: [defaultCity]
          })
        );
      } else {
        yield put(currentViewActions.updateView(type, initialState[type]));
      }
    }
    yield call(deleteProfileView, id);
    yield put(profileActions.deleteProfileView.success(id));
    flashSuccess('Filter Deleted.', {
      position: 'bottom-left'
    });
  } catch (e) {
    flashError('A problem occurred, please try again.');
  }
}

export function* updateProfileViewSaga(action) {
  try {
    const { type, id } = action.payload;
    const { companyNames, programNames, ...filters } = yield select(
      state => state.currentViews[type]
    );

    yield call(updateProfileView, id, filters);
    yield put(profileActions.updateProfileView.success(id));
    flashSuccess('Overwrite Successful.', {
      position: 'bottom-left'
    });
  } catch (e) {
    flashError('A problem occured, please try again.');
  }
}

export default function* watch() {
  yield takeLatest(ADD_PROFILE_VIEW.REQUEST, addProfileViewSaga);
  yield takeLatest(GET_PROFILE_VIEW.REQUEST, getProfileViewSaga);
  yield takeLatest(DELETE_PROFILE_VIEW.REQUEST, deleteProfileViewSaga);
  yield takeLatest(UPDATE_PROFILE_VIEW.REQUEST, updateProfileViewSaga);
}
