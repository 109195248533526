import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SvgIcon from 'components/atoms/SvgIcon';
import { blue, blueDark, blueLight } from 'helpers/colors';

const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3rem;
  min-height: 8.3rem;
`;

const Crumbs = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
`;

const Crumb = styled.li`
  position: relative;
  padding-left: 1.75rem;
  margin-right: 0.5rem;
  /* Ensures that left crumbs will be positioned over right crumbs */
  z-index: ${props => props.zIndex};
  background: ${props => (props.active ? blue : blueLight)};
  & a {
    color: ${props => (props.active ? 'white' : blueDark)};
    font-weight: ${props => (props.active ? '600' : '400')};
    text-decoration: none;
    padding: 0.7rem 2rem;
    line-height: 2.1rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
  }
  &:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
    padding-left: 0;
    &::before {
      content: none;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    /* Ensures focus outline on the a-tag isn't covered up */
    z-index: -1;
  }
  /* Left arrow */
  &::before {
    border-top: 1.75rem solid transparent;
    border-bottom: 1.75rem solid transparent;
    border-left: 1.75rem solid white;
    left: 0;
  }
  /* Right arrow */
  &::after {
    border-top: 1.75rem solid transparent;
    border-bottom: 1.75rem solid transparent;
    border-left: 1.75rem solid ${props => (props.active ? blue : blueLight)};
    left: 100%;
  }
`;

const Icon = styled(props => <SvgIcon {...props} />)`
  margin-right: 1rem;
  width: 1.4rem;
`;

const Breadcrumbs = ({ basePath, navSteps, skipButton }) => {
  return (
    <Wrapper>
      <Crumbs>
        {navSteps.map(({ path, text, icon }, i) => (
          <Crumb zIndex={navSteps.length - i} active={basePath === path} key={i}>
            <Link to={`/${path}`}>
              <Icon Svg={icon} fill={basePath === path ? 'white' : blue} />
              {text}
            </Link>
          </Crumb>
        ))}
      </Crumbs>
      {skipButton}
    </Wrapper>
  );
};

Breadcrumbs.propTypes = {
  basePath: PropTypes.string.isRequired,
  navSteps: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired
    })
  ).isRequired,
  skipButton: PropTypes.node
};

export default Breadcrumbs;
