import fetchWithRetry from './helpers/fetchWithRetry';

// The following two functions are necessary to handle UTF-16 chars in resume, such as long dashes and emojis

// Scans `text` and builds a mapping from UTF-8 byte offsets to the
// UTF-16 based 'character' offsets (as used by `slice` and friends).
// Author: Justin
const getByteOffsetTable = text => {
  const table = {};
  let bytes = 0;
  for (let i = 0; i < text.length; i++) {
    table[bytes] = i;
    const char = text.codePointAt(i);
    if (char <= 0x007f) {
      bytes += 1;
    } else if (char <= 0x07ff) {
      bytes += 2;
    } else if (char <= 0xffff) {
      bytes += 3;
    } else {
      bytes += 4;
    }
  }
  return table;
};

const getByteCountConverter = text => {
  const byteOffsetTable = getByteOffsetTable(text);
  return form => {
    form.sourceStart = byteOffsetTable[form.sourceStart];
    form.sourceEnd = byteOffsetTable[form.sourceEnd];

    return form;
  };
};

export const getParsedTextFromDoc = async (doc, type) => {
  let contentType;
  switch (type) {
    case 'docx':
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'pdf':
      contentType = 'application/pdf';
      break;
    case 'txt':
      contentType = 'text/plain';
      break;
    default:
      throw new Error('Error, detected an unsupported doc type');
  }

  let response = await fetchWithRetry(
    `/api/emsi-services/skills/versions/latest/extract/trace?includeNormalizedText=true`,
    {
      method: 'POST',
      headers: {
        'Content-Type': contentType
      },
      body: doc
    }
  );
  if (!response.ok) {
    throw new Error('Error parsing text by Skills Classification API.');
  }

  return await response.json().then(data => {
    return data.data.normalizedText;
  });
};

export const getParsedSkillsForText = async text => {
  const byteCountConverter = getByteCountConverter(`${text} `);
  let response = await fetchWithRetry(`/api/emsi-services/skills/versions/latest/extract/trace`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    body: JSON.stringify({ text })
  });

  if (!response.ok) {
    throw new Error('Error retrieving parsed skills from Skills Classification API.');
  }

  return await response.json().then(data => ({
    ...data.data,
    trace: data.data.trace.map(item => ({
      ...item,
      surfaceForm: byteCountConverter(item.surfaceForm)
    }))
  }));
};

export const getAllSkills = async () => {
  let response = await fetchWithRetry(`/api/emsi-services/skills/versions/latest/skills`);

  if (!response.ok) {
    throw new Error('Error retrieving skills from Skills API.');
  }

  const data = await response.json();
  return data.data;
};
