import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import SvgIcon from 'components/atoms/SvgIcon';
import TitledCard from 'components/organisms/TitledCard';
import LinkedInModal from 'components/molecules/LinkedInModal';
import SkillResumeResults from 'containers/SkillResumeResults';

import { ReactComponent as HighlightIcon } from 'images/highlight.svg';
import { ReactComponent as PencilIcon } from 'images/pencil.svg';
import { ReactComponent as RefreshIcon } from 'images/refresh.svg';
import { ReactComponent as CloudIcon } from 'images/cloud-upload.svg';
import { ReactComponent as LinkedInIcon } from 'images/linkedin-upload.svg';
import loading from 'images/loading.gif';

import { grayLight, blue, red } from 'helpers/colors';
import { getParsedTextFromDoc } from 'services/skillsClassification';

const Flex = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;

  @media (max-width: 559px) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

const TextArea = styled.textarea`
  display: block;
  background: ${grayLight};
  border: ${({ error }) => (error ? `solid ${red} 1px` : 'solid transparent 1px')};
  border-radius: 0.3rem;
  box-sizing: border-box;
  font-size: 1.4rem;
  padding: 1.5rem;
  width: 100%;
  resize: none;
  height: 28rem;
  @media (min-width: 1024px) {
    height: 52.4rem;
  }
`;

const UploadWrapper = styled.div`
  position: absolute;
  top: 6rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.5rem;
  button {
    margin: 0;
    padding: 1rem 4.6rem;
  }
  svg {
    width: 2rem;
  }
`;

const HiddenFileUpload = styled.input`
  visibility: hidden;
`;

const OutlineButton = styled(Button)`
  background: transparent;
  border: 1px solid ${blue};
  color: ${blue};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;

  @media (max-width: 559px) {
    margin: 1rem 0;
  }
`;

const Icon = styled(SvgIcon)`
  height: auto;
  width: 1.4rem;
  margin-right: 0.7rem;
  fill: black;
`;

const LoadingIcon = styled.img`
  width: 2rem;
  margin-right: 0.7rem;
`;

const Error = styled.div`
  display: block;
  margin-top: 1rem;
  color: ${red};
  font-size: 1.2rem;
  @media (max-width: 1023px) {
    margin-top: 1.8rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  button:last-child {
    margin-left: 2rem;
  }

  @media (max-width: 799px) {
    flex-direction: column;
    button {
      :last-child {
        margin-left: 0;
      }
      margin-top: 2rem;
      padding: 1rem;
    }
  }
`;

const initialUploadState = { isResumeUploading: false, isLinkedInUploading: false };

const SkillsByResume = ({ modalHide, modalShow }) => {
  const fileUploadElement = useRef(null);
  const [isEditing, setIsEditing] = useState(true);
  const [upload, setUpload] = useState(initialUploadState);
  const [fromModal, setFromModal] = useState(false);
  const [text, setText] = useState('');
  const [error, setError] = useState(null);

  const validateMaxLength = (inputText, maxLength = 10000) => {
    if (inputText.length > maxLength) {
      setError(
        `Résumé max length is ${maxLength} characters. Current length is ${inputText.length}`
      );
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const handleErrorState = (noParsedText = false) => {
    setError(
      noParsedText
        ? 'Upload error. Please check your file and try again.'
        : 'Sorry, supported document types are .pdf, .docx, and .txt and are limited to 10MB.'
    );
    setUpload(initialUploadState);
  };

  const hiddenFileUpload = (
    <HiddenFileUpload
      type="file"
      ref={fileUploadElement}
      accept=".pdf, .docx, .txt, application/pdf, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      onChange={e => {
        modalHide();
        setError(null);
        if (!e.target.files[0]) {
          return;
        }
        if (e.target.files[0].size > 10000000) {
          setError('Résumé max size is 10MB.');
          return;
        }
        setUpload({ [fromModal ? 'isLinkedInUploading' : 'isResumeUploading']: true });
        const docName = e.target.files[0].name;
        const docNameExt = docName.split('.').pop();
        getParsedTextFromDoc(e.target.files[0], docNameExt)
          .then(parsedText => {
            if (!parsedText) {
              handleErrorState(true);
            } else if (validateMaxLength(parsedText)) {
              setIsEditing(false);
              setText(parsedText);
            }
            setUpload(initialUploadState);
          })
          .catch(() => {
            handleErrorState();
          });
      }}
    />
  );
  return (
    <TitledCard
      helpText="Type or paste your résumé to see the skills that we can automatically identify. Words and phrases in your résumé that match to skills are highlighted."
      title="Extract From My Résumé"
      icon={<Icon Svg={HighlightIcon} />}
    >
      <LinkedInModal uploadRef={fileUploadElement} modalHide={modalHide} name="linkedin-skills" />

      {isEditing ? (
        <>
          <TextArea
            data-cy="resume-parser-textarea"
            placeholder="Type or paste your résumé..."
            aria-label="Type or paste your résumé to see the skills we can automatically identify."
            value={text}
            onChange={e => {
              validateMaxLength(e.target.value);
              setText(e.target.value);
            }}
            error={error && text}
          />

          {!text && (
            // we trigger the invisible file upload input by clicking the button below
            <UploadWrapper>
              {hiddenFileUpload}
              <ButtonContainer>
                <OutlineButton
                  disabled={upload.isResumeUploading}
                  onClick={() => {
                    setFromModal(false);
                    fileUploadElement.current.click();
                  }}
                >
                  {upload.isResumeUploading ? (
                    <LoadingIcon src={loading} alt="" />
                  ) : (
                    <Icon Svg={CloudIcon} fill={blue} />
                  )}
                  Upload My Résumé
                </OutlineButton>
                <OutlineButton
                  disabled={upload.isLinkedInUploading}
                  onClick={() => {
                    setFromModal(true);
                    modalShow('linkedin-skills');
                  }}
                >
                  {upload.isLinkedInUploading ? (
                    <LoadingIcon src={loading} alt="" />
                  ) : (
                    <Icon Svg={LinkedInIcon} fill={blue} />
                  )}
                  Find Skills in My LinkedIn Profile
                </OutlineButton>
              </ButtonContainer>
              {error && !text && <Error aria-live="polite">{error}</Error>}
            </UploadWrapper>
          )}
          {error && text && <Error aria-live="polite">{error}</Error>}
        </>
      ) : (
        <SkillResumeResults text={text} />
      )}
      <Flex>
        {isEditing ? (
          <Button disabled={text.length === 0 || !!error} onClick={() => setIsEditing(false)}>
            <Icon Svg={PencilIcon} fill="white" />
            Extract Skills
          </Button>
        ) : (
          <Button onClick={() => setIsEditing(true)}>
            <Icon Svg={HighlightIcon} fill="white" />
            Edit Text
          </Button>
        )}
        <OutlineButton
          onClick={() => {
            setIsEditing(true);
            setUpload(initialUploadState);
            setError(null);
            setText('');
          }}
        >
          <Icon Svg={RefreshIcon} fill={blue} />
          Start Over
        </OutlineButton>
      </Flex>
    </TitledCard>
  );
};

SkillsByResume.propTypes = {
  modalHide: PropTypes.func.isRequired,
  modalShow: PropTypes.func.isRequired
};

export default SkillsByResume;
