/* This function will take an array of arrays (we'll call them subarrays) and return a single ordered array
beginning with the first item of the first subarray, followed by the first item of the second subarray
and so on, until all the items in all the arrays have been added.
duplicate items (where id is the same) will be skipped and not be added.
example:
if subarrays is
[
  [{id: 'a', name: 'A1'}, {id: 'b', name: 'B'}],
  [{id: 'c', name: 'C'}, {id: 'a', name: 'A2', other: 'xxx'}, {id: 'd', name: 'D'}]
]
the function would return:
[{id: 'a', name: 'A1'}, {id: 'c', name: 'C'}, {id:, 'b', name: 'B'}, {id: 'd', name: 'D'}] */

export default function sortItemsInArrays(subarrays) {
  let result = [];
  const maxLength = Math.max(...subarrays.map(array => array.length));
  for (let i = 0; i < maxLength; i++) {
    for (let j = 0; j < subarrays.length; j++) {
      if (
        i < subarrays[j].length &&
        !result.some(val => val.id && subarrays[j][i].id && val.id === subarrays[j][i].id)
      ) {
        result.push(subarrays[j][i]);
      }
    }
  }
  return result;
}

export function formatRelatedSkills(clusters, selectedSkills) {
  const skillsArrays = clusters.map(cluster => cluster.primarySkills);
  const unfilteredRelatedSkills = sortItemsInArrays(skillsArrays);

  // filter out skills that have already been selected
  const unlimitedRelatedSkills = unfilteredRelatedSkills.filter(
    skill => !selectedSkills.includes(skill.id)
  );

  const relatedSkills = unlimitedRelatedSkills.slice(0, 60);
  return relatedSkills;
}
