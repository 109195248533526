import { getJobPostingsBySkills } from 'services/jpa';

/**
 * Get job postings of the specified city that match the user selected skills and filters
 * @param {Object} selectedSkills The object of user selected skills
 * @param {string[]} filterSkills An array of skill ids to filter on
 * @param {string[]} companyIds An Array of Company Ids
 * @param {Object} validSkills The object of all valid skills
 * @param {string[]} cities An array of city strings to filter on
 * @param {number} [offset=0] Number of MATCHED postings to skip
 */
export const getJobMatches = async (
  selectedSkills,
  filterSkills,
  companyIds,
  validSkills,
  cities,
  offset = 0
) => {
  const skills = Object.keys(selectedSkills);
  // Filtering with JPA in the way that we want requires that the filter skill names are used, not the ids
  const filterSkillNames = filterSkills.map(skillId => validSkills[skillId].name);

  // Exit early if there are no skills or companies to match on
  if (!companyIds.length && !skills.length) {
    return { data: [], total: 0 };
  }
  let postings = await getJobPostingsBySkills(
    skills,
    cities,
    companyIds,
    filterSkillNames,
    12,
    // not proper math, we only care about whole pages
    // JPA pages start at 1
    Math.ceil(offset / 12) + 1
  ).then(res => ({
    data: res.data.postings,
    // viewable_postings count from JPA is not reliable when it's >100
    // 96 is 8 pages of results when each page contains 12
    total: Math.min(res.data.viewable_postings, 96)
  }));

  // Count have skills within each posting
  postings.data.forEach(posting => {
    posting.haveSkills = posting.skills.filter(skill => selectedSkills[skill] === 'have').length;
    if (window.location.pathname === '/results/internships') {
      posting.wantSkills = posting.skills.filter(skill => selectedSkills[skill] === 'want').length;
    }
  });
  postings.data = postings.data.map(
    ({ url: [url], title_raw, company, company_name, city_name, title, ...rest }) => ({
      url,
      title: title_raw, // The title attr returned from JPA is a number, so we use title_raw
      company: company_name,
      companyId: company,
      city: city_name,
      ...rest
    })
  );

  if (window.location.pathname === '/results/internships') {
    postings.data = postings.data.sort((a, b) => b.wantSkills - a.wantSkills);
  } else {
    // sort on haveSkills
    postings.data = postings.data.sort((a, b) => b.haveSkills - a.haveSkills);
  }
  return postings;
};

/**
 * Get job postings that both match the array of posts in skills and are not contained in result matches
 * @param {String[]} skills An array of skill ids
 * @param {string[]} companyIds An Array of Company IDs
 * @param {string[]} cities An array of city strings to filter on
 * @param {number} [offset=0] Number of OTHER postings to skip
 */
export const getJobOthers = async (skills, companyIds, cities, offset = 0) => {
  let others = await getJobPostingsBySkills(
    skills,
    cities,
    companyIds,
    null,
    12,
    // not proper math, we only care about whole pages
    // JPA pages start at 1
    Math.ceil(offset / 12) + 1
  ).then(res => ({
    data: res.data.postings,
    // viewable_postings count from JPA is not reliable when it's >100
    // 96 is 8 pages of results when each page contains 12
    total: Math.min(res.data.viewable_postings, 96)
  }));
  others.data = others.data.map(
    ({ url: [url], title_raw, company, company_name, city_name, title, ...rest }) => ({
      url,
      title: title_raw, // The title attr returned from JPA is a number, so we use title_raw
      company: company_name,
      companyId: company,
      city: city_name,
      ...rest
    })
  );
  return others;
};
