import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SkillButton from 'components/molecules/SkillButton';
import SkillsGrid from 'components/atoms/SkillsGrid';

import { red } from 'helpers/colors';

const SkillsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0.5rem;
  }
`;

const Error = styled.div`
  color: ${red};
  font-size: 1.5rem;
`;

const TopClusterSkills = ({ clusterSkills = [], hasError, displayAsList, selectedSkills }) => {
  const limitedClusterSkills = clusterSkills.slice(0, 20);

  if (hasError) {
    return <Error aria-live="polite">There was an error loading related skills.</Error>;
  }

  let skills = limitedClusterSkills.map(skill => ({
    ...skill,
    selectionType: selectedSkills[skill.id]
  }));

  return displayAsList ? (
    <SkillsList data-cy="cluster-skills-list" aria-label="top in demand skills">
      {limitedClusterSkills.map(skill => (
        <SkillButton key={skill.id} skill={skill} />
      ))}
    </SkillsList>
  ) : (
    <SkillsGrid data-cy="cluster-skills-grid" ariaLabel="top in demand skills" skills={skills} />
  );
};

TopClusterSkills.propTypes = {
  clusterSkills: PropTypes.array,
  displayAsList: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  selectedSkills: PropTypes.object.isRequired
};

export default TopClusterSkills;
