import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from 'components/atoms/Card';
import Link from 'components/atoms/Link';
import SvgIcon from 'components/atoms/SvgIcon';
import { blue } from 'helpers/colors';
import CheckmarkInSlateCircle from 'images/checkmark-in-slate-circle.svg';

const StyledSkillCard = styled(Card)`
  position: relative;
  margin: 0.5rem;
  padding: 3rem 1rem;
  border: 1px solid rgba(32, 67, 84, 0.1);
  width: 40%;
  text-align: center;

  @media (max-width: 549px) {
    width: 100%;
  }
`;

const StyledCheckmark = styled.img`
  position: absolute;
  height: 2rem;
  width: auto;
  top: -0.6rem;
  left: -0.6rem;
`;

const Icon = styled(SvgIcon)`
  width: auto;
  height: 1.2rem;
  padding-right: 0.5rem;
  fill: ${blue};
`;

const SkillsLinkCard = ({ updateVisitedPages, icon, title, to, visitedSkillsPages }) => {
  return (
    <StyledSkillCard>
      {visitedSkillsPages.includes(title) && (
        <StyledCheckmark src={CheckmarkInSlateCircle} alt="" />
      )}
      <Link
        to={to}
        onClick={() =>
          updateVisitedPages(
            visitedSkillsPages.includes(title) ? visitedSkillsPages : [...visitedSkillsPages, title]
          )
        }
      >
        <Icon Svg={icon} alt="" />
        {title}
      </Link>
    </StyledSkillCard>
  );
};

SkillsLinkCard.propTypes = {
  updateVisitedPages: PropTypes.func.isRequired,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  visitedSkillsPages: PropTypes.array.isRequired
};
export default SkillsLinkCard;
