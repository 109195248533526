/**
 * This isSafari function is needed because Safari does not recognize any doNotTrack Policies
 * and therefore we have to detect if a user is using Safari and then handle
 * it in our own isTrackingEnabled function or it would break the app for Safari users.
 * Details: https://www.macworld.com/article/232426/apple-safari-removing-do-not-track.html
 */
const isSafari = () => {
  // Get the user-agent string
  const userAgentString = navigator.userAgent;
  // Detect Chrome
  const chromeAgent = userAgentString.indexOf('Chrome') > -1;
  // Detect Safari
  const safariAgent = userAgentString.indexOf('Safari') > -1;
  // Discard Safari since it also matches Chrome
  return safariAgent && !chromeAgent;
};

const isTrackingEnabled = () => {
  const doNotTrackOption =
    window.doNotTrack || window.navigator.doNotTrack || window.navigator.msDoNotTrack;

  if (!doNotTrackOption || doNotTrackOption === 'unspecified' || isSafari()) {
    return true;
  }
  return false;
};

export default isTrackingEnabled;
