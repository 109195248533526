import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Modal from 'containers/Modal';
import Button from 'components/atoms/Button';
import Card from 'components/atoms/Card';
import arrowRight from 'images/arrow-right-white.png';

const ModalCard = styled(Card)`
  display: flex;
  align-items: center;
  padding: 2rem;
  border-radius: 3px;

  @media (min-width: 800px) {
    width: 67rem;
    height: 16rem;
  }
`;

const Text = styled.h1`
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
`;

const SubText = styled.div`
  font-size: 1.6rem;
  font-weight: normal;
  text-align: center;
`;

const Buttons = styled.div`
  width: 100%;
  max-width: 54rem;
  height: 10rem;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 800px) {
    height: auto;
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  height: 4rem;
  padding-top: 0.9rem;
  @media (min-width: 800px) {
    width: 25rem;
  }
`;

const ArrowIcon = styled.img`
  margin-left: 1rem;
`;

const MoreSelectingModal = ({ name, modalHide, modalShow, shouldShowRelatedSkills, history }) => {
  return (
    <Modal name={name}>
      <ModalCard>
        <Text>Do you want to explore the other ways to select skills?</Text>
        <SubText> The more skills you add, the better results we can give you!</SubText>
        <Buttons>
          <StyledButton onClick={() => modalHide(name)}>Continue adding skills</StyledButton>
          <StyledButton
            onClick={() => {
              modalHide(name);
              if (shouldShowRelatedSkills) {
                modalShow('related-skills');
              } else {
                history.push('/results');
              }
            }}
          >
            I&apos;m done. Next section
            <ArrowIcon src={arrowRight} alt="" />
          </StyledButton>
        </Buttons>
      </ModalCard>
    </Modal>
  );
};

MoreSelectingModal.propTypes = {
  // history comes from react-router-dom
  history: PropTypes.object.isRequired,
  modalHide: PropTypes.func.isRequired,
  modalShow: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  shouldShowRelatedSkills: PropTypes.bool
};

export default withRouter(MoreSelectingModal);
