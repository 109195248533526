import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { grayMedium, green, orange } from 'helpers/colors';
import SkillShape from 'components/atoms/SkillShape';
import SkillSelectionModal from 'containers/SkillSelectionModal';
import withSelectedSkills from 'hocs/withSelectedSkills';

// This ensures an expanded button will appear on top of other buttons
const StyledLi = styled.li`
  height: 4.4rem;
`;

// This keeps the SkillSelectionModal positioned below an expanded button
const Wrapper = styled.div`
  min-height: 100%;
  position: relative;
`;

const Skill = styled.button`
  max-width: 25rem;
  background: white;
  display: flex;
  min-width: 12.5rem;
  justify-content: flex-start;
  border: solid ${({ borderColor }) => borderColor} 2px;
  border-radius: 3px;
  padding: 1rem;
  font-size: 1.4rem;
  :hover {
    cursor: pointer;
  }
  // TODO: Maybe this can be removed after we add reset.css
  // For Safari
  :active {
    color: rgba(0, 0, 0, 0.847);
  }
  ${props =>
    // z-index is necessary for the ultra-long skills
    props.displayFullSkill &&
    `position: relative; 
     z-index: 1;
     box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);`}
`;

const SkillText = styled.div`
  text-overflow: ellipsis;
  text-align: left;
  ${props => !props.displayFullSkill && 'white-space: nowrap; overflow: hidden;'}
`;

class SkillButton extends React.Component {
  state = {
    showSkillTypeModal: false
  };

  render() {
    const { selectedSkills, skill, focusId, ...rest } = this.props;
    const { showSkillTypeModal } = this.state;

    const getBorderColor = id => {
      switch (selectedSkills[id]) {
        case 'have':
          return green;
        case 'want':
          return orange;
        default:
          return grayMedium;
      }
    };

    const toggleShowSkillTypeModal = () =>
      this.setState({ showSkillTypeModal: !showSkillTypeModal });

    const selectionType = selectedSkills[skill.id];

    return (
      <StyledLi>
        <Wrapper>
          {showSkillTypeModal && (
            <SkillSelectionModal
              selectedSkills={selectedSkills}
              skill={skill}
              toggleShowSkillTypeModal={toggleShowSkillTypeModal}
            />
          )}
          <Skill
            displayFullSkill={showSkillTypeModal}
            aria-label={`${
              selectionType ? `${skill.name}, currently on list: Skills I ${selectionType}, ` : ''
            }open the skill selection menu for ${skill.name}`}
            onClick={() => {
              toggleShowSkillTypeModal();
            }}
            borderColor={getBorderColor(skill.id)}
            id={focusId}
            {...rest}
          >
            <div>
              <SkillShape selectionType={selectionType} />
            </div>
            <SkillText displayFullSkill={showSkillTypeModal}>{skill.name}</SkillText>
          </Skill>
        </Wrapper>
      </StyledLi>
    );
  }
}

SkillButton.propTypes = {
  selectedSkills: PropTypes.object.isRequired,
  skill: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  focusId: PropTypes.string
};

export default withSelectedSkills(SkillButton);
