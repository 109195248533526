import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HorizontalCardScroll from 'components/molecules/HorizontalCardScroll';
import CallToAction from 'components/molecules/CallToAction';

import { grayText } from 'helpers/colors';
import loading from 'images/loading.gif';
import { ReactComponent as EmptyIcon } from 'images/empty-list.svg';
import JobPostingCard from '../JobPostingCard';

const StatusMessage = styled.div`
  color: ${grayText};
  height: 22.2rem;
  font-size: 1.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > svg {
    margin-bottom: 1.5rem;
  }
`;

const Loading = styled.img`
  width: 2.4rem;
`;

const JobPostingsHorizontalScroll = ({
  'aria-label': ariaLabel,
  clusterId,
  errorMessage,
  helpCardJobs,
  postings,
  type
}) => {
  if (errorMessage) {
    return (
      <HorizontalCardScroll isError type={`${type}-error`}>
        <StatusMessage aria-live="polite">
          <EmptyIcon />
          {errorMessage}
        </StatusMessage>
      </HorizontalCardScroll>
    );
  }
  if (!postings) {
    return (
      <HorizontalCardScroll type={`${type}-loading`}>
        <StatusMessage>
          <Loading src={loading} alt="Loading Job Posting" data-cy="cluster-jobs-spinner" />
        </StatusMessage>
      </HorizontalCardScroll>
    );
  }
  return (
    <HorizontalCardScroll
      aria-label={ariaLabel}
      callToAction={
        helpCardJobs.length > 0 ? (
          <CallToAction
            links={helpCardJobs}
            cardText="Questions about careers?"
            ctaName="Job Postings"
          />
        ) : null
      }
      type={type}
    >
      {postings.map(posting => (
        <JobPostingCard key={posting.id} clusterId={clusterId} posting={posting} />
      ))}
    </HorizontalCardScroll>
  );
};

JobPostingsHorizontalScroll.propTypes = {
  'aria-label': PropTypes.string,
  clusterId: PropTypes.string,
  errorMessage: PropTypes.string,
  helpCardJobs: PropTypes.array,
  postings: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      company: PropTypes.string,
      city: PropTypes.string,
      skills: PropTypes.array,
      careerAreaSkills: PropTypes.number,
      haveSkills: PropTypes.number
    })
  ),
  type: PropTypes.string.isRequired
};

export default JobPostingsHorizontalScroll;
