import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from 'components/atoms/Card';
import ChangeEmailForm from 'components/molecules/ChangeEmailForm';
import ChangePasswordForm from 'components/molecules/ChangePasswordForm';
import DeleteAccountForm from 'components/molecules/DeleteAccountForm';
import Link from 'components/atoms/Link';
import withKeen from 'hocs/withKeen';

import { ReactComponent as ArrowRight } from 'images/arrow-right.svg';
import { blue, grayText } from 'helpers/colors';

const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h1`
  font-size: 2.8rem;
  font-weight: normal;
  width: calc(100% - 3rem);
  margin: 3rem 0 0 0;
  @media (min-width: 800px) {
    width: calc(100% - 6rem);
    margin: 5rem 0 1.5rem 0;
  }
`;

const StyledCard = styled(Card)`
  width: calc(100% - 6rem);
  margin: 1.5rem 0;
  padding: 3rem 1.5rem;
  &:last-child {
    margin-bottom: 3rem;
  }
  @media (min-width: 800px) {
    width: calc(100% - 12rem);
    padding: 3rem;
    &:last-child {
      margin-bottom: 6rem;
    }
  }
`;

const Subheader = styled.h2`
  font-size: 1.7rem;
  font-weight: 500;
  margin: 0 0 2rem 0;
`;

const LinkWrapper = styled.div`
  margin-top: 1rem;
  width: calc(100% - 3rem);
  @media (min-width: 800px) {
    margin-top: 0;
    width: calc(100% - 6rem);
  }
`;

const ResultsLink = styled(Link)`
  font-size: 1.4rem;
`;

const ArrowIcon = styled(ArrowRight)`
  fill: ${blue};
  height: 1rem;
  margin-right: 0.8rem;
  transform: rotate(180deg);
  width: 1.5rem;
`;

const StyledSpan = styled.span`
  font-size: 1.4rem;
  color: ${grayText};
  padding-bottom: 3rem;
`;

const Settings = ({ trackEvent, email, isEmailChanging, changeEmail }) => {
  return (
    <SettingsWrapper>
      <Header>Account Settings</Header>
      <LinkWrapper>
        <ResultsLink to="/results">
          <ArrowIcon />
          Go to Results
        </ResultsLink>
      </LinkWrapper>
      <StyledCard>
        <Subheader>My Email</Subheader>
        <StyledSpan>{email}</StyledSpan>
        <Subheader>Change Email</Subheader>
        <ChangeEmailForm
          trackEvent={trackEvent}
          changeEmail={changeEmail}
          isEmailChanging={isEmailChanging}
        />
      </StyledCard>
      <StyledCard>
        <Subheader>Change Password</Subheader>
        <ChangePasswordForm trackEvent={trackEvent} />
      </StyledCard>
      <StyledCard>
        <Subheader>Delete Account</Subheader>
        <DeleteAccountForm />
      </StyledCard>
    </SettingsWrapper>
  );
};

Settings.propTypes = {
  trackEvent: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  isEmailChanging: PropTypes.bool.isRequired,
  changeEmail: PropTypes.func.isRequired
};

export default withKeen(Settings);
