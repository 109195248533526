import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

class ClickOutsideWrapper extends React.Component {
  // This particular function is necessary for onClickOutside to do its magic
  handleClickOutside = () => {
    this.props.clickOutsideHandler();
  };

  render() {
    return this.props.children;
  }
}

ClickOutsideWrapper.propTypes = {
  clickOutsideHandler: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired
};

export default onClickOutside(ClickOutsideWrapper);
