import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Radio from 'components/atoms/Radio';
import RadioGroup from 'components/atoms/RadioGroup';
import { grayLight } from 'helpers/colors';

const StyledRadioGroup = styled(RadioGroup)`
  background-color: white;
  border: 1px solid ${grayLight};
  border-radius: 0.5rem;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  margin-bottom: 3rem;
`;

const Title = styled.label`
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
`;

const StyledRadio = styled(Radio)`
  font-size: 1.4rem;
  height: 2.1rem; // prevents resize when active
  margin-top: 0.5rem;
  label {
    ${({ checked }) => checked && 'font-weight: 600;'}
  }
`;

const CoursesProgramsToggle = ({ edType, setEdType }) => (
  <StyledRadioGroup label={<Title>Education Type</Title>}>
    <StyledRadio
      data-cy="courses-radio"
      checked={edType === 'Courses'}
      label="Courses"
      aria-label="display course results"
      onChange={() => setEdType('Courses')}
    />
    <StyledRadio
      data-cy="programs-radio"
      checked={edType === 'Programs'}
      label="Programs"
      aria-label="display program results"
      onChange={() => setEdType('Programs')}
    />
  </StyledRadioGroup>
);

CoursesProgramsToggle.propTypes = {
  edType: PropTypes.string.isRequired,
  setEdType: PropTypes.func.isRequired
};

export default CoursesProgramsToggle;
