import React from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import DeleteAccountModal from 'containers/DeleteAccountModal';
import { red } from 'helpers/colors';

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
`;

const StyledButton = styled(Button)`
  @media (min-width: 800px) {
    width: 27rem;
  }
`;

const Error = styled.p`
  font-size: 1.4rem;
  color: ${red};
  margin-top: 0;
`;

class DeleteAccountForm extends React.Component {
  state = {
    isChecked: false,
    isModalOpen: false,
    error: false
  };

  handleButtonPress = e => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { isChecked, isModalOpen, error } = this.state;
    return (
      <form>
        <CheckboxWrapper>
          <Checkbox
            ariaLabel="I wish to permanently delete my account"
            isChecked={isChecked}
            name="acknowledgement"
            onChange={() => this.setState({ isChecked: !isChecked })}
            label="I wish to permanently delete my account."
            required
          />
        </CheckboxWrapper>
        {error && <Error aria-live="polite">Error text coming soon</Error>}
        <StyledButton variant="danger" onClick={this.handleButtonPress} disabled={!isChecked}>
          Delete Account
        </StyledButton>
        <DeleteAccountModal isModalOpen={isModalOpen} closeModal={this.closeModal} />
      </form>
    );
  }
}

export default DeleteAccountForm;
