import { connect } from 'react-redux';
import { modalHide } from '../../store/reducers/modal';
import { showModalSelector } from '../../store/selectors/modal';
import Modal from 'components/atoms/Modal';

const mapStateToProps = (state, { isOpen, name }) => ({
  isOpen: isOpen || showModalSelector(state, name)
});

const mapDispatchToProps = (dispatch, { name }) => ({
  close: () => {
    dispatch(modalHide(name));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
