import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { green, grayMedium, orange } from 'helpers/colors';

const Shape = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin: ${props => props.margin || '0 1rem 0 0'};
  flex-shrink: 0;
`;

const Circle = styled(Shape)`
  background: ${green};
  border-radius: 50%;
`;

const Square = styled(Shape)`
  background: ${orange};
`;

const Diamond = styled.div`
  width: 71%;
  height: 71%;
  margin: auto;
  margin-top: 2px;
  background: ${grayMedium};
  transform: rotate(45deg);
`;

const SkillShape = ({ className, margin, selectionType, ...props }) => {
  switch (selectionType) {
    case 'have':
      return <Circle margin={margin} className={className} {...props} />;
    case 'want':
      return <Square margin={margin} className={className} {...props} />;
    default:
      return (
        <Shape margin={margin} className={className} {...props}>
          <Diamond />
        </Shape>
      );
  }
};

SkillShape.propTypes = {
  className: PropTypes.string,
  margin: PropTypes.string,
  selectionType: PropTypes.oneOf(['have', 'want', "don't have"])
};

export default SkillShape;
