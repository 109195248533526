import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Card from 'components/atoms/Card';
import Flex from 'components/atoms/Flex';
import Header from 'components/atoms/Header';
import SquareImgButton from 'components/atoms/SquareImgButton';
import withKeen from 'hocs/withKeen';

import boxWithSpace from 'images/box-with-space.png';
import climbingWoman from 'images/climbing-woman.png';
import computerGuide from 'images/computer-guide.png';
import smartphone from 'images/smartphone.png';

import { goals } from 'helpers/enum';

const ContentWrap = styled.div`
  margin: 5rem auto;
  @media (max-width: 1279px) {
    margin: 0 auto 5rem auto;
    max-width: 62rem;
  }
  @media (max-width: 639px) {
    max-width: 32rem;
  }
`;

const StyledHeader = styled(Header)`
  margin: 3rem 0;
`;

const Wrapper = styled(Card)`
  background: white;
  padding: 1rem;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
`;

const Text = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem 0 1rem 0;
  @media (max-width: 639px) {
    margin: 1rem 0 0 0;
  }
`;

const Goals = ({ history, selectGoal, trackEvent }) => {
  const trackGoal = goal => {
    trackEvent('Select Goal', { selectedGoal: goal });
  };

  return (
    <ContentWrap>
      <StyledHeader isCentered>My Goal</StyledHeader>
      <Wrapper>
        <Text>What is your objective?</Text>
        <Flex justifyContent="center" shouldWrap>
          <SquareImgButton
            imgSrc={boxWithSpace}
            onClick={() => {
              selectGoal(goals.NEW_CAREER);
              trackGoal('new career');
              history.push(window.nation === 'US' ? '/degrees' : '/skills');
            }}
          >
            {goals.NEW_CAREER}
          </SquareImgButton>
          <SquareImgButton
            imgSrc={climbingWoman}
            onClick={() => {
              selectGoal(goals.LEVEL_UP);
              trackGoal('level up');
              history.push(window.nation === 'US' ? '/degrees' : '/skills');
            }}
          >
            {goals.LEVEL_UP}
          </SquareImgButton>
          <SquareImgButton
            imgSrc={smartphone}
            onClick={() => {
              selectGoal(goals.FIND_JOB);
              trackGoal('find job');
              history.push(window.nation === 'US' ? '/degrees' : '/skills');
            }}
          >
            {goals.FIND_JOB}
          </SquareImgButton>
          <SquareImgButton
            imgSrc={computerGuide}
            onClick={() => {
              selectGoal(goals.EXPLORE);
              trackGoal('explore');
              history.push(window.nation === 'US' ? '/degrees' : '/skills');
            }}
          >
            {goals.EXPLORE}
          </SquareImgButton>
        </Flex>
      </Wrapper>
    </ContentWrap>
  );
};

Goals.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  selectGoal: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired
};
export default withRouter(withKeen(Goals));
