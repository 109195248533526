import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

const YoutubeEmbedVideo = ({
  videoUrl,
  title,
  width = 1280,
  height = 720,
  autoplay = false,
  controls = true,
  rel = true
}) => {
  return (
    // Only use the provided width and height if width is smaller than the screen size
    // Also leave 3rem margin around the video on a mobile screen
    <Media query={`(min-width: ${width + 60}px)`}>
      {matches => (
        <iframe
          title={title}
          width={matches ? width : 260}
          height={matches ? height : 150}
          src={`${videoUrl}?autoplay=${autoplay ? 1 : 0}&rel=${rel ? 1 : 0}&controls=${
            controls ? 1 : 0
          }`}
          frameBorder="0"
          allowFullScreen
        />
      )}
    </Media>
  );
};

YoutubeEmbedVideo.propTypes = {
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
  // As of September 2018,you will not be able to disable related videos.
  // Instead, if the rel parameter is set to 0, related videos will come from the same channel as the video that was just played
  rel: PropTypes.bool
};

export default YoutubeEmbedVideo;
