import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

export default Card;
