import React from 'react';
import { connect } from 'react-redux';
import Media from 'react-media';
import ResultsHeader from 'components/atoms/ResultsHeader';
import ResultsMobileNav from 'components/molecules/ResultsMobileNav';
import { ReactComponent as CareerAreasIcon } from 'images/nav-career-areas.svg';
import { ReactComponent as EducationIcon } from 'images/nav-education.svg';
import { ReactComponent as JobPostingsIcon } from 'images/nav-job-postings.svg';
import { ReactComponent as MySkillsIcon } from 'images/nav-my-skills.svg';
import { ReactComponent as PlcIcon } from 'images/nav-plc.svg';

const mapStateToProps = state => ({
  menuBackgroundColor: state.site.data.menuBackgroundColor,
  menuTextColor: state.site.data.menuTextColor,
  isPlcEnabled: state.site.data.isPlcEnabled,
  plcMenuLabel: state.site.data.plcMenuLabel
});

const ResultsNav = ({ isPlcEnabled, plcMenuLabel, ...props }) => {
  const routes = [
    { path: '/results/career-areas', icon: CareerAreasIcon, text: 'Career Areas' },
    { path: '/results/education', icon: EducationIcon, text: 'Education' },
    { path: '/results/jobs', icon: JobPostingsIcon, text: 'Job Postings' },
    isPlcEnabled && {
      path: '/results/plc',
      icon: PlcIcon,
      text: plcMenuLabel || 'Get Credit'
    },
    { path: '/skills', icon: MySkillsIcon, text: 'My Skills' }
  ].filter(Boolean);
  return (
    <Media query="(min-width:800px)">
      {match =>
        match ? <ResultsHeader routes={routes} {...props} /> : <ResultsMobileNav routes={routes} />
      }
    </Media>
  );
};

export default connect(mapStateToProps)(ResultsNav);
