import fetchWithRetry from './helpers/fetchWithRetry';
import { getSiteToken } from './sites';
import getSubdomain from 'helpers/getSubdomain';
import config from 'config';

const subdomain = getSubdomain();
const getToken = () => getSiteToken(subdomain);

export const getSkillabiSite = async site => {
  let res = await fetchWithRetry(`${config.skillabiSitesUrl}/${site}`, {
    headers: { Authorization: `Bearer ${await getToken()}` }
  });
  if (!res.ok) {
    throw new Error('Error retrieving skillabi site');
  }
  return res.json();
};

export const getSkillabiSiteNames = async siteIds => {
  try {
    const names = {};
    let res = await fetchWithRetry(config.skillabiSitesUrl, {
      headers: { Authorization: `Bearer ${await getToken()}` }
    });
    if (!res.ok) {
      throw new Error('Error retrieving skillabi site');
    }
    const { data: sites } = await res.json();

    for (let site of siteIds) {
      const siteInfo = sites.find(s => s.attributes.subdomain === site);
      const name = siteInfo?.attributes?.name;
      names[site] = name || site;
    }
    return names;
  } catch (e) {
    throw new Error(e);
  }
};
