import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import SkillShape from 'components/atoms/SkillShape';
import { orange, green, grayMedium } from 'helpers/colors.js';

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -0.8rem -0.5rem;
  padding: 0;
`;

const GridItem = styled.li`
  list-style: none;
  height: 2rem;
  margin: 0.8rem 0.5rem 0.6rem;
  .__react_component_tooltip.type-light:after {
    border: 0;
    border-right: solid ${props => props.borderColor} 2px;
    border-bottom: solid ${props => props.borderColor} 2px;
    background: white;
    height: 0.92rem;
    width: 0.92rem;
  }
  .__react_component_tooltip.place-top:after {
    transform: rotate(45deg);
    bottom: -0.7rem;
    margin-left: -0.7rem;
    z-index: -1;
    border-top-width: 0 !important;
  }
  .__react_component_tooltip.place-bottom:after {
    transform: rotate(-135deg);
    top: -0.7rem;
    margin-left: -0.7rem;
  }
  .__react_component_tooltip.place-left:after {
    transform: rotate(-45deg);
    margin-top: -0.7rem;
    right: -0.7rem;
  }
  .__react_component_tooltip.place-right:after {
    transform: rotate(135deg);
    margin-top: -0.7rem;
    left: -0.7rem;
  }
`;

const GridIcon = styled(SkillShape)`
  margin: 0;
  width: 2rem;
  height: 2rem;
`;

const SkillNameBubble = styled(ReactTooltip)`
  font-size: 1.4rem;
  opacity: 1 !important;
  padding: 0.8rem 1rem;
  border: solid ${props => props.borderColor} 2px;
  border-radius: 4px;
  text-align: center;
  pointer-events: none;
  max-width: 20rem !important;
`;

const SkillsGrid = ({ skills, ariaLabel, ...props }) => {
  return (
    <Grid aria-label={ariaLabel} {...props}>
      {skills.map(skill => {
        let borderColor;
        switch (skill.selectionType) {
          case 'have':
            borderColor = green;
            break;
          case 'want':
            borderColor = orange;
            break;
          default:
            borderColor = grayMedium;
        }
        return (
          <GridItem aria-label={skill.name} borderColor={borderColor} key={skill.id}>
            <GridIcon
              data-tip={skill.name}
              data-for={`top-skill-${skill.name}`}
              selectionType={skill.selectionType}
            />
            <SkillNameBubble
              id={`top-skill-${skill.name}`}
              borderColor={borderColor}
              effect="solid"
              type="light"
              aria-hidden
            />
          </GridItem>
        );
      })}
    </Grid>
  );
};

SkillsGrid.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selectionType: PropTypes.isRequired
    })
  ).isRequired
};
export default SkillsGrid;
