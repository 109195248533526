import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as Hamburger } from 'images/hamburger-white.svg';
import { ReactComponent as HamburgerBlack } from 'images/hamburger-black.svg';

import SidebarNavCard from 'components/molecules/SidebarNavCard';
import MobileSidebar from 'components/atoms/MobileSidebar';

import withKeen from 'hocs/withKeen';
import { getBestContrast } from 'helpers/colors';

const HamburgerButton = styled.button`
  position: absolute;
  left: 0.75rem;
  text-decoration: none;
  border: none;
  background: none;
  :hover {
    cursor: pointer;
  }
`;

const HamburgerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;

const SidebarNav = ({ trackEvent, menuBackgroundColor, ...props }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const bestContrastingColor = getBestContrast(menuBackgroundColor, ['#fff', '#000']);
  const HamburgerIcon = bestContrastingColor === '#fff' ? Hamburger : HamburgerBlack;

  return (
    <Fragment>
      <HamburgerButton
        onClick={() => {
          setIsNavVisible(true);
          trackEvent('Mobile Global Menu Clicked');
        }}
        aria-label="open navigation sidebar"
        data-cy="hamburger-nav-mobile"
      >
        <HamburgerContainer>
          <HamburgerIcon />
        </HamburgerContainer>
      </HamburgerButton>
      <MobileSidebar isOpen={isNavVisible} onOverlayClick={() => setIsNavVisible(false)}>
        <SidebarNavCard
          closeMobileNav={() => setIsNavVisible(false)}
          trackEvent={trackEvent}
          {...props}
        />
      </MobileSidebar>
    </Fragment>
  );
};

SidebarNav.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
  menuBackgroundColor: PropTypes.string,
  logout: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(SidebarNav);
