import React from 'react';
import { connect } from 'react-redux';

import SkillsByEducation from 'components/pages/SkillsByEducation';

const Wrapper = props => <SkillsByEducation {...props} />;

const mapStateToProps = state => ({
  education: state.profile.education
});

export default connect(mapStateToProps)(Wrapper);
