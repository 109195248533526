import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getAndSortJobPostings } from 'helpers/getAndSortJobPostings';

import JobPostingsHorizontalScroll from 'components/molecules/JobPostingsHorizontalScroll';

const MatchedJobPostings = ({
  clusterId,
  filterCompanyIds,
  clusterSkills,
  helpCardJobs,
  cities,
  selectedSkills
}) => {
  const [postings, setPostings] = useState(null);
  const [loadingError, setLoadingError] = useState(false);
  useEffect(() => {
    (async () => {
      if (!clusterSkills) {
        return;
      }
      setPostings(null);
      setLoadingError(false);

      try {
        const response = await getAndSortJobPostings(
          selectedSkills,
          filterCompanyIds,
          clusterSkills,
          cities
        );

        setPostings(
          response
            .map(({ url: [url], title_raw, company_name, city_name, title, ...rest }) => ({
              url,
              title: title_raw, // The title attr returned from JPA is a number, so we use title_raw
              company: company_name,
              city: city_name,
              ...rest
            }))
            .filter(posting => !!posting.url)
        );
        setLoadingError(false);
      } catch (err) {
        setPostings(null);
        setLoadingError(true);
      }
    })();
  }, [clusterSkills, selectedSkills, filterCompanyIds, cities]);

  let errorMessage;

  if (postings && postings.length === 0) {
    errorMessage = 'No jobs found for the cities you searched.';
  }
  if (loadingError) {
    errorMessage = 'There was an error loading job postings.';
  }

  return (
    <JobPostingsHorizontalScroll
      aria-label={`Job posting for this career area near ${cities.join('; ')}.`}
      errorMessage={errorMessage}
      helpCardJobs={helpCardJobs}
      clusterId={clusterId}
      postings={postings}
      type="jobs-matched"
    />
  );
};

MatchedJobPostings.propTypes = {
  clusterId: PropTypes.string,
  filterCompanyIds: PropTypes.array,
  clusterSkills: PropTypes.array,
  helpCardJobs: PropTypes.array,
  cities: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  selectedSkills: PropTypes.object.isRequired
};

export default MatchedJobPostings;
