import { connect } from 'react-redux';
import EmailVerificationForm from 'components/molecules/EmailVerificationForm';
import { profileActions } from 'store/reducers/profile';

const mapStateToProps = ({ profile }) => ({
  error: profile.error,
  isLoading: profile.isLoading
});

export default connect(
  mapStateToProps,
  {
    verifyEmail: profileActions.validateProfile.request
  }
)(EmailVerificationForm);
