import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';

import { searchPrograms } from 'services/algolia';
import Button from 'components/atoms/Button';
import Autocomplete from 'components/molecules/Autocomplete';
import Select from 'components/molecules/Select';
import EducationCard from 'containers/EducationCard';

import { red } from 'helpers/colors';

const Label = styled.label`
  letter-spacing: 0.25rem;
  margin-top: 2rem;
  margin-bottom: 0.75rem;
  font-weight: bold;
  font-size: 0.9rem;
`;

const StyledEdButton = styled(Button)`
  margin: 1rem 0 1rem;
  height: 4rem;
  padding: 1rem 3rem;
  font-size: 1.5rem;
  align-self: flex-start;
`;

const Error = styled.span`
  font-size: 1.4rem;
  color: ${red};
`;

const Margin = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
`;

class EducationSelectionWrapper extends React.Component {
  state = {
    area: null,
    completionStatus: '',
    level: null,
    error: ''
  };
  async getEducationResults(query) {
    let results = await searchPrograms(query);
    let formattedResults = results.reduce((accumulator, currVal) => {
      accumulator.push({
        id: currVal.CIPCode,
        name: currVal.CIPTitle,
        pl3: currVal.PL3Code
      });
      return accumulator;
    }, []);
    return formattedResults;
  }
  render() {
    const { addEducation, education } = this.props;
    const { area, completionStatus, error, level } = this.state;
    const EdLevels = [
      { id: 12, label: 'High School or Equivalent' },
      { id: 14, label: 'Some College (No Certificate or Degree)' },
      { id: 13, label: 'Certificate' },
      { id: 14, label: 'Associate Degree' },
      { id: 16, label: 'Bachelor Degree' },
      { id: 18, label: 'Graduate Degree' }
    ];
    const highSchoolSelected = level && level.label.includes('High School');

    const CompletionStatuses = ['Completed', 'In Progress (Active)', 'On Hold (Inactive)'];
    return (
      <>
        <Media query="(max-width: 1023px)">
          <>
            {education.map((item, index) => (
              <EducationCard key={index} edItem={item} index={index} />
            ))}
          </>
        </Media>
        <Label htmlFor="select-edlevel">SELECT LEVEL</Label>
        <Select
          required
          name="select-edlevel"
          placeholder="Select Education Level"
          ariaLabel="Use up and down arrows to select an Education Level"
          options={EdLevels.map(item => item.label)}
          onChange={e => {
            this.setState({ level: EdLevels.find(item => item.label === e.target.value) });
            if (e.target.value.includes('High School')) {
              this.setState({ area: null });
            }
          }}
          selection={level ? level.label : ''}
        />
        <Label htmlFor="autocomplete">SELECT AREA</Label>
        <Autocomplete
          disabled={highSchoolSelected}
          required={!highSchoolSelected}
          name="autocomplete"
          getItems={this.getEducationResults}
          placeholder={highSchoolSelected ? 'Not Applicable' : 'e.g. Political Science'}
          ariaLabel="Type to view and select an Education Program"
          selectedItem={area}
          selectItem={selectedArea => this.setState({ area: selectedArea })}
        />
        <Label htmlFor="select-completion">SELECT STATUS</Label>
        <Select
          required
          name="select-completion"
          placeholder="Select Degree Status"
          ariaLabel="Use up and down arrows to select a degree completion status"
          options={CompletionStatuses}
          onChange={e => this.setState({ completionStatus: e.target.value })}
          selection={completionStatus}
        />
        <Margin>
          {error && <Error aria-live="assertive">{error}</Error>}
          <StyledEdButton
            onClick={() => {
              const { error: requiredError, ...rest } = this.state;
              // For High School level, AREA is not required
              level && (area || highSchoolSelected) && completionStatus
                ? addEducation(rest) &&
                  this.setState({ area: null, level: null, completionStatus: '', error: '' })
                : this.setState({ error: 'All fields are required.' });
            }}
          >
            {education.length === 0 ? 'Add to My Education' : 'Add Another'}
          </StyledEdButton>
        </Margin>
      </>
    );
  }
}

EducationSelectionWrapper.propTypes = {
  addEducation: PropTypes.func.isRequired,
  education: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default EducationSelectionWrapper;
