import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const Scroll = styled.div`
  // necessary to prevent page becoming longer when content is long
  position: relative;
  overflow-y: auto;
  max-height: ${props => props.maxHeight};
  height: ${props => (props.takesFullHeight ? props.maxHeight : 'auto')};
  padding-right: 1rem;
`;

const Gradient = styled.span`
  z-index: 1;
  height: 5rem;
  background: linear-gradient(
    ${props => (props.position === 'top' ? '0' : '180deg')},
    /* 
      add 00 to the end of a 6-digit hex color code to make it an 8-digit hex color code. 
      The 00 is the alpha value, meaning the color will be transparent. Safari does not work when we just specify "transparent"
    */
      ${props => `${props.gradientColor}00`} 0%,
    ${props => props.gradientColor} 100%
  );
  width: 100%;
  position: absolute;
  ${props => (props.position === 'top' ? 'top: 0' : 'bottom: 0;')}
  pointer-events: none;
`;

const VerticalScrollGradient = ({
  children,
  maxHeight,
  takesFullHeight,
  gradientColor,
  className
}) => {
  const [topGradient, setTopGradient] = useState(false);
  const [bottomGradient, setBottomGradient] = useState(false);
  const scrollContainerRef = useRef();

  // if the content overflows the container, show the gradient
  const checkOverflow = () => {
    if (
      scrollContainerRef.current !== null &&
      scrollContainerRef.current.scrollHeight > scrollContainerRef.current.clientHeight
    ) {
      setBottomGradient(true);
    } else {
      setBottomGradient(false);
    }
  };

  // If already at the bottom of the scroll, hide the gradient
  const checkScroll = e => {
    if (e.target.scrollTop > 0) {
      setTopGradient(true);
    } else {
      setTopGradient(false);
    }
    const atBottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (atBottom) {
      setBottomGradient(false);
    } else {
      setBottomGradient(true);
    }
  };

  // after component mounts and updates, check if there is overflow
  // This is actually not a safe way to compare children, but ok for our purpose
  useEffect(() => {
    checkOverflow();
  }, [children]);

  // also check if we need to add gradient after window resize
  useEffect(() => {
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  });

  return (
    <Wrapper className={className}>
      {topGradient && <Gradient position="top" gradientColor={gradientColor} />}
      <Scroll
        ref={scrollContainerRef}
        takesFullHeight={takesFullHeight}
        maxHeight={maxHeight}
        onScroll={checkScroll}
      >
        {children}
      </Scroll>
      {bottomGradient && <Gradient position="bottom" gradientColor={gradientColor} />}
    </Wrapper>
  );
};

VerticalScrollGradient.propTypes = {
  // used by styled-components to specify which element to pass styles to
  className: PropTypes.string,
  children: PropTypes.node,
  // gradientColor should be a 6-digit hex color code
  gradientColor: PropTypes.string.isRequired,
  // If true, then this container will always take the full maxHeight, even when there is a lot of white space
  takesFullHeight: PropTypes.bool,
  maxHeight: PropTypes.string.isRequired
};

export default VerticalScrollGradient;
