import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonLink from 'components/atoms/ButtonLink';
import Card from 'components/atoms/Card';
import Flex from 'components/atoms/Flex';
import SkillsModal from 'components/molecules/SkillsModal';

import { ReactComponent as SkillsFilled } from 'images/skills-filled.svg';
import { ReactComponent as SkillsOutline } from 'images/nav-my-skills.svg';

import {
  blue,
  grayMedium,
  grayLight,
  grayDark,
  orange,
  orangeDark,
  green,
  greenDark
} from 'helpers/colors';

const StyledCard = styled(Card)`
  box-sizing: border-box;
  width: 30rem;
  height: 25rem;
  padding: 1.8rem;
  margin: 1rem;
  text-align: center;
  border: 1px solid ${grayLight};
  justify-content: space-evenly;
  @media (max-width: 699px) {
    width: calc(100% - 2rem);
  }
`;

const NoStyleH2 = styled.h2`
  font-size: ${props => (props.biggerFont ? '1.6rem' : '1.4rem')};
  font-weight: unset;
  margin: unset;
`;

const Count = styled.div`
  font-size: 3.6rem;
  font-weight: bold;
`;

const Circle = styled.span`
  display: flex;
  height: ${props => (props.bigger ? '1.4rem' : '1rem')};
  margin-right: 1rem;
  padding: 0.5rem 0.5rem 0.4rem 0.4rem;
  border-radius: 50%;
  background: ${props => props.color}60;
`;

const StyledSkillsFilled = styled(({ bigger, ...rest }) => <SkillsFilled {...rest} />)`
  width: ${props => (props.bigger ? '1.4rem' : '1rem')};
  height: ${props => (props.bigger ? '1.4rem' : '1rem')};
  path {
    fill: ${props => props.color};
  }
`;

const StyledHr = styled.hr`
  width: 16rem;
  margin: 2rem auto;
  border: solid 1px ${grayMedium};
`;

const StyledButtonLink = styled(ButtonLink)`
  width: fit-content;
  margin: 0 auto;
  padding: 1rem 2rem;
`;

const StyledSkillsOutline = styled(SkillsOutline)`
  margin-right: 1rem;
  position: relative;
  top: 0.5rem;
`;

const SkillsList = styled.div`
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const Pill = styled.span`
  max-width: 100%;
  background: ${grayLight};
  padding: 0.2rem 1rem;
  margin: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: ${props => (props.rounded ? '1.3rem' : '0.5rem')};
  font-size: ${props => (props.rounded ? '1.2rem' : '1.4rem')};
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  color: ${blue};
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0.1rem 0.5rem;
  /* For Safari*/
  margin: 0;
`;

const SkillCountCard = ({ skills, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  skills = skills.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <StyledCard>
      <NoStyleH2 biggerFont={type === 'All'}>
        {type !== 'All' && <Count>{skills.length}</Count>}
        {type === 'All' ? (
          <Flex alignItems="center" justifyContent="center">
            <Circle bigger color={grayMedium}>
              <StyledSkillsFilled bigger color={grayDark} />
            </Circle>
            All Skills Offered &nbsp;<Pill rounded>{skills.length}</Pill>
          </Flex>
        ) : (
          <Flex alignItems="center" justifyContent="center">
            <Circle color={type === 'Want' ? orange : green}>
              <StyledSkillsFilled color={type === 'Want' ? orangeDark : greenDark} />
            </Circle>
            Skills You {type}
          </Flex>
        )}
        {skills.length > 2 && (
          <>
            <StyledButton
              aria-label={`View more ${type} skills`}
              onClick={() => setIsModalOpen(true)}
            >
              View All
            </StyledButton>
            <SkillsModal
              type={type}
              skills={skills}
              isModalOpen={isModalOpen}
              closeModal={() => setIsModalOpen(false)}
            />
          </>
        )}
      </NoStyleH2>

      <StyledHr />

      {skills.length > 0 ? (
        <SkillsList>
          {// the space might fit up to 12-ish skills
          skills.slice(0, 12).map(skill => (
            <Pill key={skill.id}>{skill.name}</Pill>
          ))}
        </SkillsList>
      ) : (
        <StyledButtonLink inverseColor to="/skills">
          <StyledSkillsOutline />
          Add More Skills {type !== 'All' && `You ${type}`}
        </StyledButtonLink>
      )}
    </StyledCard>
  );
};

SkillCountCard.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired })
  ).isRequired,
  type: PropTypes.oneOf(['Want', 'Have', 'All']).isRequired
};

export default SkillCountCard;
