import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import KeenChart from 'Admin/components/KeenChart';
import KeenListData from 'Admin/components/KeenListData';
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';

import { fetchKeenData } from 'Admin/helpers/keen';
import useMountedAsync from 'helpers/useMountedAsync';
import loading from 'images/loading.gif';
import { ReactComponent as RefreshSvg } from 'images/refresh.svg';
import { ReactComponent as Mountains } from 'images/mountains.svg';
import { blue, grayDark } from 'helpers/colors';

const StyledCard = styled(Card)`
  padding: 2rem 2.5rem;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  height: 30rem;
  box-sizing: border-box;
  margin: 1rem;
  overflow: visible;
  @media (min-width: 640px) {
    padding: 2rem 3rem;
    width: calc(50% - 2rem);
  }
`;

const StyledH2 = styled.h2`
  width: 13rem;
`;

const Count = styled.div`
  font-size: 3rem;
  color: ${blue};
`;

const SmallWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  display: ${({ shrinkChart }) => shrinkChart && 'flex'};
  margin-bottom: ${({ shrinkChart }) => shrinkChart && '3rem'};
`;

const Loading = styled.img`
  width: 6rem;
  margin: auto;
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  font-size: 1.4rem;
  color: ${grayDark};
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 20rem;
  svg {
    margin-right: 1rem;
  }
`;

const KeenCard = ({
  analysisType,
  eventCollection,
  subdomain,
  tabs,
  targetProperty,
  timeframe,
  interval,
  ...rest
}) => {
  const [count, setCount] = useState(null);
  const [error, setError] = useState(null);

  useMountedAsync(
    () => fetchKeenData({ timeframe, analysisType, targetProperty, eventCollection, subdomain }),
    ({ result }) => setCount(result),
    () => setError(true),
    [timeframe]
  );

  if (error) {
    return (
      <StyledCard>
        <StyledH2>{rest.title}</StyledH2>
        <ErrorMessage>
          <Mountains />
          <br />
          We couldn&apos;t load these stats.
          <br /> If you have an ad blocker, try turning it off.
          <StyledButton onClick={() => location.reload()} inverseColor>
            <RefreshSvg fill={blue} />
            Reload Page
          </StyledButton>
        </ErrorMessage>
      </StyledCard>
    );
  } else if (count === null) {
    return (
      <StyledCard>
        <StyledH2>{rest.title}</StyledH2>
        <Loading src={loading} alt="Loading" />
      </StyledCard>
    );
  } else if (count === 0) {
    return (
      <StyledCard>
        <StyledH2>{rest.title}</StyledH2>
        <ErrorMessage>
          <Mountains />
          <br />
          There is no data recorded during this time frame.
        </ErrorMessage>
      </StyledCard>
    );
  } else {
    return (
      <StyledCard>
        <SmallWrapper shrinkChart={!!tabs}>
          <StyledH2>
            {rest.title}
            <Count>{count.toLocaleString('en-US')}</Count>
          </StyledH2>
          <KeenChart
            shrinkChart={!!tabs}
            setError={setError}
            targetProperty={targetProperty}
            subdomain={subdomain}
            analysisType={analysisType}
            eventCollection={eventCollection}
            timeframe={timeframe}
            interval={interval}
          />
        </SmallWrapper>
        {!!tabs && (
          <KeenListData
            tabs={tabs}
            setError={setError}
            subdomain={subdomain}
            eventCollection={eventCollection}
            timeframe={timeframe}
            {...rest}
          />
        )}
      </StyledCard>
    );
  }
};

KeenCard.propTypes = {
  title: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  timeframe: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      otherGroupBy: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  tabFilterProperties: PropTypes.shape({
    property_name: PropTypes.string.isRequired,
    property_type: PropTypes.string,
    operator: PropTypes.string,
    property_value: PropTypes.string
  }),
  targetProperty: PropTypes.string,
  analysisType: PropTypes.string,
  eventCollection: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  formatLink: PropTypes.func,
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default KeenCard;
