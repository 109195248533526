import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import ButtonLink from 'components/atoms/ButtonLink';
import Card from 'components/atoms/Card';

import { ReactComponent as PlusIcon } from 'images/plus.svg';
import { ReactComponent as ShapesIcon } from 'images/circle-and-square.svg';
import { blue } from 'helpers/colors';

const Wrapper = styled(Card)`
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  box-sizing: border-box;
  font-size: 1.4rem;
  margin: 1rem 0 0 1rem;
  padding: 1.5rem 3rem;
  width: calc(100% - 1.7rem);
  @media (max-width: 599px) {
    padding: 1.5rem;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -1.5rem;
  @media (max-width: 729px) {
    align-items: center;
    flex-direction: column;
  }
`;

const AlignItems = styled.div`
  align-items: center;
  display: flex;
  margin: 1.5rem 3rem 0 0;
`;

const Shapes = styled(ShapesIcon)`
  margin-right: 1rem;
  min-height: 2.9rem;
  min-width: 2.9rem;
`;

const StyledButton = styled(ButtonLink)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-size: 1.4rem;
  margin-top: 1.5rem;
  padding: 1rem 3rem;
`;

const Plus = styled(PlusIcon)`
  fill: ${blue};
  height: 1.5rem;
  margin-right: 1rem;
  width: 1.5rem;
`;

const NoResultsSkillCard = () => {
  const isEducation = useLocation().pathname === '/results/education';

  return (
    <Wrapper>
      <Content>
        <AlignItems>
          <Shapes />
          {isEducation ? (
            <span>
              Add skills <strong> you want</strong> to your profile to see your education matches!
            </span>
          ) : (
            <span>You haven&apos;t added any skills to your profile. Start now!</span>
          )}
        </AlignItems>
        <StyledButton inverseColor to="/skills">
          <Plus />
          Start Adding Skills
        </StyledButton>
      </Content>
    </Wrapper>
  );
};

export default NoResultsSkillCard;
