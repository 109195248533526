import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ResultsScreenReader from 'components/atoms/ResultsScreenReader';
import FlexCardContainer from 'components/molecules/FlexCardContainer';
import NoResultsFilterCard from 'components/molecules/NoResultsFilterCard';
import NoResultsSkillCard from 'components/molecules/NoResultsSkillCard';

import { blueText } from 'helpers/colors';
import { ReactComponent as EmptyIcon } from 'images/empty-list.svg';
import loadingGif from 'images/loading.gif';

const MatchesHeader = styled.h2`
  font-size: 1.8rem;
  font-weight: normal;
  margin: 0 0 0 1rem;
`;

const OtherMatchesHeader = styled(MatchesHeader)`
  margin: 4.5rem 0 0 1rem;
`;

const Loader = styled.img`
  width: 5rem;
  margin: 6rem auto;
`;

// center-aligned messages for error and loading
const ErrorMessage = styled.div`
  align-items: center;
  color: ${blueText};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  > svg {
    margin-bottom: 1.5rem;
  }
`;

const ResultsDisplay = ({
  contentName,
  error,
  isLoading,
  selectedSkills,
  openMobileSidebar,
  clearFilters,
  matchedResults,
  otherResults
}) => {
  if (isLoading) {
    return <Loader src={loadingGif} alt="loading" />;
  }

  if (error) {
    return (
      <ErrorMessage aria-live="polite">
        <EmptyIcon />
        There was an error loading {contentName.toLowerCase()}.
      </ErrorMessage>
    );
  }

  const hasSelectedSkills = !!Object.keys(selectedSkills).length;
  const noMatchesCard = hasSelectedSkills ? (
    <NoResultsFilterCard
      section="matched"
      contentName={contentName}
      openMobileSidebar={openMobileSidebar}
    />
  ) : (
    <NoResultsSkillCard />
  );

  return (
    <>
      <MatchesHeader>
        <strong>{contentName}</strong>
        &nbsp;Matched to You
      </MatchesHeader>
      {matchedResults.length ? (
        <FlexCardContainer data-cy="matched-to-you">{matchedResults}</FlexCardContainer>
      ) : (
        noMatchesCard
      )}
      {otherResults && (
        <>
          <OtherMatchesHeader>
            Other <strong>{contentName}</strong>
          </OtherMatchesHeader>
          {otherResults.length ? (
            <FlexCardContainer data-cy="other">{otherResults}</FlexCardContainer>
          ) : (
            <NoResultsFilterCard
              section="other"
              contentName={contentName}
              clearFilters={clearFilters}
            />
          )}
        </>
      )}
      <ResultsScreenReader
        contentName={contentName}
        hasSelectedSkills={hasSelectedSkills}
        matchedCount={matchedResults.length}
        othersCount={otherResults ? otherResults.length : undefined}
      />
    </>
  );
};

ResultsDisplay.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  matchedResults: PropTypes.arrayOf(PropTypes.element).isRequired,
  otherResults: PropTypes.arrayOf(PropTypes.element),
  contentName: PropTypes.string.isRequired,
  selectedSkills: PropTypes.object.isRequired,
  openMobileSidebar: PropTypes.func.isRequired
};

export default ResultsDisplay;
