import { createAction, handleActions } from 'redux-actions';

// ACTION TYPES
export const UPDATE_VIEW = 'currentViews/UPDATE_VIEW';

// Actions
export const currentViewActions = {
  updateView: createAction(UPDATE_VIEW, (view, updates) => ({ view, updates }))
};

export const initialState = {
  careerAreas: { filterSkills: [] },
  education: {
    filterSkills: [],
    filterSites: [],
    filterLevels: [],
    filterEdType: '',
    filterProgramIds: [],
    programNames: {}
  },
  jobs: { filterSkills: [], filterCompanyIds: [], companyNames: {}, filterCities: [] }
};

export default handleActions(
  {
    [UPDATE_VIEW]: (state, action) => {
      const { view, updates } = action.payload;
      return {
        ...state,
        [view]: {
          ...state[view],
          ...updates
        }
      };
    }
  },
  initialState
);
