import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Modal from 'containers/Modal';
import Button from 'components/atoms/Button';
import Card from 'components/atoms/Card';
import SkillsChooser from 'components/molecules/SkillsChooser';
import arrowRight from 'images/arrow-right-white.png';

const StyledButton = styled(Button)`
  height: 4rem;
  padding-top: 0.9rem;
  @media (min-width: 800px) {
    width: 25rem;
  }
`;

const ModalCard = styled(Card)`
  display: flex;
  align-items: center;
  padding: 2rem;
  border-radius: 3px;
  @media (min-width: 800px) {
    width: 67rem;
    padding: 2rem 3.5rem 3.5rem;
  }
`;

const ArrowIcon = styled.img`
  margin-left: 1rem;
`;

const Title = styled.h1`
  font-size: 1.6rem;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (min-width: 800px) {
    width: auto;
    text-align: left;
  }
`;

const Subtitle = styled.span`
  font-size: 1.6rem;
`;

const RelatedSkillsModal = ({ name, modalHide, history, relatedSkills, isLoading }) => {
  return (
    <Modal name={name}>
      <ModalCard>
        <Title>One more thing!</Title>
        <Subtitle>Do you have any of these related skills?</Subtitle>
        <SkillsChooser skillIds={relatedSkills.map(skill => skill.id)} isLoading={isLoading} />
        <StyledButton
          onClick={() => {
            history.push('/results');
            modalHide(name);
          }}
        >
          Done adding skills
          <ArrowIcon src={arrowRight} alt="" />
        </StyledButton>
      </ModalCard>
    </Modal>
  );
};

RelatedSkillsModal.propTypes = {
  // history comes from react-router-dom
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
  isLoading: PropTypes.bool.isRequired,
  modalHide: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  relatedSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  )
};

export default withRouter(RelatedSkillsModal);
