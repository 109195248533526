import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { blueText } from 'helpers/colors';
import { ReactComponent as Close } from 'images/close.svg';

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  width: auto;
  height: 1.5rem;
  :hover {
    cursor: pointer;
  }
  & svg {
    fill: ${blueText};
  }
`;

const Wrapper = styled(ReactModal)`
  .ReactModal__Body--open {
    overflow-y: scroll;
  }
  margin: 1rem;
  @media (min-width: 800px) {
    position: absolute;
    top: 10rem;
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translate(-50%);
    right: auto;
    bottom: auto;
    outline: 0;
  }
`;

const StyledReactModal = styled(({ className, ...props }) => (
  // Wrapper around ReactModal to position the children of the modal
  <Wrapper overlayClassName={className} closeTimeoutMS={250} {...props} />
))`
  /* overlay class properties */
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  z-index: 99;
  &[class*='after-open'] {
    opacity: 1;
  }
  &[class*='before-close'] {
    opacity: 0;
  }
`;

// The app element allows you to specify the portion of your app
// that should be hidden (via aria-hidden) to prevent assistive technologies
// such as screenreaders from reading content outside of the content of your modal.
ReactModal.setAppElement('#root');

const Modal = ({ isOpen, close, children, ...props }) => {
  return (
    <StyledReactModal isOpen={isOpen} onRequestClose={close} {...props}>
      <CloseButton onClick={close} aria-label="close modal">
        <Close />
      </CloseButton>
      {children}
    </StyledReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};

export default Modal;
