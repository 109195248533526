import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ExternalLink from 'components/atoms/ExternalLink';
import withKeen from 'hocs/withKeen';
import truncate from 'helpers/truncate';
import { grayText, grayLight, blue, green, orange, grayMedium, blueText } from 'helpers/colors';

const StyledExternalLink = styled(ExternalLink)`
  padding: 2.5rem;
  min-height: 18rem;
  height: 100%;
  :hover,
  :focus {
    text-decoration: none;
    h3 {
      text-decoration: underline;
    }
    div {
      color: ${blue};
    }
    span {
      border: 2px solid ${props => props.borderColor};
      color: ${blueText};
    }
  }
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  text-align: center;
  color: ${blueText};
  font-weight: 300;
`;

const JobTitle = styled.h3`
  font-weight: 300;
  font-size: 1.6rem;
  margin: 0 0 0.5rem 0;
`;

const CompanyName = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Location = styled.div`
  font-size: 1.4rem;
  color: ${grayText};
`;

const PillArea = styled.span`
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  font-size: 1.4rem;
  bottom: 0;
  background-color: ${props => props.color};
  border: 2px solid transparent;
  border-radius: 2rem;
  padding: 0.5rem;
  width: 15rem;
`;

const JobPostingCard = ({ clusterId, posting, trackEvent }) => {
  let skillCountText = '',
    pillColor = grayLight,
    borderColor = grayMedium;
  // wantSkills and Orange color are added for /results/internships page. Can be removed when the page is done
  if (posting.wantSkills) {
    skillCountText = `${posting.wantSkills} Skill${posting.wantSkills > 1 ? 's' : ''} You Want`;
    pillColor = `${orange}80`;
    borderColor = orange;
  } else if (posting.haveSkills) {
    skillCountText = `${posting.haveSkills} Skill${posting.haveSkills > 1 ? 's' : ''} You Have`;
    pillColor = `${green}80`;
    borderColor = green;
  } else if (posting.careerAreaSkills) {
    skillCountText = `${posting.careerAreaSkills} Career Area Skill${
      posting.careerAreaSkills > 1 ? 's' : ''
    }`;
  } else {
    skillCountText = `${posting.skills.length} Skill${
      posting.skills.length > 1 ? 's' : ''
    } Identified`;
  }

  return (
    <StyledExternalLink
      borderColor={borderColor}
      aria-label={`Click to get more information about ${posting.title}. ${
        posting.company ? `Posted by ${posting.company}.` : ''
      } ${skillCountText}`}
      onClick={() =>
        trackEvent('Job Posting Link Clicked', {
          title: posting.title,
          url: posting.url,
          company: posting.company,
          location: posting.city,
          clusterId
        })
      }
      url={posting.url}
    >
      <Wrapper data-cy="jobs-match">
        <JobTitle>{truncate(posting.title, 50)}</JobTitle>
        {posting.company && (
          <CompanyName data-cy="jobs-match-company">{posting.company}</CompanyName>
        )}
        <Location>{posting.city}</Location>
        <PillArea color={pillColor}>{skillCountText}</PillArea>
      </Wrapper>
    </StyledExternalLink>
  );
};

JobPostingCard.propTypes = {
  clusterId: PropTypes.string, // Only passed in while on cluster page
  posting: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    careerAreaSkills: PropTypes.number, // Only passed in while on cluster page
    haveSkills: PropTypes.number,
    wantSkills: PropTypes.number,
    company: PropTypes.string,
    city: PropTypes.string.isRequired,
    skills: PropTypes.array
  }).isRequired,
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(JobPostingCard);
