import { connect } from 'react-redux';

import EducationSelectionWrapper from 'components/organisms/EducationSelectionWrapper';
import { profileActions } from 'store/reducers/profile';

const { addEducation } = profileActions;

const mapStateToProps = state => ({
  education: state.profile.education
});

export default connect(
  mapStateToProps,
  { addEducation }
)(EducationSelectionWrapper);
