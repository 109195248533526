import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'lodash.isequal';
import Media from 'react-media';

import withSkillsForIds from 'hocs/withSkillsForIds';
import SkillButton from 'components/molecules/SkillButton';
import { grayMedium, blue } from 'helpers/colors';
import spinner from 'images/loading.gif';
import arrow from 'images/arrow-right-white.png';
import emptyIcon from 'images/empty-skill-list.svg';

const PAGE_SIZE = 15;
const MOBILE_PAGE_SIZE = 8;

const ChooserWrapper = styled.div`
  width: 100%;
  min-height: 30rem;
`;

const PlaceHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  margin-top: 6rem;
  font-size: 1.4rem;
  > div a {
    font-size: 1.4rem;
  }
`;

const EmptyText = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const EmptyImage = styled.img`
  height: 4rem;
`;

const Spinner = styled.img`
  width: 5rem;
`;

const ListWrapper = styled.div`
  margin: 1.5rem 0;
`;

const SkillList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  padding: 0;
  list-style: none;
  li {
    margin: 0.5rem;
  }
`;

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 1.5rem 0;
`;

const PageButton = styled.button`
  background: ${blue};
  margin-right: 1rem;
  padding: 1rem 5rem;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  color: white;
  :disabled {
    cursor: not-allowed;
    background: ${grayMedium};
  }
`;

const FlipImg = styled.img`
  transform: rotate(180deg);
`;

class SkillsChooser extends React.Component {
  state = {
    page: 1
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.skills, this.props.skills)) {
      this.setState({ page: 1 });
    }
    if (this.state.page !== prevState.page) {
      const firstSkill = document.getElementById('skill-button-focus-id-0');
      firstSkill && firstSkill.focus();
    }
  }

  render() {
    const { skills, isLoading, emptyText, children, forceMobilePageSize } = this.props;
    let placeHolder;
    if (isLoading) {
      placeHolder = (
        <PlaceHolder>
          <Spinner data-cy="spinner" src={spinner} alt="loading relevant skills" />
        </PlaceHolder>
      );
    } else if (!skills.length && emptyText) {
      placeHolder = (
        <PlaceHolder>
          <EmptyImage alt="" src={emptyIcon} />
          <EmptyText> {children || emptyText}</EmptyText>
        </PlaceHolder>
      );
    }

    const { page } = this.state;
    return (
      <ChooserWrapper>
        {placeHolder || (
          <Media query="(max-width: 554px)">
            {matches => {
              const pageSize = matches || forceMobilePageSize ? MOBILE_PAGE_SIZE : PAGE_SIZE;

              const pageCount = Math.ceil(skills.length / pageSize);
              const displayedSkills = skills.slice(pageSize * (page - 1), pageSize * page);

              return (
                <>
                  <ListWrapper>
                    <SkillList aria-label={`Related skills, page ${page} of ${pageCount}.`}>
                      {displayedSkills.map((skill, index) => (
                        <SkillButton
                          key={skill.id}
                          skill={skill}
                          focusId={`skill-button-focus-id-${index}`}
                          data-cy={`skill-button-${index}`}
                        />
                      ))}
                    </SkillList>
                  </ListWrapper>
                  <ButtonBar>
                    <PageButton
                      disabled={page === 1}
                      aria-label={
                        page === 1
                          ? 'No previous pages of related skills'
                          : `Go to page ${page - 1} of related skills`
                      }
                      data-cy="skills-chooser-back"
                      onClick={() => this.setState(old => ({ page: old.page - 1 }))}
                    >
                      <FlipImg src={arrow} alt="" />
                    </PageButton>
                    <PageButton
                      disabled={page === pageCount}
                      aria-label={
                        page === pageCount
                          ? 'No more pages of related skills'
                          : `Go to page ${page + 1} of related skills`
                      }
                      data-cy="skills-chooser-next"
                      onClick={() => this.setState(old => ({ page: old.page + 1 }))}
                    >
                      <img src={arrow} alt="" />
                    </PageButton>
                  </ButtonBar>
                </>
              );
            }}
          </Media>
        )}
      </ChooserWrapper>
    );
  }
}

SkillsChooser.propTypes = {
  children: PropTypes.node,
  emptyText: PropTypes.string,
  forceMobilePageSize: PropTypes.bool,
  isLoading: PropTypes.bool,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired
};

export default withSkillsForIds(SkillsChooser);
