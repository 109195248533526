import React from 'react';
import styled from 'styled-components';

const ScrollToContent = styled.button`
  padding: 6px;
  position: absolute;
  top: -4rem;
  left: 0;
  color: #000;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: transparent;
  transition: top 0.1s ease-out, background 0.1s linear;
  z-index: 100;
  font-size: 1.4rem;
  border: none;

  &:focus {
    top: 0;
    background: #f5474f;
    outline: 0;
  }
`;

const SkipToContent = ({ afterAnimate }) => (
  <ScrollToContent
    onClick={() => {
      document.getElementById('main-content').scrollIntoView();
      afterAnimate();
    }}
  >
    skip to main content
  </ScrollToContent>
);

export default SkipToContent;
