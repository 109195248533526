import React from 'react';
import styled from 'styled-components';

const SvgIcon = styled(({ fill, Svg, ...props }) => <Svg {...props} />)`
  & > path {
    fill: ${props => props.fill};
  }
`;

export default SvgIcon;
