import { connect } from 'react-redux';
import EducationCard from 'components/molecules/EducationCard';

import { profileActions } from 'store/reducers/profile';

const { removeEducation } = profileActions;

export default connect(
  null,
  { removeEducation }
)(EducationCard);
