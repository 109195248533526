import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import ButtonLink from 'components/atoms/ButtonLink';
import Card from 'components/atoms/Card';
import CloseButton from 'components/atoms/CloseButton';
import NavItem from 'components/atoms/NavItem';
import VerticalScrollGradient from 'components/atoms/VerticalScrollGradient';

import { grayLight, grayMedium, grayText } from 'helpers/colors';

const SidebarCard = styled(Card)`
  // for the absolutely-positioned close button
  position: relative;
  border-radius: 0;
  width: 26rem;
  height: 100%;
  padding: 3rem 0;
  box-sizing: border-box;
`;

const GradientContainer = styled(VerticalScrollGradient)`
  height: calc(100% - 4rem);
  > div {
    // skip the padding-right we usually save for the scroll bar
    padding-right: 0;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  letter-spacing: 0.2rem;
  padding-left: 3.5rem;
  font-size: 0.9rem;
  font-weight: bold;
  color: ${grayText};
  margin: 0.5rem 0;
`;

const StyledHr = styled.hr`
  border: none;
  border-top: 1px solid ${grayMedium};
  margin: 1.5rem 0 2rem 0;
`;

const Buttons = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
`;

const Button = styled(ButtonLink)`
  font-weight: normal;
  padding: 1rem 0;
  border: 0;
  border-radius: 0;
  background: ${props => props.inverseColor && grayLight};
  flex: 1 0 50%;
`;

const SidebarNavCard = ({
  closeMobileNav,
  isLoggedIn,
  userRole,
  location,
  logout,
  trackEvent,
  plcMenuLabel,
  isPlcEnabled
}) => {
  // If redirectUrl already exists, then preserve it across login/signup/verification flows
  // Otherwise, use current page's url as redirectUrl
  let redirectUrl;
  const currentPath = location.pathname;
  if (
    currentPath.includes('login') ||
    currentPath.includes('signup') ||
    currentPath.includes('forgot-password') ||
    currentPath.includes('verification')
  ) {
    redirectUrl = queryString.parse(window.location.search).redirect;
  } else if (currentPath !== '/') {
    redirectUrl = encodeURIComponent(currentPath);
  }

  if (redirectUrl) {
    redirectUrl = `?redirect=${redirectUrl}`;
  } else {
    redirectUrl = '';
  }

  const sectionRoutes = [
    {
      sectionTitle: 'ABOUT ME',
      routes: [
        {
          path: '/goals',
          title: 'My Goal'
        },
        window.nation === 'US' && {
          path: '/degrees',
          title: 'My Education'
        },
        {
          path: '/skills',
          title: 'My Skills'
        }
      ].filter(Boolean)
    },
    {
      sectionTitle: 'MY MATCHES',
      routes: [
        { title: 'Career Areas', path: '/results/career-areas' },
        { title: 'Education', path: '/results/education' },
        { title: 'Job Postings', path: '/results/jobs' },
        isPlcEnabled && { title: plcMenuLabel, path: '/results/plc' }
      ].filter(Boolean)
    }
  ];

  if (['Admin', 'View Everything', 'View Profiles', 'View Analytics'].includes(userRole)) {
    sectionRoutes.push({
      sectionTitle: 'ADMIN',
      routes: [
        userRole !== 'View Profiles' && {
          path: '/admin/analytics',
          title: 'Analytics'
        },
        userRole !== 'View Analytics' && {
          path: '/admin/profiles',
          title: 'Profiles'
        },
        userRole === 'Admin' && {
          path: '/admin/team',
          title: 'Team Settings'
        }
      ].filter(Boolean)
    });
  }

  if (isLoggedIn) {
    sectionRoutes.push({
      sectionTitle: 'MY ACCOUNT',
      routes: [
        {
          path: '/account/settings',
          title: 'Settings'
        }
      ]
    });
  }

  return (
    <SidebarCard>
      <CloseButton onClick={closeMobileNav} ariaLabel="close navigation sidebar" />
      <GradientContainer gradientColor="#FFFFFF" maxHeight="100%">
        {sectionRoutes.map((section, index) => (
          <Links key={section.sectionTitle}>
            {index !== 0 && <StyledHr />}
            <SectionTitle>{section.sectionTitle}</SectionTitle>
            {section.routes.map(item => (
              <NavItem key={item.title} closeMobileNav={closeMobileNav} {...item} />
            ))}
          </Links>
        ))}
      </GradientContainer>
      <Buttons>
        {isLoggedIn ? (
          <Button
            to="/"
            role="button"
            onClick={() => {
              logout();
              closeMobileNav();
            }}
          >
            Log Out
          </Button>
        ) : (
          <>
            <Button
              inverseColor
              to={`/login${redirectUrl}`}
              onClick={() => {
                trackEvent('Login Link Clicked');
                closeMobileNav();
              }}
            >
              Login
            </Button>
            <Button
              to={`/signup${redirectUrl}`}
              onClick={() => {
                trackEvent('Sign up Link Clicked');
                closeMobileNav();
              }}
            >
              Sign up
            </Button>
          </>
        )}
      </Buttons>
    </SidebarCard>
  );
};

SidebarNavCard.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  logout: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  closeMobileNav: PropTypes.func.isRequired,
  plcMenuLabel: PropTypes.string,
  isPlcEnabled: PropTypes.bool
};

export default withRouter(SidebarNavCard);
