const fetchWithRetry = (...args) =>
  fetch(...args).then(res => {
    if (res.ok) {
      return res;
    } else {
      return fetch(...args);
    }
  });

export default fetchWithRetry;
