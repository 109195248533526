import { connect } from 'react-redux';
import SelectedSkillsPanel from 'components/molecules/SelectedSkillsPanel';
import { profileActions } from 'store/reducers/profile';

const { toggleDisplayAsList, removeSelectedSkill } = profileActions;

const mapStateToProps = ({ profile: { selectedSkills, displayAsList }, validSkills }) => {
  const posessedSkills = Object.keys(selectedSkills)
    .filter(key => selectedSkills[key] === 'have')
    .filter(id => validSkills.data[id])
    .map(id => ({ ...validSkills.data[id], selectionType: 'have' }));
  const wantedSkills = Object.keys(selectedSkills)
    .filter(key => selectedSkills[key] === 'want')
    .filter(id => validSkills.data[id])
    .map(id => ({ ...validSkills.data[id], selectionType: 'want' }));

  return {
    posessedSkills,
    wantedSkills,
    displayAsList,
    validSkillsIsLoaded: validSkills.isLoaded,
    validSkillsError: !!validSkills.error
  };
};

export default connect(mapStateToProps, { toggleDisplayAsList, removeSelectedSkill })(SelectedSkillsPanel);
