import truncate from './truncate';
/**
 * Accepts an array of skills and a number for the max number of characters in your final string.
 * the function concatenates the top 3 skill names separated by a / .
 * If the string is longer than the max number of characters, it trims to the last full word within that limit.
 * @param {array} primarySkills - an array of skill objects. needs a name in the object
 * @param {object} validSkills - an object of all valid skills accessible by id
 * @param {number} maxChars - a number to limit the number of characters in the returned string
 * @returns {string} concatenated string of top skills
 */
// TODO: this method can be eventually removed.
// Top 3 skill names are available as `name` field from Clusters API. Just need to refactor to use it
const getAndTrimTopSkills = (primarySkills = [], validSkills, maxChars) => {
  // returns a string of the top three skills joined by a / separator
  let topSkills = primarySkills
    .map(({ id }) => validSkills[id] && validSkills[id].name)
    .filter(Boolean)
    .slice(0, 3)
    .join(' / ');
  // We want to truncate the string of top skills if the length of the string is too long
  return truncate(topSkills, maxChars);
};

export default getAndTrimTopSkills;
