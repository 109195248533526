import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { blue, blueText } from 'helpers/colors';

const Item = styled(NavLink)`
  border-left: 3px solid transparent;
  padding-left: 3rem;
  color: ${blueText};
  font-size: 1.4rem;
  line-height: 3rem;
  text-decoration: none;
`;

const NavItem = ({ path, title, closeMobileNav, ...props }) => {
  return (
    <Item
      to={path}
      activeStyle={{
        borderLeft: `3px solid ${blue}`,
        fontWeight: '700',
        background: `${blue}0A`
      }}
      onClick={() => closeMobileNav()}
      {...props}
    >
      {title}
    </Item>
  );
};

NavItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  closeMobileNav: PropTypes.func.isRequired
};

export default NavItem;
