import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Margin = styled(({ component: Component, ...props }) => <Component {...props} />)`
  margin: ${props => `${props.margin}`};
`;

Margin.defaultProps = {
  component: 'div'
};

Margin.propTypes = {
  margin: PropTypes.string
};

export default Margin;
