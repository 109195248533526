import { connect } from 'react-redux';
import Settings from 'components/pages/Settings';
import { profileActions } from 'store/reducers/profile';

const mapStateToProps = ({ profile }) => ({
  email: profile.email,
  isEmailChanging: profile.isEmailChanging
});

export default connect(mapStateToProps, {
  changeEmail: profileActions.changeEmail.request
})(Settings);
