import { countEducationSkills } from 'helpers/countEducationSkills';
import { getAllOfEdType, getCoursesBySkills, getGroupsBySkills } from 'services/courses';

/**
 * Get education results of specified type that match inputed skills
 * @param {Object} selectedSkills The object of user selected skills
 * @param {string[]} filterSkills An array of skillIds to further filter education by
 * @param {string[]} sites An array of selected skillabi sites to filter education by
 * @param {String[]} levels An array of selected groupTypeClass IDs to filter programs by
 * @param {String[]} programIds An array of selected program IDs to filter courses by
 * @param {'Courses' | 'Programs'} edType The type of education item being retrieved
 * @param {number} [offset=0] The number of results to skip in the DB
 * @returns {Object[]} Returns array of either course or program objects
 */
export const getEducationMatches = async (
  selectedSkills,
  filterSkills,
  sites,
  levels,
  programIds,
  edType,
  offset = 0
) => {
  let skillScoreMappings = {};
  // Remove this conditional if we get rid of the plc page
  let isPlc = window.location.pathname === '/results/plc';

  Object.keys(selectedSkills).forEach(skill => {
    if (isPlc && selectedSkills[skill] === 'have') {
      skillScoreMappings[skill] = 1;
    } else if (!isPlc && selectedSkills[skill] === 'want') {
      skillScoreMappings[skill] = 1;
    }
  });

  let matches =
    edType === 'Courses'
      ? await getCoursesBySkills(skillScoreMappings, filterSkills, sites, programIds, 12, offset)
      : await getGroupsBySkills(skillScoreMappings, filterSkills, sites, levels, 12, offset);

  // Bounding total by 96 for consistency with jobs
  return {
    data: countEducationSkills(matches.data, selectedSkills, null, edType),
    total: Math.min(matches.meta.totalAvailable, 96)
  };
};

/**
 * Get nonmatching education results
 * @param {String[]} filterSkills An array of skillIds to filter Other Education by
 * @param {string[]} sites An array of selected skillabi sites to filter Other Education by
 * @param {String[]} levels An array of selected groupTypeClass IDs to filter Other Programs by
 * @param {String[]} programIds An array of selected program IDs to filter Other Courses by
 * @param {'Courses' | 'Programs'} edType The type of education item being retrieved
 * @param {number} [offset=0] The number of results to skip in the DB
 * @returns {Object[]} Returns array of either course or program objects
 */
export const getEducationOthers = async (
  filterSkills,
  sites,
  levels,
  programIds,
  edType,
  offset = 0
) => {
  let others = await getAllOfEdType(
    filterSkills,
    sites,
    edType === 'Programs' ? levels : [],
    // only filter courses by programs
    edType === 'Programs' ? [] : programIds,
    edType === 'Programs' ? 'group' : 'course',
    12,
    offset
  );
  // Bounding total by 96 for consistency with jobs
  return { data: others.data, total: Math.min(others.meta.totalAvailable, 96) };
};
