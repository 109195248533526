const overviewData = (sortedProfiles, skills) => {
  const data = sortedProfiles.map(profile => {
    if (!profile.attributes.selectedSkills) {
      profile.attributes.selectedSkills = [];
    }
    const skillsObject = profile.attributes.selectedSkills;
    profile.skills = Object.keys(skillsObject)
      .map(skillId => skills.filter(skill => skill.id === skillId))
      .flat();

    return [
      profile.attributes.email,
      profile.attributes.createdAt.slice(0, 10),
      /*
      These updatedAt times are stored in UTC which means data can be skewed by (-8 PST).
      Meaning that 4pm pst is technically stored as beginning tomorrow.
      If we want all profiles updated in the last day, this may need to be updated accordingly.
       */
      profile.attributes.updatedAt.slice(0, 10),
      profile.attributes.goal,
      profile.attributes.city,
      '(see "Education" tab)',
      '(see "Want Skills" tab)',
      '(see "Have Skills" tab)',
      Object.values(skillsObject).filter(skill => skill === 'want').length,
      Object.values(skillsObject).filter(skill => skill === 'have').length,
      Object.values(skillsObject).length,
      ...profile.skills.map(skill => skill.name)
    ];
  });

  const columns = [
    'Email',
    'Created',
    'Updated',
    'Goal',
    'City',
    'Education',
    'Want Skills',
    'Have Skills',
    '# of Want Skills',
    '# of Have Skills',
    'Total Skills',
    'All Skills -->'
  ];
  return { data, columns };
};

export default overviewData;
