import { connect } from 'react-redux';
import { fetchValidSkillsActions } from 'store/reducers/validSkills';

const withFetchValidSkills = connect(
  null,
  {
    fetchValidSkills: fetchValidSkillsActions.requestOnce
  }
);

export default withFetchValidSkills;
