import KeenAnalysis from 'keen-analysis';
import config from 'config';

const client = new KeenAnalysis({
  projectId: config.keen.projectId,
  readKey: config.keen.readKey
});

const fetchKeenData = ({
  eventCollection,
  analysisType = 'count',
  targetProperty,
  subdomain,
  timeframe,
  interval,
  groupBy = null,
  filters,
  orderBy = null
}) => {
  let baseFilters = [
    {
      operator: 'eq',
      property_name: 'subdomain',
      property_value: subdomain
    },
    // Remove Lightcast's IP address from stat counts
    {
      operator: 'ne',
      property_name: 'ip_address',
      // IP Address for old building
      property_value: '64.126.168.95'
    },
    {
      operator: 'ne',
      property_name: 'ip_address',
      // IP Address for new building
      property_value: '162.142.27.11'
    },
    {
      operator: 'ne',
      property_name: 'ip_address',
      // Also IP Address for new building
      property_value: '162.142.27.12'
    },
    // ignore bot traffic
    {
      operator: 'not_contains',
      property_name: 'user_agent',
      property_value: 'bot'
    }
  ];
  let allFilters = filters ? filters.concat(baseFilters) : baseFilters;
  return client.query({
    eventCollection,
    analysisType,
    targetProperty,
    groupBy,
    timeframe,
    interval,
    orderBy,
    filters: allFilters
  });
};

export { fetchKeenData };
