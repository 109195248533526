import React from 'react';
import PropTypes from 'prop-types';

import JobPostingCard from 'components/molecules/JobPostingCard';
import LoadMoreButton from 'components/molecules/LoadMoreButton';
import ResultsDisplay from 'components/organisms/ResultsDisplay';

const JobResults = ({
  clearFilters,
  jobResults,
  selectedSkills,
  setMobileSidebarOpen,
  loadMoreJobs
}) => {
  const { matches, others, isLoading, isLoadingMore, endOfList, error } = jobResults;

  const matchedCards = matches
    // some temporary logic filtering duplicate postings from JPA
    // can remove this filtering if JPA no longer returns duplicates
    .filter(
      (currentItem, currentIndex) =>
        // if currentItem is the first item with the same id, then keep it. Filter out the rest
        matches.findIndex(duplicate => duplicate.id === currentItem.id) === currentIndex
    )
    .map(posting => <JobPostingCard key={posting.id} posting={posting} />);

  // When `others` is null, the OTHERS section won't display at all
  let otherCards =
    others &&
    others
      // remove duplicates
      .filter(
        (currentItem, currentIndex) =>
          others.findIndex(duplicate => duplicate.id === currentItem.id) === currentIndex
      )
      // filter out MATCHES from OTHERS
      .filter(other => matches.every(match => match.id !== other.id))
      .map(posting => <JobPostingCard key={posting.id} posting={posting} />);

  return (
    <>
      <ResultsDisplay
        contentName="Jobs"
        matchedResults={matchedCards}
        otherResults={otherCards}
        selectedSkills={selectedSkills}
        openMobileSidebar={() => setMobileSidebarOpen(true)}
        isLoading={isLoading}
        error={error}
        clearFilters={clearFilters}
      />
      {!isLoading && !error && (
        <LoadMoreButton isLoading={isLoadingMore} loadMore={loadMoreJobs} endOfList={endOfList} />
      )}
    </>
  );
};

JobResults.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  jobResults: PropTypes.shape({
    matches: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
    others: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
    isLoading: PropTypes.bool.isRequired,
    isLoadingMore: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    endOfList: PropTypes.bool.isRequired
  }).isRequired,
  selectedSkills: PropTypes.object.isRequired,
  setMobileSidebarOpen: PropTypes.func.isRequired,
  loadMoreJobs: PropTypes.func.isRequired
};

export default JobResults;
