import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Amplify from '@aws-amplify/core';
import cognitoAuth from '@aws-amplify/auth';
import styled from 'styled-components';
import Media from 'react-media';

import AdminSettingsTable from 'Admin/components/AdminSettingsTable';
import AdminSettingsList from 'Admin/components/AdminSettingsList';
import NewMemberModal from 'Admin/components/NewMemberModal';
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';

import { ReactComponent as PlusSvg } from 'images/plus.svg';
import satelliteEarth from 'images/satellite-earth.svg';
import loadingGif from 'images/loading.gif';
import { flashError } from 'helpers/flashMessage';
import { blue } from 'helpers/colors';
import { getAllProfiles } from 'services/profiles';

import config from 'config';

Amplify.configure({
  Auth: config.awsCognitoAuth
});

const BodyCard = styled(Card)`
  width: calc(100% - 7rem);
  margin: 4rem auto;
  @media (max-width: 799px) {
    width: calc(100% - 3.5rem);
  }
`;

const CardWrapper = styled.div`
  padding: 2rem 3rem;
`;

const HeaderSection = styled.div`
  display: flex;
  padding-bottom: 1rem;
  justify-content: space-between;
  @media (max-width: 799px) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 2.5rem;
  margin: 0;
  @media (max-width: 799px) {
    margin-bottom: 1.5rem;
  }
`;

const StyledPlusSvg = styled(PlusSvg)`
  fill: ${blue};
  padding-right: 1rem;
`;

const InviteMemberBtn = styled(Button)`
  background-color: white;
  color: ${blue};
  border: 1px solid ${blue};
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  padding: 0.8rem 2.5rem;
  max-width: 21rem;
`;

const Loading = styled.img`
  margin: auto;
  width: 6rem;
`;

const ErrorMessage = styled.div`
  max-width: 50rem;
  font-size: 2rem;
  text-align: center;
  margin: auto;
  padding: 5rem 5rem 10rem;
  @media (max-width: 559px) {
    text-align: left;
    padding: 5rem 2rem;
  }
`;

const ErrorImage = styled.img`
  display: block;
  width: 20rem;
  margin: auto;
`;

const AdminTeamSettings = ({ subdomain, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isNewMemberModalOpen, setIsNewMemberModalOpen] = useState(false);
  const [profiles, setProfiles] = useState(null);
  const userRoles = [
    'Admin',
    'View Everything',
    'View Analytics',
    'View Profiles',
    'Remove Access'
  ];

  const getAndSetAdminProfiles = async () => {
    setIsLoading(true);
    try {
      const response = await getAllProfiles(subdomain);
      const adminProfiles = response.data
        .map(profile => profile.attributes)
        .filter(profile => profile.userRole);
      setProfiles(adminProfiles);
    } catch (e) {
      setProfiles(null);
    }
    setIsLoading(false);
  };

  const checkUserAccess = async callbackFunc => {
    const authenticatedUser = await cognitoAuth.currentUserInfo();
    if (!authenticatedUser) {
      history.push('/login?redirect=Admin/team');
    } else if (authenticatedUser.attributes['custom:userRole'] !== 'Admin') {
      history.push('/');
      flashError('Sorry, you must be a site admin to access this page');
    } else {
      callbackFunc();
    }
  };

  useEffect(() => {
    document.title = 'Lightcast SkillsMatch - Admin Team Settings';
    checkUserAccess(getAndSetAdminProfiles);
  }, [history]);

  if (isLoading) {
    return <Loading src={loadingGif} alt="" />;
  } else if (!profiles) {
    return (
      <ErrorMessage>
        <ErrorImage src={satelliteEarth} alt="" />
        We are sorry, there was an error getting the profiles. Try refreshing the page or try again
        later.
      </ErrorMessage>
    );
  } else {
    return (
      <>
        <BodyCard>
          <CardWrapper>
            <HeaderSection>
              <Title>Team Settings</Title>
              <InviteMemberBtn onClick={() => setIsNewMemberModalOpen(true)}>
                <StyledPlusSvg />
                Invite New Member
              </InviteMemberBtn>
            </HeaderSection>
            {profiles.length ? (
              <Media query="(min-width: 800px)">
                {matches =>
                  matches ? (
                    <AdminSettingsTable
                      reloadAdminProfiles={getAndSetAdminProfiles}
                      options={userRoles}
                      adminProfiles={profiles}
                    />
                  ) : (
                    <AdminSettingsList
                      reloadAdminProfiles={getAndSetAdminProfiles}
                      options={userRoles}
                      adminProfiles={profiles}
                    />
                  )
                }
              </Media>
            ) : (
              <ErrorMessage>
                There are no admin members on your team yet. Click the
                <b> &quot; Invite New Member &quot; </b>
                button above to add a new admin member to your team.
              </ErrorMessage>
            )}
          </CardWrapper>
        </BodyCard>
        <NewMemberModal
          isOpen={isNewMemberModalOpen}
          close={() => setIsNewMemberModalOpen(false)}
          reloadAdminProfiles={getAndSetAdminProfiles}
          subdomain={subdomain}
        />
      </>
    );
  }
};

AdminTeamSettings.propTypes = {
  subdomain: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default AdminTeamSettings;
