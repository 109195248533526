import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';

import FormInput from 'components/atoms/FormInput';
import LoadingButton from 'components/molecules/LoadingButton';

import { required, isEmail, composeValidators } from 'helpers/validateForm';

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 850px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const EmailInputWrapper = styled.div`
  @media (min-width: 850px) {
    flex-basis: 49%;
  }
`;

const StyledButton = styled(LoadingButton)`
  @media (min-width: 800px) {
    width: 27rem;
  }
`;

const ChangeEmailForm = ({ trackEvent, isEmailChanging, changeEmail }) => {
  return (
    <Form
      onSubmit={values => {
        changeEmail(values.newEmail.toLowerCase());
        trackEvent('Attempted email change');
      }}
      validate={values => {
        const errors = {};
        if (values.newEmail !== values.confirmEmail) {
          errors.confirmEmail = 'Email must match';
        }
        return errors;
      }}
    >
      {({ handleSubmit, form, hasValidationErrors }) => (
        <form
          onSubmit={values => {
            handleSubmit(values);
            form.restart();
          }}
        >
          <EmailWrapper>
            <Field name="newEmail" validate={composeValidators(required, isEmail)}>
              {({ input, meta }) => (
                <EmailInputWrapper>
                  <FormInput
                    name="email"
                    autoComplete="off"
                    label="New Email"
                    error={meta.error && meta.touched ? meta.error : ''}
                    {...input}
                  />
                </EmailInputWrapper>
              )}
            </Field>

            <Field name="confirmEmail" validate={composeValidators(required, isEmail)}>
              {({ input, meta }) => (
                <EmailInputWrapper>
                  <FormInput
                    name="email"
                    autoComplete="off"
                    label="Re-enter New Email"
                    error={meta.error && meta.touched ? meta.error : ''}
                    {...input}
                  />
                </EmailInputWrapper>
              )}
            </Field>
          </EmailWrapper>

          <StyledButton disabled={hasValidationErrors} isLoading={isEmailChanging}>
            Change Email
          </StyledButton>
        </form>
      )}
    </Form>
  );
};

ChangeEmailForm.propTypes = {
  trackEvent: PropTypes.func.isRequired,
  isEmailChanging: PropTypes.bool.isRequired,
  changeEmail: PropTypes.func.isRequired
};

export default ChangeEmailForm;
