import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const VisuallyHidden = styled.div`
  font-size: 0;
`;

const AriaAlert = ({ children }) => (
  <VisuallyHidden role="alert" aria-live="assertive">
    {children}
  </VisuallyHidden>
);

AriaAlert.propTypes = {
  children: PropTypes.node
};

export default AriaAlert;
