import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'helpers/colors';

const TabButton = styled.button`
  background-color: transparent;
  color: ${colors.blueText};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: ${props => (props.isActive ? 600 : 400)};
  margin: 0;
  padding: 0 1rem;
  white-space: nowrap;
`;

TabButton.propTypes = {
  isActive: PropTypes.bool.isRequired
};

export default TabButton;
