import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';

import Card from 'components/atoms/Card';
import CircleButton from 'components/atoms/CircleButton';
import DirectionalCaret from 'components/atoms/DirectionalCaret';
import HorizontalScroll from 'components/atoms/HorizontalScroll';

import colors from 'helpers/colors';

const MobileCardsWrapper = styled.div`
  display: flex;
  margin-left: 3rem;

  @media (max-width: 549px) {
    margin-left: 1.5rem;
  }
`;

const scrollCardWidthRems = 26;
const scrollCardHorizontalMargin = 0.5;
const buttonWrapperWidth = 4;

const ButtonWrapper = styled.div`
  align-items: center;
  ${({ position }) => position === 'right' && 'margin-right: 1rem;'}
  display: flex;
  height: auto;
  justify-content: center;
  min-width: ${buttonWrapperWidth}rem;
  z-index: 2;
`;

const NoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const DesktopCardsWrapper = styled.div`
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
  margin: 0;
  overflow-x: hidden;
  position: relative;
  width: calc(100% - ${buttonWrapperWidth * 2 + 1}rem);
`;

const AnimatedWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  left: ${({ left }) => left}px;
  position: relative;
  transition: ${({ animationDisabled }) => (animationDisabled ? 'none' : 'left 0.5s ease')};
`;

const Gradient = styled.div`
  background: linear-gradient(
    ${({ right }) => (right ? '270' : '90')}deg,
    rgba(${colors.grayLightRGB}, 1) 10%,
    rgba(${colors.grayLightRGB}, 0) 100%);
  height: calc(100% - 2rem);
  top: 1rem;
  position: absolute;
  transition: ${({ animationDisabled }) => (animationDisabled ? 'none' : 'z-index .1s ease-out')};
  width: 4rem;
  ${({ right }) => right && `right: 0rem;`}
  z-index: ${({ isVisible }) => (isVisible ? '3' : '-1')};
`;

const Spacer = styled.div`
  ${({ scrollPosition }) => (scrollPosition ? `min-width: 3rem;` : `min-width: 0;`)}
  transition:${({ animationDisabled }) => (animationDisabled ? 'none' : 'min-width 0.5s ease')};
`;

const ScrollCard = styled(Card)`
  box-sizing: border-box;
  margin: 1rem ${scrollCardHorizontalMargin}rem;
  min-width: ${scrollCardWidthRems}rem;
  width: ${scrollCardWidthRems}rem;
  transition: all 0.25s ease-in-out;
  :hover {
    transform: ${props => (props.isError ? '' : 'scale(1.04)')};
    box-shadow: ${props => (props.isError ? '' : '0px 3px 8px 3px rgba(0, 0, 0, 0.17);')};
  }

  ${({ isError }) =>
    isError
      ? `flex-grow: 1`
      : `&:last-of-type {
          margin-right: 3rem;
        }`}

  @media (max-width: 549px) {
    &:last-of-type {
      margin-right: 1.5rem;
    }
  }
`;

const CallToActionWrapper = styled.div`
  margin: 1rem ${scrollCardHorizontalMargin}rem;
  min-width: ${scrollCardWidthRems}rem;

  div {
    margin: unset;
  }

  h3,
  h4 {
    padding: ${({ type }) => (type === 'education' ? '2.75rem' : '1.1rem')};
  }

  a {
    padding: 1.15rem;
  }
`;

export const HorizontalCardScroll = ({
  callToAction,
  children,
  className,
  'aria-label': ariaLabel,
  isError,
  type
}) => {
  let [scrollPosition, setScrollPosition] = useState(0);
  let [scrollLength, setScrollLength] = useState(0);
  let [maxScrollDistance, setMaxScrollDistance] = useState(null);
  let [containerDimensions, setContainerDimensions] = useState(null);
  let containerEl = useRef(null);
  let cardRef = useRef(null);

  const getDimensions = () => {
    const cardWidth = cardRef.current.getBoundingClientRect().width;
    const pxPerRem = cardWidth / scrollCardWidthRems;
    const cardWidthWithMargin = cardWidth + pxPerRem * scrollCardHorizontalMargin * 2;
    const scrollCardContainerDimensions = containerEl.current.getBoundingClientRect();
    const maxFullCardsInInnerWidth = Math.floor(
      scrollCardContainerDimensions.width / cardWidthWithMargin
    );
    const newScrollLength = maxFullCardsInInnerWidth * cardWidthWithMargin;
    const childCount = callToAction
      ? React.Children.count(children) + 1
      : React.Children.count(children);
    const maxNumScrolls = Math.ceil(childCount / maxFullCardsInInnerWidth);
    const newMaxScrollDistance = newScrollLength * maxNumScrolls * -1;

    return {
      scrollCardContainerDimensions,
      scrollLength: newScrollLength,
      maxScrollDistance: newMaxScrollDistance
    };
  };

  const setScrollStats = () => {
    if (containerEl && containerEl.current && cardRef && cardRef.current) {
      let dimensions = getDimensions();
      setMaxScrollDistance(dimensions.maxScrollDistance);
      setScrollLength(dimensions.scrollLength);
      setContainerDimensions(dimensions.scrollCardContainerDimensions);
    }
  };

  const canContinueRight = () => {
    if (!maxScrollDistance) {
      return false;
    } else {
      return -maxScrollDistance + scrollPosition - scrollLength >= scrollLength;
    }
  };

  let childrenLength = React.Children.count(children);
  useEffect(() => setScrollPosition(0), [type, childrenLength]);
  useLayoutEffect(setScrollStats, [containerEl, cardRef, React.Children.count(children)]);
  useLayoutEffect(() => {
    window.addEventListener('resize', setScrollStats);
    return () => window.removeEventListener('resize', setScrollStats);
  });

  const animateScroll = direction => {
    if (direction === 'left' && scrollPosition >= 0) {
      return;
    }

    const dimensions = getDimensions();
    let newPosition =
      direction === 'left'
        ? scrollPosition + dimensions.scrollLength
        : scrollPosition - dimensions.scrollLength;

    // Ensure that we never end up scrolling the starting card right of the starting point
    if (newPosition > 0) {
      newPosition = 0;
    }

    setScrollPosition(newPosition);
  };

  return (
    <>
      <Media query="(min-width: 800px)">
        <NoWrap className={className} aria-label={ariaLabel}>
          <ButtonWrapper position="left">
            {!isError && (
              <CircleButton
                aria-label={`Scroll and see more ${
                  type.startsWith('ed') ? 'education' : 'job postings'
                } to the left.`}
                data-cy={`${type}-scroll-left`}
                disabled={scrollPosition >= 0}
                onClick={() => animateScroll('left')}
              >
                <DirectionalCaret position="left" fillColor={colors.blue} />
              </CircleButton>
            )}
          </ButtonWrapper>
          <DesktopCardsWrapper ref={containerEl}>
            <Gradient isVisible={!!scrollPosition} animationDisabled={type.includes('loading')} />
            <Gradient
              isVisible={canContinueRight(maxScrollDistance, scrollPosition, scrollLength)}
              right={containerDimensions && containerDimensions.width}
            />
            <AnimatedWrapper left={scrollPosition} animationDisabled={type.includes('loading')}>
              <Spacer
                scrollPosition={scrollPosition}
                animationDisabled={type.includes('loading')}
              />
              {callToAction && (
                <CallToActionWrapper type={callToAction.props.type}>
                  {callToAction}
                </CallToActionWrapper>
              )}
              {React.Children.map(
                children,
                (child, i) =>
                  child && (
                    <ScrollCard
                      scrollCardType={child.props.scrollCardType}
                      isError={isError}
                      key={child.key || i}
                      ref={i === 0 ? cardRef : null}
                    >
                      {child}
                    </ScrollCard>
                  )
              )}
            </AnimatedWrapper>
          </DesktopCardsWrapper>
          <ButtonWrapper position="right">
            {!isError && (
              <CircleButton
                aria-label={`Scroll and see more ${
                  type.startsWith('ed') ? 'education' : 'job postings'
                } to the right.`}
                data-cy={`${type}-scroll-right`}
                disabled={!canContinueRight(maxScrollDistance, scrollPosition, scrollLength)}
                onClick={() => animateScroll('right')}
              >
                <DirectionalCaret position="right" fillColor={colors.blue} />
              </CircleButton>
            )}
          </ButtonWrapper>
        </NoWrap>
      </Media>
      <Media query="(max-width: 799px)">
        <HorizontalScroll className={className} aria-label={ariaLabel}>
          <MobileCardsWrapper>
            {callToAction && (
              <CallToActionWrapper type={callToAction.props.type}>
                {callToAction}
              </CallToActionWrapper>
            )}
            {React.Children.map(
              children,
              (child, i) =>
                child && (
                  <ScrollCard
                    scrollCardType={child.props.scrollCardType}
                    isError={isError}
                    key={child.key || i}
                  >
                    {child}
                  </ScrollCard>
                )
            )}
          </MobileCardsWrapper>
        </HorizontalScroll>
      </Media>
    </>
  );
};

HorizontalCardScroll.propTypes = {
  'aria-label': PropTypes.string,
  callToAction: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  isError: PropTypes.bool,
  type: PropTypes.string.isRequired
};

export default HorizontalCardScroll;
