import cognitoAuth from '@aws-amplify/auth';
import { updateProfile, createProfile } from 'services/profiles';
import fetchWithRetry from './helpers/fetchWithRetry';
import { flashError, flashSuccess } from 'helpers/flashMessage';

export const formatRequest = (method, data) => {
  const formattedData = {
    data: {
      attributes: data
    }
  };
  const payload = JSON.stringify(formattedData);
  return cognitoAuth.currentSession().then(res => {
    const token = res.idToken.jwtToken;
    return {
      method,
      body: payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  });
};

export const updateUser = async data => {
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-cognito-user`,
    await formatRequest('PATCH', data)
  );
  if (!response.ok) {
    throw new Error('problem updating user');
  }
};

export const createUser = async data => {
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-cognito-user`,
    await formatRequest('POST', data)
  );
  if (!response.ok) {
    throw new Error('problem creating user');
  }
  const json = await response.json();
  return json;
};

export const updateUserRole = async (subdomain, profileId, userRole) => {
  try {
    await updateUser({
      userId: profileId,
      newUserRole: userRole
    });
    await updateProfile(subdomain, profileId, {
      userRole
    });
    flashSuccess("The user's permission was updated");
  } catch (e) {
    flashError("There was a problem updating the user's permission");
  }
};

export const createNewMember = async (subdomain, userRole, email) => {
  try {
    let newMember = await createUser({
      subdomain,
      userRole,
      email
    });
    const { userId } = newMember.data.attributes;
    await createProfile(subdomain, userId, {
      email,
      userRole,
      cognitoStatus: 'RESET_REQUIRED'
    });
    flashSuccess('The invite was sent successfully');
  } catch (e) {
    flashError('There was a problem sending the invite');
  }
};
