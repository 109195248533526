import { connect } from 'react-redux';

import MatchCluster from 'components/pages/MatchCluster';

import { profileActions } from 'store/reducers/profile';
import { currentViewActions } from 'store/reducers/currentViews';

const { toggleDisplayAsList } = profileActions;
const { updateView } = currentViewActions;

const mapStateToProps = ({
  profile: { displayAsList, selectedSkills },
  currentViews,
  site: {
    data: {
      helpCardEdu,
      helpCardJobs,
      skillabiSiteNames,
      groupTypeClasses,
      jobPostingOrgIds,
      hideEdType
    }
  }
}) => ({
  currentViews,
  profile: { displayAsList, selectedSkills },
  site: {
    helpCardJobs,
    helpCardEdu,
    institutions: skillabiSiteNames,
    groupTypeClasses,
    jobPostingOrgIds,
    hideEdType
  }
});

export default connect(mapStateToProps, {
  updateView,
  toggleDisplayAsList
})(MatchCluster);
