// colors
export const blue = '#3E6DCC';
export const blueText = '#204354';
export const blueLight = '#F0F5FC';
export const blueDark = '#2E5299';
export const grayDark = '#8BA1AA';
export const gray = '#E9EBED';
export const grayLight = '#F7F7F7';
export const grayLightRGB = '240, 241, 243';
export const grayMedium = '#D2D7E1';
export const grayText = '#5d757d';
export const green = '#41D592';
export const greenDark = '#31A872';
export const orange = '#ED7E41';
export const orangeDark = '#D87C00';
export const red = '#D22928';
export const redLight = '#f5474f';

// misc
export const gradient = 'linear-gradient(143.91deg, #7f9bff -10.98%, #41d491 70.24%);';
export const innerCardBoxShadow = '0px 5px 14px rgba(0, 0, 0, 0.09)';

export default {
  blue,
  blueText,
  blueLight,
  blueDark,
  grayDark,
  grayLight,
  grayLightRGB,
  grayMedium,
  green,
  orange,
  red,
  gradient,
  innerCardBoxShadow
};

/**
 * Format a hexadecimal color by grabbing the R, G, and B values and returning them in an object.
 * @param {*} color A hexadecimal string. Can be either shorthand hex or a full, 6-digit hex color.
 */
export const getFormattedHexColor = color => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  color = color.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const fullHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  let match = color.match(fullHexRegex);
  if (!match) {
    throw Error(
      `Color values must be 6 digit hexadecimal numbers to find the contrast ratio. Got: ${color} `
    );
  }
  return {
    r: match[1],
    g: match[2],
    b: match[3]
  };
};

/**
 * Get the relative luminance of a color as described in the WCAG 2.0 documentation:
 * https://www.w3.org/TR/WCAG20/#relativeluminancedef
 * @param {object} color The color object with keys `r`, `g`, and `b` (each containing a hexadecimal value) from which to calculate the relative luminance.
 */
export const getRelativeLuminance = color => {
  if (typeof color !== 'object' || color === null) {
    throw new Error(
      `getRelativeLuminance can except only an object with keys 'r', 'g', and 'b', containing hexadecimal strings. Instead got: ${color}`
    );
  }
  let potentialKeys = ['r', 'g', 'b'];
  let foundProblem = false;
  for (let key of Object.keys(color)) {
    if (
      potentialKeys.includes(key) &&
      typeof color[key] === 'string' &&
      color[key].match(/^[a-f\d]{2}$/i)
    ) {
      potentialKeys = potentialKeys.filter(item => item !== key);
      continue;
    } else {
      foundProblem = true;
    }
  }
  if (potentialKeys.length || foundProblem) {
    throw new Error(
      `getRelativeLuminance can except only an object with keys 'r', 'g', and 'b', containing hexadecimal strings. Instead got: ${color}`
    );
  }
  const RGB = Object.keys(color).reduce((acc, currKey) => {
    const SRGB = parseInt(color[currKey], 16) / 255;

    if (SRGB <= 0.03928) {
      acc[currKey] = SRGB / 12.92;
    } else {
      acc[currKey] = ((SRGB + 0.055) / 1.055) ** 2.4;
    }
    return acc;
  }, {});
  return 0.2126 * RGB.r + 0.7152 * RGB.g + 0.0722 * RGB.b;
};

/**
 * Calculate the contrast ratio between two colors using the WCAG 2.0 definition found
 * here: https://www.w3.org/TR/WCAG20/#contrast-ratiodef.
 * @param {string} color1 A hexadecimal string representing a color.
 * @param {string} color2 A hexadecimal string representing a color.
 */
export const getContrast = (color1, color2) => {
  color1 = getFormattedHexColor(color1);
  color2 = getFormattedHexColor(color2);

  const color1RelLuminance = getRelativeLuminance(color1);
  const color2RelLuminance = getRelativeLuminance(color2);

  return color1RelLuminance > color2RelLuminance
    ? ((color2RelLuminance + 0.05) / (color1RelLuminance + 0.05)).toFixed(2)
    : ((color1RelLuminance + 0.05) / (color2RelLuminance + 0.05)).toFixed(2);
};

/**
 * Note: this functionality should be moved to an SM Admin when possible.
 * This function will test all colors within the `colorOptions` array against `color` to find the
 * contrast ratio. The first color in the array to pass WCAG 2.0 standards will be returned. If
 * none pass, the color with the highest contrast option will be returned.
 * @param {string} color The color to test against. Must be hexadecimal.
 * @param {array} colorOptions An array of hexadecimal strings against which `color` will be tested for contrast.
 */
export const getBestContrast = (color, colorOptions) => {
  // For invalid site, color will be undefined
  if (!color) {
    return '#fff';
  }
  // Note: this functionality should be moved to an SM Admin when possible.
  if (!colorOptions || !colorOptions.length) {
    throw new Error('No color options were received in getBestContrast');
  }
  let optionsWithContrast;
  try {
    optionsWithContrast = colorOptions.map(colorOption => ({
      color: colorOption,
      contrast: getContrast(color, colorOption)
    }));

    // AA-level WCAG 2.0 contrast level for small text (or AAA-level for large text) is 4.5:1, or 0.22222
    // If none of the colorOptions pass WCAG 2.0 contrast standards, return the color with most contrast.
    // Remember: 7.5:1 has greater contrast than 4.5:1, and those are converted to decimal like so:
    // 1/7.5 = 0.1333, and 1/4.5 = 0.2222
    // So SMALLER numbers have a higher contrast ratio. So any number less than or equal to the cutoff
    // (0.22222) is acceptable.
    for (let item of optionsWithContrast) {
      if (item.contrast <= 0.22222) {
        return item.color;
      }
    }
    return optionsWithContrast.sort((a, b) => a.contrast - b.contrast)[0].color;
  } catch (e) {
    throw e;
  }
};
