import React from 'react';
import PropTypes from 'prop-types';

import SearchAndFilterInput from 'components/molecules/SearchAndFilterInput';

import withKeen from 'hocs/withKeen';

const SkillFilter = ({ view, validSkills, updateView, trackEvent }) => {
  const { filterSkills } = view;

  const getSuggestions = cleanInputValue => {
    if (cleanInputValue.length === 0) {
      return [];
    } else {
      cleanInputValue = cleanInputValue.toLowerCase();
      let res = Object.values(validSkills)
        .filter(
          skill =>
            skill.name.toLowerCase().includes(cleanInputValue) && !filterSkills.includes(skill.id)
        )
        .sort((s1, s2) => {
          // Results that start with the input should appear first
          const a = s1.name.toLowerCase();
          const b = s2.name.toLowerCase();
          if (a.startsWith(cleanInputValue) && !b.startsWith(cleanInputValue)) {
            return -1;
          }
          if (b.startsWith(cleanInputValue) && !a.startsWith(cleanInputValue)) {
            return 1;
          }
          return 0;
        })
        .slice(0, 10);
      return res;
    }
  };

  const makeSelection = suggestion => {
    trackEvent('Results Page Skill Searched', {
      skillId: suggestion.id
    });
    updateView({
      filterSkills: [suggestion.id, ...filterSkills]
    });
  };

  const removeSuggestion = async skillId => {
    let newfilterSkills = filterSkills.filter(thisSkill => thisSkill !== skillId);
    updateView({
      filterSkills: newfilterSkills
    });
  };

  const fetchRequested = async e => {
    return await getSuggestions(e.value);
  };

  return (
    <SearchAndFilterInput
      disabled={validSkills.length === 0}
      type="Skill"
      getSuggestions={getSuggestions}
      removeSuggestion={removeSuggestion}
      makeSelection={makeSelection}
      fetchRequested={fetchRequested}
      pillData={filterSkills.map(id => [id, validSkills[id].name])}
    />
  );
};

export default withKeen(SkillFilter);

SkillFilter.propTypes = {
  view: PropTypes.shape({
    filterSkills: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  validSkills: PropTypes.object.isRequired,
  updateView: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired
};
