import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import FormInput from 'components/atoms/FormInput';
import LoadingButton from 'components/molecules/LoadingButton';
import { red } from 'helpers/colors';
import { required, isEmail, composeValidators } from 'helpers/validateForm';

const Error = styled.span`
  font-size: 1.4rem;
  color: ${red};
  margin-top: 1.5rem;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ForgotPasswordEmailForm = ({ isLoading, error, onSubmit }) => (
  <Form onSubmit={({ email }) => onSubmit(email.toLowerCase())}>
    {({ handleSubmit }) => {
      return (
        <StyledForm onSubmit={handleSubmit}>
          <Field validate={composeValidators(required, isEmail)} name="email">
            {({ input, meta }) => {
              return (
                <FormInput
                  label="enter email"
                  autoComplete="email"
                  error={meta.error && meta.touched ? meta.error : ''}
                  {...input}
                />
              );
            }}
          </Field>
          <LoadingButton isLoading={isLoading}>Send Email</LoadingButton>
          {error && <Error aria-live="polite">{error}</Error>}
        </StyledForm>
      );
    }}
  </Form>
);

ForgotPasswordEmailForm.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ForgotPasswordEmailForm;
