import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';

import EducationSelectionWrapper from 'containers/EducationSelectionWrapper';
import Button from 'components/atoms/Button';
import Header from 'components/atoms/Header';
import Link from 'components/atoms/Link';
import SelectedEducationCard from 'components/molecules/SelectedEducationCard';
import TitledCard from 'components/organisms/TitledCard';

import arrowRight from 'images/arrow-right-white.png';

const FullWidth = styled.div`
  width: 100%;
`;

const StyledHeader = styled(Header)`
  margin: 5rem 3rem 0 3rem;
  @media (max-width: 1023px) {
    margin-top: 3rem;
  }
`;

const StyledTitledCard = styled(TitledCard)`
  width: calc(100% - 8rem);
  @media screen and (min-width: 1024px) {
    width: 70%;
  }
`;

const DegreesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
`;

const StyledButton = styled(Button)`
  margin-right: 3rem;
  ${({ fullWidth }) => fullWidth && 'margin-left: 3rem'};
  margin-bottom: 2rem;
  padding: 1rem 4rem;
  width: ${({ fullWidth }) => (fullWidth ? '27rem' : '100%')};
  box-sizing: border-box;
`;

const StyledText = styled.div`
  margin-bottom: 2rem;
  padding: 0 3rem;
  ${({ fullWidth }) => fullWidth && 'text-align: center'};
  width: ${({ fullWidth }) => (fullWidth ? '27rem' : '100%')};
  font-size: 1.4rem;
  > a {
    font-size: 1.4rem;
  }
`;

const YourEdAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 30%;
  min-width: 30rem;
  padding-left: 3rem;
`;

const ArrowIcon = styled.img`
  margin-left: 1rem;
`;

const Degrees = ({ education, history }) => {
  return (
    <FullWidth>
      <StyledHeader>My Education</StyledHeader>
      <DegreesWrapper>
        <Media query="(max-width: 1023px)">
          <>
            <StyledTitledCard
              helpText="Add any education that you have completed or that is in progress. We’ll use this information to suggest skills in the next section."
              title="Add All Your Education"
            >
              <EducationSelectionWrapper />
            </StyledTitledCard>
            <ButtonWrapper>
              <StyledButton
                fullWidth
                disabled={education.length === 0}
                onClick={() => {
                  history.push('/skills');
                }}
              >
                Continue to Skills <ArrowIcon src={arrowRight} alt="" />
              </StyledButton>
              {education.length === 0 && (
                <StyledText fullWidth>
                  Add your education before continuing or
                  <Link to="/skills"> continue without adding education</Link>
                </StyledText>
              )}
            </ButtonWrapper>
          </>
        </Media>
        <Media query="(min-width: 1024px)">
          <>
            <StyledTitledCard
              helpText="Add any education that you have completed or that is in progress. We’ll use this information to suggest skills in the next section."
              title="Add All Your Education"
            >
              <EducationSelectionWrapper />
            </StyledTitledCard>
            <YourEdAndButtonWrapper>
              <SelectedEducationCard education={education} />
              <StyledButton
                disabled={education.length === 0}
                onClick={() => {
                  history.push('/skills');
                }}
              >
                Continue to Skills <ArrowIcon src={arrowRight} alt="" />
              </StyledButton>
              {education.length === 0 && (
                <StyledText>
                  Add your education before continuing or
                  <Link to="/skills"> continue without adding education</Link>
                </StyledText>
              )}
            </YourEdAndButtonWrapper>
          </>
        </Media>
      </DegreesWrapper>
    </FullWidth>
  );
};

Degrees.propTypes = {
  education: PropTypes.arrayOf(PropTypes.object).isRequired,
  // history comes from react-router-dom
  history: PropTypes.shape({ push: PropTypes.func.isRequired })
};

export default withRouter(Degrees);
