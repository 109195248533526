import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Card from 'components/atoms/Card';
import EducationCard from 'containers/EducationCard';

import PlaceholderImg from 'images/empty-skill-list.svg';

import { blueText, grayText } from 'helpers/colors';

const StyledCard = styled(Card)`
  width: 100%;
  box-sizing: border-box;
  flex-grow: 5;
  padding: 2rem;
  margin: 3rem;
  margin-left: 0;
`;

const StyledImg = styled.img`
  width: 20rem;
  margin-bottom: 1rem;
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
`;

const StyledHeader = styled.h2`
  color: ${blueText};
  font-size: 1.6rem;
  margin-bottom: 3rem;
`;

const CardHeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const SubText = styled.div`
  color: ${grayText};
  font-size: 1.4rem;
  font-style: italic;
  width: 100%;
`;

const StyledDiv = styled.div`
  font-size: 1.4rem;
`;

const SelectedEducationCard = ({ education }) => {
  return (
    <StyledCard data-cy="selected-ed-card">
      <CardHeadingWrapper>
        <StyledHeader>Added Education</StyledHeader>
      </CardHeadingWrapper>
      {education.length === 0 && (
        <PlaceholderWrapper>
          <StyledImg src={PlaceholderImg} alt="" />
          <StyledDiv>Education you add on the left will appear here!</StyledDiv>
        </PlaceholderWrapper>
      )}
      {education.map((item, index) => (
        <EducationCard key={index} edItem={item} index={index} />
      ))}
      {education.length > 0 && (
        <SubText>
          Add all education that you have received. When finished, continue to skills.
        </SubText>
      )}
    </StyledCard>
  );
};

SelectedEducationCard.propTypes = {
  education: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SelectedEducationCard;
