import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Media from 'react-media';

import Card from 'components/atoms/Card';
import Link from 'components/atoms/Link';

import { ReactComponent as PlusCircleIcon } from 'images/plus-circle-border.svg';
import { ReactComponent as SearchIcon } from 'images/magnifying-glass-circle.svg';
import { ReactComponent as XCircleIcon } from 'images/x-circle.svg';
import { blue } from 'helpers/colors';

const Wrapper = styled(Card)`
  box-sizing: border-box;
  box-shadow: 0px 0.5rem 1.4rem rgba(0, 0, 0, 0.0713505);
  flex-direction: row;
  font-size: 1.4rem;
  margin: 1rem 0 0 1rem;
  padding: 1.5rem 3rem;
  width: calc(100% - 1.7rem);
  @media (max-width: 599px) {
    padding: 1.5rem;
  }
`;

const Search = styled(SearchIcon)`
  height: 2.6rem;
  margin-right: 1rem;
  width: 2.6rem;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -1rem;
`;

const Text = styled.div`
  margin-top: 1.4rem;
  @media (min-width: 600px) {
    margin-right: 3rem;
  }
`;

const OpenSidebarBtn = styled.button`
  background: none;
  border: none;
  color: ${blue};
  font-size: 1.4rem;
  padding: 0;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  margin-top: 1rem;
`;

const ClearFiltersBtn = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${blue};
  display: flex;
  font-size: 1.4rem;
  margin-top: 1rem;
  padding: 0;
  cursor: pointer;
`;

const Icon = styled(({ component: Component, ...props }) => <Component {...props} />)`
  fill: ${blue};
  height: 1.5rem;
  margin-right: 1rem;
  width: 1.5rem;
`;

const NoResultsFilterCard = ({ clearFilters, contentName, openMobileSidebar, section }) => {
  const isEducation = useLocation().pathname === '/results/education';

  return (
    <Wrapper>
      <Search />
      <Content>
        {section === 'matched' ? (
          <>
            <Text data-cy="filtered-out-matched">
              All profile matches filtered out.&nbsp;
              <Media query="(min-width: 1024px)">
                {matches =>
                  matches ? (
                    'Adjust your filters'
                  ) : (
                    <OpenSidebarBtn onClick={openMobileSidebar}>Adjust your filters</OpenSidebarBtn>
                  )
                }
              </Media>
              {isEducation ? (
                <span>
                  {' '}
                  or add skills <strong>you want</strong> to your profile to see your education
                  matches!
                </span>
              ) : (
                <span> or add skills to your profile to see more matches!</span>
              )}
            </Text>
            <StyledLink to="/skills">
              <Icon component={PlusCircleIcon} />
              Add Skills
            </StyledLink>
          </>
        ) : (
          <>
            <Text data-cy="filtered-out-others">
              These {contentName} were filtered out. Try adjusting or clearing your filters.
            </Text>
            <ClearFiltersBtn onClick={clearFilters}>
              <Icon component={XCircleIcon} />
              Clear Filters
            </ClearFiltersBtn>
          </>
        )}
      </Content>
    </Wrapper>
  );
};

NoResultsFilterCard.propTypes = {
  clearFilters: PropTypes.func, // required for section = other
  contentName: PropTypes.string.isRequired,
  openMobileSidebar: PropTypes.func, // required for section = matched
  section: PropTypes.oneOf(['matched', 'other']).isRequired
};

export default NoResultsFilterCard;
