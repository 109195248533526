import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';

import Card from 'components/atoms/Card';
import Modal from 'components/atoms/Modal';
import Button from 'components/atoms/Button';
import FormInput from 'components/atoms/FormInput';
import RoleDropdown from 'Admin/components/RoleDropdown';

import loading from 'images/loading.gif';
import { getAllProfiles } from 'services/profiles';
import { updateUserRole, createNewMember } from 'services/cognitoUser';
import { required, isEmail, composeValidators } from 'helpers/validateForm';

const StyledCard = styled(Card)`
  padding: 3rem 1.5rem;
  @media (min-width: 800px) {
    padding: 3rem;
    width: 57.5rem;
  }
`;

const Title = styled.h1`
  font-weight: 400;
  margin: 0;
`;

const StyledDiv = styled.div`
  display: flex;
  margin: 2rem 0 0 0;
  @media (max-width: 549px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

const StyledInput = styled(FormInput)`
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-left: 2rem;
`;

const LoadingIcon = styled.img`
  width: 4rem;
  margin: 4rem auto;
`;

const NewMemberModal = ({ isOpen, close, subdomain, reloadAdminProfiles }) => {
  const options = ['Admin', 'View Everything', 'View Analytics', 'View Profiles'];
  const [profiles, setProfiles] = useState(null);

  useEffect(() => {
    const fetchAllProfiles = async () => {
      const profilesResponse = await getAllProfiles(subdomain);
      setProfiles(profilesResponse.data);
    };
    fetchAllProfiles();
  }, []);

  const inviteMember = async data => {
    const existingProfile = profiles.find(profile => profile.attributes.email === data.email);
    if (existingProfile) {
      const { profileId } = existingProfile.attributes;
      await updateUserRole(subdomain, profileId, data.userRole);
    } else {
      await createNewMember(subdomain, data.userRole, data.email);
    }
    await reloadAdminProfiles();
    close();
  };

  if (!profiles) {
    return (
      <Modal isOpen={isOpen} close={close}>
        <StyledCard>
          <Title>Invite Team Member</Title>
          <LoadingIcon src={loading} alt="" />
        </StyledCard>
      </Modal>
    );
  } else {
    return (
      <Modal isOpen={isOpen} close={close}>
        <StyledCard>
          <Title>Invite Team Member</Title>
          <Form
            onSubmit={values => {
              inviteMember({ ...values, email: values.email.toLowerCase() });
            }}
          >
            {({ handleSubmit }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <StyledDiv>
                    <Field name="email" validate={composeValidators(required, isEmail)}>
                      {({ input, meta }) => (
                        <StyledInput
                          label="Email"
                          autoComplete="email"
                          error={meta.error && meta.touched ? meta.error : ''}
                          {...input}
                        />
                      )}
                    </Field>
                    <Field name="userRole" initialValue={options[0]}>
                      {({ input }) => (
                        <RoleDropdown
                          isNewMemberModal
                          onSelect={input.onChange}
                          ariaLabel="Click to open the dropdown to select member access."
                          options={options}
                          selectedRole={input.value}
                        />
                      )}
                    </Field>
                  </StyledDiv>
                  <ButtonWrapper>
                    <StyledButton type="button" variant="secondary" onClick={close}>
                      Cancel
                    </StyledButton>
                    <StyledButton>Send Invite</StyledButton>
                  </ButtonWrapper>
                </form>
              </>
            )}
          </Form>
        </StyledCard>
      </Modal>
    );
  }
};

NewMemberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  subdomain: PropTypes.string.isRequired,
  reloadAdminProfiles: PropTypes.func.isRequired
};

export default NewMemberModal;
