import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atoms/Link';
import { ReactComponent as Arrow } from 'images/arrow-right.svg';
import StarFilled from 'images/star-filled.svg';
import StarOutline from 'images/star-outline.svg';
import Trophy from 'images/trophy.svg';

import { blueText, grayLight, blue } from 'helpers/colors';

import getAndTrimTopSkills from 'helpers/getAndTrimTopSkills';
import getModelVersion from 'helpers/getModelVersion';

const StyledLink = styled(Link)`
  font-size: 1.4rem;
  &:hover,
  &:focus {
    text-decoration: none;
    border-bottom: 0.1rem solid ${blue};
  }
`;

const ClusterImage = styled.img.attrs(({ subgroupId }) => ({
  src: `https://d3s4k660pqkq8k.cloudfront.net/images/subgroup/${getModelVersion()}/${subgroupId}_long_small.jpg`,
  alt: ''
}))`
  height: 4.1rem;
  border-radius: 0.5rem 0.5rem 0 0;
`;

const CardBody = styled.div`
  color: ${blueText};
  margin: 1.5rem;
  margin-bottom: 0;
`;

const CardBodyTop = styled.div`
  height: 14rem;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const StyledSpan = styled.span`
  font-size: 1.2rem;
  display: block;
`;

const TopSkills = styled.div`
  font-size: 1.6rem;
  margin: 0;
  font-weight: normal;
`;

const CardFooter = styled.div`
  border-top: 0.2rem solid ${grayLight};
  padding: 1.8rem;
  text-align: center;
`;

const ArrowIcon = styled(Arrow)`
  margin-left: 0.5rem;
  fill: ${blue};
  height: 1rem;
  width: 1.8rem;
`;

const MatchScore = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
`;

// the display:flex is used to vertically align the stars in the middle of the div
const Stars = styled.div`
  display: flex;
`;

// the display:flex is used to vertically align the text in the middle of the div
const Text = styled.span`
  font-weight: bold;
  font-size: 1.3rem;
  display: flex;
  white-space: nowrap;
`;

const StyledStar = styled.img`
  margin-left: 0.4rem;
  position: relative;
  top: 4px;
  width: 1.3rem;
  height: 1.3rem;
`;

const StyledIcon = styled.img`
  margin-right: 1rem;
`;

const ClusterCardBody = ({ cluster, validSkills, showMatchScore = true }) => {
  const { primarySkills, score } = cluster;
  const topSkills = getAndTrimTopSkills(primarySkills, validSkills, 50);

  return (
    <>
      <ClusterImage subgroupId={cluster.subgroup_id} />
      <CardBody data-cy="cluster-match">
        <CardBodyTop>
          <Title>{cluster.subgroupName}</Title>
          <StyledSpan>With emphasis on</StyledSpan>
          <TopSkills>{topSkills}</TopSkills>
        </CardBodyTop>
        {showMatchScore && (
          <MatchScore
            aria-label={`Your skills match score for this career area is ${
              score > 0.4 ? 5 : Math.ceil(score * 10)
            } stars`}
          >
            <Text>
              <StyledIcon src={Trophy} alt="" aria-hidden />
              <span aria-hidden> Match Score</span>
            </Text>
            <Stars>
              <StyledStar src={score > 0 ? StarFilled : StarOutline} alt="" aria-hidden />
              <StyledStar src={score > 0.1 ? StarFilled : StarOutline} alt="" aria-hidden />
              <StyledStar src={score > 0.2 ? StarFilled : StarOutline} alt="" aria-hidden />
              <StyledStar src={score > 0.3 ? StarFilled : StarOutline} alt="" aria-hidden />
              <StyledStar src={score > 0.4 ? StarFilled : StarOutline} alt="" aria-hidden />
            </Stars>
          </MatchScore>
        )}
      </CardBody>
      <CardFooter>
        <StyledLink
          to={`/results/cluster/${cluster.clusterId}`}
          aria-label={`View more information about ${cluster.subgroupName} with emphasis on ${topSkills}`}
        >
          View Details <ArrowIcon />
        </StyledLink>
      </CardFooter>
    </>
  );
};

ClusterCardBody.propTypes = {
  cluster: PropTypes.object.isRequired,
  validSkills: PropTypes.object.isRequired,
  showMatchScore: PropTypes.bool
};

export default ClusterCardBody;
