import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ClickOutsideWrapper from 'components/atoms/ClickOutsideWrapper';
import DirectionalCaret from 'components/atoms/DirectionalCaret';

import { ReactComponent as Checkmark } from 'images/checkmark.svg';
import { red, grayLight, blue, blueText } from 'helpers/colors';

const DropDownContainer = styled.div`
  font-size: 1.4rem;
  position: relative;
  ${({ isNewMemberModal }) => isNewMemberModal && `width: 18rem; margin-left: 2rem;`}
  @media (max-width: 549px) {
    width: 100%;
    margin-left: 0;
  }
`;

const DropDownHeader = styled.button`
  ${({ isNewMemberModal }) =>
    !isNewMemberModal ? 'background: transparent' : `background:${grayLight}`};
  border-radius: 3px;
  border: 0;
  color: ${blueText};
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
`;

const StyledDirectionalCaret = styled(DirectionalCaret)`
  top: 0.4rem;
`;

const DropDownList = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  ${({ isPermissionDropdownModal }) =>
    isPermissionDropdownModal
      ? `align-items: center;`
      : `
      border-radius: 3px;
      box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
      position: absolute;
      `}
`;

const ListItem = styled.button`
  background-color: transparent;
  border: 0;
  border-radius: 0.4rem;
  color: ${blueText};
  ${({ isRed }) => isRed && `color:${red}`};
  cursor: pointer;
  font-size: 1.4rem;
  margin: 0 1rem 0.5rem 1rem;
  padding: 0.6rem 2.1rem;
  text-align: left;
  ${({ isSelected }) =>
      isSelected &&
      `
      color:${blue};
      padding-left: 0.5rem;
      `}
    :nth-last-child(2) {
    margin-bottom: 0;
  }
  :hover {
    background: ${grayLight};
  }
`;

const Check = styled(Checkmark)`
  padding-right: 0.5rem;
`;

const HiddenItem = styled.button`
  height: 0;
  opacity: 0;
  width: 0;
`;

const RoleDropdown = ({
  ariaLabel,
  isPermissionDropdownModal,
  isNewMemberModal,
  onSelect,
  options,
  selectedRole
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isOpen && document.getElementById('member-access-list').children[1].focus();
  }, [isOpen]);

  const onOptionClicked = value => {
    if (selectedRole !== value) {
      onSelect(value);
    }
    setIsOpen(false);
  };

  return (
    <>
      <DropDownContainer selectedRole={selectedRole} isNewMemberModal={isNewMemberModal}>
        {!isPermissionDropdownModal && (
          <ClickOutsideWrapper clickOutsideHandler={() => setIsOpen(false)}>
            <DropDownHeader
              type="button"
              isNewMemberModal={isNewMemberModal}
              selectedRole={selectedRole}
              onClick={() => setIsOpen(!isOpen)}
              aria-label={ariaLabel}
              aria-expanded={isOpen}
            >
              {selectedRole}
              <StyledDirectionalCaret fillColor={blueText} position={isOpen ? 'up' : 'down'} />
            </DropDownHeader>
          </ClickOutsideWrapper>
        )}
        {(isOpen || isPermissionDropdownModal) && (
          <DropDownList
            isPermissionDropdownModal={isPermissionDropdownModal}
            id="member-access-list"
            className="ignore-react-onclickoutside"
          >
            {!isPermissionDropdownModal && (
              <HiddenItem type="button" onFocus={() => setIsOpen(false)} />
            )}
            {options.map(option => {
              const isSelected = selectedRole === option;
              return (
                <ListItem
                  type="button"
                  isSelected={isSelected}
                  onClick={() => onOptionClicked(option)}
                  key={option}
                  isRed={option === 'Remove Access'}
                  aria-label={
                    isSelected
                      ? `${selectedRole} is your selected member access.`
                      : `Select ${option} as your current member access.`
                  }
                >
                  {isSelected && <Check />}
                  {option}
                </ListItem>
              );
            })}
            {!isPermissionDropdownModal && <HiddenItem onFocus={() => setIsOpen(false)} />}
          </DropDownList>
        )}
      </DropDownContainer>
    </>
  );
};

RoleDropdown.propTypes = {
  ariaLabel: PropTypes.string,
  isPermissionDropdownModal: PropTypes.bool,
  isNewMemberModal: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRole: PropTypes.string.isRequired
};

export default RoleDropdown;
