import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';

import ButtonLink from 'components/atoms/ButtonLink';
import Flex from 'components/atoms/Flex';
import Margin from 'components/atoms/Margin';
import IntroCtaCard from 'components/molecules/IntroCtaCard';
import IntroCtaRow from 'components/molecules/IntroCtaRow';
import YoutubeEmbedVideo from 'components/atoms/YoutubeEmbedVideo';

import IntroBackground from 'images/intro-background.svg';

import IntroCtaCardImage1 from 'images/intro-cta-card-1.png';
import IntroCtaCardImage2 from 'images/intro-cta-card-2.png';

import IntroCtaRowImage1 from 'images/intro-cta-row-1.png';
import IntroCtaRowImage2 from 'images/intro-cta-row-2.png';
import IntroCtaRowImage3 from 'images/intro-cta-row-3.png';

import { ReactComponent as StarOutline } from 'images/star-outline.svg';
import { ReactComponent as OpenBook } from 'images/open-book.svg';
import { ReactComponent as JobPostings } from 'images/job-postings.svg';

const WhiteBackground = styled.div`
  width: 100%;
  background: white;
  position: relative;
`;

const Top = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
`;

const BackgroundContainer = styled.div`
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const BackgroundShape = styled.div`
  position: absolute;
  width: 100%;
  height: ${props => props.height};

  // This component needs to be a div, and set the image as background, because we need to control overflow cropping
  background-image: url(${props => props.src});
  background-size: 100% auto;
  background-position: 0 100%;
  @media (max-width: 1023px) {
    background-size: auto 100%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    margin: 0 auto;
    padding: 0 3rem 5rem;
    max-width: 50rem;
    align-items: center;
  }
`;

const IntroText = styled.div`
  @media (min-width: 1024px) {
    margin-right: 10rem;
  }
`;

const Header = styled.h1`
  font-size: 3.2rem;
  font-weight: normal;
  margin-top: 4rem;
  margin-bottom: 1rem;
  @media (min-width: 1024px) {
    margin-top: 8rem;
  }
`;

const SubHeader = styled.h2`
  font-size: ${props => (props.bigFont ? '1.8rem' : '1.4rem')};
  font-weight: bold;
  margin-top: ${props => (props.bigFont ? '4rem' : '1rem')};
  margin-bottom: 0.5rem;
`;

const Text = styled.div`
  @media (min-width: 1024px) {
    max-width: 35rem;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  margin: 0.7rem 0;
`;

// There are two BackgroundShapes, because they are in different parent containers
const Intro = ({ customVideo }) => (
  <WhiteBackground>
    <Top>
      <Media query="(min-width: 1024px)">
        <BackgroundShape height="31rem" src={IntroBackground} />
      </Media>
      <BackgroundContainer>
        <Media query="(max-width: 1023px)">
          <BackgroundShape height="100%" src={IntroBackground} />
        </Media>
        <Wrapper>
          <IntroText>
            <Header>Welcome to SkillsMatch!</Header>
            <Text>
              Identify your skills and receive personalized career and educational recommendations.
            </Text>
          </IntroText>

          <Media query="(max-width: 1023px)">
            <>
              <SubHeader>Get Started Now</SubHeader>
              <StyledButtonLink to="/goals">Identify My Skills</StyledButtonLink>
              <StyledButtonLink inverseColor to="/results">
                Explore Opportunities
              </StyledButtonLink>
            </>
          </Media>
        </Wrapper>
      </BackgroundContainer>
      <Wrapper>
        <Media query="(min-width: 1024px)" render={() => <Margin margin="1.5rem 0" />} />
        <SubHeader>See How It Works</SubHeader>
        <Margin margin="0.5rem 0 0 0">
          <YoutubeEmbedVideo
            width={400}
            height={205}
            title="SkillsMatch Intro Video"
            videoUrl={customVideo || 'https://www.youtube.com/embed/HANBurXMt4g'}
          />
        </Margin>
      </Wrapper>
    </Top>
    <Media query="(min-width: 1024px)">
      <Flex direction="column" alignItems="center">
        <SubHeader bigFont>Get Started Now</SubHeader>
        <Flex>
          <IntroCtaCard
            title="Find Opportunities Based on Your Skills"
            time="5-15 mins / Highly tailored"
            image={IntroCtaCardImage1}
            url="/goals"
          />
          <IntroCtaCard
            title="Start Exploring Opportunities Now"
            time="Start now / No tailoring"
            image={IntroCtaCardImage2}
            url="/results"
          />
        </Flex>
      </Flex>
    </Media>
    <Flex shouldWrap direction="column" alignItems="center">
      <IntroCtaRow
        title="Interesting Career Area Matches"
        text="Sometimes the perfect match is something you would have never thought of! Explore new career areas that need your unique skill set, or learn how to level-up in your current career."
        icon={StarOutline}
        image={IntroCtaRowImage1}
        linkText="Explore Career Areas"
        linkUrl="/results/career-areas"
        imagePosition="right"
      />
      <IntroCtaRow
        title="Education Where You Need It"
        text="We’ll show you educational opportunities that pinpoint the skills you want or need to give you a leg-up in the job hunt. No searching through course catalogs!"
        icon={OpenBook}
        image={IntroCtaRowImage2}
        linkText="Find Educational Opportunities"
        linkUrl="/results/education"
        imagePosition="left"
      />
      <IntroCtaRow
        title="Help in the Job Hunt"
        text="Your skills are valuable in many different job types. Find jobs that fit your current career, or take you in a new direction if you’re looking to use your skills in a fresh setting."
        icon={JobPostings}
        image={IntroCtaRowImage3}
        linkText="Find the Right Job"
        linkUrl="/results/jobs"
        imagePosition="right"
      />
    </Flex>
  </WhiteBackground>
);

Intro.propTypes = {
  customVideo: PropTypes.string
};

export default Intro;
