import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import { OptimizelyExperiment, withOptimizely } from '@optimizely/react-sdk';

import SkillShape from 'components/atoms/SkillShape';
import ClickOutsideWrapper from 'components/atoms/ClickOutsideWrapper';
import { grayLight, grayMedium, blue } from 'helpers/colors';
import InformationIcon from 'images/information-icon.svg';

const ModalCard = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 100%;
  min-width: 100%;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  background: white;
  border: 2px solid ${grayMedium};
  border-radius: 3px;
`;

const SkillTypeButton = styled.button`
  position: relative;
  width: 100%;
  height: 3.9rem;
  display: flex;
  align-items: center;
  background: white;
  border: 5px solid white;
  color: ${blue};
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 2.7px;
  white-space: nowrap;
  :hover {
    cursor: pointer;
    background: ${grayLight};
  }
  /* To make the focused button's outline at the top */
  :focus {
    z-index: 3;
  }
`;

const ExitModalButton = styled.button`
  position: fixed;
  height: 0;
  width: 0;
  opacity: 0;
`;

const HelpIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
`;

const SkillSelectionModal = ({
  skill,
  removeSelectedSkill,
  addSelectedSkill,
  selectedSkills,
  toggleShowSkillTypeModal,
  optimizely
}) => {
  const selectionType = selectedSkills[skill.id];

  return (
    <ClickOutsideWrapper clickOutsideHandler={toggleShowSkillTypeModal}>
      <FocusTrap focusTrapOptions={{ initialFocus: 'button[aria-label*="to Skills"]' }}>
        <ModalCard>
          {/* Optimizely A/B Testing: Users will either see HAVE option first, or WANT option first
          The goal is to evaluate whether users will more frequently select HAVE or WANT */
          selectionType !== 'want' && (
            <OptimizelyExperiment experiment="want_or_have_skills_first_test">
              {variation =>
                variation === 'wantSkillsComeFirst' && (
                  <SkillTypeButton
                    aria-label={`add ${skill.name} to Skills I Want`}
                    onClick={() => {
                      addSelectedSkill({ skillId: skill.id, selectionType: 'want' });
                      optimizely.track('want_skill_selected');
                      toggleShowSkillTypeModal();
                    }}
                  >
                    <SkillShape selectionType="want" />I WANT
                  </SkillTypeButton>
                )
              }
            </OptimizelyExperiment>
          )}
          {selectionType !== 'have' && (
            <SkillTypeButton
              aria-label={`add ${skill.name} to Skills I Have`}
              onClick={() => {
                addSelectedSkill({ skillId: skill.id, selectionType: 'have' });
                optimizely.track('have_skill_selected');
                toggleShowSkillTypeModal();
              }}
            >
              <SkillShape selectionType="have" />I HAVE
            </SkillTypeButton>
          )}
          {selectionType !== 'want' && (
            <OptimizelyExperiment experiment="want_or_have_skills_first_test">
              {variation =>
                variation !== 'wantSkillsComeFirst' && (
                  <SkillTypeButton
                    aria-label={`add ${skill.name} to Skills I Want`}
                    onClick={() => {
                      addSelectedSkill({ skillId: skill.id, selectionType: 'want' });
                      optimizely.track('want_skill_selected');
                      toggleShowSkillTypeModal();
                    }}
                  >
                    <SkillShape selectionType="want" />I WANT
                  </SkillTypeButton>
                )
              }
            </OptimizelyExperiment>
          )}
          {selectionType && (
            <SkillTypeButton
              aria-label={`remove ${skill.name} from Skills I ${selectionType}`}
              onClick={() => {
                removeSelectedSkill({ skillId: skill.id });
                toggleShowSkillTypeModal();
              }}
            >
              <SkillShape />
              DESELECT
            </SkillTypeButton>
          )}
          <SkillTypeButton
            aria-label={`learn more information about ${skill.name}`}
            role="link"
            onClick={() => {
              window.open(`https://skills.emsidata.com/skills/${skill.id}`, '_blank');
            }}
          >
            <HelpIcon src={InformationIcon} alt="" />
            INFO
          </SkillTypeButton>
          <ExitModalButton
            onFocus={toggleShowSkillTypeModal}
            aria-label="close skill selection menu"
          />
        </ModalCard>
      </FocusTrap>
    </ClickOutsideWrapper>
  );
};

SkillSelectionModal.propTypes = {
  removeSelectedSkill: PropTypes.func.isRequired,
  addSelectedSkill: PropTypes.func.isRequired,
  selectedSkills: PropTypes.object.isRequired,
  skill: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  toggleShowSkillTypeModal: PropTypes.func.isRequired,
  optimizely: PropTypes.shape({ track: PropTypes.func.isRequired }).isRequired
};

export default withOptimizely(SkillSelectionModal);
