import { connect } from 'react-redux';
import DeleteAccountModal from 'components/molecules/DeleteAccountModal';
import { profileActions } from 'store/reducers/profile';

const mapStateToProps = ({ profile }) => ({
  isDeleting: profile.isDeleting
});

const { deleteProfile } = profileActions;

export default connect(mapStateToProps, {
  deleteProfile: deleteProfile.request
})(DeleteAccountModal);
