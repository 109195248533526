import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash.debounce';

import DirectionalCaret from 'components/atoms/DirectionalCaret';

import { blueText, blue, grayLight, grayText } from 'helpers/colors';
import { ReactComponent as Close } from 'images/close.svg';
import LoadingGif from 'images/loading.gif';
import { ReactComponent as MagnifyingGlass } from 'images/magnifying-glass.svg';

const InputWrapper = styled.div`
  position: relative;
  color: ${blueText};
  display: flex;
  align-items: center;

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
    margin-top: 0.5rem;
  }

  .react-autosuggest__suggestions-container--open {
    width: 100%;
    display: block;
    position: absolute;
    background-color: #fff;
    max-height: 40rem;
    box-sizing: border-box;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    overflow-y: auto;
    z-index: 1;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0 1.5rem;
    list-style-type: none;
    font-size: 1.4rem;
  }

  .react-autosuggest__suggestion {
    line-height: 200%;
    margin: 0.7rem 0;
    cursor: pointer;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${grayLight};
  }

  .react-autosuggest__container {
    width: 100%;
  }

  [data-theme='custom'] .react-autosuggest__suggestions-container {
    width: 100%;
  }

  [data-theme='custom'] .react-autosuggest__suggestions-list {
    margin: 1.25rem 0;
  }
`;

const Input = styled.input`
  font-size: 1.4rem;
  background-color: ${grayLight};
  padding: 1rem 0;
  border: none;
  border-radius: 0.3rem;
  width: 100%;
  text-indent: 4rem;

  @media screen and (max-width: 499px) {
    font-size: 1.6rem;
  }

  ::placeholder {
    font-size: 1.4rem;
    font-style: italic;
    color: ${blueText};

    @media screen and (max-width: 499px) {
      font-size: 1.6rem;
    }
  }
`;

const Item = styled.div`
  padding: 0.2rem 2rem;
`;

const Loading = styled.img`
  position: absolute;
  width: 2.5rem;
  padding: 0.5rem;
`;

const StyledMagnifyingGlass = styled(MagnifyingGlass)`
  position: absolute;
  height: 3.5rem;
  width: 3.5rem;
  padding: 0.25rem;
`;

const ReadByScreenReader = styled.div.attrs({
  'aria-live': 'assertive',
  role: 'alert'
})`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const AllPillsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  background-color: white;
  border: 1px solid ${grayLight};
  border-radius: 0.5rem;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  padding: 2rem 1.5rem;
  margin: 0 0 3rem 0;
`;

const Pill = styled.div`
  align-items: center;
  background: ${blue};
  border-radius: 3px;
  box-sizing: border-box;
  color: white;
  display: flex;
  font-size: 1.4rem;
  margin-top: 0.5rem;
  max-width: 100%;
  padding: 0.4rem 0.6rem 0.5rem 0.6rem;

  :first-child {
    margin-top: 1.5rem;
  }
`;

const PillText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  width: auto;
  cursor: pointer;

  svg {
    fill: white;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

const CollapseToggle = styled.button`
  align-items: center;
  align-self: flex-start;
  background: none;
  border: none;
  color: ${blue};
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  margin-top: 1rem;
`;

const Caret = styled(DirectionalCaret)`
  margin-left: 1rem;
`;

const Label = styled.label`
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
`;

const Default = styled.div`
  font-size: 1.3rem;
  padding: 1rem 0.5rem 0 0;
  color: ${grayText};
`;

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => {
  return (
    <Item>
      <span>{suggestion.name}</span>
    </Item>
  );
};

const SearchAndFilterInput = ({
  disabled,
  type,
  removeSuggestion,
  makeSelection,
  fetchRequested,
  defaultMessage,
  pillData
}) => {
  const [expanded, setExpanded] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');

  // Debounce returns a function and its reference must be preserved on rerenders for it to work
  // The dependencies are required to ensure no stale functions are used
  const handleSuggestionsFetchRequested = useCallback(
    debounce(async e => {
      let resp = await fetchRequested(e);
      setSuggestions(resp);
    }, 300),
    [fetchRequested, setSuggestions]
  );

  const handleRemoveSuggestion = async companyId => {
    removeSuggestion(companyId);
  };

  const handleSuggestionSelected = (_, { suggestion }) => {
    setValue('');
    setSuggestions([]);
    return makeSelection(suggestion);
  };

  const onChange = (_, { newValue }) => setValue(newValue);

  const handleSuggestedClearRequested = () => {
    setSuggestions([]);
  };

  const renderInput = inputProps => <Input id={`${type}-filter`} {...inputProps} />;

  const inputProps = {
    disabled,
    placeholder: disabled ? 'Loading skills to search...' : `Enter a ${type}...`,
    value,
    onChange,
    id: `${type}-filter`
  };

  let allPills = defaultMessage ? (
    <Default>{defaultMessage}</Default>
  ) : (
    pillData.map(([itemId, itemName]) => (
      <Pill data-cy={`${type}-pill`} key={itemId}>
        <CloseButton
          onClick={() => handleRemoveSuggestion(itemId)}
          data-cy={`remove-${itemName}-btn`}
          aria-label={`remove ${itemName} from the filter ${type} list`}
        >
          <Close />
        </CloseButton>
        <PillText>{itemName}</PillText>
      </Pill>
    ))
  );

  const needsCollapsing = pillData.length > 5;

  if (needsCollapsing && !expanded) {
    allPills = allPills.slice(0, 5);
  }

  return (
    <Wrapper data-theme="custom">
      <Label
        htmlFor={`${type}-filter`}
        aria-label={`Filter by ${type}`}
      >{`Filter by ${type}`}</Label>
      <InputWrapper aria-label={`Enter a ${type} to filter by`}>
        {disabled ? (
          <Loading src={LoadingGif} alt="loading options" />
        ) : (
          <StyledMagnifyingGlass aria-hidden />
        )}
        <Autosuggest
          id={`${type}-autosuggest`}
          suggestions={suggestions}
          onSuggestionSelected={handleSuggestionSelected}
          onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={handleSuggestedClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInput}
          inputProps={inputProps}
          shouldRenderSuggestions={() => true}
        />
        {suggestions.length > 0 && (
          <ReadByScreenReader>results available, use arrows to select.</ReadByScreenReader>
        )}
      </InputWrapper>
      <AllPillsWrapper>{allPills}</AllPillsWrapper>
      {needsCollapsing && (
        <CollapseToggle
          onClick={() => setExpanded(e => !e)}
          aria-expanded={expanded}
          aria-label={
            expanded ? `hide selected ${type} filters` : `expand selected ${type} filters`
          }
        >
          {expanded ? 'Hide' : 'Show All'}
          <Caret fillColor={blue} position={expanded ? 'up' : 'down'} />
        </CollapseToggle>
      )}
    </Wrapper>
  );
};

SearchAndFilterInput.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired, // Used to ensure that the input passes accessibility tests
  removeSuggestion: PropTypes.func.isRequired,
  makeSelection: PropTypes.func.isRequired,
  defaultMessage: PropTypes.string, // Not required, only used for LocationAutocomplete
  fetchRequested: PropTypes.func.isRequired,
  pillData: PropTypes.arrayOf(PropTypes.array)
};

export default SearchAndFilterInput;
