import { connect } from 'react-redux';
import Goals from 'components/pages/Goals';
import { profileActions } from 'store/reducers/profile';

const { selectGoal } = profileActions;

export default connect(
  null,
  { selectGoal }
)(Goals);
