import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const wrapIndex = (newIndex, length) => (newIndex === -1 ? length - 1 : newIndex % length);

// NOTE: Only Radio components should be children of this component, otherwise things will break.
// The label prop should be used if a label is needed
const RadioGroup = ({ children, className, label }) => {
  const refs = useRef(new Map()).current;
  const childrenArr = React.Children.toArray(children);
  return (
    <div role="radiogroup" className={className}>
      {label}
      {childrenArr.map((child, i) =>
        React.cloneElement(child, {
          ref: el => {
            refs.set(i, el);
          },
          handleUpArrow: () => {
            const el = refs.get(wrapIndex(i - 1, childrenArr.length));
            el.click();
            el.focus();
          },
          handleDownArrow: () => {
            const el = refs.get(wrapIndex(i + 1, childrenArr.length));
            el.click();
            el.focus();
          }
        })
      )}
    </div>
  );
};

RadioGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.node
};

export default RadioGroup;
