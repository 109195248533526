import clonedeep from 'lodash.clonedeep';

/**
 * This function will take an array of education items (courses or groups) and return an array of ed items with the appropriate counts for the "want" and "careerAreaSkills" skills category.
 * @param {array} edArray - An array of either course or group objects.
 * @param {object | null} profileSkills - An object with skill ids as the keys marked as 'have' or 'want'. eg: {KS123XL73F2L2R1ZXK0K: 'have', KS123196V19HLL2MP68M: 'want'}. Can be null.
 * @param {array | null} clusterSkills - An array of objects that hold the skills that pertain to that cluster. Can be null.
 * @param {string} type - A string that describes education type. Can be either 'Courses' or 'Programs'.
 * @return {array} - An array of objects that have the counts of each category of skills added as a field for each category.
 */
export function countEducationSkills(edArray, profileSkills, clusterSkills, type) {
  let newArray = clonedeep(edArray);

  newArray = newArray.map(item => {
    if (type === 'Programs') {
      // group does not have an attribute for all skills it contains, which is necessary to compute the count of OTHER skills.
      // Therefore, we need to compute the list of skills by combining skills from all of its courses, and de-duplicate (through Set).
      let groupSkills = [];
      item.courses.forEach(course => (groupSkills = groupSkills.concat(course.skills)));
      item.skills = [...new Set(groupSkills)]; // Deduplication via Set(), then spread to convert to an array
    }
    if (clusterSkills) {
      let clusterSkillIds = clusterSkills.map(skill => skill.id);
      item.careerAreaSkills = item.skills.filter(skill => clusterSkillIds.includes(skill)).length;
    }

    if (profileSkills) {
      item.wantSkills = item.skills.filter(skill => profileSkills[skill] === 'want').length;
      // haveSkills count is useful for the prior learning credit (plc) page
      item.haveSkills = item.skills.filter(skill => profileSkills[skill] === 'have').length;
    }

    return item;
  });
  return newArray;
}
