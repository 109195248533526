import React from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import Media from 'react-media';

import { red } from 'helpers/colors';
import { getSkillsByTitle } from 'services/analystProfiles';
import AriaAlert from 'components/atoms/AriaAlert';
import Input from 'components/atoms/Input';
import SkillsChooser from 'components/molecules/SkillsChooser';
import IconButton from 'components/atoms/IconButton';
import CloseButton from 'components/atoms/CloseButton';

const StyledInput = styled(Input)`
  padding-right: 4rem;
  @media screen and (min-width: 800px) {
    padding-right: 11rem;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0.3rem;
  padding: 0.5rem;
`;

const StyledCloseButton = styled(CloseButton)`
  top: 0.8rem;
`;

const ErrorMessage = styled.div`
  font-size: 1.2rem;
  color: ${red};
  padding-top: 0.5rem;
`;

class SkillNameWrapper extends React.Component {
  state = {
    isRequesting: false,
    query: '',
    queryError: false,
    skillIds: []
  };

  handleInputFocus = () => {
    if (window.screen.width < 500 && this.skillNameInput) {
      this.skillNameInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  handleInputChange = event => {
    this.setState({
      query: event.target.value,
      isRequesting: true
    });
    this.updateSkills(event.target.value);
  };

  updateSkills = debounce(async query => {
    if (!query) {
      return this.setState({
        isRequesting: false,
        skillIds: []
      });
    }

    try {
      let skills = await getSkillsByTitle(query);
      if (query === this.state.query) {
        this.setState({
          queryError: false,
          skillIds: skills.map(skill => skill.id),
          isRequesting: false
        });
      }
    } catch (e) {
      this.setState({
        queryError: true,
        isRequesting: false
      });
    }
  }, 250);

  render() {
    const { isRequesting, query, queryError, skillIds } = this.state;

    const handleClick = () => {
      this.setState({ skillIds: [], query: '', isRequesting: false });
      const inputElement = document.getElementById('skill-page-input');
      inputElement && inputElement.focus();
    };

    let clearButton = null;
    if (query !== '') {
      clearButton = (
        <Media query={{ maxWidth: 799 }}>
          {matches =>
            matches ? (
              <StyledCloseButton
                onClick={handleClick}
                ariaLabel="Try searching another skill name"
              />
            ) : (
              <IconButton
                onClick={handleClick}
                buttonText="+ Try Another"
                ariaLabel="Try searching another skill name"
              />
            )
          }
        </Media>
      );
    }

    return (
      <>
        <InputWrapper>
          <StyledInput
            onChange={this.handleInputChange}
            placeholder="e.g. Writing"
            onFocus={this.handleInputFocus}
            aria-label="Type to view and select a skill."
            ref={c => (this.skillNameInput = c)}
            value={query}
            id="skill-page-input"
          />
          <ButtonWrapper>{clearButton}</ButtonWrapper>
        </InputWrapper>
        {queryError && (
          <ErrorMessage aria-live="polite">There was an error requesting skills.</ErrorMessage>
        )}
        <AriaAlert>
          {!isRequesting && skillIds.length > 0 && 'Tab to select skills matching your search.'}
        </AriaAlert>
        <SkillsChooser
          skillIds={skillIds}
          isLoading={false}
          emptyText="Enter a skill name or keyword to see related skills here."
        />
      </>
    );
  }
}

export default SkillNameWrapper;
