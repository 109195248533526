export const required = value => (value ? undefined : 'Required');

export const isValidPassword = value =>
  !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value) &&
  '8 characters, one number, upper and lower';

export const isEmail = value => !/^\S+@\S+\.\S+$/i.test(value) && 'Invalid Email Address';

// This can be used to validate multiple options at once e.g. the input is an email and required
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
