import { getJobPostingsBySkills } from 'services/jpa';

/**
 * This helper function takes the user's selected skills, skills from a specific cluster, and a city name and return a sorted array of job postings
 * in that area with skills related to that cluster
 * @param {array} selectedSkills - An object with skillIds as keys, and "have" or "want" as the value.
 * @param {array} filterCompanyIds - An Array of Company Id's
 * @param {array} clusterSkills - An array of skill objects
 * @param {string[]} cities - An array of city strings to filter on
 * @return {array} - An array of job posting objects that have been sorted by haveSkills and careerAreaSkills.
 */
export async function getAndSortJobPostings(
  selectedSkills,
  filterCompanyIds,
  clusterSkills,
  cities
) {
  const clusterSkillsArray = clusterSkills.map(skill => skill.id);
  const response = await getJobPostingsBySkills(
    clusterSkillsArray,
    cities,
    filterCompanyIds,
    null,
    100,
    1
  );
  const { postings } = response.data;

  // This determines which skills in the cluster are ALSO in the posting then counts up how many of the skills the user has
  // and then adds them to the posting as haveSkills and CareerAreaSkills as new attributes
  postings.forEach(posting => {
    // Amount of Career Area Skills
    posting.careerAreaSkills = posting.skills.filter(skill =>
      clusterSkillsArray.includes(skill)
    ).length;

    // Amount of Have Skills
    posting.haveSkills = posting.skills.filter(skill => selectedSkills[skill] === 'have').length;
  });

  const sortedPostings = postings.sort((a, b) => {
    return b.haveSkills - a.haveSkills;
  });
  return sortedPostings;
}
