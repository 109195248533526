import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormInput from 'components/atoms/FormInput';
import Link from 'components/atoms/Link';
import LoadingButton from 'components/molecules/LoadingButton';
import withKeen from 'hocs/withKeen';
import { red } from 'helpers/colors';
import { required, isEmail, composeValidators } from 'helpers/validateForm';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledLink = styled(Link)`
  font-size: 1.4rem;
  align-self: flex-end;
  margin-bottom: 0.4rem;
`;

const Error = styled.span`
  font-size: 1.4rem;
  color: ${red};
  margin-top: 1rem;
`;

const LoginForm = ({ error, login, isLoading, clearProfileError, trackEvent, history }) => {
  // This effect clears the profile error state so that it does not persist into another form
  useEffect(() => () => clearProfileError(), [clearProfileError]);

  useEffect(() => {
    if (error === 'Password reset required for the user') {
      history.push('/forgot-password?required=true');
    }
  }, [error, history]);

  return (
    <Form
      onSubmit={values => {
        const data = {
          ...values,
          email: values.email.toLowerCase()
        };
        login(data);
        trackEvent('Attempted log in');
      }}
    >
      {({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Field name="email" validate={composeValidators(required, isEmail)}>
            {({ input, meta }) => (
              <FormInput
                label="Email"
                autoComplete="email"
                error={meta.error && meta.touched ? meta.error : ''}
                {...input}
              />
            )}
          </Field>
          <StyledLink to={`/forgot-password${window.location.search}`}>Forgot Password?</StyledLink>
          <Field name="password" type="password" validate={required}>
            {({ input, meta }) => (
              <FormInput
                label="Password"
                autoComplete="current-password"
                error={meta.error && meta.touched ? meta.error : ''}
                {...input}
              />
            )}
          </Field>
          <LoadingButton isLoading={isLoading}>Login</LoadingButton>
          {error && <Error aria-live="polite">{error}</Error>}
        </StyledForm>
      )}
    </Form>
  );
};

LoginForm.propTypes = {
  clearProfileError: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default withKeen(withRouter(LoginForm));
