import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';

import SkillsLinkCard from 'components/organisms/SkillsLinkCard';
import TitledCard from 'components/organisms/TitledCard';
import { ReactComponent as CheckmarkInBox } from 'images/checkmark-in-box.svg';
import { ReactComponent as JobPostings } from 'images/job-postings.svg';
import { ReactComponent as OpenBook } from 'images/open-book.svg';
import { ReactComponent as ScrewdriverAndWrench } from 'images/screwdriver-and-wrench.svg';
import { ReactComponent as Highlighter } from 'images/highlight.svg';

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
`;

const SkillsOverview = ({ updateVisitedPages, hasSkills, visitedSkillsPages }) => {
  const title = hasSkills ? 'Continue adding skills' : 'How do you want to start adding skills?';

  return (
    <TitledCard title={title} centerHeader>
      <Flex>
        <Media query="(min-width: 800px)">
          <SkillsLinkCard
            to="/skills/resume"
            icon={Highlighter}
            visitedSkillsPages={visitedSkillsPages}
            title="Extract From My Résumé"
            updateVisitedPages={updateVisitedPages}
          />
        </Media>
        <SkillsLinkCard
          to="/skills/job"
          icon={JobPostings}
          visitedSkillsPages={visitedSkillsPages}
          title="By Job Title"
          updateVisitedPages={updateVisitedPages}
        />
        <SkillsLinkCard
          to="/skills/category"
          icon={CheckmarkInBox}
          visitedSkillsPages={visitedSkillsPages}
          title="By Category"
          updateVisitedPages={updateVisitedPages}
        />
        <SkillsLinkCard
          to="/skills/name"
          icon={ScrewdriverAndWrench}
          visitedSkillsPages={visitedSkillsPages}
          title="By Name"
          updateVisitedPages={updateVisitedPages}
        />
        {window.nation === 'US' && (
          <SkillsLinkCard
            to="/skills/education"
            icon={OpenBook}
            visitedSkillsPages={visitedSkillsPages}
            title="By My Education"
            updateVisitedPages={updateVisitedPages}
          />
        )}
      </Flex>
    </TitledCard>
  );
};

SkillsOverview.propTypes = {
  hasSkills: PropTypes.bool.isRequired,
  updateVisitedPages: PropTypes.func.isRequired,
  visitedSkillsPages: PropTypes.array.isRequired
};
export default SkillsOverview;
