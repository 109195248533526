import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import LoadingButton from 'components/molecules/LoadingButton';
import FormInput from 'components/atoms/FormInput';
import { changeCognitoPassword } from 'services/auth';
import { red } from 'helpers/colors';
import { isValidPassword, required, composeValidators } from 'helpers/validateForm';
import { flashSuccess } from 'helpers/flashMessage';

const PasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media (min-width: 850px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const PasswordInputWrapper = styled.div`
  @media (min-width: 850px) {
    flex-basis: 49%;
    &:first-child {
      flex-basis: 100%;
    }
    &:first-child > div {
      width: 49%;
    }
  }
`;

const StyledButton = styled(LoadingButton)`
  @media (min-width: 800px) {
    width: 27rem;
  }
`;

const Error = styled.p`
  font-size: 1.4rem;
  margin-top: 0;
  color: ${red};
`;

const ChangePasswordForm = ({ trackEvent }) => {
  const [error, setError] = useState(null);

  return (
    <Form
      onSubmit={async ({ oldPassword, newPassword }) => {
        setError(null);
        try {
          await changeCognitoPassword(oldPassword, newPassword);
          trackEvent('Password Changed');
          flashSuccess('Password Changed!');
        } catch (e) {
          if (e.message === 'Incorrect username or password.') {
            setError('The Old Password you entered is incorrect');
          } else {
            setError(e.message);
          }
        }
      }}
      validate={values => {
        const errors = {};
        if (values.newPassword !== values.confirmPassword) {
          errors.confirmPassword = 'Passwords must match';
        }
        return errors;
      }}
    >
      {({ handleSubmit, form, hasValidationErrors, submitting }) => (
        <form
          onSubmit={values => {
            handleSubmit(values).then(() => form.restart());
          }}
        >
          <PasswordWrapper>
            <Field
              name="oldPassword"
              type="password"
              validate={composeValidators(required, isValidPassword)}
            >
              {({ input, meta }) => (
                <PasswordInputWrapper>
                  <FormInput
                    label="Old Password"
                    autoComplete="current-password"
                    error={meta.error && meta.touched ? meta.error : ''}
                    {...input}
                  />
                </PasswordInputWrapper>
              )}
            </Field>
            <Field
              name="newPassword"
              type="password"
              validate={composeValidators(required, isValidPassword)}
            >
              {({ input, meta }) => (
                <PasswordInputWrapper>
                  <FormInput
                    label="New Password"
                    autoComplete="new-password"
                    error={meta.error && meta.touched ? meta.error : ''}
                    {...input}
                  />
                </PasswordInputWrapper>
              )}
            </Field>
            <Field name="confirmPassword" type="password" validate={required}>
              {({ input, meta }) => (
                <PasswordInputWrapper>
                  <FormInput
                    label="Re-enter New Password"
                    autoComplete="new-password"
                    error={meta.error && meta.touched ? meta.error : ''}
                    {...input}
                  />
                </PasswordInputWrapper>
              )}
            </Field>
          </PasswordWrapper>
          {error && <Error aria-live="polite">{error}</Error>}
          <StyledButton disabled={hasValidationErrors} isLoading={submitting}>
            Change Password
          </StyledButton>
        </form>
      )}
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  trackEvent: PropTypes.func.isRequired
};

export default ChangePasswordForm;
