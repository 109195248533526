import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import { createAction, handleActions, combineActions } from 'redux-actions';

import { UPDATE_VIEW } from 'store/reducers/currentViews';

// ACTION TYPES
export const SELECT_GOAL = 'profile/SELECT_GOAL';
export const UPDATE_PAGES = 'profile/UPDATE_PAGES';
export const SELECT_SKILL = 'select-skill/SELECT_SKILL';
export const SELECT_ALL_SKILLS = 'select-skill/SELECT_ALL_SKILLS';
export const DESELECT_SKILL = 'select-skill/DESELECT_SKILL';
export const DESELECT_ALL_SKILLS = 'select-skill/DESELECT_ALL_SKILLS';
export const ADD_EDUCATION = 'profile/ADD_EDUCATION';
export const REMOVE_EDUCATION = 'profile/REMOVE_EDUCATION';
export const TOGGLE_DISPLAY_AS_LIST = 'profile/TOGGLE_DISPLAY_AS_LIST';
export const FORCE_VERIFICATION = 'profile/FORCE_VERIFICATION';
export const LOGOUT = 'profile/LOGOUT';
export const CLEAR_PROFILE_ERROR = 'profile/CLEAR_PROFILE_ERROR';
export const CLEANUP_FORGOT_PASSWORD = 'profile/CLEANUP_FORGOT_PASSWORD';
export const CLOSE_SIGNUP_PROMPT = 'profile/CLOSE_SIGNUP_PROMPT';

export const GET_PROFILE_VIEW = {
  REQUEST: 'profile/GET_PROFILE_VIEW.REQUEST',
  SUCCESS: 'profile/GET_PROFILE_VIEW.SUCCESS'
};

export const ADD_PROFILE_VIEW = {
  REQUEST: 'profile/ADD_PROFILE_VIEW.REQUEST',
  SUCCESS: 'profile/ADD_PROFILE_VIEW.SUCCESS'
};

export const DELETE_PROFILE_VIEW = {
  REQUEST: 'profile/DELETE_PROFILE_VIEW.REQUEST',
  SUCCESS: 'profile/DELETE_PROFILE_VIEW.SUCCESS'
};

export const UPDATE_PROFILE_VIEW = {
  REQUEST: 'profile/UPDATE_PROFILE_VIEW.REQUEST',
  SUCCESS: 'profile/UPDATE_PROFILE_VIEW.SUCCESS'
};

export const SIGNUP = {
  REQUEST: 'profile/SIGNUP.REQUEST',
  SUCCESS: 'profile/SIGNUP.SUCCESS',
  FAILURE: 'profile/SIGNUP.FAILURE'
};

export const VALIDATE_PROFILE = {
  REQUEST: 'profile/VALIDATE_PROFILE.REQUEST',
  SUCCESS: 'profile/VALIDATE_PROFILE.SUCCESS',
  FAILURE: 'profile/VALIDATE_PROFILE.FAILURE'
};

export const LOGIN = {
  REQUEST: 'profile/LOGIN.REQUEST',
  FAILURE: 'profile/LOGIN.FAILURE'
};

export const PROFILE_CREATE = {
  REQUEST: 'profile/PROFILE_CREATE.REQUEST',
  SUCCESS: 'profile/PROFILE_CREATE.SUCCESS',
  FAILURE: 'profile/PROFILE_CREATE.FAILURE'
};

export const PROFILE_FETCH = {
  REQUEST: 'profile/PROFILE_FETCH.REQUEST',
  SUCCESS: 'profile/PROFILE_FETCH.SUCCESS',
  FAILURE: 'profile/PROFILE_FETCH.FAILURE'
};

export const PROFILE_DELETE = {
  REQUEST: `profile/PROFILE_DELETE.REQUEST`,
  SUCCESS: `profile/PROFILE_DELETE.SUCCESS`,
  FAILURE: `profile/PROFILE_DELETE.FAILURE`
};

export const INITIATE_FORGOT_PASSWORD = {
  REQUEST: 'profile/INITIATE_FORGOT_PASSWORD.REQUEST',
  SUCCESS: 'profile/INITIATE_FORGOT_PASSWORD.SUCCESS',
  FAILURE: 'profile/INITIATE_FORGOT_PASSWORD.FAILURE'
};

export const SUBMIT_FORGOT_PASSWORD = {
  REQUEST: 'profile/SUBMIT_FORGOT_PASSWORD.REQUEST',
  SUCCESS: 'profile/SUBMIT_FORGOT_PASSWORD.SUCCESS',
  FAILURE: 'profile/SUBMIT_FORGOT_PASSWORD.FAILURE'
};

export const CHANGE_EMAIL = {
  REQUEST: 'profile/CHANGE_EMAIL.REQUEST',
  SUCCESS: 'profile/CHANGE_EMAIL.SUCCESS',
  FAILURE: 'profile/CHANGE_EMAIL.FAILURE'
};

// Actions
export const profileActions = {
  selectGoal: createAction(SELECT_GOAL),
  addEducation: createAction(ADD_EDUCATION),
  removeEducation: createAction(REMOVE_EDUCATION),
  updateVisitedPages: createAction(UPDATE_PAGES),
  addSelectedSkill: createAction(SELECT_SKILL),
  selectAllSkills: createAction(SELECT_ALL_SKILLS),
  removeSelectedSkill: createAction(DESELECT_SKILL),
  deselectAllSelectedSkills: createAction(DESELECT_ALL_SKILLS),
  toggleDisplayAsList: createAction(TOGGLE_DISPLAY_AS_LIST),
  forceVerification: createAction(FORCE_VERIFICATION),
  clearProfileError: createAction(CLEAR_PROFILE_ERROR),
  cleanupForgotPassword: createAction(CLEANUP_FORGOT_PASSWORD),
  logout: createAction(LOGOUT),
  closeSignUpPrompt: createAction(CLOSE_SIGNUP_PROMPT),

  getProfileView: {
    request: createAction(GET_PROFILE_VIEW.REQUEST),
    success: createAction(GET_PROFILE_VIEW.SUCCESS)
  },
  addProfileView: {
    request: createAction(ADD_PROFILE_VIEW.REQUEST),
    success: createAction(ADD_PROFILE_VIEW.SUCCESS)
  },
  deleteProfileView: {
    request: createAction(DELETE_PROFILE_VIEW.REQUEST),
    success: createAction(DELETE_PROFILE_VIEW.SUCCESS)
  },
  updateProfileView: {
    request: createAction(UPDATE_PROFILE_VIEW.REQUEST),
    success: createAction(UPDATE_PROFILE_VIEW.SUCCESS)
  },
  login: {
    request: createAction(LOGIN.REQUEST),
    failure: createAction(LOGIN.FAILURE)
  },
  signUp: {
    request: createAction(SIGNUP.REQUEST),
    success: createAction(SIGNUP.SUCCESS),
    failure: createAction(SIGNUP.FAILURE)
  },
  createProfile: {
    request: createAction(PROFILE_CREATE.REQUEST),
    success: createAction(PROFILE_CREATE.SUCCESS),
    failure: createAction(PROFILE_CREATE.FAILURE)
  },
  fetchProfile: {
    request: createAction(PROFILE_FETCH.REQUEST),
    success: createAction(PROFILE_FETCH.SUCCESS),
    failure: createAction(PROFILE_FETCH.FAILURE)
  },
  deleteProfile: {
    request: createAction(PROFILE_DELETE.REQUEST),
    success: createAction(PROFILE_DELETE.SUCCESS),
    failure: createAction(PROFILE_DELETE.FAILURE)
  },
  validateProfile: {
    request: createAction(VALIDATE_PROFILE.REQUEST),
    success: createAction(VALIDATE_PROFILE.SUCCESS),
    failure: createAction(VALIDATE_PROFILE.FAILURE)
  },
  initiateForgotPassword: {
    request: createAction(INITIATE_FORGOT_PASSWORD.REQUEST),
    success: createAction(INITIATE_FORGOT_PASSWORD.SUCCESS),
    failure: createAction(INITIATE_FORGOT_PASSWORD.FAILURE)
  },
  submitForgotPassword: {
    request: createAction(SUBMIT_FORGOT_PASSWORD.REQUEST),
    success: createAction(SUBMIT_FORGOT_PASSWORD.SUCCESS),
    failure: createAction(SUBMIT_FORGOT_PASSWORD.FAILURE)
  },
  changeEmail: {
    request: createAction(CHANGE_EMAIL.REQUEST),
    success: createAction(CHANGE_EMAIL.SUCCESS),
    failure: createAction(CHANGE_EMAIL.FAILURE)
  }
};

const initialState = {
  activeViewId: null,
  displayAsList: false,
  education: [],
  email: '',
  error: null,
  goal: '',
  isDeleting: false,
  isEmailChanging: false,
  isForgotPassEmailSent: false,
  isLoading: false,
  isLoggedIn: false,
  needsVerification: false,
  selectedSkills: {},
  tempPassword: null,
  views: [],
  visitedSkillsPages: [],
  hasSignUpPromptClosed: false
};

export default handleActions(
  {
    [combineActions(LOGIN.REQUEST, SIGNUP.REQUEST)]: (state, action) => ({
      ...state,
      isLoading: true,
      error: null,
      tempPassword: action.payload.password,
      email: action.payload.email
    }),
    [SIGNUP.SUCCESS]: state => ({
      ...state,
      needsVerification: true
    }),
    [FORCE_VERIFICATION]: state => ({
      ...state,
      needsVerification: true,
      isLoading: false,
      error: null
    }),
    [LOGOUT]: state => ({
      ...initialState,
      // Keep the existing error state on Logout. Without this, if a user has an error on login or fetching their profile, the error will not persist since we use a LOGOUT action to logout of cognito when that happens.
      // This error is likely some sort of validation error such as `Profile does not exist` or `Profile already exists on another subdomain` etc...
      error: state.error
    }),
    [CLOSE_SIGNUP_PROMPT]: state => ({
      ...state,
      hasSignUpPromptClosed: true
    }),
    [CLEAR_PROFILE_ERROR]: state => ({
      ...state,
      error: null
    }),
    [PROFILE_FETCH.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload.description
    }),
    [LOGIN.FAILURE]: (state, action) => {
      // Strip out the tempPassword state from the reducer when login fails
      const { tempPassword, ...rest } = state;
      return {
        ...rest,
        isLoading: false,
        // We set needsVerification to false here to redirect back to the login page. If it's true the user is redirected to the verification page.
        // This applies when attempting to login to an unverified account with a wrong password. This way the user can retry signing in.
        needsVerification: false,
        error: action.payload.description
      };
    },
    [combineActions(SIGNUP.FAILURE, PROFILE_CREATE.FAILURE, VALIDATE_PROFILE.FAILURE)]: (
      state,
      action
    ) => ({
      ...state,
      isLoading: false,
      isLoggedIn: false,
      error: action.payload.description
    }),
    [combineActions(
      PROFILE_CREATE.REQUEST,
      VALIDATE_PROFILE.REQUEST,
      PROFILE_FETCH.REQUEST,
      SUBMIT_FORGOT_PASSWORD.REQUEST,
      ADD_PROFILE_VIEW.REQUEST
    )]: state => ({
      ...state,
      isLoading: true,
      error: null,
      activeViewId: null
    }),
    [PROFILE_CREATE.SUCCESS]: state => ({
      ...state,
      isLoading: false
    }),
    [PROFILE_FETCH.SUCCESS]: (state, action) => {
      // Strip out the tempPassword state from the reducer when it is no longer needed
      const { tempPassword, ...rest } = state;
      const mergedEducation = [...rest.education];
      const mergedSelectedSkills = { ...rest.selectedSkills };
      const mergedVisitedSkillsPages = [...rest.visitedSkillsPages];
      const {
        education: fetchedEducation,
        selectedSkills: fetchedSelectedSkills,
        visitedSkillsPages: fetchedVisitedSkillsPages
      } = action.payload;

      // rest.goal, or the goal in state, will always take precedence if rest.goal is not empty
      // otherwise it'll use the saved goal from what was fetched.
      const updatedGoal = rest.goal || action.payload.goal;

      // Merge in education from fetched profile
      // A duplicate education item has a matching area and level.
      // If a duplicate item is found between the fetched profile and the one in state, the one in state is preserved.
      // For High School level, Area is Null
      if (fetchedEducation) {
        for (let a of fetchedEducation) {
          const inState = mergedEducation.some(
            x => x.level.id === a.level.id && (!x.area || x.area.id === a.area.id)
          );
          if (!inState) {
            mergedEducation.push(a);
          }
        }
      }

      // Merge in selectedSkills from fetched profile
      // If a duplicate skill is found between the fetched profile and the one in state, the one in state is preserved.
      if (fetchedSelectedSkills) {
        for (let a in fetchedSelectedSkills) {
          if (!mergedSelectedSkills[a]) {
            mergedSelectedSkills[a] = fetchedSelectedSkills[a];
          }
        }
      }

      // Merge in visitedSkillsPages from fetched profile
      if (fetchedVisitedSkillsPages) {
        for (let a of fetchedVisitedSkillsPages) {
          if (!mergedVisitedSkillsPages.includes(a)) {
            mergedVisitedSkillsPages.push(a);
          }
        }
      }

      // sort currentViewFilters by updatedAt times
      const sortedViews = action.payload.views.sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );

      return {
        ...rest,
        ...action.payload,
        views: sortedViews,
        education: mergedEducation,
        selectedSkills: mergedSelectedSkills,
        visitedSkillsPages: mergedVisitedSkillsPages,
        goal: updatedGoal,
        isLoading: false,
        isLoggedIn: true
      };
    },
    [PROFILE_DELETE.REQUEST]: state => ({
      ...state,
      isDeleting: true
    }),
    [PROFILE_DELETE.SUCCESS]: () => ({
      ...initialState
    }),
    [PROFILE_DELETE.FAILURE]: state => ({
      ...state,
      isDeleting: false
    }),
    [CHANGE_EMAIL.REQUEST]: state => ({
      ...state,
      isEmailChanging: true
    }),
    [CHANGE_EMAIL.SUCCESS]: (state, action) => ({
      ...state,
      email: action.payload,
      isEmailChanging: false
    }),
    [CHANGE_EMAIL.FAILURE]: state => ({
      ...state,
      isEmailChanging: false
    }),
    [VALIDATE_PROFILE.SUCCESS]: state => ({
      ...state,
      isLoading: false,
      // needsVerification is used to redirect back to whichever flow triggered the verification
      needsVerification: false
    }),
    [SELECT_GOAL]: (state, action) => ({
      ...state,
      goal: action.payload
    }),
    [SELECT_SKILL]: (state, action) => ({
      ...state,
      selectedSkills: {
        ...state.selectedSkills,
        [action.payload.skillId]: action.payload.selectionType
      }
    }),
    [ADD_PROFILE_VIEW.SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      activeViewId: action.payload.id,
      views: [action.payload, ...state.views]
    }),
    [combineActions(DELETE_PROFILE_VIEW.REQUEST, UPDATE_PROFILE_VIEW.REQUEST)]: state => ({
      ...state,
      isLoading: true
    }),
    [DELETE_PROFILE_VIEW.SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      views: state.views.filter(view => view.id !== action.payload)
    }),
    [GET_PROFILE_VIEW.REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
      activeViewId: action.payload
    }),
    [combineActions(GET_PROFILE_VIEW.SUCCESS, UPDATE_PROFILE_VIEW.SUCCESS)]: (state, action) => ({
      ...state,
      isLoading: false,
      /*
      activeViewId needs to be reset here because the steps between
      .request and .success have an updateView func call which resets activeViewId
      */
      activeViewId: action.payload
    }),
    [UPDATE_VIEW]: state => ({
      ...state,
      activeViewId: null
    }),
    [SELECT_ALL_SKILLS]: (state, action) => ({
      ...state,
      selectedSkills: {
        ...state.selectedSkills,
        ...action.payload
      }
    }),
    [DESELECT_SKILL]: (state, action) => {
      const { selectedSkills } = state;
      let newSelectedSkills = { ...selectedSkills };
      delete newSelectedSkills[action.payload.skillId];
      return {
        ...state,
        selectedSkills: newSelectedSkills
      };
    },
    [DESELECT_ALL_SKILLS]: (state, action) => {
      let newSelectedSkills = { ...state.selectedSkills };
      action.payload.forEach(skill => {
        delete newSelectedSkills[skill];
      });
      return {
        ...state,
        selectedSkills: newSelectedSkills
      };
    },
    [ADD_EDUCATION]: (state, action) => {
      let newEdArray = [...state.education, cloneDeep(action.payload)];
      return {
        ...state,
        education: newEdArray
      };
    },
    [REMOVE_EDUCATION]: (state, action) => {
      const badIndex = state.education.findIndex(item => isEqual(item, action.payload));
      if (badIndex === -1) {
        return state;
      }
      let newEdArray = [
        ...state.education.slice(0, badIndex),
        ...state.education.slice(badIndex + 1)
      ];
      return {
        ...state,
        education: newEdArray
      };
    },
    [UPDATE_PAGES]: (state, action) => ({
      ...state,
      visitedSkillsPages: action.payload
    }),
    [TOGGLE_DISPLAY_AS_LIST]: state => ({
      ...state,
      displayAsList: !state.displayAsList
    }),
    [CLEANUP_FORGOT_PASSWORD]: state => ({
      ...state,
      isForgotPassEmailSent: false,
      error: null
    }),
    [INITIATE_FORGOT_PASSWORD.REQUEST]: (state, action) => ({
      ...state,
      email: action.payload,
      isLoading: true,
      error: null
    }),
    [INITIATE_FORGOT_PASSWORD.SUCCESS]: state => ({
      ...state,
      isLoading: false,
      isForgotPassEmailSent: true
    }),
    [SUBMIT_FORGOT_PASSWORD.SUCCESS]: state => ({
      ...state,
      isLoading: false
    }),
    [combineActions(INITIATE_FORGOT_PASSWORD.FAILURE, SUBMIT_FORGOT_PASSWORD.FAILURE)]: (
      state,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: action.payload
    })
  },
  initialState
);

/* ****************** Selectors ********************* */

export const nonMetaProfileSelector = state => {
  const {
    isDeleting,
    isEmailChanging,
    isForgotPassEmailSent,
    isLoading,
    isLoggedIn,
    needsVerification,
    tempPassword,
    views,
    error,
    createdAt,
    updatedAt,
    lastLogin,
    activeViewId,
    hasSignUpPromptClosed,
    ...nonMetaProfileData
  } = state.profile;
  return nonMetaProfileData;
};

export const emailAndPasswordSelector = state => ({
  email: state.profile.email,
  tempPassword: state.profile.tempPassword
});
