import React from 'react';
import Media from 'react-media';
import propTypes from 'prop-types';
import styled from 'styled-components';

import Card from 'components/atoms/Card';
import SelectedSkillsPanel from 'containers/SelectedSkillsPanel';
import SkillsProgress from 'components/molecules/SkillsProgress';
import SelectedSkillsDrawer from 'components/molecules/SelectedSkillsDrawer';

import { grayMedium } from 'helpers/colors';

const SidebarWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    margin-left: 3rem;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 3rem;
    width: 35rem;
    flex-shrink: 0;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 3rem;
  margin-right: 3rem;
  @media screen and (max-width: 1023px) {
    margin-bottom: 0;
  }
`;

const StyledHr = styled.hr`
  margin: 0;
  @media screen and (min-width: 1024px) {
    margin: 0 2rem;
  }
  border: solid 1px ${grayMedium};
`;

const SelectedSkillsSidebar = ({ bottomButton }) => {
  return (
    <SidebarWrapper>
      <StyledCard>
        <SkillsProgress />
        <StyledHr />
        <Media query="(min-width: 1024px)">
          {matches => (matches ? <SelectedSkillsPanel /> : <SelectedSkillsDrawer />)}
        </Media>
      </StyledCard>
      {bottomButton}
    </SidebarWrapper>
  );
};

SelectedSkillsSidebar.propTypes = {
  bottomButton: propTypes.node
};

export default SelectedSkillsSidebar;
