import { createAction, handleActions } from 'redux-actions';

export const FETCH_SITE = {
  REQUEST: 'site/FETCH_SITE.REQUEST',
  SUCCESS: 'site/FETCH_SITE.SUCCESS',
  NOT_FOUND: 'site/FETCH_SITE.NOT_FOUND',
  FAILURE: 'site/FETCH_SITE.FAILURE'
};

export const fetchSiteActions = {
  request: createAction(FETCH_SITE.REQUEST),
  success: createAction(FETCH_SITE.SUCCESS),
  notFound: createAction(FETCH_SITE.NOT_FOUND),
  failure: createAction(FETCH_SITE.FAILURE)
};

const initialState = {
  data: {},
  isLoaded: false,
  notFound: false,
  error: null
};

export default handleActions(
  {
    [FETCH_SITE.REQUEST]: state => ({
      ...state,
      isLoaded: false,
      notFound: false,
      error: null
    }),
    [FETCH_SITE.SUCCESS]: (state, action) => ({
      ...state,
      isLoaded: true,
      notFound: false,
      error: null,
      data: action.payload
    }),
    [FETCH_SITE.NOT_FOUND]: state => ({
      ...state,
      isLoaded: true,
      notFound: true,
      error: null
    }),
    [FETCH_SITE.FAILURE]: (state, action) => ({
      ...state,
      isLoaded: false,
      notFound: false,
      error: action.payload || 'Unknown error loading site data.'
    })
  },
  initialState
);
