import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';

const GlobalStyle = createGlobalStyle`
  body {
      ${props => props.isOpen && 'overflow-y: hidden;'}
  }
`;

const Wrapper = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
`;

const Overlay = styled.div`
  flex: 1;
  background: rgba(0, 0, 0, 0.5);
`;

const MobileSidebar = ({ children, className, isOpen, onOverlayClick }) => {
  return (
    <>
      <GlobalStyle isOpen={isOpen} />
      <Wrapper isOpen={isOpen}>
        <div className={className}>{children}</div>
        <Overlay onClick={onOverlayClick} />
      </Wrapper>
    </>
  );
};

MobileSidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onOverlayClick: PropTypes.func.isRequired
};

export default MobileSidebar;
