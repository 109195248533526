import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ClickOutsideWrapper from 'components/atoms/ClickOutsideWrapper';
import SvgIcon from 'components/atoms/SvgIcon';

import { ReactComponent as SaveIcon } from 'images/save.svg';
import { ReactComponent as DeleteIcon } from 'images/delete.svg';
import { ReactComponent as OptionsSvg } from 'images/options-button.svg';
import { grayLight, red, blue } from 'helpers/colors.js';

const OptionsButton = styled.button`
  display: flex;
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent;
`;

const MenuList = styled.div`
  position: absolute;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  border: 1px solid ${grayLight};
  border-radius: 0.5rem;
  top: 2.7rem;
  right: 0;
  z-index: 1;
  padding: 1rem;
  background-color: white;
`;

const MenuItem = styled.button`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: ${({ isDelete }) => (isDelete ? red : blue)};
  border: none;
  padding: 0.5rem;
  white-space: nowrap;
  background-color: transparent;
  &:hover {
    background-color: ${grayLight};
  }
`;

const StyledIcon = styled(SvgIcon)`
  width: 1.3rem;
  height: 1.35rem;
  margin-right: 1.25rem;
`;

const HiddenMenuItem = styled.button`
  position: fixed;
  height: 0;
  width: 0;
  opacity: 0;
`;

const ViewOptionsButton = ({ toggleOption, view, openModal, selectedId }) => {
  // when MenuList is open, move focus to the first delete button
  useEffect(() => {
    selectedId && document.getElementById('options-list').children[1].focus();
  }, [selectedId]);

  return (
    <>
      <OptionsButton
        className="ignore-react-onclickoutside"
        aria-label={`Click to open options for ${view.name}`}
        aria-expanded={selectedId === view.id}
        onClick={toggleOption}
      >
        <OptionsSvg />
      </OptionsButton>
      {selectedId === view.id && (
        <ClickOutsideWrapper clickOutsideHandler={toggleOption}>
          <MenuList id="options-list">
            <HiddenMenuItem onFocus={toggleOption} />
            <MenuItem
              onClick={() => openModal('Overwrite')}
              aria-label={`Click to overwrite ${view.name}`}
            >
              <StyledIcon Svg={SaveIcon} />
              Overwrite
            </MenuItem>
            <MenuItem
              isDelete
              onClick={() => openModal('Delete')}
              aria-label={`Click to delete ${view.name}`}
            >
              <StyledIcon Svg={DeleteIcon} fill={red} />
              Delete
            </MenuItem>
            <HiddenMenuItem onFocus={toggleOption} />
          </MenuList>
        </ClickOutsideWrapper>
      )}
    </>
  );
};

ViewOptionsButton.propTypes = {
  toggleOption: PropTypes.func,
  view: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  selectedId: PropTypes.string,
  openModal: PropTypes.func.isRequired
};

export default ViewOptionsButton;
