import config from 'config';
import KeenTracking from 'keen-tracking';
import getSubdomain from 'helpers/getSubdomain';
import isTrackingEnabled from 'helpers/isTrackingEnabled';

// This is Keen Project ID and Write Key for all subdomains
const client = new KeenTracking({
  projectId: config.keen.projectId,
  writeKey: config.keen.writeKey,
  respectDoNotTrack: true,
  // prevent too many retries when uBlock Origin (ad blocker) is on
  requestType: 'beaconAPI'
});

// This is New Horizon's & Purdue Global's unique Keen Project ID and Write Key
// We collect and send data to their AWS bucket for their analysis
const newHorizonsClient = new KeenTracking({
  projectId: config.keenNewHorizons.projectId,
  writeKey: config.keenNewHorizons.writeKey
});

const purdueGlobalClient = new KeenTracking({
  projectId: config.keenPurdueGlobal.projectId,
  writeKey: config.keenPurdueGlobal.writeKey
});

// Automatically add these props to every event recorded
const addKeenMetaData = () => {
  return {
    // eslint-disable-next-line no-template-curly-in-string
    ip_address: '${keen.ip}',
    // eslint-disable-next-line no-template-curly-in-string
    user_agent: '${keen.user_agent}',
    page: {
      title: document.title,
      url: document.location.href,
      info: {
        /* Enriched data created by the keen:url_parser addon output will go in here */
      }
    },
    referrer: {
      url: document.referrer,
      info: {
        /* Enriched data created by the keen:referrer_parser addon output will go in here */
      }
    },
    keen: {
      addons: [
        {
          name: 'keen:ip_to_geo',
          input: {
            ip: 'ip_address'
          },
          output: 'ip_geo_info'
        },
        {
          name: 'keen:url_parser',
          input: {
            url: 'page.url'
          },
          output: 'page.info'
        },
        {
          name: 'keen:referrer_parser',
          input: {
            referrer_url: 'referrer.url',
            page_url: 'page.url'
          },
          output: 'referrer.info'
        },
        {
          name: 'keen:ua_parser',
          input: {
            ua_string: 'user_agent'
          },
          output: 'parsed_user_agent'
        }
      ]
    }
  };
};

client.extendEvents(addKeenMetaData);

newHorizonsClient.extendEvents(addKeenMetaData);

purdueGlobalClient.extendEvents(addKeenMetaData);

/**
 * Send an event to Keen IO for tracking
 * @method  trackEvent
 * @param   {string}      eventName  the name of the event to be tracked
 * @param   {object}      eventBody  the object containing pertinent event info
 */
export const trackEvent = (eventName, eventBody = {}) => {
  if (!navigator.cookieEnabled || !isTrackingEnabled()) {
    return;
  }

  const visitId = localStorage.getItem('visitId');
  const visitorId = localStorage.getItem('visitorId');

  const subdomain = getSubdomain();

  const { modelVersion } = config;

  client.recordEvent(eventName, {
    modelVersion,
    subdomain,
    visitId,
    visitorId,
    ...eventBody
  });

  // newhorizons and marathonpetroleum are both controlled by New Horizons. marathonpetroleum is an experimental domain for running experiments on SkillsMatch with job postings only coming from a handful of employers
  if (subdomain === 'newhorizons' || subdomain === 'marathonpetroleum') {
    newHorizonsClient.recordEvent(eventName, {
      modelVersion,
      subdomain,
      visitId,
      visitorId,
      ...eventBody
    });
  }

  // pg and pg-google are both controlled by Purdue Global.
  if (subdomain === 'pg' || subdomain === 'pg-google') {
    purdueGlobalClient.recordEvent(eventName, {
      modelVersion,
      subdomain,
      visitId,
      visitorId,
      ...eventBody
    });
  }
};
