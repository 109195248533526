import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'containers/BackgroundImage';
import Button from 'components/atoms/Button';

const TextWrapper = styled.div`
  position: relative;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.h1`
  margin-bottom: 2rem;
  color: white;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
`;

const ErrorPage = () => (
  <BackgroundImage>
    <TextWrapper>
      <Text role="alert">Sorry, the server has encountered an error.</Text>
      <Button onClick={() => window.location.reload()}>Try Again</Button>
    </TextWrapper>
  </BackgroundImage>
);

export default ErrorPage;
