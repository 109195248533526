import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import CircleButton from 'components/atoms/CircleButton';
import DirectionalCaret from 'components/atoms/DirectionalCaret';
import Margin from 'components/atoms/Margin';
import SvgIcon from 'components/atoms/SvgIcon';
import withKeen from 'hocs/withKeen';

import { blue, blueText, grayLight } from 'helpers/colors';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  ${props => props.extraStyles}
`;

const Overlay = styled.div`
  width: 100%;
  position: absolute;
  z-index: 97;
  background-color: rgba(0, 0, 0, 0.5);

  top: 4.5rem;
  height: calc(100% - 4.5rem);
  @media (min-width: 800px) {
    top: 6rem;
    height: calc(100% - 6rem);
  }
`;

const LeftRightArrow = styled(DirectionalCaret)`
  width: 1.2rem;
`;

const StyledOrderedList = styled.ol`
  padding: 0;
`;

const Row = styled.li`
  width: 26rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:first-child {
    margin-top: 2rem;
  }
`;

const NavItem = styled.div`
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  font-size: 1.6rem;
`;

// remove `active` from props to avoid console error
const NavLink = styled(({ active, ...props }) => <Link {...props} />)`
  text-decoration: none;
  background: ${props => (props.active ? grayLight : 'white')};
  color: ${props => (props.active ? blueText : blue)};
  border-radius: 3px;
`;

const ExpandButton = styled.button`
  background: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: ${blue};
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`;

const StyledHr = styled.hr`
  width: 100%;
  border: 1px solid ${grayLight};
`;

const MobileNav = ({ basePath, navSteps, skipButton, trackEvent }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const currentPathIndex = navSteps.findIndex(item => item.path === basePath);

  const { icon, text, path } = navSteps[currentPathIndex];
  return (
    <>
      <FlexContainer
        extraStyles={
          /* When nav is expanded, this part needs to be hidden from screen reader, but also needs to occupy space to prevent the page below from shifting */
          isExpanded && 'visibility:hidden;'
        }
      >
        <CircleButton
          component={Link}
          size="3.5rem"
          disabled={currentPathIndex === 0}
          to={currentPathIndex > 0 ? `/${navSteps[currentPathIndex - 1].path}` : `/${path}`}
          aria-label={
            currentPathIndex > 0
              ? `Go To Previous Step: ${navSteps[currentPathIndex - 1].text}`
              : 'This Is the First Step'
          }
        >
          <LeftRightArrow position="left" fillColor={blue} />
        </CircleButton>

        <FlexContainer flexDirection="column">
          <Margin margin="1rem 0">
            <NavItem>
              <SvgIcon Svg={icon} fill={blueText} />
              &nbsp;&nbsp;{text}
            </NavItem>
          </Margin>
          <Margin margin="0 0 1.4rem 0">
            <ExpandButton
              aria-label="Show All Navigation Steps"
              onClick={() => {
                setIsExpanded(true);
                trackEvent('Mobile Nav Toggle', { action: 'expand' });
              }}
            >
              SEE ALL <DirectionalCaret position="down" fillColor={blue} />
            </ExpandButton>
          </Margin>
        </FlexContainer>
        <CircleButton
          component={Link}
          size="3.5rem"
          disabled={currentPathIndex === navSteps.length - 1}
          to={
            currentPathIndex < navSteps.length - 1
              ? `/${navSteps[currentPathIndex + 1].path}`
              : `/${path}`
          }
          aria-label={
            currentPathIndex < navSteps.length - 1
              ? `Go to Next Step: ${navSteps[currentPathIndex + 1].text}`
              : 'This Is the Last Step'
          }
        >
          <LeftRightArrow position="right" fillColor={blue} />
        </CircleButton>
      </FlexContainer>

      {isExpanded && (
        <Overlay onClick={() => setIsExpanded(false)}>
          <FlexContainer flexDirection="column" extraStyles="width: 100%;">
            <StyledOrderedList>
              {navSteps.map((item, index) => (
                <Row key={`nav-${item.path}`}>
                  <NavLink
                    active={item.path === basePath}
                    to={`/${item.path}`}
                    onClick={() => setIsExpanded(false)}
                  >
                    <NavItem>
                      <SvgIcon Svg={item.icon} fill={item.path === basePath ? blueText : blue} />
                      &nbsp;&nbsp;
                      {item.text}
                    </NavItem>
                  </NavLink>
                  {index !== navSteps.length - 1 && <StyledHr />}
                </Row>
              ))}
            </StyledOrderedList>
            {currentPathIndex < navSteps.length - 1 && (
              <Margin margin="3rem 0 1rem 0">
                {React.cloneElement(skipButton, {
                  onClick: () => {
                    setIsExpanded(false);
                  }
                })}
              </Margin>
            )}
            <Margin margin="2rem 0">
              <ExpandButton
                aria-label="Hide Navigation Steps"
                onClick={() => {
                  setIsExpanded(false);
                  trackEvent('Mobile Nav Toggle', { action: 'collapse' });
                }}
              >
                HIDE ALL <DirectionalCaret position="up" fillColor={blue} />
              </ExpandButton>
            </Margin>
          </FlexContainer>
        </Overlay>
      )}
    </>
  );
};

MobileNav.propTypes = {
  basePath: PropTypes.string.isRequired,
  navSteps: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired
    })
  ).isRequired,
  skipButton: PropTypes.node,
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(MobileNav);
