import { connect } from 'react-redux';
import ForgotPassword from 'components/pages/ForgotPassword';
import { profileActions } from 'store/reducers/profile';

const mapStateToProps = ({ profile }) => ({
  isEmailSent: profile.isForgotPassEmailSent,
  error: profile.error,
  isLoading: profile.isLoading
});

export default connect(mapStateToProps, {
  cleanup: profileActions.cleanupForgotPassword,
  initiateForgotPass: profileActions.initiateForgotPassword.request,
  submitForgotPass: profileActions.submitForgotPassword.request
})(ForgotPassword);
