import cognitoAuth from '@aws-amplify/auth';
import fetchWithRetry from './helpers/fetchWithRetry';

export const formatProfileRequest = (method, data, type, id) => {
  const requestOptions = {
    method
  };
  if (data) {
    const formattedData = {
      data: {
        type,
        attributes: data
      }
    };
    if (id) {
      formattedData.data.attributes.profileId = id;
    }
    requestOptions.body = JSON.stringify(formattedData);
  }
  return cognitoAuth
    .currentSession()
    .then(userSession => {
      const token = userSession.idToken.jwtToken;
      requestOptions.headers = {
        Authorization: `Bearer ${token}`
      };
      return requestOptions;
    })
    .catch(() => {
      // This catch is important for the createProfile function, since Cognito needs email verification
      // before a user can log in and get a token that's needed to make all other requests.
      return requestOptions;
    });
};

export const getAllProfiles = async subdomain => {
  const requestOptions = await formatProfileRequest();
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-profiles/${subdomain}`,
    requestOptions
  );
  const json = await response.json();
  return json;
};

export const createProfile = async (subdomain, profileId, data) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'profile',
        attributes: {
          ...data,
          profileId
        }
      }
    })
  };
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-profiles/${subdomain}`,
    requestOptions
  );
  const json = await response.json();
  return json;
};

export const getProfile = async (subdomain, profileId) => {
  const requestOptions = await formatProfileRequest();
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-profiles/${subdomain}/${profileId}`,
    requestOptions
  );
  const json = await response.json();
  return json;
};

export const updateProfile = async (subdomain, profileId, data) => {
  const requestOptions = await formatProfileRequest('PATCH', data, 'profile');
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-profiles/${subdomain}/${profileId}`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error('problem updating profile');
  }
  const json = await response.json();
  return json;
};

export const deleteProfile = async ({ subdomain, profileId }) => {
  const requestOptions = await formatProfileRequest('DELETE');
  await fetchWithRetry(`/api/emsi-services/sm-profiles/${subdomain}/${profileId}`, requestOptions);
};

// Profile Views Functions
export const createProfileView = async data => {
  const requestOptions = await formatProfileRequest('POST', data, 'view');
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-profiles/saved-views`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error('problem creating view');
  }
  const json = await response.json();
  return json;
};

export const getProfileView = async viewId => {
  const requestOptions = await formatProfileRequest();
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-profiles/saved-views/${viewId}`,
    requestOptions
  );
  const json = await response.json();
  return json;
};

export const deleteProfileView = async viewId => {
  const requestOptions = await formatProfileRequest('DELETE');
  await fetchWithRetry(`/api/emsi-services/sm-profiles/saved-views/${viewId}`, requestOptions);
};

export const updateProfileView = async (viewId, data) => {
  const requestOptions = await formatProfileRequest('PATCH', data, 'view');
  const response = await fetchWithRetry(
    `/api/emsi-services/sm-profiles/saved-views/${viewId}`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error('problem updating view');
  }
  const json = await response.json();
  return json;
};
