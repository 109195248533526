import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Flex from 'components/atoms/Flex';
import Card from 'components/atoms/Card';
import ExternalLink from 'components/atoms/ExternalLink';
import Modal from 'containers/Modal';

import LinkedInIcon from 'images/big-linkedin-upload.svg';
import CloudUpload from 'images/cloud-upload-big.svg';
import LinkedInExample from 'images/linkedin-modal-step2.png';
import GreyCircle from 'images/grey-circle.svg';

import { blue, blueText, grayLight } from 'helpers/colors';

const ModalCard = styled(Card)`
  align-items: center;
  padding: 2rem;

  @media (min-width: 800px) {
    width: 88rem;
    height: 40rem;
  }
`;

const Text = styled.span`
  margin: 3rem 1rem;
  font-size: 1.6rem;
  font-weight: 700;
`;

const StepCard = styled(Card)`
  margin: 2rem 2.5rem 0 2.5rem;
  justify-content: center;
  align-items: center;
  border: 1px solid ${grayLight};
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  border-radius: 5px;
  width: 23rem;
`;

const ImageContainer = styled.div`
  height: 18rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const IconImage = styled.img`
  position: absolute;
  z-index: 2;
`;

const ExampleImageContainer = styled.div`
  height: 18rem;
  width: 100%;
`;

const ExampleImage = styled.img`
  width: 100%;
  border: 1px solid ${grayLight};
`;

const StyledExternalLink = styled(ExternalLink)`
  text-decoration: underline;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const StyledSpan = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: ${blueText};
  padding: 1rem 2rem;
`;

const Button = styled.button`
  color: ${blue};
  text-decoration: underline;
  font-size: 1.4rem;
  line-height: 2rem;
  background: none;
  border: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const LinkedInModal = ({ name, uploadRef }) => {
  return (
    <Modal name={name}>
      <ModalCard>
        <Text>Find Skills in Your LinkedIn Profile</Text>
        <Flex>
          <StepCard>
            <ImageContainer>
              <IconImage src={LinkedInIcon} alt="" />
            </ImageContainer>
            <div>
              <Text>Step 1</Text>
            </div>
            <StyledExternalLink href="https://www.linkedin.com/" target="_blank">
              Log in to Your LinkedIn Profile
            </StyledExternalLink>
          </StepCard>
          <StepCard>
            <ExampleImageContainer>
              <ExampleImage src={LinkedInExample} alt="" />
            </ExampleImageContainer>
            <div>
              <Text>Step 2</Text>
            </div>
            <StyledSpan>
              Click <strong>&apos;More&apos;</strong> and select{' '}
              <strong>&apos;Save to PDF&apos;</strong>
            </StyledSpan>
          </StepCard>
          <StepCard>
            <ImageContainer>
              <IconImage src={GreyCircle} alt="" />
              <IconImage src={CloudUpload} alt="" />
            </ImageContainer>
            <div>
              <Text>Step 3</Text>
            </div>
            <Button onClick={() => uploadRef.current.click()}>Upload Your LinkedIn Profile</Button>
          </StepCard>
        </Flex>
      </ModalCard>
    </Modal>
  );
};

LinkedInModal.propTypes = {
  name: PropTypes.string.isRequired,
  uploadRef: PropTypes.shape({
    current: PropTypes.object
  }).isRequired
};

export default LinkedInModal;
