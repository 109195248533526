import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import SvgIcon from 'components/atoms/SvgIcon';
import { blue, blueText } from 'helpers/colors.js';

const Wrapper = styled.div`
  background: white;
  font-size: 1.2rem;
  position: fixed;
  z-index: 98;
  bottom: 0;
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px -5px 14px rgba(0, 0, 0, 0.0713505);
`;

const Spacer = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: auto;
`;

const Link = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${blueText};
  text-decoration: none;
  padding: 0 0.5rem;
  path {
    fill: ${blueText};
  }
  &.active {
    font-weight: bold;
    color: ${blue};
    border-top: 3px solid ${blue};
    path {
      fill: ${blue};
    }
  }
`;

const Icon = styled(props => <SvgIcon {...props} />)`
  margin: 0.5rem;
  width: 2rem;
  height: 2rem;
`;

const ResultsMobileNav = ({ routes }) => (
  <Wrapper>
    <Spacer>
      {routes.map(route => (
        <Link activeClassName="active" key={route.text} to={route.path}>
          <Icon Svg={route.icon} />
          <span>{route.text}</span>
        </Link>
      ))}
    </Spacer>
  </Wrapper>
);

ResultsMobileNav.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired
    })
  ).isRequired
};

export default ResultsMobileNav;
