import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ViewModalFactory from 'components/organisms/ViewModalFactory';
import ViewOptionsButton from 'components/molecules/ViewOptionsButton';
import DirectionalCaret from 'components/atoms/DirectionalCaret';
import Button from 'components/atoms/Button';

import { ReactComponent as FilterSvg } from 'images/filter-with-circle.svg';
import { ReactComponent as PlusCircleSvg } from 'images/plus-circle-border.svg';
import { grayLight, blue, blueDark, blueText, blueLight } from 'helpers/colors.js';

const Wrapper = styled.div`
  background-color: white;
  border: 1px solid ${grayLight};
  border-radius: 0.5rem;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  padding: 2rem 1.5rem;
  margin-bottom: 3rem;
`;

const Label = styled.label`
  color: ${blueText};
  font-size: 1.4rem;
  font-weight: 600;
`;

const Content = styled.div`
  display: flex;
  @media (min-width: 1024px) {
    align-items: center;
    flex-direction: column;
  }
`;

const FilterIcon = styled(FilterSvg)`
  height: auto;
  width: 4rem;
  margin-right: 1rem;
  @media (min-width: 1024px) {
    margin-right: 0;
    margin-top: 1.8rem;
  }
`;

const Text = styled.div`
  font-size: 1.4rem;
  margin: 1.8rem 0;
  @media (min-width: 1024px) {
    margin: 1.3rem 0 2.6rem 0;
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  padding: 1.2rem 4rem;
  margin: 0 auto;
`;

const PlusIcon = styled(PlusCircleSvg)`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.8rem;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CollapseToggle = styled.button`
  padding: 0.2rem;
  align-items: center;
  background: none;
  border: none;
  color: ${blue};
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  margin: 1rem 0 0 -0.2rem;
`;

const Caret = styled(DirectionalCaret)`
  margin-left: 1rem;
`;

const SaveNew = styled.button`
  padding: 0.3rem;
  margin-top: -0.3rem;
  font-size: 1.4rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: ${blue};
  display: flex;
  align-items: center;
`;

const FilterListWrapper = styled.div`
  margin: 1rem 0 0 -0.6rem;
  display: flex;
  flex-direction: column;
`;

const FilterWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
`;

const Filter = styled.button`
  cursor: pointer;
  flex-grow: 1;
  padding: 0.15rem 0.6rem;
  text-align: left;
  border-radius: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem;
  border: none;
  color: ${blueDark};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  background-color: ${({ selected }) => (selected ? blueLight : 'transparent')};
`;

const SavedViews = ({
  isLoggedIn,
  savedViews,
  section,
  getProfileView,
  addProfileView,
  deleteProfileView,
  updateProfileView,
  selectedViewId
}) => {
  const [modalType, setModalType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedOptionView, setSelectedOptionView] = useState(null);
  const sectionViews = savedViews.filter(view => view.type === section);

  const openModal = type => {
    if (!isLoggedIn) {
      setModalType('Auth');
    } else {
      setModalType(type);
    }
    setIsModalOpen(true);
  };

  const needsCollapsing = sectionViews.length > 3;
  let filters = sectionViews.map(view => (
    <FilterWrapper key={view.id}>
      <Filter
        selected={selectedViewId === view.id}
        onClick={() => getProfileView(view.id)}
        aria-label={
          selectedViewId === view.id
            ? `${view.name} is your selected filter`
            : `Select ${view.name} as active filter`
        }
      >
        {view.name}
      </Filter>
      <ViewOptionsButton
        toggleOption={() =>
          setSelectedOptionView(
            selectedOptionView && selectedOptionView.id === view.id ? null : view
          )
        }
        view={view}
        openModal={openModal}
        selectedId={selectedOptionView && selectedOptionView.id}
      />
    </FilterWrapper>
  ));

  if (needsCollapsing && !expanded) {
    filters = filters.slice(0, 3);
  }

  return (
    <Wrapper>
      <ViewModalFactory
        type={modalType}
        isOpen={isModalOpen}
        close={() => {
          setIsModalOpen(false);
          setSelectedOptionView(null);
        }}
        addProfileView={addProfileView}
        deleteProfileView={deleteProfileView}
        section={section}
        selectedFilterObj={selectedOptionView}
        updateProfileView={updateProfileView}
      />
      <TopSection>
        <Label>Saved Filters</Label>
        {sectionViews.length ? (
          <SaveNew aria-label="Save a new filter" onClick={() => openModal('Save')}>
            <PlusIcon />
            Save New
          </SaveNew>
        ) : null}
      </TopSection>
      {sectionViews.length ? (
        <>
          <FilterListWrapper>{filters}</FilterListWrapper>
          {needsCollapsing && (
            <CollapseToggle
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label={expanded ? 'Hide saved filters' : 'expand saved filters'}
            >
              {expanded ? 'Hide' : 'Show All'}
              <Caret fillColor={blue} position={expanded ? 'up' : 'down'} />
            </CollapseToggle>
          )}
        </>
      ) : (
        <>
          <Content>
            <FilterIcon />
            <Text>Save your filters and quickly recall them later.</Text>
          </Content>
          <StyledButton
            aria-label="Save a new filter"
            inverseColor
            onClick={() => openModal('Save')}
          >
            <PlusIcon />
            Save Filters
          </StyledButton>
        </>
      )}
    </Wrapper>
  );
};

SavedViews.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  savedViews: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, type: PropTypes.string.isRequired })
  ).isRequired,
  section: PropTypes.oneOf(['careerAreas', 'education', 'jobs']).isRequired,
  getProfileView: PropTypes.func.isRequired,
  addProfileView: PropTypes.func.isRequired,
  deleteProfileView: PropTypes.func.isRequired,
  updateProfileView: PropTypes.func.isRequired,
  selectedViewId: PropTypes.string
};

export default SavedViews;
