import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from 'components/atoms/Card';

const FlexContainer = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-wrap: wrap;
  width: 100%;
`;

const StyledCard = styled(Card)`
  margin: 1rem;
  width: 26rem;
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;

  :hover {
    transform: scale(1.08);
    box-shadow: 0px 3px 8px 3px rgba(0, 0, 0, 0.17);
  }

  @media (max-width: 599px) {
    width: calc(100% - 2rem);
    :hover {
      transform: scale(1.03);
    }
  }
`;

const FlexCardContainer = ({ children, ...rest }) => {
  return (
    <FlexContainer {...rest}>
      {React.Children.map(children, child => (
        <StyledCard key={child.key}>{child}</StyledCard>
      ))}
    </FlexContainer>
  );
};

FlexCardContainer.propTypes = {
  children: PropTypes.node
};

export default FlexCardContainer;
