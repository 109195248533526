import React from 'react';
import PropTypes from 'prop-types';

import SearchAndFilterInput from 'components/molecules/SearchAndFilterInput';

import { searchCityNames } from 'services/jpa';

import withKeen from 'hocs/withKeen';

const LocationAutocomplete = ({ defaultCity, cities, trackEvent, updateCities }) => {
  const handleRemoveSuggestion = cityToRemove => {
    const newJobsViewCities = cities.filter(city => city !== cityToRemove);
    if (newJobsViewCities.length) {
      updateCities(newJobsViewCities);
    } else {
      updateCities([defaultCity]);
    }
  };

  const searchCities = async searchTerm => {
    const data = await searchCityNames(searchTerm.value);
    return data.map(res => ({ id: res.name, name: res.name }));
  };
  const selectCity = item => {
    if (item && !cities.includes(item.name)) {
      trackEvent('Location Searched', {
        location: item.name
      });
      if (cities[0] === defaultCity && cities.length === 1) {
        updateCities([item.name]);
      } else {
        updateCities([item.name, ...cities]);
      }
    }
  };

  let defaultMessage;
  if (cities.length === 1 && cities[0] === defaultCity) {
    defaultMessage = `Showing results for ${defaultCity} (default)`;
  }
  return (
    <SearchAndFilterInput
      type="Location"
      removeSuggestion={handleRemoveSuggestion}
      makeSelection={selectCity}
      fetchRequested={searchCities}
      defaultMessage={defaultMessage}
      // This is needed for pilldata array based structures because we need to pass an ID and NAME setup for SearchAndFilterInput
      pillData={cities.map(city => [city, city])}
    />
  );
};

LocationAutocomplete.propTypes = {
  defaultCity: PropTypes.string.isRequired,
  cities: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  trackEvent: PropTypes.func.isRequired,
  updateCities: PropTypes.func.isRequired
};

export default withKeen(LocationAutocomplete);
