import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import JobPostingCard from 'components/molecules/JobPostingCard';
import LoadMoreButton from 'components/molecules/LoadMoreButton';
import VisuallyHidden from 'components/atoms/VisuallyHidden';
import FlexCardContainer from 'components/molecules/FlexCardContainer';
import NoResultsFilterCard from 'components/molecules/NoResultsFilterCard';
import NoResultsSkillCard from 'components/molecules/NoResultsSkillCard';

import IsAuthenticated from 'hocs/IsAuthenticated';
import { blueText } from 'helpers/colors';
import { ReactComponent as EmptyIcon } from 'images/empty-list.svg';
import loadingGif from 'images/loading.gif';

const MatchesHeader = styled.h2`
  font-size: 1.8rem;
  font-weight: normal;
  margin: 0 0 0 1rem;
`;

const Loader = styled.img`
  width: 5rem;
  margin: 6rem auto;
`;

// center-aligned messages for error and loading
const ErrorMessage = styled.div`
  align-items: center;
  color: ${blueText};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  > svg {
    margin-bottom: 1.5rem;
  }
`;

// This component is a blend of JobResults, ResultsDisplay, and ResultsScreenReader.
// It's done this way so if we don't like Internships as an experiment, it's easy to
// remove without changing other components
const InternshipResults = ({
  jobResults,
  selectedSkills,
  setMobileSidebarOpen,
  loadMoreJobs,
  isAuthenticated
}) => {
  const { matches, error, isLoading, isLoadingMore, endOfList } = jobResults;

  if (isLoading) {
    return <Loader src={loadingGif} alt="loading" />;
  }

  if (error) {
    return (
      <ErrorMessage aria-live="polite">
        <EmptyIcon />
        There was an error loading internships.
      </ErrorMessage>
    );
  }

  const matchedCards = matches
    // some temporary logic filtering duplicate postings from JPA
    // can remove this filtering if JPA no longer returns duplicates
    .filter(
      (currentItem, currentIndex) =>
        // if currentItem is the first item with the same id, then keep it. Filter out the rest
        matches.findIndex(duplicate => duplicate.id === currentItem.id) === currentIndex
    )
    .map(posting => <JobPostingCard key={posting.id} posting={posting} />);

  const hasSelectedSkills = !!Object.keys(selectedSkills).length;
  const noMatchesCard = hasSelectedSkills ? (
    <NoResultsFilterCard
      section="matched"
      contentName="Internships"
      openMobileSidebar={() => setMobileSidebarOpen(true)}
    />
  ) : (
    <NoResultsSkillCard />
  );

  return isAuthenticated ? (
    <>
      <MatchesHeader>
        <strong>Internships</strong>
        &nbsp;Matched to You
      </MatchesHeader>
      {matchedCards.length ? (
        <FlexCardContainer data-cy="matched-to-you">{matchedCards}</FlexCardContainer>
      ) : (
        noMatchesCard
      )}
      <VisuallyHidden>
        {hasSelectedSkills && (
          <div aria-atomic="true" aria-live="polite">
            {matchedCards.length
              ? `displaying ${matchedCards.length} Internships that match your skills`
              : 'all Internships matched to your skills have been filtered out'}
          </div>
        )}
      </VisuallyHidden>
      {matches.length >= 12 && !isLoading && !error && (
        <LoadMoreButton isLoading={isLoadingMore} loadMore={loadMoreJobs} endOfList={endOfList} />
      )}
    </>
  ) : (
    <MatchesHeader>
      You must be logged in to see <strong>Internship</strong> availability.
    </MatchesHeader>
  );
};

InternshipResults.propTypes = {
  jobResults: PropTypes.shape({
    matches: PropTypes.arrayOf(PropTypes.object).isRequired,
    others: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool.isRequired,
    isLoadingMore: PropTypes.bool.isRequired,
    endOfList: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  selectedSkills: PropTypes.objectOf(PropTypes.string).isRequired,
  setMobileSidebarOpen: PropTypes.func.isRequired,
  loadMoreJobs: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

export default IsAuthenticated(InternshipResults);
