import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withKeen from 'hocs/withKeen';
import Button from 'components/atoms/Button';
import LoadingButton from 'components/molecules/LoadingButton';
import Card from 'components/atoms/Card';
import Modal from 'components/atoms/Modal';
import { blueText } from 'helpers/colors';

const StyledCard = styled(Card)`
  padding: 3rem 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 800px) {
    width: 62rem;
    padding: 3rem;
  }
`;

const ModalHeader = styled.h1`
  font-size: 1.7rem;
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 800;
`;

const Text = styled.p`
  margin: 0;
  font-size: 1.5rem;
  line-height: 2.7rem;
  text-align: center;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  width: 100%;
  @media (min-width: 800px) {
    flex-direction: row;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  @media (min-width: 800px) {
    width: 22rem;
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  margin-top: 1.5rem;
  @media (min-width: 800px) {
    width: 22rem;
    margin-top: 0;
  }
`;

const CancelButton = styled(StyledButton)`
  color: ${blueText};
  @media (min-width: 800px) {
    margin-right: 3rem;
  }
`;

const DeleteAccountModal = ({ closeModal, deleteProfile, isDeleting, isModalOpen, trackEvent }) => {
  return (
    <Modal isOpen={isModalOpen} close={closeModal}>
      <StyledCard>
        <ModalHeader>Delete Your Account?</ModalHeader>
        <Text>This will log you out and permanently delete your SkillsMatch account.</Text>
        <ButtonWrap>
          <CancelButton variant="secondary" onClick={closeModal}>
            Cancel
          </CancelButton>
          <StyledLoadingButton
            variant="danger"
            isLoading={isDeleting}
            onClick={() => {
              trackEvent('Attempted account deletion');
              deleteProfile();
            }}
          >
            Delete Account
          </StyledLoadingButton>
        </ButtonWrap>
      </StyledCard>
    </Modal>
  );
};

DeleteAccountModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(DeleteAccountModal);
