import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SkillShape from 'components/atoms/SkillShape';
import { blue } from 'helpers/colors.js';

import { ReactComponent as XCircle } from 'images/x-circle.svg';
import withKeen from 'hocs/withKeen';

const List = styled.ul`
  padding: 0;
  font-size: 1.2rem;
  margin: 0;
`;

const ListItem = styled.li`
  margin: 0.4rem 0;
  list-style: none;
  display: flex;
  align-items: center;
  > div {
    margin-right: 0.8rem;
  }
`;

const SkillName = styled.div`
  flex-grow: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 17rem;
`;

const RemoveSkillButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  padding: 0.3rem;
`;

const ShowAllButton = styled.button`
  border: none;
  background: white;
  padding: 0.2rem;
  margin-left: 1.8rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: ${blue};
  cursor: pointer;
`;

class SelectedSkillsList extends React.Component {
  state = {
    showAll: false
  };

  render() {
    const { skills, ariaLabel, removeSkill, trackEvent, ...props } = this.props;
    const { showAll } = this.state;

    const skillList = skills.map(skill => (
      <ListItem key={skill.id}>
        <SkillShape selectionType={skill.selectionType} />
        <SkillName>{skill.name}</SkillName>
        <RemoveSkillButton
          aria-label={`remove ${skill.name} from I ${skill.selectionType} list`}
          onClick={() => {
            removeSkill({ skillId: skill.id });
            trackEvent('Skill Removed', {
              skillName: skill.name,
              skillId: skill.id
            });
          }}
        >
          <XCircle />
        </RemoveSkillButton>
      </ListItem>
    ));
    return (
      <>
        <List aria-label={ariaLabel} {...props}>
          {showAll ? skillList : skillList.slice(0, 4)}
        </List>
        {skillList.length > 4 && (
          <ShowAllButton
            aria-label={`Show ${this.state.showAll ? 'fewer' : 'more'} ${ariaLabel}.`}
            onClick={() => this.setState({ showAll: !this.state.showAll })}
          >
            Show {this.state.showAll ? 'fewer' : 'more'}&hellip;
          </ShowAllButton>
        )}
      </>
    );
  }
}

SelectedSkillsList.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  removeSkill: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(SelectedSkillsList);
