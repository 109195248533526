import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';
import { blue } from 'helpers/colors';

const Link = styled(ReactLink)`
  color: ${blue};
  font-size: 1.6rem;
  text-decoration: none;
  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export default Link;
