import { useState, useLayoutEffect } from 'react';
import { getJobMatches, getJobOthers } from 'helpers/getJobResults';
import { getEducationMatches, getEducationOthers } from 'helpers/getEducationResults';
const initialResultState = {
  matches: [],
  // if others is null, then the OTHERS section won't display
  others: null,
  isLoading: true,
  isLoadingMore: false,
  endOfList: false,
  error: false
};
export default (section, dependencies) => {
  const [results, setResults] = useState(initialResultState);
  const {
    isProfileLoading,
    selectedSkills,
    validSkills,
    filterSkills,
    companyIds,
    cities,
    educationLevels,
    programIds,
    edType,
    searchedSites,
    location
  } = dependencies;

  // useLayoutEffect prevents flashing on edType change
  useLayoutEffect(() => {
    const load = async () => {
      try {
        setResults(initialResultState);
        const matchesResult =
          section === 'jobs'
            ? await getJobMatches(selectedSkills, filterSkills, companyIds, validSkills, cities)
            : await getEducationMatches(
                selectedSkills,
                filterSkills,
                searchedSites,
                educationLevels,
                programIds,
                edType
              );
        let othersResult = null;
        // If there are >=12 matches, then completely skip the OTHERS section
        if (matchesResult.data.length < 12) {
          othersResult =
            section === 'jobs'
              ? await getJobOthers(filterSkills, companyIds, cities)
              : await getEducationOthers(
                  filterSkills,
                  searchedSites,
                  educationLevels,
                  programIds,
                  edType
                );
          if (othersResult.total <= 12) {
            setResults(prevState => ({ ...prevState, endOfList: true }));
          }
        } else if (matchesResult.total <= 12) {
          // if there happens to be just 12 matches in total, also set endOfList to true
          setResults(prevState => ({ ...prevState, endOfList: true }));
        }
        setResults(prevState => ({
          ...prevState,
          isLoading: false,
          matches: matchesResult.data,
          others: othersResult && othersResult.data
        }));
      } catch (e) {
        setResults(prevState => ({ ...prevState, isLoading: false, error: true }));
      }
    };
    if (!isProfileLoading && Object.keys(validSkills).length) {
      load();
    }
  }, [
    isProfileLoading,
    validSkills,
    cities,
    companyIds,
    filterSkills,
    selectedSkills,
    section,
    edType,
    searchedSites,
    educationLevels,
    // Used to update results for PLC and Internships page
    location.pathname
  ]);

  const loadMore = async () => {
    try {
      setResults(prevState => ({ ...prevState, isLoadingMore: true }));
      let { matches, others } = results;

      // If there are >=12 matches, then completely skip the OTHERS section
      if (matches.length >= 12) {
        const result =
          section === 'jobs'
            ? await getJobMatches(
                selectedSkills,
                filterSkills,
                companyIds,
                validSkills,
                cities,
                matches.length
              )
            : await getEducationMatches(
                selectedSkills,
                filterSkills,
                searchedSites,
                educationLevels,
                programIds,
                edType,
                matches.length
              );
        matches.push(...result.data);
        setResults(prevState => ({
          ...prevState,
          isLoadingMore: false,
          matches
        }));
        if (matches.length >= result.total) {
          setResults(prevState => ({ ...prevState, endOfList: true }));
        }
      } else {
        const result =
          section === 'jobs'
            ? await getJobOthers(filterSkills, companyIds, cities, others.length)
            : await getEducationOthers(
                filterSkills,
                searchedSites,
                educationLevels,
                programIds,
                edType,
                others.length
              );
        others.push(...result.data);
        setResults(prevState => ({
          ...prevState,
          isLoadingMore: false,
          others
        }));
        if (others.length >= result.total) {
          setResults(prevState => ({ ...prevState, endOfList: true }));
        }
      }
    } catch (e) {
      setResults(prevState => ({ ...prevState, isLoadingMore: false, error: true }));
    }
  };
  return [results, loadMore];
};
