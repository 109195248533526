import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from 'containers/BackgroundImage';
import Card from 'components/atoms/Card';
import EmailVerificationForm from 'containers/EmailVerificationForm';

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 799px) {
    align-items: flex-start;
  }
`;

const FormCard = styled(Card)`
  width: 27rem;
  padding: 2.7rem 2.5rem 3.5rem 2.5rem;

  @media (max-width: 799px) {
    margin: 1.5rem 1rem 1rem 1rem;
  }
`;

const Header = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const Subheader = styled.span`
  font-size: 1.4rem;
  margin-bottom: 2.6rem;
`;

const EmailVerification = ({ location }) => {
  // To use signup-specific wording, use `/verification?flow=signup` route
  // Otherwise, simply use `/verification` route
  const initialSignUp = location.search.includes('flow=signup');
  return (
    <BackgroundImage hideImageOnMobile>
      <ContentWrapper>
        <FormCard>
          <Header>We need to verify your email before continuing</Header>
          <Subheader>
            {!initialSignUp && "Looks like you haven't completed the Signup process."} Check your
            email&apos;s inbox (or spam folder) for {initialSignUp ? 'your' : 'a new'} verification
            code.
          </Subheader>
          <EmailVerificationForm />
        </FormCard>
      </ContentWrapper>
    </BackgroundImage>
  );
};

EmailVerification.propTypes = {
  // from react-router. This prop is automatically included when using `<Route>` to match to a url
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired
};

export default EmailVerification;
