import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import BackgroundImage from 'containers/BackgroundImage';

const TextWrapper = styled.div`
  position: relative;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.h1`
  margin-bottom: 2rem;
  color: white;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
`;

const Subtext = styled.div`
  width: 25rem;
  color: white;
  font-size: 1.4rem;
  text-align: center;
  @media (min-width: 800px) {
    width: 41rem;
  }
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 1rem;
  :hover {
    text-decoration: underline;
  }
`;

const NotFound = () => {
  useEffect(() => {
    document.title = 'Lightcast SkillsMatch - Not Found';
  }, []);
  return (
    <BackgroundImage>
      <TextWrapper>
        <Text>Oops! The page you&apos;re looking for doesn&apos;t exist...</Text>
        <Subtext>Please check the URL for proper spelling, hit the back button or</Subtext>
        <StyledLink to="/"> Visit the Homepage.</StyledLink>
      </TextWrapper>
    </BackgroundImage>
  );
};
export default NotFound;
