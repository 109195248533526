import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { blue } from 'helpers/colors';

const StyledA = styled.a`
  color: ${blue};
  text-decoration: none;
  :hover,
  :focus {
    text-decoration: underline;
  }
`;

const ExternalLink = ({ url, children, ...other }) => {
  return url ? (
    // Ternary checks if there is a url then add redirection and make the card clickable
    // otherwise there is no redirection or card clickability
    <StyledA
      {...other}
      href={
        // Checks if given link starts with http(s):// and appends it if not
        /^https?:\/\//i.test(url) ? url : `http://${url}`
      }
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </StyledA>
  ) : (
    <StyledA {...other}>{children}</StyledA>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.any.isRequired,
  url: PropTypes.string
};

export default ExternalLink;
