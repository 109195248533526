import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RoleDropdown from 'Admin/components/RoleDropdown';
import RoleConfirmationModal from 'Admin/components/RoleConfirmationModal';
import DirectionalCaret from 'components/atoms/DirectionalCaret';
import Modal from 'components/atoms/Modal';
import Card from 'components/atoms/Card';

import { ReactComponent as MailSvg } from 'images/mail-icon.svg';
import { blue, grayText, grayMedium } from 'helpers/colors';

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${grayMedium};
  padding: 1.2rem 0;
  cursor: pointer;
`;

const NotAcceptedMsg = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  color: ${grayText};
`;

const StyledMailIcon = styled(MailSvg)`
  padding-right: 1rem;
`;

const ListBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Email = styled.div`
  color: ${blue};
  font-size: 1.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledDirectionalCaret = styled(DirectionalCaret)`
  padding-left: 0.5rem;
  min-width: 1rem;
  min-height: 1rem;
`;

const StyledCard = styled(Card)`
  padding: 3rem;
`;

const AdminSettingsList = ({ adminProfiles, options, reloadAdminProfiles }) => {
  const [isDropdownModalOpen, setIsDropdownModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [newUserRole, setNewUserRole] = useState('');
  const [selectedProfile, setSelectedProfile] = useState({
    subdomain: '',
    profileId: '',
    email: '',
    userRole: ''
  });
  return (
    <>
      <StyledUl>
        {adminProfiles.map(profile => (
          <li key={profile.profileId}>
            <ListItem
              onClick={() => {
                setIsDropdownModalOpen(true);
                setSelectedProfile({
                  subdomain: profile.subdomain,
                  profileId: profile.profileId,
                  email: profile.email,
                  userRole: profile.userRole
                });
              }}
              aria-label={`Click to edit permissions for ${profile.email}. ${
                profile.cognitoStatus === 'RESET_REQUIRED'
                  ? `${profile.email} has not accepted their invite`
                  : ''
              }`}
            >
              {profile.cognitoStatus === 'RESET_REQUIRED' && (
                <NotAcceptedMsg aria-hidden>
                  <StyledMailIcon /> Invite Not Yet Accepted
                </NotAcceptedMsg>
              )}
              <ListBody>
                <Email>{profile.email}</Email>
                <StyledDirectionalCaret fillColor={blue} position="down" />
              </ListBody>
            </ListItem>
          </li>
        ))}
      </StyledUl>
      <Modal isOpen={isDropdownModalOpen} close={() => setIsDropdownModalOpen(false)}>
        <StyledCard>
          <RoleDropdown
            isPermissionDropdownModal
            onSelect={value => {
              setIsConfirmModalOpen(true);
              setNewUserRole(value);
            }}
            options={options}
            selectedRole={selectedProfile.userRole}
          />
        </StyledCard>
      </Modal>
      <RoleConfirmationModal
        subdomain={selectedProfile.subdomain}
        profileId={selectedProfile.profileId}
        email={selectedProfile.email}
        selectedUserRole={newUserRole}
        isOpen={isConfirmModalOpen}
        close={() => setIsConfirmModalOpen(false)}
        reloadAdminProfiles={reloadAdminProfiles}
      />
    </>
  );
};

AdminSettingsList.propTypes = {
  adminProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      cognitoStatus: PropTypes.string,
      email: PropTypes.string.isRequired,
      profileId: PropTypes.string.isRequired,
      userRole: PropTypes.string.isRequired
    })
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  reloadAdminProfiles: PropTypes.func.isRequired
};

export default AdminSettingsList;
