import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';

import BackgroundImage from 'containers/BackgroundImage';
import Card from 'components/atoms/Card';
import ForgotPasswordEmailForm from 'components/molecules/ForgotPasswordEmailForm';
import ForgotPasswordResetForm from 'components/molecules/ForgotPasswordResetForm';

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 799px) {
    align-items: flex-start;
  }
`;

const FormCard = styled(Card)`
  width: 27rem;
  padding: 2.7rem 2.5rem 3.5rem 2.5rem;
  @media (max-width: 799px) {
    margin: 1.5rem 1rem 1rem 1rem;
  }
`;

const Header = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const Subheader = styled.span`
  font-size: 1.4rem;
  margin-bottom: 2.6rem;
`;

const ForgotPassword = ({
  error,
  isEmailSent,
  isLoading,
  cleanup,
  location,
  initiateForgotPass,
  submitForgotPass
}) => {
  // Reset redux state on component dismount
  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const verificationMsg = !location.search.includes('just-verified=true')
    ? "Check your email's inbox (or spam folder) for your verification code."
    : 'Another verification code has been sent to your email for password reset.';

  // During login, if the profile requires a password reset then they are redirected to this page with the 'required' query param set
  const { required } = queryString.parse(location.search);

  return (
    <BackgroundImage hideImageOnMobile>
      <ContentWrapper>
        <FormCard>
          <Header>{required ? `Password Reset Required` : 'Reset Password'}</Header>
          <Subheader>
            {!isEmailSent
              ? "Enter your account email and we'll send you an email to reset your password."
              : verificationMsg}
          </Subheader>
          {!isEmailSent ? (
            <ForgotPasswordEmailForm
              error={error}
              isLoading={isLoading}
              onSubmit={initiateForgotPass}
            />
          ) : (
            <ForgotPasswordResetForm
              error={error}
              isLoading={isLoading}
              onSubmit={submitForgotPass}
            />
          )}
        </FormCard>
      </ContentWrapper>
    </BackgroundImage>
  );
};

ForgotPassword.propTypes = {
  error: PropTypes.string,
  isEmailSent: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  cleanup: PropTypes.func.isRequired,
  initiateForgotPass: PropTypes.func.isRequired,
  submitForgotPass: PropTypes.func.isRequired
};

export default ForgotPassword;
