import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import ButtonLink from 'components/atoms/ButtonLink';
import Link from 'components/atoms/Link';
import MyAccountButton from 'components/atoms/MyAccountButton';
import SidebarNav from 'containers/SidebarNav';
import withKeen from 'hocs/withKeen';
import { blueText } from 'helpers/colors.js';
import LightcastLogoWhite from 'images/lightcast-logo-white.png';
import LightcastLogoAndNameWhite from 'images/lightcast-logo-white-name.png';

const LogoWrapper = styled.div`
  @media screen and (max-width: 799px) {
    padding-right: 1.5rem;
  }
  @media screen and (min-width: 800px) {
    padding-left: 4rem;
  }
`;

const MobileLogo = styled.img`
  height: 3rem;

  @media screen and (min-width: 800px) {
    display: none;
  }
`;

const DesktopLogo = styled.img`
  height: 3rem;

  @media screen and (max-width: 799px) {
    display: none;
  }
`;

const StyledDiv = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 4.5rem;
  width: 100%;
  font-size: 1.2rem;
  background: ${props => props.menuBackgroundColor};
  @media screen and (min-width: 800px) {
    justify-content: space-between;
    height: 6rem;
    font-size: 0.85rem;
  }
`;

const HeaderButtonLink = styled(({ backgroundColor, textColor, ...props }) => (
  <ButtonLink {...props} />
))`
  background: ${props => props.backgroundColor};
  border: 1px solid ${props => props.textColor};
  margin-right: 3rem;
  padding: 1rem 2rem;
  color: ${props => props.textColor};
  font-weight: normal;
  font-size: 1.4rem;
`;

const StyledLink = styled(({ textColor, ...props }) => <Link {...props} />)`
  margin: 1.2rem 3rem;
  color: ${props => props.textColor};
  font-size: 1.4rem;
`;

const Logos = styled.div`
  display: flex;
  align-items: flex-end;
`;

const GlobalHeader = ({
  institutionName,
  isLoggedIn,
  userRole,
  location,
  logoImage,
  logout,
  menuBackgroundColor = blueText,
  menuTextColor = 'white',
  trackEvent
}) => {
  // If redirectUrl already exists, then preserve it across login/signup/verification/forgot password flows
  // Otherwise, use current page's url as redirectUrl
  let redirectUrl;
  const currentPath = location.pathname;
  if (
    currentPath.includes('login') ||
    currentPath.includes('signup') ||
    currentPath.includes('verification') ||
    currentPath.includes('forgot-password')
  ) {
    redirectUrl = queryString.parse(window.location.search).redirect;
  } else if (currentPath !== '/') {
    redirectUrl = encodeURIComponent(currentPath);
  }

  if (redirectUrl) {
    redirectUrl = `?redirect=${redirectUrl}`;
  } else {
    redirectUrl = '';
  }

  return (
    <StyledDiv menuBackgroundColor={menuBackgroundColor}>
      <Media query="(max-width: 799px)">
        <SidebarNav />
      </Media>
      <Logos>
        <LogoWrapper>
          <Link to="/" aria-label="Click to go to SkillsMatch home page.">
            <MobileLogo
              data-cy="site-logo-mobile"
              src={logoImage || LightcastLogoWhite}
              alt={`${institutionName || 'Lightcast'} logo.`}
            />

            <DesktopLogo
              data-cy="site-logo-desktop"
              src={logoImage || LightcastLogoAndNameWhite}
              alt={`${institutionName || 'Lightcast'} logo.`}
            />
          </Link>
        </LogoWrapper>
      </Logos>
      <Media query="(min-width: 800px)">
        <div>
          {isLoggedIn ? (
            <MyAccountButton
              textColor={menuTextColor}
              backgroundColor={menuBackgroundColor}
              userRole={userRole}
              logout={logout}
            />
          ) : (
            <>
              <StyledLink
                textColor={menuTextColor}
                to={`/login${redirectUrl}`}
                onClick={() => trackEvent('Login Link Clicked')}
              >
                Login
              </StyledLink>
              <HeaderButtonLink
                textColor={menuTextColor}
                backgroundColor={menuBackgroundColor}
                to={`/signup${redirectUrl}`}
                onClick={() => trackEvent('Sign up Link Clicked')}
              >
                Sign up
              </HeaderButtonLink>
            </>
          )}
        </div>
      </Media>
    </StyledDiv>
  );
};

GlobalHeader.propTypes = {
  institutionName: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  logoImage: PropTypes.string,
  logout: PropTypes.func.isRequired,
  menuBackgroundColor: PropTypes.string,
  menuTextColor: PropTypes.string,
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(withRouter(GlobalHeader));
