import { connect } from 'react-redux';

const mapStateToProps = ({ validSkills }) => ({
  validSkills: validSkills.data,
  validSkillsIsLoading: validSkills.isLoading,
  validSkillsIsLoaded: validSkills.isLoaded,
  validSkillsError: validSkills.error
});

export const WithValidSkills = connect(mapStateToProps);

export default WithValidSkills;
