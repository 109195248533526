import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormInput from 'components/atoms/FormInput';
import Button from 'components/atoms/Button';

const Wrapper = styled.div`
  width: 95%;
`;

const Heading = styled.h1`
  font-size: 1.6rem;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  @media (max-width: 599px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  width: 50%;
  font-size: 1.4rem;
  margin: 0 2rem 0 2rem;
  @media (max-width: 599px) {
    width: 100%;
    margin: 0 0 1rem 0;
  }
`;

const FilterNameInput = styled(FormInput)`
  background-color: white;
  margin-top: 2rem;
`;

const ViewSaveContents = ({ close, section, addProfileView }) => {
  const [error, setError] = useState('');
  const [newFilterName, setNewFilterName] = useState('');

  const saveNewFilter = name => {
    if (!name.length) {
      setError('Please enter a filter name.');
    } else if (name.length > 25) {
      setError('Maximum length of 25 characters allowed.');
    } else {
      setError('');
      addProfileView({ section, newFilterName });
      close();
    }
  };

  return (
    <Wrapper>
      <Heading>Name Saved Filter</Heading>
      <FilterNameInput
        required
        onInput={e => setNewFilterName(e.target.value)}
        error={error}
        placeholder="Enter filter name..."
        type="text"
        aria-label="Enter a name for your filter"
      />
      <ButtonWrapper>
        <StyledButton onClick={close} variant="secondary">
          Cancel
        </StyledButton>
        <StyledButton variant="primary" onClick={() => saveNewFilter(newFilterName.trim())}>
          Save
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

ViewSaveContents.propTypes = {
  close: PropTypes.func.isRequired,
  section: PropTypes.oneOf(['education', 'jobs', 'careerAreas']).isRequired,
  addProfileView: PropTypes.func.isRequired
};

export default ViewSaveContents;
