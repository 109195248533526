import React from 'react';
import PropTypes from 'prop-types';
import withValidSkills from 'containers/withValidSkills';

const withSkillsForIds = WrappedComponent => ({
  skillIds,
  validSkills = {},
  validSkillsIsLoading,
  isLoading,
  ...props
}) => {
  // get skill names from store, ignore invalid skills
  const namedSkills = skillIds ? skillIds.map(id => validSkills[id]).filter(Boolean) : [];
  const eitherIsLoading = isLoading || (skillIds.length > 0 && validSkillsIsLoading);

  return <WrappedComponent skills={namedSkills} isLoading={eitherIsLoading} {...props} />;
};

withSkillsForIds.propTypes = {
  skillIds: PropTypes.arrayOf(PropTypes.string).required,
  validSkills: PropTypes.object,
  validSkillsIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool.required
};

export default WrappedComponent => {
  return withValidSkills(withSkillsForIds(WrappedComponent));
};
