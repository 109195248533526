import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { grayMedium, red } from 'helpers/colors';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  min-height: 5.7rem;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 4.2rem;
  border: 0.1rem solid ${props => (props.error ? red : grayMedium)};
  border-radius: 0.6rem;
  font-size: 1.5rem;
  padding: 1.5rem;
  @media screen and (max-width: 499px) {
    font-size: 1.6rem;
  }
`;

const Error = styled.span`
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  font-size: 1.2rem;
  color: ${red};
`;

const StyledLabel = styled.label`
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 2.4rem;
  position: absolute;
  z-index: ${props => (props.isActive ? 1 : 0)};
  top: ${props => (props.isActive ? '-1.2rem' : '1rem')};
  cursor: auto;
  left: 1.1rem;
  background: #ffffff;
  padding: 0 0.5rem;
  transition: all 0.3s;
  letter-spacing: 0.25rem;
`;

const FormInput = ({
  label,
  name,
  error,
  value,
  className,
  onFocus: parentOnFocus,
  onBlur: parentOnBlur,
  ...rest
}) => {
  const [isFocused, setFocused] = useState(false);

  return (
    <Wrapper className={className}>
      {label && (
        <StyledLabel htmlFor={name} isActive={isFocused || value}>
          {label}
        </StyledLabel>
      )}
      <Input
        id={name}
        value={value}
        error={error}
        name={name}
        onFocus={e => {
          setFocused(true);
          parentOnFocus && parentOnFocus(e);
        }}
        onBlur={e => {
          setFocused(false);
          parentOnBlur && parentOnBlur(e);
        }}
        {...rest}
      />
      {error && <Error aria-live="polite">{error}</Error>}
    </Wrapper>
  );
};

FormInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

export default FormInput;
