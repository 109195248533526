import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { grayLight, blue } from 'helpers/colors';

const Container = styled.div`
  height: 20px;
  background: ${grayLight};
  margin-bottom: 1rem;
  position: relative;
`;

const Bar = styled.div`
  height: 20px;
  width: calc(100% / 60 * ${props => props.length});
  background: ${blue};
  transition: width 0.2s;
`;

const Divider = styled.div`
  background: white;
  height: 20px;
  width: 2px;
  position: absolute;
  top: 0;
  left: calc(100% / 5 * ${props => props.index});
`;

const ProgressBar = ({ numberSelected }) => {
  const dividers = [];
  for (let i = 1; i < 5; i++) {
    dividers.push(<Divider index={i} key={`progress-divider-${i}`} />);
  }
  return (
    <Container>
      <Bar length={numberSelected < 60 ? numberSelected : 60} />
      {dividers}
    </Container>
  );
};

ProgressBar.propTypes = {
  numberSelected: PropTypes.number.isRequired
};

export default ProgressBar;
