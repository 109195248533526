import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from 'components/atoms/Link';

import withValidSkills from 'containers/withValidSkills';

import getAndTrimTopSkills from 'helpers/getAndTrimTopSkills';
import getModelVersion from 'helpers/getModelVersion';
import { blue } from 'helpers/colors';
import { ReactComponent as ArrowRight } from 'images/arrow-right.svg';

const Hero = styled.div`
  ${props => `background-image: 
  //overlay of #204354, with 71% opacity
    linear-gradient(#204354b5, #204354b5), url(${props.url})`};
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  box-sizing: border-box;
  height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 1.4rem;
  padding: 0 1.5rem;
  @media (min-width: 550px) {
    padding: 0 3rem;
  }
`;

const Subgroup = styled.h1`
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-weight: normal;
  font-size: 2.8rem;
  @media (max-width: 549px) {
    font-size: 2rem;
  }
`;

const TopSkills = styled.span`
  font-weight: bold;
`;

const ViewMoreLink = styled(Link)`
  font-size: 1.4rem;
  display: block;
  line-height: 4rem;
  margin-top: 2rem;
  margin-left: 3rem;
  @media (max-width: 549px) {
    margin-left: 1.5rem;
  }
`;

const ArrowIcon = styled(ArrowRight)`
  width: 1.5rem;
  height: 1rem;
  margin-right: 0.8rem;
  fill: ${blue};
  transform: rotate(180deg);
`;

const MatchHeader = ({
  className,
  error = false,
  subgroupId,
  subgroupName = '',
  topSkillIds = [],
  validSkills,
  validSkillsIsLoaded
}) => {
  return (
    <>
      <Hero
        url={`https://d3s4k660pqkq8k.cloudfront.net/images/subgroup/${getModelVersion()}/${
          error ? 'default' : subgroupId
        }_hero.jpg`}
        className={className}
      >
        <Subgroup>{subgroupName}</Subgroup>
        {validSkillsIsLoaded && (
          <div>
            With Emphasis on: &nbsp;
            <TopSkills>
              {getAndTrimTopSkills(
                topSkillIds.map(id => ({ id })),
                validSkills,
                80
              )}
            </TopSkills>
          </div>
        )}
      </Hero>
      <ViewMoreLink to="/results/career-areas">
        <ArrowIcon />
        Back to Matches
      </ViewMoreLink>
    </>
  );
};

MatchHeader.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  subgroupId: PropTypes.string.isRequired,
  subgroupName: PropTypes.string,
  topSkillIds: PropTypes.arrayOf(PropTypes.string),
  validSkills: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  validSkillsIsLoaded: PropTypes.bool
};

export default withValidSkills(MatchHeader);
