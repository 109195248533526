import { takeLatest, call, put, select } from 'redux-saga/effects';

import { fetchSiteActions, FETCH_SITE } from 'store/reducers/site';
import { currentViewActions } from 'store/reducers/currentViews';
import { getSite } from 'services/sites';
import { getSkillabiSiteNames } from 'services/skillabiSites';
import { getAllGroupTypeClasses } from 'services/courses';

// we don't want this to run more than once, since the response is so large
// and shouldn't change very often

export function* fetchSite({ payload: subdomain }) {
  try {
    let response = yield call(getSite, subdomain);
    if (!response) {
      yield put(fetchSiteActions.notFound());
    } else {
      window.nation = response.nation;

      response.skillabiSiteNames = yield call(getSkillabiSiteNames, response.skillabiSites);
      // groupTypeClasses are actually the same for all sites, but we just put them in site for simplicity
      response.groupTypeClasses = yield call(getAllGroupTypeClasses);
      const defaultEduTab = response.defaultEduTab || 'Programs';
      yield put(currentViewActions.updateView('education', { filterEdType: defaultEduTab }));
      const currentViewJobsCities = yield select(state => state.currentViews.jobs.filterCities);
      if (!currentViewJobsCities.length) {
        yield put(currentViewActions.updateView('jobs', { filterCities: [response.defaultCity] }));
      }
      yield put(fetchSiteActions.success(response));
    }
  } catch (e) {
    yield put(fetchSiteActions.failure(e.message));
  }
}

export default function* watch() {
  yield takeLatest(FETCH_SITE.REQUEST, fetchSite);
}
