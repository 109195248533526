import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import SvgIcon from 'components/atoms/SvgIcon';
import { blueText, grayLight, grayMedium } from 'helpers/colors';
import { ReactComponent as DownCaret } from 'images/down-caret.svg';

const OpenModalButton = styled.button`
  background: ${props => props.backgroundColor};
  border: 1px solid ${props => props.textColor};
  border-radius: 3px;
  margin-right: 3rem;
  padding: 1rem 2rem;
  cursor: pointer;
  text-align: center;
  color: ${props => props.textColor};
  font-size: 1.4rem;
`;

const OptionsModal = styled.div`
  position: absolute;
  top: 5.1rem;
  right: 3rem;
  width: ${props => (props.userRole ? '18.5rem' : '13.5rem')};
  padding: 0.5rem 0.3rem;
  font-size: 1.4rem;
  background: white;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  z-index: 98;
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Link = styled(NavLink)`
  display: block;
  margin: 0 1rem;
  padding: 1rem;
  color: ${blueText};
  text-decoration: none;
  :hover {
    background: ${grayLight};
  }
`;

const StyledHr = styled.hr`
  width: 90%;
  border: 1px solid ${grayMedium};
`;

const Caret = styled(({ fillColor, ...props }) => <SvgIcon {...props} />)`
  position: relative;
  top: 0.15rem;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  fill: ${props => props.fillColor};
`;

const MyAccountButton = ({ backgroundColor, logout, textColor, userRole }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userRoles = ['Admin', 'View Everything', 'View Profiles', 'View Analytics'];

  const OptionLink = ({ children, onClick, ...props }) => (
    <li role="menuitem">
      <Link
        activeStyle={{ fontWeight: 'bold' }}
        onClick={() => {
          onClick && onClick();
          setIsModalOpen(false);
        }}
        {...props}
      >
        {children}
      </Link>
    </li>
  );

  return (
    <div onMouseLeave={() => setIsModalOpen(false)}>
      <OpenModalButton
        backgroundColor={backgroundColor}
        textColor={textColor}
        onClick={() => setIsModalOpen(!isModalOpen)}
        onMouseOver={() => setIsModalOpen(true)}
        aria-label={`${isModalOpen ? 'close' : 'open'} account menu`}
        aria-expanded={isModalOpen}
      >
        My Account{userRole && '/Admin'}
        <Caret Svg={DownCaret} fillColor={textColor} />
      </OpenModalButton>
      {isModalOpen && (
        <OptionsModal userRole={userRole}>
          <nav>
            <StyledList role="menu">
              <OptionLink to="/" isActive={() => false}>
                Skills Match
              </OptionLink>
              <OptionLink to="/account/settings">Settings</OptionLink>
              <StyledHr />

              {userRoles.includes(userRole) && (
                <>
                  {userRole !== 'View Profiles' && (
                    <OptionLink to="/admin/analytics">Analytics</OptionLink>
                  )}
                  {userRole !== 'View Analytics' && (
                    <OptionLink to="/admin/profiles">Profiles</OptionLink>
                  )}
                  {userRole === 'Admin' && <OptionLink to="/admin/team">Team Settings</OptionLink>}
                  <StyledHr />
                </>
              )}

              <OptionLink role="button" to="/" isActive={() => false} onClick={logout}>
                Log Out
              </OptionLink>
            </StyledList>
          </nav>
        </OptionsModal>
      )}
    </div>
  );
};

MyAccountButton.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  textColor: PropTypes.string.isRequired,
  userRole: PropTypes.string
};

export default MyAccountButton;
