import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from 'components/atoms/Card';
import ExternalLink from 'components/atoms/ExternalLink';
import withKeen from 'hocs/withKeen';

import { blueText, grayLight } from 'helpers/colors';

const StyledCard = styled(Card)`
  width: 100%;
  margin: 1rem 0;
  height: 100%;

  @media (min-width: 560px) {
    width: 26rem;
    margin: 1rem;
  }
`;

const BlueTop = styled.div`
  background: ${blueText};
  border-radius: 0.5rem 0.5rem 0 0;
  height: 1.7rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CardText = styled.h3`
  margin: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  text-align: center;
  flex: 1;
  font-weight: normal;
`;

const StyledLink = styled(ExternalLink)`
  font-size: 1.4rem;
  display: inline-block;
  border-top: 0.1rem solid ${grayLight};
  padding: 1.9rem;
  text-align: center;
`;

const LinkIcon = styled.img.attrs(props => ({
  src: `https://d3s4k660pqkq8k.cloudfront.net/images/cta-icons/${props.icon}.svg`,
  alt: ''
}))`
  width: 1.6rem;
  margin-right: 1rem;
`;

const CallToAction = ({ links, cardText, trackEvent, ctaName }) => {
  return (
    <StyledCard>
      <BlueTop />
      <Flex>
        <CardText>{cardText}</CardText>
        {links.map((link, i) => (
          <StyledLink
            url={link.url}
            key={`${link.url}-${i}`}
            aria-label={link.text}
            onClick={() => {
              trackEvent('Call to action clicked', {
                url: link.url,
                text: link.text,
                ctaName
              });
            }}
          >
            <LinkIcon icon={link.icon} />
            <span>{link.text}</span>
          </StyledLink>
        ))}
      </Flex>
    </StyledCard>
  );
};

CallToAction.propTypes = {
  cardText: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  // Track event comes from withKeen HOC
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(CallToAction);
