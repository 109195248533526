import styled from 'styled-components';
import { grayLight, grayText, blueText } from 'helpers/colors';

const Input = styled.input.attrs({
  type: 'text'
})`
  background: ${grayLight};
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  border: none;
  display: block;
  margin: auto;
  padding: 1rem;
  text-indent: 0.5rem;
  font-size: 1.4rem;
  @media screen and (max-width: 499px) {
    font-size: 1.6rem;
  }
  color: ${blueText};
  ::placeholder {
    font-style: italic;
    color: ${blueText};
  }
  :disabled {
    ::placeholder {
      color: ${grayText};
    }
  }
  /* Prevent Edge from adding an 'x' in the input */
  ::-ms-clear {
    display: none;
  }
`;

export default Input;
