import React from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import PropTypes from 'prop-types';
import DirectionalCaret from 'components/atoms/DirectionalCaret';
import MobileSidebar from 'components/atoms/MobileSidebar';
import { ReactComponent as FilterSvg } from 'images/filter.svg';
import { blue, grayLight, grayDark } from 'helpers/colors';

const DesktopSidebar = styled.div`
  background-color: ${grayLight};
  box-sizing: border-box;
  min-width: 30rem;
  padding: 3.8rem 1.7rem;
  width: 20%;
`;

const OpenBtnWrapper = styled.div`
  display: flex;
  background-color: white;
  height: 4rem;
  justify-content: center;
  width: 100%;
`;

const StyledMobileSidebar = styled(MobileSidebar)`
  background-color: ${grayLight};
  width: 32rem;
`;

const Overflow = styled.div`
  box-sizing: border-box;
  height: calc(100% - 5rem);
  overflow: auto;
  padding: 1.7rem 1.7rem 3.4rem 1.7rem;
`;

const CloseBtnWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${grayDark};
  justify-content: flex-end;
  padding: 1.7rem 1.7rem 1rem 0;
`;

const MenuBtn = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${blue};
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
`;

const FilterIcon = styled(FilterSvg)`
  margin-right: 1rem;
`;

const Caret = styled(DirectionalCaret)`
  margin-right: 1rem;
  top: 0;
`;

const ResultsSidebar = ({
  children,
  mobileOnly,
  mobileOpen,
  type,
  selectedFilterCount,
  setMobileOpen
}) => {
  return (
    <Media query="(min-width: 1024px)" onChange={() => setMobileOpen(false)}>
      {matches =>
        matches && !mobileOnly ? (
          <DesktopSidebar>{children}</DesktopSidebar>
        ) : (
          <>
            {!mobileOnly && (
              <OpenBtnWrapper>
                <MenuBtn
                  aria-label={
                    selectedFilterCount
                      ? `edit ${selectedFilterCount} selected filter${
                          selectedFilterCount > 1 ? 's' : ''
                        }`
                      : ''
                  }
                  onClick={() => setMobileOpen(true)}
                >
                  <FilterIcon />
                  {selectedFilterCount ? `Edit Filters (${selectedFilterCount})` : 'Add Filters'}
                </MenuBtn>
              </OpenBtnWrapper>
            )}
            <StyledMobileSidebar isOpen={mobileOpen} onOverlayClick={() => setMobileOpen(false)}>
              <CloseBtnWrapper>
                <MenuBtn onClick={() => setMobileOpen(false)}>
                  <Caret position="left" fillColor={blue} />
                  Hide {type} Filters
                </MenuBtn>
              </CloseBtnWrapper>
              <Overflow>{children}</Overflow>
            </StyledMobileSidebar>
          </>
        )
      }
    </Media>
  );
};

ResultsSidebar.propTypes = {
  children: PropTypes.node,
  mobileOnly: PropTypes.bool,
  mobileOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  selectedFilterCount: PropTypes.number,
  setMobileOpen: PropTypes.func.isRequired
};

export default ResultsSidebar;
