import { connect } from 'react-redux';
import SkillsOverview from 'components/pages/SkillsOverview';
import { profileActions } from 'store/reducers/profile';

const { updateVisitedPages } = profileActions;
export const mapStateToProps = ({ profile }) => ({
  hasSkills: Object.keys(profile.selectedSkills).length > 0,
  visitedSkillsPages: profile.visitedSkillsPages
});

export default connect(
  mapStateToProps,
  { updateVisitedPages }
)(SkillsOverview);
