const educationData = sortedProfiles => {
  const data = sortedProfiles.map(profile => {
    let educationArray = [];
    profile.attributes.education
      .sort((a, b) => b.level.id - a.level.id)
      .slice(0, 5)
      .forEach(item => {
        educationArray.push(item.level.label);
        educationArray.push(item.area ? item.area.name : '');
        educationArray.push(item.completionStatus);
      });
    return [profile.attributes.email, ...educationArray];
  });

  const columns = [
    'Email',
    'Education 1 Level',
    'Education 1 Area',
    'Education 1 Status',
    'Education 2 Level',
    'Education 2 Area',
    'Education 2 Status',
    'Education 3 Level',
    'Education 3 Area',
    'Education 3 Status',
    'Education 4 Level',
    'Education 4 Area',
    'Education 4 Status',
    'Education 5 Level',
    'Education 5 Area',
    'Education 5 Status'
  ];

  return { data, columns };
};

export default educationData;
