import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import LoadingButton from 'components/molecules/LoadingButton';
import FormInput from 'components/atoms/FormInput';
import { red } from 'helpers/colors';
import { required } from 'helpers/validateForm';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Error = styled.span`
  font-size: 1.4rem;
  color: ${red};
  margin-top: 1rem;
`;

const EmailVerificationForm = ({ error, verifyEmail, isLoading }) => {
  return (
    <Form onSubmit={verifyEmail}>
      {({ handleSubmit }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <Field name="code" validate={required}>
              {({ input, meta }) => {
                return (
                  <FormInput
                    label="Verification Code"
                    autoComplete="one-time-code"
                    error={meta.error && meta.touched ? meta.error : ''}
                    {...input}
                  />
                );
              }}
            </Field>

            <LoadingButton isLoading={isLoading}>Submit</LoadingButton>
            {error && <Error aria-live="polite">{error}</Error>}
          </StyledForm>
        );
      }}
    </Form>
  );
};

EmailVerificationForm.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  verifyEmail: PropTypes.func.isRequired
};

export default EmailVerificationForm;
