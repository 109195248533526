import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { blue, grayMedium, redLight, grayDark, blueText } from 'helpers/colors';

const buttonVariants = {
  primary: blue,
  secondary: grayMedium,
  danger: redLight
};

const fontColorVariants = {
  primary: 'white',
  secondary: blueText,
  danger: 'white'
};

const StyledButton = styled.button`
  display: inline-block;
  background: ${props => buttonVariants[props.variant]};
  cursor: pointer;
  padding: 1.2rem 2rem;
  border-radius: 0.3rem;
  border: 0;
  color: ${props => fontColorVariants[props.variant]};
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;

  ${props =>
    props['aria-disabled'] &&
    `cursor: not-allowed;
     background: ${grayDark};
     :hover {
       box-shadow: none;
     }
    `}
  ${props =>
    props.inverseColor &&
    `
      font-weight: 400;
      background: transparent;
      border: 1px solid ${buttonVariants[props.variant]};
      color: ${buttonVariants[props.variant]};
    `}
`;

const Button = ({
  disabled,
  children,
  className,
  onClick,
  dataCy,
  inverseColor,
  variant = 'primary',
  ...rest
}) => (
  <StyledButton
    data-cy={dataCy}
    className={className}
    onClick={disabled ? e => e.preventDefault() : onClick}
    aria-disabled={disabled}
    variant={variant}
    inverseColor={inverseColor}
    {...rest}
  >
    {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  inverseColor: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string
};

export default Button;
