import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import modal from './modal';
import profile from './profile';
import site from './site';
import validSkills from './validSkills';
import currentViews from './currentViews';

export default combineReducers({
  modal,
  profile,
  site,
  validSkills,
  currentViews,
  toastr
});
