import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tabs from 'components/atoms/Tabs';
import TabButton from 'components/atoms/TabButton';
import ExternalLink from 'components/atoms/ExternalLink';

import AdminToolTip from 'Admin/components/AdminToolTip';
import KeenListDataModal from 'Admin/components/KeenListDataModal';

import { ReactComponent as Mountains } from 'images/mountains.svg';
import { fetchKeenData } from 'Admin/helpers/keen';
import { blue, grayDark } from 'helpers/colors';

const TabsWrapper = styled.div`
  border-bottom: 1px solid black;
`;

const StyledTabButton = styled(TabButton)`
  border: none;
  border-bottom: ${({ isActive }) => (isActive ? 'solid black' : 'none')};
  margin: 0 1rem -2px;
  text-transform: capitalize;
`;

const StyledH3 = styled.h3`
  margin-top: 0;
  font-size: 1.4rem;
`;

const List = styled.ol`
  padding: 0;
`;

const ItemWrapper = styled.li`
  display: flex;
  font-size: 1.4rem;
  margin: 1.1rem -0.3rem;
`;

const StyledToolTipChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemResult = styled.div`
  margin-left: 1rem;
`;

const StyledLink = styled(ExternalLink)`
  font-size: 1.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.3rem;
`;

const NonLinkedItem = styled.span`
  font-size: 1.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.3rem;
`;

const ViewAll = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  margin-left: -0.5rem;
  font-weight: 600;
  color: ${blue};
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  text-align: center;
  font-size: 1.4rem;
  color: ${grayDark};
`;

const StyledMountains = styled(Mountains)`
  margin-bottom: 1rem;
`;

const KeenListData = ({
  timeframe,
  setError,
  tabs,
  tabFilterProperties,
  eventCollection,
  formatLink,
  formatText,
  subdomain,
  groupBy,
  title,
  subtitle
}) => {
  const [data, setData] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formatListData = list => {
    /*
    This oneResultCount with filter and map is in place
    to allow us to get the top 5 results that hold a count of 1
    */
    let oneResultCount = 0;
    return list
      .filter(item => Object.values(item).filter(value => value === null).length === 0)
      .filter(item => {
        if (item.result === 1) {
          oneResultCount++;
        }
        return oneResultCount <= 5;
      })
      .map(item => {
        if (formatLink) {
          item.url = formatLink(item);
        }
        return item;
      });
  };

  useEffect(() => {
    // Cant use 'useKeenAsync' here due to complexity of this useEffect
    let isLoading = true;
    let orderBy = [
      {
        property_name: 'result',
        direction: 'DESC'
      }
    ];
    Promise.all(
      tabs.map(tab => {
        const { name, otherGroupBy } = tab;
        let allGroupBy = groupBy;
        if (otherGroupBy) {
          allGroupBy = allGroupBy.concat(otherGroupBy);
        }
        let allFilters = [];
        if (tabFilterProperties) {
          allFilters = [
            {
              property_type: 'string',
              operator: 'contains',
              property_value: name,
              ...tabFilterProperties
            }
          ];
        }
        return fetchKeenData({
          timeframe,
          filters: allFilters,
          orderBy,
          groupBy: allGroupBy,
          eventCollection,
          subdomain
        }).then(({ result }) => {
          return formatListData(result);
        });
      })
    )
      .then(keenData => {
        if (isLoading) {
          setData(keenData);
        }
      })
      .catch(() => setError(true));
    return () => {
      isLoading = false;
    };
  }, [timeframe]);

  if (data.length === 0) {
    return null;
  }

  return (
    <div>
      {tabs.length > 1 && (
        <TabsWrapper>
          <Tabs tabGroupId={title} onChange={selection => setActiveTabIndex(selection)}>
            {({ getTabProps }) => (
              <>
                {tabs.map(tabItem => (
                  <StyledTabButton
                    aria-label={
                      tabs[activeTabIndex].name === tabItem.name
                        ? `Showing results for ${tabItem.name} ${title}`
                        : `Click to view ${tabItem.name} ${title} options`
                    }
                    key={tabItem.name}
                    isActive={tabs[activeTabIndex].name === tabItem.name}
                    {...getTabProps(tabItem.name, tabs[activeTabIndex].name)}
                  >
                    {tabItem.name}
                  </StyledTabButton>
                ))}
              </>
            )}
          </Tabs>
        </TabsWrapper>
      )}
      {subtitle && <StyledH3>{subtitle}</StyledH3>}
      {data[activeTabIndex].length ? (
        <>
          <List id={`panel-${tabs[activeTabIndex].name}-${title.replaceAll(' ', '-')}`}>
            {data[activeTabIndex].slice(0, 2).map((item, i) => (
              <ItemWrapper key={`${formatText(item)} - ${i}`}>
                <AdminToolTip hideToolTip={formatText(item).length <= 49} text={formatText(item)}>
                  <StyledToolTipChildren>
                    {item.url ? (
                      <StyledLink url={item.url}>{formatText(item)}</StyledLink>
                    ) : (
                      <NonLinkedItem>{formatText(item)}</NonLinkedItem>
                    )}
                    <ItemResult>{item.result}</ItemResult>
                  </StyledToolTipChildren>
                </AdminToolTip>
              </ItemWrapper>
            ))}
          </List>
          <ViewAll
            aria-label={`Click to see all ${title} for ${tabs[activeTabIndex].name}`}
            onClick={() => setIsModalOpen(true)}
          >
            View All
          </ViewAll>
        </>
      ) : (
        <ErrorMessage>
          <StyledMountains />
          There is no data recorded during this time frame.
        </ErrorMessage>
      )}
      <KeenListDataModal
        title={title}
        subtitle={tabs[activeTabIndex].name}
        listData={data[activeTabIndex]}
        formatText={formatText}
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </div>
  );
};

KeenListData.propTypes = {
  timeframe: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired,
  setError: PropTypes.func.isRequired,
  formatLink: PropTypes.func,
  formatText: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      otherGroupBy: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  tabFilterProperties: PropTypes.shape({
    property_name: PropTypes.string.isRequired,
    property_type: PropTypes.string,
    operator: PropTypes.string,
    property_value: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  eventCollection: PropTypes.string.isRequired,
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  subdomain: PropTypes.string.isRequired
};

export default KeenListData;
