const skillsData = (sortedProfiles, skills) => {
  const filterSkills = (profile, type) => {
    if (!profile.attributes.selectedSkills) {
      profile.attributes.selectedSkills = [];
    }
    let data = Object.keys(profile.attributes.selectedSkills)
      .filter(skill => profile.attributes.selectedSkills[skill] === type)
      .map(skillId => skills.filter(skill => skill.id === skillId))
      .flat();
    return [profile.attributes.email, data.length, ...data.map(skill => skill.name)];
  };

  const haveData = sortedProfiles.map(profile => {
    return filterSkills(profile, 'have');
  });

  const wantData = sortedProfiles.map(profile => {
    return filterSkills(profile, 'want');
  });

  const haveColumns = ['Email', '# of Have Skills', 'All Have Skills -->'];
  const wantColumns = ['Email', '# of Want Skills', 'All Want Skills -->'];

  return { haveData, haveColumns, wantData, wantColumns };
};

export default skillsData;
