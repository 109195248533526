import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Link from 'components/atoms/Link';

import greenWave from 'images/greenWave.png';
import orangeWave from 'images/orangeWave.png';
import grayWave from 'images/grayWave.png';
import blueWave from 'images/blueWave.png';
import truncate from 'helpers/truncate';
import { blue, blueText, grayText } from 'helpers/colors.js';

const StyledImage = styled.img`
  position: absolute;
  width: calc(100% + 1rem);
  height: 39%;
  left: -0.5rem;
  bottom: -0.5rem;
`;

const StyledDiv = styled.div`
  display: flex;
  bottom: 0;
  position: absolute;
  margin: 0 0 0.6rem 0.8rem;
`;

const Pill = styled.div`
  background: white;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 600;
  width: 2.4rem;
  height: 2.4rem;
  color: ${blueText};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WaveText = styled.div`
  color: ${props => props.color || blueText};
  font-size: 1.25rem;
  align-self: center;
  padding-left: 5px;
`;

const StyledLink = styled(Link)`
  padding: 1.5rem;
  min-height: 22rem;
  height: 100%;
  :hover {
    text-decoration: none;
    h3,
    span {
      color: ${props => props.url && blue};
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Title = styled.h3`
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 22px;
  color: ${blueText};
  margin: 0 0 1rem 0;
  :hover {
    text-decoration: ${props => (props.hasUrl ? 'underline' : 'none')};
  }
`;

const Attribute = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${grayText};
`;

const ProgramCourseCard = ({ edItem, edType, institutionNames, shouldShowInstitution }) => {
  let waveDetails;
  let skillCountText = '';

  // Remove this conditional if we get rid of the plc page
  if (window.location.pathname === '/results/plc') {
    skillCountText = `Found ${edItem.haveSkills} Skill${
      edItem.haveSkills > 1 ? 's' : ''
    } you have!`;

    waveDetails = (
      <>
        <StyledImage alt="" src={greenWave} />
        <StyledDiv>
          <Pill>{edItem.haveSkills} </Pill>
          <WaveText>Skill{edItem.haveSkills > 1 && 's'} You Have!</WaveText>
        </StyledDiv>
      </>
    );
  } else if (edItem.wantSkills) {
    skillCountText = `Found ${edItem.wantSkills} Skill${
      edItem.wantSkills > 1 ? 's' : ''
    } you want!`;

    waveDetails = (
      <>
        <StyledImage alt="" src={orangeWave} />
        <StyledDiv>
          <Pill>{edItem.wantSkills} </Pill>
          <WaveText>Skill{edItem.wantSkills > 1 && 's'} You Want!</WaveText>
        </StyledDiv>
      </>
    );
  } else if (edItem.careerAreaSkills) {
    skillCountText = `Found ${edItem.careerAreaSkills} Career Area Skill${
      edItem.careerAreaSkills > 1 ? 's' : ''
    }!`;

    waveDetails = (
      <>
        <StyledImage alt="" src={blueWave} />
        <StyledDiv>
          <Pill>{edItem.careerAreaSkills} </Pill>
          <WaveText color="white">Career Area Skill{edItem.careerAreaSkills > 1 && 's'}!</WaveText>
        </StyledDiv>
      </>
    );
  } else {
    waveDetails = <StyledImage alt="" src={grayWave} />;
  }

  let edItemSite = edItem.site ? edItem.site : edItem.subdomain;

  return (
    <StyledLink
      to={`/results/${edItemSite}/${edType === 'Courses' ? `course` : `group`}/${edItem.id}`}
      aria-label={`Click to get more information about ${edItem.title}. ${
        shouldShowInstitution && institutionNames[edItemSite]
          ? `Provided by ${institutionNames[edItemSite]}.`
          : ''
      } ${skillCountText}`}
    >
      <Wrapper data-cy="education-match">
        <Title hasUrl={edItem.url}>{truncate(edItem.title, 80)}</Title>
        {edItem.groupType && <Attribute>{edItem.groupType.label}</Attribute>}
        {shouldShowInstitution && <Attribute>{institutionNames[edItemSite]}</Attribute>}
        {waveDetails}
      </Wrapper>
    </StyledLink>
  );
};

ProgramCourseCard.propTypes = {
  edItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    site: PropTypes.string,
    subdomain: PropTypes.string,
    groupType: PropTypes.shape({
      groupTypeClass: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }),
    description: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
    haveSkills: PropTypes.number,
    wantSkills: PropTypes.number,
    careerAreaSkills: PropTypes.number,
    url: PropTypes.string
  }).isRequired,
  edType: PropTypes.string.isRequired,
  institutionNames: PropTypes.objectOf(PropTypes.string),
  shouldShowInstitution: PropTypes.bool.isRequired
};

export default ProgramCourseCard;
