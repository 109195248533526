import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgramCourseCard from 'containers/ProgramCourseCard';
import HorizontalCardScroll from 'components/molecules/HorizontalCardScroll';
import CallToAction from 'components/molecules/CallToAction';
import loading from 'images/loading.gif';

import { countEducationSkills } from 'helpers/countEducationSkills';
import { getCoursesBySkills, getGroupsBySkills } from 'services/courses';
import { grayDark } from 'helpers/colors';
import { ReactComponent as EmptyIcon } from 'images/empty-list.svg';

const MinHeightHorizontalCardScroll = styled(HorizontalCardScroll)`
  > div {
    min-height: 27rem;
  }
`;

const StatusMessage = styled.div`
  color: ${grayDark};
  height: 22.2rem;
  font-size: 1.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  > svg {
    margin-bottom: 1.5rem;
  }
`;

const Loading = styled.img`
  width: 2.4rem;
`;

const Education = ({
  clusterId,
  clusterSkills,
  helpCardEdu,
  edType,
  selectedSkills,
  sites,
  selectedSites,
  selectedLevels,
  selectedPrograms
}) => {
  const [education, setEducation] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    // prevent race condition when user changes filters quickly
    let isRequestStale = false;
    (async () => {
      if (clusterSkills && !error) {
        try {
          let skillScoreMappings = {};
          clusterSkills.forEach(clusterSkill => {
            const selectedType = selectedSkills[clusterSkill.id];
            // Attach a score 0 to all HAVE skills
            // This will make sure any courses/groups that contain HAVE skills will be returned, but not prioritized in sorting (score is only used for sorting)
            if (selectedType === 'have') {
              skillScoreMappings[clusterSkill.id] = 0;
            } else {
              // Keep the score from clusterSkills for WANT and GAP skills
              skillScoreMappings[clusterSkill.id] = clusterSkill.score;
            }
          });

          const uncountedEducation =
            edType === 'Courses'
              ? await getCoursesBySkills(
                  skillScoreMappings,
                  [],
                  selectedSites,
                  selectedPrograms,
                  100
                )
              : await getGroupsBySkills(skillScoreMappings, [], selectedSites, selectedLevels, 100);

          const countedEducation = countEducationSkills(
            uncountedEducation.data,
            selectedSkills,
            clusterSkills,
            edType
          );

          if (!isRequestStale) {
            setEducation(countedEducation);
            setError(false);
          }
        } catch (e) {
          setError(true);
        }
      }
    })();
    return () => {
      isRequestStale = true;
    };
  }, [clusterSkills, selectedSkills, selectedSites, selectedLevels, selectedPrograms]);

  if (error) {
    return (
      <MinHeightHorizontalCardScroll isError type={`ed--${edType}-error`}>
        <StatusMessage aria-live="polite">
          <EmptyIcon />
          There was an error loading education opportunities.
        </StatusMessage>
      </MinHeightHorizontalCardScroll>
    );
  }
  if (!education) {
    return (
      <MinHeightHorizontalCardScroll type={`ed--${edType}-loading`}>
        <StatusMessage>
          <Loading
            src={loading}
            alt="Loading Educational Opportunities"
            data-cy="cluster-education-spinner"
          />
        </StatusMessage>
      </MinHeightHorizontalCardScroll>
    );
  }
  if (education.length === 0) {
    return (
      <MinHeightHorizontalCardScroll type={`ed--${edType}-empty`} isError>
        <StatusMessage aria-live="polite">
          <EmptyIcon />
          We couldn&apos;t find any education with the skills you need for this match.
        </StatusMessage>
      </MinHeightHorizontalCardScroll>
    );
  }
  return (
    <MinHeightHorizontalCardScroll
      callToAction={
        helpCardEdu.length > 0 ? (
          <CallToAction
            links={helpCardEdu}
            cardText="Questions about education?"
            type="education"
            ctaName="Educational Opportunities"
            height="27.2rem"
          />
        ) : null
      }
      type={`ed--${edType}`}
    >
      {education.map(edItem => (
        <ProgramCourseCard
          key={edItem.id}
          edItem={edItem}
          edType={edType}
          clusterId={clusterId}
          shouldShowInstitution={sites.length > 1}
        />
      ))}
    </MinHeightHorizontalCardScroll>
  );
};

Education.propTypes = {
  clusterId: PropTypes.string.isRequired,
  clusterSkills: PropTypes.array,
  helpCardEdu: PropTypes.array,
  edType: PropTypes.oneOf(['Courses', 'Programs']).isRequired,
  sites: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  selectedSkills: PropTypes.object.isRequired,
  selectedSites: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedLevels: PropTypes.arrayOf(PropTypes.string), // groupTypeClass IDs, only needed for groups
  selectedPrograms: PropTypes.arrayOf(PropTypes.string) // programIds, only needed for courses.
};

export default Education;
