import { connect } from 'react-redux';

export const mapStateToProps = state => {
  return {
    selectedSkills: state.profile.selectedSkills
  };
};

const withSelectedSkills = connect(mapStateToProps);

export default withSelectedSkills;
