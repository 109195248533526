import fetchWithRetry from './helpers/fetchWithRetry';

export const getJobPostingsBySkills = async (
  skills,
  cities,
  companyIds,
  filterSkillNames,
  limit,
  page
) => {
  const filter = {
    when: 'active',
    title_name: {
      exclude: ['Unknown', 'unknown']
    },
    company_name: {
      exclude: ['[Unknown]', 'Unknown', 'unknown']
    },
    city_name: cities
  };
  if (skills.length) {
    filter.skills = skills;
  }
  if (companyIds.length) {
    filter.company = { include: companyIds };
  }

  if (filterSkillNames && filterSkillNames.length) {
    filter.skills_name = filterSkillNames;
  }

  if (window.location.pathname === '/results/internships') {
    filter.is_internship = true;
  }

  let response = await fetchWithRetry(
    `/api/emsi-services/${window.nation === 'UK' ? 'uk-' : ''}jpa/postings`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        filter,
        fields: [
          'id',
          'url',
          'title_raw',
          'company_name',
          'city_name',
          'body',
          'skills',
          'skills_name'
        ],
        limit,
        page
      })
    }
  );

  if (!response.ok) {
    throw new Error('Error retrieving job postings from JPA.');
  }
  return response.json();
};

export const searchCityNames = async searchTerm => {
  let response = await fetchWithRetry(
    `/api/emsi-services/${
      window.nation === 'UK' ? 'uk-' : ''
    }jpa/taxonomies/city?q=${searchTerm}&limit=10`
  );

  if (!response.ok) {
    throw new Error('Error retrieving city names.');
  }
  const unfilteredData = await response.json();
  const invalidTerms = ['[Unknown city]'];
  return unfilteredData.data.filter(city => !invalidTerms.includes(city.name));
};

export const searchCompanies = async searchTerm => {
  let response = await fetchWithRetry(
    `/api/emsi-services/${
      window.nation === 'UK' ? 'uk-' : ''
    }jpa/taxonomies/company?q=${searchTerm}`
  );

  if (!response.ok) {
    throw new Error('Error retrieving company names.');
  }
  return await response.json();
};

export const searchCompaniesbyId = async companiesArr => {
  let response = await fetchWithRetry(
    `/api/emsi-services/${window.nation === 'UK' ? 'uk-' : ''}jpa/taxonomies/company/lookup`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        ids: companiesArr
      })
    }
  );
  if (!response.ok) {
    throw new Error('Error retrieving company names.');
  }
  return response.json();
};
