import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from 'components/atoms/Card';
import Modal from 'components/atoms/Modal';
import ViewAuthContents from 'components/molecules/ViewAuthContents';
import ViewSaveContents from 'components/molecules/ViewSaveContents';
import ViewOptionsContents from 'components/molecules/ViewOptionsContents';

const StyledCard = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  padding: 3rem 1.5rem;
  text-align: center;
  @media (min-width: 800px) {
    padding: 3rem;
    width: 62rem;
  }
`;

const ViewModalFactory = ({
  close,
  isOpen,
  type,
  addProfileView,
  deleteProfileView,
  selectedFilterObj,
  updateProfileView,
  section
}) => {
  let modal;
  switch (type) {
    case 'Auth':
      modal = <ViewAuthContents close={close} />;
      break;
    case 'Save':
      modal = <ViewSaveContents section={section} close={close} addProfileView={addProfileView} />;
      break;
    case 'Overwrite':
      modal = (
        <ViewOptionsContents
          close={close}
          confirmationFunc={updateProfileView}
          optionType={type}
          selectedFilterObj={selectedFilterObj}
        />
      );
      break;
    case 'Delete':
      modal = (
        <ViewOptionsContents
          close={close}
          confirmationFunc={deleteProfileView}
          optionType={type}
          selectedFilterObj={selectedFilterObj}
        />
      );
      break;
    default:
      return null;
  }
  return (
    <Modal id="modal" className="ignore-react-onclickoutside" isOpen={isOpen} close={close}>
      <StyledCard>{modal}</StyledCard>
    </Modal>
  );
};

ViewModalFactory.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['Auth', 'Save', 'Delete', 'Overwrite']),
  addProfileView: PropTypes.func.isRequired,
  deleteProfileView: PropTypes.func.isRequired,
  updateProfileView: PropTypes.func.isRequired,
  selectedFilterObj: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['careerAreas', 'education', 'jobs']).isRequired
  }),
  section: PropTypes.oneOf(['careerAreas', 'education', 'jobs']).isRequired
};

export default ViewModalFactory;
