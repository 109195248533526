import React from 'react';
import { toastr } from 'react-redux-toastr';
import { ReactComponent as CheckMarkCircle } from 'images/checkmark-in-white-circle.svg';

export const flashError = message => toastr.error('Error', message);

export const flashSuccess = (message, options) =>
  toastr.success('Success', message, { icon: <CheckMarkCircle />, ...options });

export const flashWarning = message => toastr.warning('Warning', message);

export const flashInfo = message => toastr.info(message);
