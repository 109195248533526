import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SvgIcon from 'components/atoms/SvgIcon';

import { ReactComponent as DownCaret } from 'images/down-caret.svg';

const Caret = styled(({ fillColor, position, ...props }) => <SvgIcon {...props} />)`
  position: relative;
  top: 0.15rem;
  width: 1rem;
  height: 1rem;
  margin-left: 0.2rem;
  transform: ${({ position }) => {
    switch (position) {
      case 'down':
        return 'rotate(0deg) translate(-0.1rem)';
      case 'up':
        return 'rotate(180deg) translate(0.1rem, 0.1rem)';
      case 'right':
        return 'rotate(270deg)';
      case 'left':
      default:
        return 'rotate(90deg) translate(0, 0.1rem)';
    }
  }};
  fill: ${props => props.fillColor};
`;

const DirectionalCaret = ({ className, fillColor, position, ...props }) => (
  <Caret
    className={className}
    position={position}
    Svg={DownCaret}
    fillColor={fillColor}
    {...props}
  />
);

DirectionalCaret.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string.isRequired,
  position: PropTypes.string
};
export default DirectionalCaret;
