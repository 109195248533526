import React from 'react';
import styled from 'styled-components';

import SelectedSkillsPanel from 'containers/SelectedSkillsPanel';

import downCaret from 'images/down-caret-dark-blue.svg';

const DrawerHandle = styled.button`
  padding: 1.5rem 1.3rem;
  font-size: 1.4rem;
  border: none;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.isOpen && `img { transform: rotate(180deg); }`}
`;

class SelectedSkillsDrawer extends React.Component {
  state = {
    showSelectedSkills: false
  };
  render() {
    const { showSelectedSkills } = this.state;
    return (
      <>
        <DrawerHandle
          isOpen={showSelectedSkills}
          onClick={() => this.setState({ showSelectedSkills: !showSelectedSkills })}
        >
          {showSelectedSkills ? 'Hide' : 'View'} selected skills
          <img src={downCaret} alt="" />
        </DrawerHandle>
        {showSelectedSkills && <SelectedSkillsPanel />}
      </>
    );
  }
}

export default SelectedSkillsDrawer;
