import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';
import { Switch, Redirect, Route } from 'react-router-dom';

import Header from 'components/atoms/Header';
import LoadingButton from 'components/molecules/LoadingButton';
import MoreSelectingModal from 'components/molecules/MoreSelectingModal';
import RelatedSkillsModal from 'components/molecules/RelatedSkillsModal';
import SelectedSkillsSidebar from 'components/organisms/SelectedSkillsSidebar';
import SkillsByCategory from 'components/pages/SkillsByCategory';
import SkillsByJob from 'components/pages/SkillsByJob';
import SkillsByName from 'components/pages/SkillsByName';
import ErrorPage from 'components/pages/ErrorPage';
import SkillsOverview from 'containers/SkillsOverview';
import SkillsByEducation from 'containers/SkillsByEducation';
import SkillsByResume from 'containers/SkillsByResume';

import arrowRight from 'images/arrow-right-white.png';

import withFetchValidSkills from 'hocs/withFetchValidSkills';
import { getClustersBySkills } from 'services/skillsClusters';
import { formatRelatedSkills } from 'helpers/sortItemsInArrays';

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  margin: 5rem 3rem 0 3rem;
  @media (max-width: 1023px) {
    margin-top: 3rem;
  }
`;

const SkillsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;

  @media screen and (max-width: 1023px) {
    display: block;
    padding: 0;
    padding-top: 2rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 800px) {
    margin-right: 3rem;
    align-items: flex-end;
  }
`;

const StyledButton = styled(LoadingButton)`
  width: calc(100% - 4rem);
  margin-left: 2rem;

  @media screen and (max-width: 799px) {
    line-height: 2rem;
  }

  @media screen and (min-width: 800px) {
    margin-left: 0;
    width: 100%;
    max-width: 32rem;
  }
`;

const ArrowIcon = styled.img`
  margin-left: 1rem;
`;

const Skills = ({
  modalHide,
  modalShow,
  selectedSkills,
  visitedSkillsPages,
  history,
  errorRetrievingSkills,
  fetchValidSkills
}) => {
  const [relatedSkills, setRelatedSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchValidSkills();
  }, []);

  useEffect(() => {
    let currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    currentPath = currentPath.charAt(0).toUpperCase() + currentPath.slice(1);
    document.title = `Lightcast SkillsMatch - Skills${
      currentPath === 'Skills' ? '' : ` By ${currentPath}`
    }`;
  }, [location.pathname]);

  if (errorRetrievingSkills) {
    return <ErrorPage />;
  }

  const getRelatedSkills = async selectedSkillIds => {
    setIsLoading(true);
    const clusters = await getClustersBySkills(selectedSkillIds, 9);
    setIsLoading(false);
    return formatRelatedSkills(clusters, selectedSkillIds);
  };

  const selectedSkillIds = Object.keys(selectedSkills);

  const openModal = () => {
    getRelatedSkills(selectedSkillIds).then(result => {
      setRelatedSkills(result);
      if (visitedSkillsPages.length > 1 && result.length > 0) {
        modalShow('related-skills');
      } else if (visitedSkillsPages.length < 2) {
        modalShow('more-selecting');
      } else {
        history.push('/results');
      }
    });
  };

  const SideBarButton = (
    <Switch>
      <Route
        path="/skills"
        exact
        render={() => (
          <ButtonWrapper>
            <StyledButton onClick={openModal} isLoading={isLoading}>
              Done Adding Skills
              <ArrowIcon src={arrowRight} alt="" />
            </StyledButton>
          </ButtonWrapper>
        )}
      />
      <Route
        path="/skills/:selectionType"
        render={() => (
          <ButtonWrapper>
            <StyledButton
              dataCy="return-to-skills-button"
              onClick={() => {
                history.push('/skills');
              }}
            >
              Save and Return to Skills Overview
            </StyledButton>
          </ButtonWrapper>
        )}
      />
    </Switch>
  );

  return (
    <PageWrapper>
      <StyledHeader>My Skills</StyledHeader>
      <SkillsWrapper>
        <Media query="(max-width: 1023px)">
          {match => (
            <>
              {match && <SelectedSkillsSidebar key="sidebar" />}
              <Switch>
                <Route path="/skills" exact component={SkillsOverview} />
                <Route path="/skills/job" exact component={SkillsByJob} />
                <Route path="/skills/category" exact component={SkillsByCategory} />
                <Route path="/skills/name" exact component={SkillsByName} />
                {window.nation === 'US' && (
                  <Route path="/skills/education" exact component={SkillsByEducation} />
                )}
                <Route path="/skills/resume" exact component={SkillsByResume} />
                <Redirect to="/skills" />
              </Switch>
              {!match && <SelectedSkillsSidebar key="sidebar" bottomButton={SideBarButton} />}
            </>
          )}
        </Media>
      </SkillsWrapper>
      <MoreSelectingModal
        modalHide={modalHide}
        modalShow={modalShow}
        name="more-selecting"
        shouldShowRelatedSkills={!!relatedSkills.length}
      />
      <RelatedSkillsModal
        modalHide={modalHide}
        name="related-skills"
        relatedSkills={relatedSkills}
        isLoading={isLoading}
      />
      <Media query="(max-width: 1023px)">{SideBarButton}</Media>
    </PageWrapper>
  );
};

Skills.propTypes = {
  errorRetrievingSkills: PropTypes.string,
  fetchValidSkills: PropTypes.func.isRequired,
  // history comes from react-router-dom
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  modalHide: PropTypes.func.isRequired,
  modalShow: PropTypes.func.isRequired,
  selectedSkills: PropTypes.object.isRequired,
  visitedSkillsPages: PropTypes.array.isRequired
};

export default withFetchValidSkills(Skills);
