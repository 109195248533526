import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Background from 'images/background.png';

const ContentWrap = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.59)),
    url(${props => props.backgroundImage || Background});
  background-size: cover;
  width: 100%;

  @media (max-width: 799px) {
    background-position: 50% bottom;
    ${props => props.hideImageOnMobile && 'background-image: none'};
  }
`;

const BackgroundImage = ({ backgroundImage, children, hideImageOnMobile }) => (
  <ContentWrap hideImageOnMobile={hideImageOnMobile} backgroundImage={backgroundImage}>
    {children}
  </ContentWrap>
);

BackgroundImage.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  hideImageOnMobile: PropTypes.bool
};

export default BackgroundImage;
