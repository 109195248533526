import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Amplify from '@aws-amplify/core';
import cognitoAuth from '@aws-amplify/auth';
import KeenCard from 'Admin/components/KeenCard';
import DateRangePicker from 'Admin/components/DateRangePicker';
import { flashError } from 'helpers/flashMessage';

dayjs.extend(utc);

import config from 'config';

Amplify.configure({
  Auth: config.awsCognitoAuth
});

const Margin = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 2rem;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -1rem;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledH1 = styled.h1`
  font-size: 2.8rem;
  font-weight: normal;
  margin-right: 2.8rem;
  @media (max-width: 639px) {
    margin-right: 1rem;
  }
`;

const dateOptions = [
  {
    name: '7 Days',
    timeFrame: {
      start: dayjs
        .utc()
        .subtract(7, 'day')
        .startOf('day')
        .format(),
      end: dayjs
        .utc()
        .subtract(1, 'day')
        .endOf('day')
        .format()
    },
    interval: 'daily'
  },
  {
    name: '2 Weeks',
    timeFrame: {
      start: dayjs
        .utc()
        .subtract(2, 'week')
        .startOf('day')
        .format(),
      end: dayjs
        .utc()
        .subtract(1, 'day')
        .endOf('day')
        .format()
    },
    interval: 'daily'
  },
  {
    name: '1 Month',
    timeFrame: {
      start: dayjs
        .utc()
        .subtract(1, 'month')
        .startOf('day')
        .format(),
      end: dayjs
        .utc()
        .subtract(1, 'day')
        .endOf('day')
        .format()
    },
    interval: 'daily'
  },
  {
    name: '3 Months',
    timeFrame: {
      start: dayjs
        .utc()
        .subtract(3, 'month')
        .startOf('day')
        .format(),
      end: dayjs
        .utc()
        .subtract(1, 'day')
        .endOf('day')
        .format()
    },
    interval: 'weekly'
  },
  {
    name: '6 Months',
    timeFrame: {
      start: dayjs
        .utc()
        .subtract(6, 'month')
        .startOf('day')
        .format(),
      end: dayjs
        .utc()
        .subtract(1, 'day')
        .endOf('day')
        .format()
    },
    interval: 'weekly'
  },
  {
    name: '12 Months',
    timeFrame: {
      start: dayjs
        .utc()
        .subtract(12, 'month')
        .startOf('day')
        .format(),
      end: dayjs
        .utc()
        .subtract(1, 'day')
        .endOf('day')
        .format()
    },
    interval: 'weekly'
  }
];

for (let i = 1; i <= 12; i++) {
  dateOptions.push({
    name: dayjs()
      .utc()
      .subtract(i, 'month')
      .format('MMMM YYYY'),
    timeFrame: {
      start: dayjs
        .utc()
        .subtract(i, 'month')
        .startOf('month')
        .format(),
      end: dayjs
        .utc()
        .subtract(i, 'month')
        .endOf('month')
        .format()
    },
    interval: 'daily'
  });
}

const AdminAnalytics = ({ subdomain, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTimeframe, setActiveTimeFrame] = useState(dateOptions[0]);

  useEffect(() => {
    (async () => {
      const authenticatedUser = await cognitoAuth.currentUserInfo();
      if (!authenticatedUser) {
        history.push('/login?redirect=Admin/analytics');
      } else if (
        !['Admin', 'View Everything', 'View Analytics'].includes(
          authenticatedUser.attributes['custom:userRole']
        )
      ) {
        history.push('/');
        flashError('Sorry, you must be a site admin to access this page');
      }
      setIsLoading(false);
    })();
  }, [history]);

  return (
    <Margin>
      {!isLoading && (
        <>
          <TopSection>
            <StyledH1>Analytics</StyledH1>
            <DateRangePicker
              options={dateOptions}
              setActiveTimeFrame={setActiveTimeFrame}
              activeTimeframe={activeTimeframe}
            />
          </TopSection>
          <Flex>
            <KeenCard
              title="Unique Visitors"
              eventCollection="Page Views"
              analysisType="count_unique"
              targetProperty="visitorId"
              subdomain={subdomain}
              timeframe={activeTimeframe.timeFrame}
              interval={activeTimeframe.interval}
            />
            <KeenCard
              title="Skills Selected"
              eventCollection="Skill Added"
              tabs={[{ name: 'want' }, { name: 'have' }]}
              tabFilterProperties={{ property_name: 'selectionType' }}
              groupBy={['skillName', 'skillId']}
              formatLink={item =>
                item.skillId && `https://skills.emsidata.com/skills/${item.skillId}`
              }
              formatText={item => item.skillName}
              subdomain={subdomain}
              timeframe={activeTimeframe.timeFrame}
              interval={activeTimeframe.interval}
            />
            <KeenCard
              title="Education Views"
              eventCollection="Program Course Page Visited"
              tabs={[
                {
                  name: 'Courses',
                  otherGroupBy: ['courseId']
                },
                { name: 'Programs', otherGroupBy: ['groupType.label'] }
              ]}
              tabFilterProperties={{ property_name: 'type' }}
              groupBy={['title', 'page.url', 'type']}
              formatLink={item => item['page.url']}
              formatText={item =>
                item.type === 'Courses'
                  ? `${item.title} ${item.courseId && ` - ${item.courseId}`}`
                  : `${item.title} - ${item['groupType.label']}`
              }
              subdomain={subdomain}
              timeframe={activeTimeframe.timeFrame}
              interval={activeTimeframe.interval}
            />
            <KeenCard
              title="Job Clicks"
              subtitle="Job Postings"
              tabs={[{ name: '' }]}
              eventCollection="Job Posting Link Clicked"
              groupBy={['title']}
              formatText={item => item.title}
              subdomain={subdomain}
              timeframe={activeTimeframe.timeFrame}
              interval={activeTimeframe.interval}
            />
          </Flex>
        </>
      )}
    </Margin>
  );
};

AdminAnalytics.propTypes = {
  subdomain: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default AdminAnalytics;
