import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { gradient, getBestContrast } from 'helpers/colors.js';
import ExternalLink from 'components/atoms/ExternalLink';
import LightcastLogoWhiteName from 'images/lightcast-logo-white-name.png';
import LightcastLogoColorName from 'images/lightcast-logo-color-name.png';

const Footer = styled.footer`
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.menuBackgroundColor || gradient};
  padding: 0 2rem;
  color: ${props => props.menuTextColor || 'white'};
  font-size: 1.1rem;
  margin-bottom: ${props => (props.mobileMarginBottom ? '6rem' : '0')};

  @media (min-width: 800px) {
    margin-bottom: 0;
    height: 5rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LeftItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  @media (min-width: 800px) {
    margin: 0;
    width: 47rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const RightItems = styled.div`
  width: 100%;
  text-align: center;
  @media (min-width: 800px) {
    width: auto;
    flex-grow: 1;
    text-align: right;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const InternalLink = styled(({ menuBackgroundColor, menuTextColor, ...props }) => (
  <Link {...props} />
))`
  color: ${({ menuTextColor, menuBackgroundColor }) =>
    getBestContrast(menuBackgroundColor, [menuTextColor, '#fff', '#000'])};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const StyledExternalLink = styled(({ menuBackgroundColor, menuTextColor, ...props }) => (
  <ExternalLink {...props} />
))`
  color: ${({ menuTextColor, menuBackgroundColor }) =>
    getBestContrast(menuBackgroundColor, [menuTextColor, '#fff', '#000'])};
`;

const PipeWrapper = styled.div`
  margin: 0 0.5rem;
`;

const LightCastLogo = styled.img`
  margin: 0 0.5rem;
  height: 1.5rem;
  vertical-align: bottom;
`;

const CopyrightText = styled.span`
  text-align: center;
  @media (max-width: 409px) {
    width: 100%;
  }
`;

const CopyrightSymbol = styled.span`
  font-family: sans-serif;
`;

const GlobalFooter = ({ menuBackgroundColor, menuTextColor }) => {
  const currentYear = new Date().getFullYear();
  const LightcastLogo =
    getBestContrast(menuBackgroundColor, ['#fff', '#000']) === '#fff'
      ? LightcastLogoWhiteName
      : LightcastLogoColorName;

  // The Results page shows floating nav tabs at the bottom
  // therefore the footer needs extra margin-bottom to make sure it is not covered by mobile nav tabs
  // `:route` will match to any string without another slash, so it won't match to individual cluster page's url, e.g. `/results/cluster/ca2907-6-0`
  const isResultsPage = useRouteMatch({ path: '/results/:route', exact: true });
  return (
    <Footer
      mobileMarginBottom={isResultsPage}
      menuTextColor={menuTextColor}
      menuBackgroundColor={menuBackgroundColor}
    >
      <LeftItems>
        <Flex>
          <StyledExternalLink
            url="https://www.lightcast.io/privacy-policy/"
            menuTextColor={menuTextColor}
            menuBackgroundColor={menuBackgroundColor}
          >
            Privacy Policy
          </StyledExternalLink>
          <PipeWrapper>|</PipeWrapper>
          <InternalLink
            to="/disclaimer"
            menuTextColor={menuTextColor}
            menuBackgroundColor={menuBackgroundColor}
          >
            Disclaimer
          </InternalLink>
          <PipeWrapper>|</PipeWrapper>
          <StyledExternalLink
            url="https://status.emsidata.com/"
            menuTextColor={menuTextColor}
            menuBackgroundColor={menuBackgroundColor}
          >
            Status
          </StyledExternalLink>
          <PipeWrapper>|</PipeWrapper>
          <CopyrightText>
            <CopyrightSymbol>&copy;&nbsp;</CopyrightSymbol>Copyright {currentYear}. All Rights
            Reserved.
          </CopyrightText>
        </Flex>
      </LeftItems>
      <RightItems>
        SkillsMatch is powered by
        <ExternalLink url="https://www.lightcast.io/">
          <LightCastLogo src={LightcastLogo} alt="Lightcast" />
        </ExternalLink>
        data.
      </RightItems>
    </Footer>
  );
};

GlobalFooter.propTypes = {
  menuBackgroundColor: PropTypes.string,
  menuTextColor: PropTypes.string
};
export default GlobalFooter;
