import React from 'react';
import PropTypes from 'prop-types';
import Amplify from '@aws-amplify/core';
import cognitoAuth from '@aws-amplify/auth';
import styled from 'styled-components';
import GlobalFooter from 'containers/GlobalFooter';
import GlobalHeader from 'containers/GlobalHeader';
import getSubdomain from 'helpers/getSubdomain';

import emptyIcon from 'images/empty-skill-list.svg';
import spinner from 'images/loading.gif';
import isTrackingEnabled from 'helpers/isTrackingEnabled';

import config from 'config';

Amplify.configure({
  Auth: config.awsCognitoAuth
});

const StyledH1 = styled.h1`
  margin-bottom: 4rem;
  text-align: center;
`;

const Loading = styled.div`
  position: absolute;
  top: calc(50% - 1.28rem);
  left: calc(50% - 3rem);
  img {
    width: 6rem;
  }
`;

const InvalidSubdomain = styled.div`
  width: 100%;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex: 1;
  img {
    width: 16rem;
    margin: 2rem;
  }
`;

const CenterText = styled.p`
  text-align: center;
`;
class LoadSite extends React.Component {
  async componentDidMount() {
    const { logout, fetchProfile } = this.props;
    const subdomain = getSubdomain();

    this.props.fetchSite(subdomain);
    const authenticatedUser = await cognitoAuth.currentUserInfo();

    if (authenticatedUser) {
      fetchProfile({ profileId: authenticatedUser.attributes.sub, subdomain });
    } else {
      logout();
    }
  }

  shouldComponentUpdate(newProps) {
    return newProps.siteIsLoaded;
  }

  async componentDidUpdate(oldProps) {
    if (navigator.cookieEnabled && isTrackingEnabled()
      && this.props.siteIsLoaded && !oldProps.siteIsLoaded) {
        const timeStamp =  new Date().toLocaleTimeString();
        let visitor = {
          id: localStorage.visitorId,
          visitId: localStorage.visitId,
          visitTime: timeStamp
        };
        if (this.props.salesforceId) {
          visitor.sf_emsi_account_no = this.props.salesforceId;
        }
        pendo.initialize({
          visitor,
          account: {
            id: getSubdomain()
          }
        });
    }
  }

  render() {
    const { children, siteIsLoaded, siteNotFound, siteIsDisabled } = this.props;
    if (siteNotFound || siteIsDisabled) {
      return (
        <>
          <GlobalHeader />
          <InvalidSubdomain>
            <StyledH1>We couldn&apos;t find that page.</StyledH1>
            <img src={emptyIcon} alt="" />
            <CenterText>
              The URL <b>&quot;{typeof window !== 'undefined' && window.location.hostname}&quot;</b>{' '}
              does not match a valid SkillsMatch site. Are you sure you typed it correctly?
            </CenterText>
          </InvalidSubdomain>
          <GlobalFooter />
        </>
      );
    }
    if (!siteIsLoaded) {
      return (
        <Loading>
          <img src={spinner} alt="Loading Site" data-cy="spinner" />
        </Loading>
      );
    }

    return <>{children}</>;
  }
}

LoadSite.propTypes = {
  children: PropTypes.node.isRequired,
  salesforceId: PropTypes.string,
  siteIsLoaded: PropTypes.bool.isRequired,
  siteNotFound: PropTypes.bool.isRequired,
  siteIsDisabled: PropTypes.bool,
  fetchSite: PropTypes.func.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default LoadSite;
