import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';

import { getJobTitles } from 'services/analystProfiles';
import Autocomplete from 'components/molecules/Autocomplete';
import JobSkillsChooser from 'components/organisms/JobSkillsChooser';
import withValidSkills from 'containers/withValidSkills';
import IconButton from 'components/atoms/IconButton';
import CloseButton from 'components/atoms/CloseButton';

const StyledCloseButton = styled(CloseButton)`
  top: 0.8rem;
`;

class JobTitleWrapper extends React.Component {
  state = {
    selectedJob: null,
    jobSkillsRequestError: false,
    jobTitlesRequestError: false
  };

  getJobTitles = async query => {
    let response = [];

    try {
      response = await getJobTitles(query);
      this.state.jobTitlesRequestError && this.setState({ jobTitlesRequestError: false });
    } catch (e) {
      this.setState({ jobTitlesRequestError: true });
    }

    return response;
  };

  render() {
    const { selectedJob, jobSkillsRequestError, jobTitlesRequestError } = this.state;

    const error =
      jobSkillsRequestError || this.props.validSkillsError || jobTitlesRequestError
        ? 'There was an error loading relevant skills.'
        : null;

    const handleClick = () => {
      this.setState({
        selectedJob: null
      });
      const inputElement = document.getElementById('skill-page-input');
      inputElement && inputElement.focus();
    };

    let clearButton = null;
    if (selectedJob !== null && !error) {
      clearButton = (
        <Media query={{ maxWidth: 799 }}>
          {matches =>
            matches ? (
              <StyledCloseButton
                onClick={handleClick}
                ariaLabel="Try searching another job title"
              />
            ) : (
              <IconButton
                onClick={handleClick}
                buttonText="+ Try Another"
                ariaLabel="Try searching another job title"
              />
            )
          }
        </Media>
      );
    }

    return (
      <>
        <Autocomplete
          getItems={this.getJobTitles}
          placeholder="e.g. Graphic Designer"
          ariaLabel="Type to view and select a job title."
          selectItem={item => this.setState({ selectedJob: item })}
          selectedItem={selectedJob}
          errorMessage={error}
          id="skill-page-input"
          button={clearButton}
        />
        <JobSkillsChooser
          jobId={selectedJob && selectedJob.id}
          onJobSkillsErrorUpdate={errorMessage =>
            this.setState({ jobSkillsRequestError: !!errorMessage })
          }
        />
      </>
    );
  }
}

JobTitleWrapper.propTypes = {
  validSkillsError: PropTypes.string
};

export default withValidSkills(JobTitleWrapper);
