import { connect } from 'react-redux';
import Skills from 'components/pages/Skills';
import { modalHide, modalShow } from 'store/reducers/modal';

export const mapStateToProps = state => {
  return {
    selectedSkills: state.profile.selectedSkills,
    visitedSkillsPages: state.profile.visitedSkillsPages,
    errorRetrievingSkills: state.validSkills.error
  };
};

export default connect(mapStateToProps, { modalHide, modalShow })(Skills);
