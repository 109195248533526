import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Downshift from 'downshift';
import debounce from 'lodash.debounce';

import Input from 'components/atoms/Input';
import loadingGif from 'images/loading.gif';
import { grayMedium, grayLight, blueText, red } from 'helpers/colors';

const StyledDownshiftWrapper = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }
`;

const ParentDiv = styled.div`
  width: 100%;
  position: relative;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled(Input)`
  padding-right: 3.5rem;
  ${props =>
    props.hasButton &&
    `
      @media screen and (min-width: 800px) {
        padding-right: 11rem;
      }
  `};
`;

const Spinner = styled.img`
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
  width: 2.5rem;
  @media screen and (min-width: 500px) {
    right: 0.8rem;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0.3rem;
  padding: 0.5rem;
`;

const Item = styled.div`
  height: auto;
  cursor: pointer;
  padding: 1rem 1.5rem;
  line-height: 2rem;
  font-size: 1.4rem;
  color: ${blueText};
`;

const ItemContainer = styled.div`
  margin-top: 0.5rem;
  position: absolute;
  width: 100%;
  max-height: 19rem;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #ffffff;
  padding: 1rem;
  border: 1px solid ${grayMedium};
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  box-sizing: border-box;
  z-index: 1;
`;

const ErrorMessage = styled.div`
  font-size: 1.2rem;
  color: ${red};
  padding-top: 0.5rem;
`;

class Autocomplete extends React.Component {
  state = {
    items: [],
    isLoading: false
  };

  handleInputFocus = () => {
    if (window.screen.width < 500 && this.inputRef) {
      this.inputRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  handleInputChange = query => {
    this.setState({ isLoading: true });
    this.retrieveItems(query);
  };

  retrieveItems = debounce(async query => {
    const { getItems } = this.props;
    const items = await getItems(query);
    this.setState({ items, isLoading: false });
  }, 250);

  render() {
    const {
      name = '',
      id,
      disabled,
      required,
      placeholder,
      selectedItem,
      selectItem,
      ariaLabel,
      errorMessage,
      className,
      onFocus,
      onBlur,
      button
    } = this.props;
    const { items, isLoading } = this.state;
    return (
      <StyledDownshiftWrapper className={className}>
        <Downshift
          onChange={inputValue => selectItem(inputValue)}
          itemToString={item => (item ? item.name : '')}
          selectedItem={selectedItem}
          onSelect={() => window.screen.width < 500 && this.inputRef.blur()}
        >
          {({
            // Downshift props
            getInputProps,
            getItemProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex
          }) => (
            <div aria-labelledby={null}>
              <ParentDiv>
                <InputWrapper>
                  <StyledInput
                    hasButton={!!button}
                    {...getInputProps({
                      onChange: event =>
                        event.target.value.length > 0 && this.handleInputChange(event.target.value),
                      onFocus: e => {
                        onFocus && onFocus(e);
                        this.handleInputFocus();
                      },
                      onBlur,
                      placeholder: inputValue.length > 0 ? null : placeholder,
                      '::placeholder': `{color: ${blueText}}`,
                      'aria-label': ariaLabel,
                      'aria-labelledby': null,
                      ref: c => (this.inputRef = c),
                      spellCheck: false,
                      name,
                      id,
                      disabled,
                      required
                    })}
                  />
                  {!button && isLoading && <Spinner src={loadingGif} alt="Loading" />}
                  {button && <ButtonWrapper>{button}</ButtonWrapper>}
                </InputWrapper>
                {isOpen && inputValue.length > 0 && items.length > 0 && (
                  <ItemContainer {...getMenuProps()}>
                    {items.map((item, index) => (
                      <Item
                        key={item.id}
                        {...getItemProps({
                          key: item.id,
                          index,
                          item,
                          style: {
                            backgroundColor: highlightedIndex === index ? grayLight : '#ffffff',
                            fontWeight:
                              selectedItem && selectedItem.name === item.name ? 'bold' : 'normal'
                          }
                        })}
                      >
                        {item.name}
                      </Item>
                    ))}
                  </ItemContainer>
                )}
                {errorMessage && <ErrorMessage aria-live="polite">{errorMessage}</ErrorMessage>}
              </ParentDiv>
            </div>
          )}
        </Downshift>
      </StyledDownshiftWrapper>
    );
  }
}

Autocomplete.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  getItems: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  name: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  // The selectedItem should be controlled and tracked by the parent
  selectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
  }),
  selectItem: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  button: PropTypes.node
};

export default Autocomplete;
