import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SkillsGrid from 'components/atoms/SkillsGrid';
import SelectedSkillsList from 'components/atoms/SelectedSkillsList';
import ToggleSwitch from 'components/atoms/ToggleSwitch';

import emptyIcon from 'images/empty-skill-list.svg';
import spinner from 'images/loading.gif';
import { red } from 'helpers/colors';

const EmptyPanel = styled.div`
  display: flex;
  font-size: 1.4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  text-align: center;
  > * {
    margin: 0.5rem;
  }
`;

const EmptyImage = styled.img`
  height: 4rem;
`;

const Spinner = styled.img`
  width: 5rem;
`;
const Error = styled.div`
  color: ${red};
`;

const Panel = styled.div`
  padding: 1rem 2rem 2rem;
`;

const ToggleRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 0.2rem;
`;

const ListWrapper = styled.div`
  ${props =>
    props.allowWrap &&
    `
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > div {
        box-sizing: border-box;
        flex-grow: 1;
        flex-basis: 24rem;
        flex-shrink: 1;
        @media screen and (max-width: 1023px) and (min-width: 580px) {
          width: 50%;
          &:not(:last-child) {
            padding-right: 1.2rem;
          }
          &:nth-child(2) {
            padding-left: 1.2rem;
          }
        }
      }
  `}
`;

const Margin = styled.div`
  margin-bottom: 1.5rem;
`;

const ListHeader = styled.h3`
  margin: 0.6rem 0;
  font-size: 0.8rem;
  letter-spacing: 0.2rem;
`;

const SelectedSkillsPanel = ({
  posessedSkills,
  wantedSkills,
  displayAsList,
  toggleDisplayAsList,
  validSkillsIsLoaded,
  validSkillsError,
  removeSelectedSkill
}) => {
  if (!posessedSkills.length && !wantedSkills.length) {
    return (
      <EmptyPanel>
        <EmptyImage alt="" src={emptyIcon} />
        <div>Skills you select will appear here.</div>
      </EmptyPanel>
    );
  }

  if (validSkillsError) {
    return (
      <EmptyPanel>
        <Error aria-live="polite">There was an error loading skill information</Error>
      </EmptyPanel>
    );
  }

  if (!validSkillsIsLoaded) {
    return (
      <EmptyPanel>
        <Spinner src={spinner} alt="" />
        <div>Loading skill information</div>
      </EmptyPanel>
    );
  }

  const SelectedSkills = displayAsList ? SelectedSkillsList : SkillsGrid;

  return (
    <Panel>
      <ToggleRow>
        <span aria-hidden>GRID</span>
        <ToggleSwitch
          isOn={displayAsList}
          onChange={toggleDisplayAsList}
          ariaLabel={`Toggle Switch.  Toggle to select either Grid view or List view for selected skills.  Current selection is ${
            displayAsList ? 'list' : 'grid'
          } view.`}
          solidBackground
          scale={0.5}
        />
        <span aria-hidden>LIST</span>
      </ToggleRow>
      <ListWrapper allowWrap={displayAsList}>
        {posessedSkills.length !== 0 && (
          <Margin>
            <ListHeader>I HAVE</ListHeader>
            <SelectedSkills
              data-cy="selected-skills-have-list"
              ariaLabel="skills I have"
              skills={posessedSkills}
              removeSkill={removeSelectedSkill}
            />
          </Margin>
        )}
        {wantedSkills.length !== 0 && (
          <div>
            <ListHeader>I WANT</ListHeader>
            <SelectedSkills
              data-cy="selected-skills-want-list"
              ariaLabel="skills I want"
              skills={wantedSkills}
              removeSkill={removeSelectedSkill}
            />
          </div>
        )}
      </ListWrapper>
    </Panel>
  );
};

SelectedSkillsPanel.propTypes = {
  posessedSkills: PropTypes.arrayOf(
    PropTypes.shape({
      // id and name shouldn't be required. They will cause propTypes errors when refreshing
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  wantedSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  displayAsList: PropTypes.bool.isRequired,
  toggleDisplayAsList: PropTypes.func.isRequired,
  removeSelectedSkill: PropTypes.func.isRequired,
  validSkillsIsLoaded: PropTypes.bool.isRequired,
  validSkillsError: PropTypes.bool.isRequired
};

export default SelectedSkillsPanel;
