import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from 'components/atoms/Card';
import CoursesProgramsToggle from 'components/molecules/CoursesProgramsToggle';
import Education from 'components/organisms/Education';
import Flex from 'components/atoms/Flex';
import MatchHeader from 'components/atoms/MatchHeader';
import ResultsSidebar from 'components/molecules/ResultsSidebar';
import SkillShape from 'components/atoms/SkillShape';
import ToggleSwitch from 'components/atoms/ToggleSwitch';
import TopClusterSkills from 'components/molecules/TopClusterSkills';
import CompanyFilter from 'components/molecules/CompanyFilter';
import CheckboxFilter from 'components/molecules/CheckboxFilter';
import ProgramFilter from 'components/molecules/ProgramFilter';
import HelpIconTooltip from 'components/atoms/HelpIconTooltip';

import MatchedJobPostings from 'containers/MatchedJobPostings';
import LocationAutocomplete from 'containers/LocationAutocomplete';

import { getClustersMeta } from 'services/clustersMeta';
import { getSkillsByCluster } from 'services/skillsClusters';
import { ReactComponent as FilterSvg } from 'images/filter.svg';
import { blue } from 'helpers/colors';

const ClusterWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5rem;
`;

const TitleWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  margin-left: 3rem;
  flex-wrap: wrap;
  > * {
    margin: 0 3rem 1.5rem 0;
  }
  @media (max-width: 549px) {
    width: unset;
    margin-left: 1.5rem;
  }
`;

const Styledh2 = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  flex-shrink: 0;
`;

const PillButton = styled.button`
  background: white;
  color: ${blue};
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FilterIcon = styled(FilterSvg)`
  margin-right: 1rem;
`;

const SkillsLegend = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0.5rem;
`;

const SmallCaps = styled.span`
  letter-spacing: 0.1rem;
  font-size: 1.3rem;
  font-variant: small-caps;
  font-weight: 500;
`;

const ClusterSkillsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const FullWidth = styled.div`
  width: 100%;
`;

const TopClusterSkillsCard = styled(Card)`
  align-self: stretch;
  margin: 0 3rem 2rem 3rem;
  padding: 3rem;
  @media (max-width: 549px) {
    margin: 0 1.5rem 1.5rem 1.5rem;
    padding: 1.5rem;
  }
  h2 {
    margin: 0.5rem;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 2rem 0 0.5rem;
`;

const MatchCluster = ({
  currentViews,
  site,
  profile,
  match: {
    params: { clusterId }
  },
  toggleDisplayAsList,
  updateView
}) => {
  let [clusterSkills, setClusterSkills] = useState(undefined);
  let [subgroupName, setSubgroupName] = useState(undefined);
  let [topSkillIds, setTopSkillIds] = useState(undefined);
  let [loadingError, setLoadingError] = useState(false);
  let [educationSidebarOpen, setEducationSidebarOpen] = useState(false);
  let [jobsSidebarOpen, setJobsSidebarOpen] = useState(false);

  const {
    education: { filterSkills: _eduViewSkills, ...eduView },
    jobs: { filterSkills: _jobViewSkills, ...jobsView }
  } = currentViews;

  const selectedFilterCount = (viewObject, viewType) => {
    let count = Object.values(viewObject).filter(filter => filter.length).length;
    if (viewType === 'education' && site.hideEdType) {
      count--;
    }
    return count;
  };

  const educationSelectedFilterCount = selectedFilterCount(eduView, 'education');

  useEffect(() => {
    getSkillsByCluster(clusterId)
      .then(data => setClusterSkills(data))
      .catch(() => setLoadingError(true));

    getClustersMeta(clusterId)
      .then(([data]) => {
        setSubgroupName(data.subgroupName);
        document.title = `Lightcast SkillsMatch - ${data.subgroupName}`;
        setTopSkillIds(data.topSkillIds);
      })
      .catch(() => setLoadingError(true));
  }, [clusterId]);

  // useMemo used to avoid unnecessary Courses API calls
  // The memoized value is recomputed only when a dependency changes
  const searchedSites = useMemo(() => {
    const validSites = eduView.filterSites.filter(filterSite =>
      Object.keys(site.institutions).includes(filterSite)
    );
    return validSites.length ? validSites : Object.keys(site.institutions);
  }, [eduView.filterSites, site.institutions]);

  return (
    <ClusterWrapper>
      <MatchHeader
        subgroupId={clusterId.split('-')[0]}
        subgroupName={subgroupName}
        topSkillIds={topSkillIds}
        error={loadingError}
      />
      <TopClusterSkillsCard>
        <ClusterSkillsHeader>
          <Flex shouldWrap alignItems="center">
            <Styledh2>Top In-demand Skills</Styledh2>
            <HelpIconTooltip helpText="Skills are shown in order of importance to employers based on job posting data (left-to-right)." />
            <ToggleWrapper>
              <SmallCaps>grid</SmallCaps>
              <ToggleSwitch
                solidBackground
                isOn={profile.displayAsList}
                scale={0.6}
                onChange={() => toggleDisplayAsList()}
                ariaLabel={`Toggle Switch. Toggle to view your skills in grid or list format. Current view: ${
                  profile.displayAsList ? 'List' : 'Grid'
                }`}
              />
              <SmallCaps>list</SmallCaps>
            </ToggleWrapper>
          </Flex>
          <SkillsLegend aria-hidden="true">
            <SkillShape selectionType="have" margin="0 0.33rem 0 0rem" />
            <SmallCaps>have</SmallCaps>
            <SkillShape selectionType="want" margin="0 0.33rem 0 1.5rem" />
            <SmallCaps>want</SmallCaps>
            <SkillShape margin="0 0.33rem 0 1.5rem" />
            <SmallCaps>don&apos;t have</SmallCaps>
          </SkillsLegend>
        </ClusterSkillsHeader>
        <TopClusterSkills
          clusterSkills={clusterSkills}
          displayAsList={profile.displayAsList}
          hasError={!clusterSkills && loadingError}
          selectedSkills={profile.selectedSkills}
        />
      </TopClusterSkillsCard>

      <TitleWrapper>
        <Styledh2>Educational Opportunities</Styledh2>
        {(!site.hideEdType ||
          Object.keys(site.institutions).length > 1 ||
          eduView.filterEdType === 'Programs') && (
          <PillButton
            onClick={() => setEducationSidebarOpen(true)}
            aria-label="Edit Education filters"
            data-cy="cluster-education-filters"
          >
            <FilterIcon />
            {educationSelectedFilterCount
              ? `Edit Filters (${educationSelectedFilterCount})`
              : 'Add Filters'}
          </PillButton>
        )}
      </TitleWrapper>
      {eduView.filterEdType === 'Programs' && (
        <Education
          clusterId={clusterId}
          clusterSkills={clusterSkills}
          helpCardEdu={site.helpCardEdu}
          edType="Programs"
          selectedSkills={profile.selectedSkills}
          selectedSites={searchedSites}
          selectedLevels={eduView.filterLevels}
          sites={Object.keys(site.institutions)}
        />
      )}
      {eduView.filterEdType === 'Courses' && (
        <Education
          clusterId={clusterId}
          clusterSkills={clusterSkills}
          helpCardEdu={site.helpCardEdu}
          edType="Courses"
          selectedSkills={profile.selectedSkills}
          selectedSites={searchedSites}
          selectedPrograms={eduView.filterProgramIds}
          sites={Object.keys(site.institutions)}
        />
      )}

      <ResultsSidebar
        type="Education"
        mobileOnly
        mobileOpen={educationSidebarOpen}
        setMobileOpen={setEducationSidebarOpen}
      >
        {!site.hideEdType && (
          <CoursesProgramsToggle
            edType={eduView.filterEdType}
            setEdType={eduType => updateView('education', { filterEdType: eduType })}
          />
        )}
        {eduView.filterEdType === 'Courses' && (
          <ProgramFilter
            view={eduView}
            updateView={updates => updateView('education', updates)}
            sites={Object.keys(site.institutions)}
          />
        )}
        {Object.keys(site.institutions).length > 1 && (
          <CheckboxFilter
            section="education"
            title="Institutions"
            selectedItemIds={eduView.filterSites}
            items={Object.entries(site.institutions).map(i => ({ id: i[0], name: i[1] }))}
            onChange={updates => updateView('education', { filterSites: updates })}
          />
        )}
        <CheckboxFilter
          section="education"
          isHidden={eduView.filterEdType === 'Courses'}
          title="Education Levels"
          selectedItemIds={eduView.filterLevels}
          items={Object.values(site.groupTypeClasses)}
          onChange={updates => updateView('education', { filterLevels: updates })}
        />
      </ResultsSidebar>

      <FullWidth>
        <TitleWrapper>
          <Styledh2>Job Postings</Styledh2>
          <PillButton
            onClick={() => setJobsSidebarOpen(true)}
            aria-label="Edit Jobs filters"
            data-cy="cluster-jobs-filters"
          >
            <FilterIcon />
            Edit Filters ({selectedFilterCount(jobsView)})
          </PillButton>
        </TitleWrapper>
        <MatchedJobPostings
          clusterId={clusterId}
          clusterSkills={clusterSkills}
          filterCompanyIds={
            jobsView.filterCompanyIds.length ? jobsView.filterCompanyIds : site.jobPostingOrgIds
          }
          helpCardJobs={site.helpCardJobs}
          selectedSkills={profile.selectedSkills}
        />
      </FullWidth>

      <ResultsSidebar
        type="Jobs"
        mobileOnly
        mobileOpen={jobsSidebarOpen}
        setMobileOpen={setJobsSidebarOpen}
      >
        <LocationAutocomplete />
        {/* The CompanyFilter will only display when there are 0 or more than 1 jobPostingOrgIds.
          In the case of 1 jobPostingOrgId, the results will display job postings for that 1 company.
        */
        site.jobPostingOrgIds.length !== 1 && (
          <CompanyFilter
            view={jobsView}
            updateView={updates => updateView('jobs', updates)}
            jobPostingOrgIds={site.jobPostingOrgIds}
          />
        )}
      </ResultsSidebar>
    </ClusterWrapper>
  );
};

const StringArray = PropTypes.arrayOf(PropTypes.string).isRequired;

MatchCluster.propTypes = {
  currentViews: PropTypes.shape({
    education: PropTypes.shape({
      filterSkills: StringArray,
      filterLevels: StringArray,
      filterSites: StringArray,
      filterEdType: PropTypes.string.isRequired
    }).isRequired,
    jobs: PropTypes.shape({
      filterSkills: StringArray,
      filterCompanyIds: StringArray
    }).isRequired
  }).isRequired,
  updateView: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      clusterId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  site: PropTypes.shape({
    institutions: PropTypes.objectOf(PropTypes.string).isRequired,
    groupTypeClasses: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    helpCardEdu: PropTypes.array,
    helpCardJobs: PropTypes.array,
    jobPostingOrgIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideEdType: PropTypes.bool
  }),
  profile: PropTypes.shape({
    displayAsList: PropTypes.bool.isRequired,
    selectedSkills: PropTypes.object.isRequired
  }),
  toggleDisplayAsList: PropTypes.func.isRequired
};
export default MatchCluster;
