import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Media from 'react-media';

import ButtonLink from 'components/atoms/ButtonLink';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import MobileNav from 'components/molecules/MobileNav';
import { blue } from 'helpers/colors';

import { ReactComponent as ArrowSvg } from 'images/arrow-right.svg';
import { ReactComponent as EducationIcon } from 'images/nav-education.svg';
import { ReactComponent as GoalsIcon } from 'images/nav-goals.svg';
import { ReactComponent as IntroIcon } from 'images/nav-intro.svg';
import { ReactComponent as MatchesIcon } from 'images/nav-matches.svg';
import { ReactComponent as SkillsIcon } from 'images/nav-skills.svg';

const ArrowIcon = styled(ArrowSvg)`
  margin-left: 1rem;
  width: 1.7rem;
  height: auto;
  fill: ${blue};
`;

const SkipButton = styled(ButtonLink)`
  padding: 1rem 3rem;
`;

const NavBar = () => {
  const navSteps = [
    { path: '', icon: IntroIcon, text: 'Intro', displayBreadCrumbs: false },
    { path: 'goals', icon: GoalsIcon, text: 'My Goal', displayBreadCrumbs: true },
    window.nation === 'US' && {
      path: 'degrees',
      icon: EducationIcon,
      text: 'My Education',
      displayBreadCrumbs: true
    },
    { path: 'skills', icon: SkillsIcon, text: 'My Skills', displayBreadCrumbs: true },
    {
      path: 'results',
      icon: MatchesIcon,
      text: 'My Matches',
      displayBreadCrumbs: false
    }
  ].filter(Boolean);

  const basePath = useLocation().pathname.split('/')[1];
  // Only display the navigation bar in the navigation flow. Hide it on other pages such as `/login`
  // But don't show navigation on Intro and Results pages for a clean page layout
  if (!navSteps.find(item => item.path === basePath && item.displayBreadCrumbs)) {
    return null;
  }
  return (
    <nav>
      <Media query="(min-width: 1225px)">
        {matches =>
          React.createElement(matches ? Breadcrumbs : MobileNav, {
            basePath,
            navSteps,
            skipButton: basePath !== 'results' && (
              <SkipButton inverseColor to="/results">
                Skip and Start Exploring
                <ArrowIcon />
              </SkipButton>
            )
          })
        }
      </Media>
    </nav>
  );
};

export default NavBar;
