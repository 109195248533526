import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Media from 'react-media';

import AriaAlert from 'components/atoms/AriaAlert';
import SvgIcon from 'components/atoms/SvgIcon';
import IconButton from 'components/atoms/IconButton';
import VerticalScrollGradient from 'components/atoms/VerticalScrollGradient';
import SkillChooser from 'components/molecules/SkillsChooser';
import { getParsedSkillsForText } from 'services/skillsClassification';

import { blue, blueText } from 'helpers/colors';

import emptyIcon from 'images/empty-skill-list.svg';
import { ReactComponent as Plus } from 'images/plus-circle-border.svg';
import { ReactComponent as ClearAll } from 'images/x-circle.svg';
import LoadingGif from 'images/loading.gif';

const Wrapper = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    display: flex;
    margin-bottom: 1rem;
  }
`;

const ResumeText = styled.div`
  width: 100%;
  /* in case the text does not have spaces, this will prevent overflow */
  overflow-wrap: anywhere;
  font-size: 1.4rem;
  flex-shrink: 0;
  white-space: pre-wrap;
  @media (min-width: 1024px) {
    flex: 3;
  }
`;

const Highlight = styled.mark`
  display: inline-block;
  background-color: ${blueText};
  color: white;
  padding: 0 0.5rem;
  white-space: nowrap;

  ::before,
  ::after {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  ::before {
    content: ' [highlight start] ';
  }

  ::after {
    content: ' [highlight end] ';
  }
`;

const SkillsWrapper = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex: 2;
    margin-left: 4rem;
  }
`;

const EmptySkills = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  margin: 6rem 0 4.5rem 0;

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

const LoadingImg = styled.img`
  width: 6rem;
  height: 6rem;
`;

const StyledDiv = styled.div`
  margin-top: 1.5rem;
  max-width: 29rem;
  font-size: 1.4rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;

  @media (max-width: 1023px) {
    margin-top: 1rem;
  }
`;

const StyledIcon = styled(SvgIcon)`
  fill: ${blue};
  margin-right: 0.5rem;
`;

const SkillResumeResults = ({ text, selectAllSkills, deselectAllSkills }) => {
  const [skills, setSkills] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [isRequesting, setIsRequesting] = useState(true);

  useEffect(() => {
    if (text) {
      setIsRequesting(true);
      getParsedSkillsForText(text).then(result => {
        setSkills(result.skills.map(({ skill }) => skill.id));
        setHighlightedIndices(result.trace.map(item => item.surfaceForm));
        setIsRequesting(false);
      });
    }
  }, [text]);

  const addParsedSkills = () => {
    const parsedSkills = {};
    skills.forEach(skill => {
      parsedSkills[skill] = 'have';
    });
    selectAllSkills({ ...parsedSkills });
  };

  const removeParsedSkills = () => {
    deselectAllSkills(skills);
  };

  let highlightedText;
  if (highlightedIndices.length === 0) {
    highlightedText = text;
  } else {
    highlightedText = [];
    // Loop through all start/end index pairs
    highlightedIndices.forEach(({ sourceStart, sourceEnd }, index) => {
      // First add the string before the current highlight to an array (for children)
      highlightedText.push(
        text.substring(index === 0 ? 0 : highlightedIndices[index - 1].sourceEnd, sourceStart)
      );
      // Then add the curent highlight
      highlightedText.push(
        <Highlight
          aria-label={text.substring(sourceStart, sourceEnd)}
          tabIndex="0"
          key={sourceStart}
        >
          {text.substring(sourceStart, sourceEnd)}
        </Highlight>
      );
      // For the last highlight, add the end string as well
      if (index === highlightedIndices.length - 1) {
        highlightedText.push(text.substring(sourceEnd, text.length));
      }
    });
  }

  return (
    <Wrapper>
      <ResumeText>
        <Media query="(min-width: 1024px)">
          {matches => (
            <VerticalScrollGradient
              takesFullHeight
              gradientColor="#FFFFFF"
              maxHeight={matches ? '52.4rem' : '24rem'}
            >
              <>{highlightedText}</>
            </VerticalScrollGradient>
          )}
        </Media>
      </ResumeText>
      <SkillsWrapper>
        {skills.length > 0 ? (
          <Flex>
            <ButtonWrapper>
              <IconButton
                buttonText="Select All"
                ariaLabel="Add all skills to have skills"
                icon={<StyledIcon Svg={Plus} />}
                onClick={addParsedSkills}
              />
              <IconButton
                buttonText="Deselect All"
                ariaLabel="Remove all skills from your skills"
                icon={<StyledIcon Svg={ClearAll} />}
                onClick={removeParsedSkills}
              />
            </ButtonWrapper>
            <AriaAlert>
              {!isRequesting &&
                `${skills.length} ${
                  skills.length === 1 ? 'skill was' : 'skills were'
                } detected in your résumé. Tab backwards to reach skills list.`}
            </AriaAlert>
            <SkillChooser skillIds={skills} forceMobilePageSize />
          </Flex>
        ) : (
          <EmptySkills>
            {isRequesting ? (
              <>
                <LoadingImg src={LoadingGif} alt="" />
                <AriaAlert>Extracting Skills...</AriaAlert>
              </>
            ) : (
              <>
                <img src={emptyIcon} alt="" />
                <AriaAlert>
                  We didn&apos;t recognize any skills from your résumé. Try adding some skills that
                  you have.
                </AriaAlert>
                <StyledDiv>
                  We didn&apos;t recognize any skills from your résumé. Try adding some skills that
                  you have.
                </StyledDiv>
              </>
            )}
          </EmptySkills>
        )}
      </SkillsWrapper>
    </Wrapper>
  );
};

SkillResumeResults.propTypes = {
  text: PropTypes.string.isRequired,
  deselectAllSkills: PropTypes.func.isRequired,
  selectAllSkills: PropTypes.func.isRequired
};

export default SkillResumeResults;
