import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { blue } from 'helpers/colors';

const StyledButton = styled.button`
  border: none;
  background: transparent;
  color: ${blue};
  font-size: 1.4rem;
  :hover {
    cursor: pointer;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = ({ onClick, buttonText, ariaLabel, icon }) => {
  return (
    <StyledButton onClick={onClick} aria-label={ariaLabel}>
      <Flex>
        {icon && icon}
        {buttonText}
      </Flex>
    </StyledButton>
  );
};

IconButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired
};

export default IconButton;
