import { connect } from 'react-redux';
import LocationAutocomplete from 'components/molecules/LocationAutocomplete';
import { currentViewActions } from 'store/reducers/currentViews';
const { updateView } = currentViewActions;

const mapStateToProps = ({ currentViews, site }) => ({
  cities: currentViews.jobs.filterCities,
  defaultCity: site.data.defaultCity
});

export default connect(mapStateToProps, {
  updateCities: cities => updateView('jobs', { filterCities: cities })
})(LocationAutocomplete);
