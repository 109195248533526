import Amplify from '@aws-amplify/core';
import AWS from 'aws-sdk';
import cognitoAuth from '@aws-amplify/auth';

import config from 'config';

const { awsCognitoAuth } = config;

Amplify.configure({
  Auth: awsCognitoAuth
});

/**
 * @method signUpCognito - Creates a new cognito user
 * @param {string} email
 * @param {string} password
 * @param {string} subdomain
 */
export const signUpCognito = (email, password, subdomain) =>
  cognitoAuth.signUp({
    username: email,
    password,
    attributes: { email, 'custom:subdomain': subdomain }
  });

/**
 * @method loginCognito - Logs a user into their cognito account
 * @param {string} email
 * @param {string} password
 */
export const loginCognito = (email, password) =>
  cognitoAuth.signIn(email, password).then(() => cognitoAuth.currentUserInfo());

/**
 * @method confirmSignUpCognito - verifies the cognito user
 * @param {string} email
 * @param {string} code
 */
export const confirmSignUpCognito = (email, code) => cognitoAuth.confirmSignUp(email, code);

/**
 * @method resendVerificationCognito - Resend a verification code if requested
 * @param {string} email
 */
export const resendVerificationCognito = email => cognitoAuth.resendSignUp(email);

export const changeCognitoPassword = (oldPassword, newPassword) =>
  cognitoAuth
    .currentAuthenticatedUser()
    .then(user => cognitoAuth.changePassword(user, oldPassword, newPassword));

export const logoutCognito = () => cognitoAuth.signOut();

export const deleteCognitoUser = () => {
  const CognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
    region: config.awsCognitoAuth.region
  });
  return new Promise((resolve, reject) =>
    cognitoAuth.currentSession().then(({ accessToken }) =>
      CognitoIdentityServiceProvider.deleteUser(
        {
          AccessToken: accessToken.jwtToken
        },
        (err, data) => (err ? reject(err) : resolve(data))
      )
    )
  );
};

/**
 * @method forgotCognitoPasswordRequest - Send the verification code email for password reset
 * @param {string} email
 */
export const forgotCognitoPasswordRequest = email => cognitoAuth.forgotPassword(email);

/**
 * @method forgotCognitoPasswordSubmit- Reset the user's password
 * @param {string} email
 * @param {string} verificationCode
 * @param {string} newPassword
 */
export const forgotCognitoPasswordSubmit = ({ email, verificationCode, newPassword }) =>
  cognitoAuth.forgotPasswordSubmit(email, verificationCode, newPassword);
