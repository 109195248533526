import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import FormInput from 'components/atoms/FormInput';
import LoadingButton from 'components/molecules/LoadingButton';
import { grayMedium, red } from 'helpers/colors';
import { required, isValidPassword, composeValidators } from 'helpers/validateForm';

const Error = styled.span`
  font-size: 1.4rem;
  color: ${red};
  margin-top: 1.5rem;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledHr = styled.hr`
  margin: 0 0 3rem 0;
  border: solid 1px ${grayMedium};
`;

const ForgotPasswordResetForm = ({ error, isLoading, onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    validate={values => {
      const errors = {};
      if (values.newPassword !== values.confirmNewPassword) {
        errors.confirmNewPassword = 'Passwords must match';
      }
      return errors;
    }}
  >
    {({ handleSubmit }) => {
      return (
        <StyledForm onSubmit={handleSubmit}>
          <Field name="verificationCode" validate={required}>
            {({ input, meta }) => {
              return (
                <FormInput
                  label="verification code"
                  autoComplete="one-time-code"
                  error={meta.error && meta.touched ? meta.error : ''}
                  {...input}
                />
              );
            }}
          </Field>
          <StyledHr />
          <Field name="newPassword" validate={composeValidators(required, isValidPassword)}>
            {({ input, meta }) => {
              return (
                <FormInput
                  type="password"
                  label="new password"
                  autoComplete="new-password"
                  error={meta.error && meta.touched ? meta.error : ''}
                  {...input}
                />
              );
            }}
          </Field>
          <Field name="confirmNewPassword" validate={required}>
            {({ input, meta }) => {
              return (
                <FormInput
                  type="password"
                  label="re-enter new password"
                  autoComplete="new-password"
                  error={meta.error && meta.touched ? meta.error : ''}
                  {...input}
                />
              );
            }}
          </Field>
          <LoadingButton isLoading={isLoading}>Submit</LoadingButton>
          {error && <Error aria-live="polite">{error}</Error>}
        </StyledForm>
      );
    }}
  </Form>
);

ForgotPasswordResetForm.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ForgotPasswordResetForm;
