import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ClusterCardBody from 'components/molecules/ClusterCardBody';
import LoadMoreButton from 'components/molecules/LoadMoreButton';
import ResultsDisplay from 'components/organisms/ResultsDisplay';

const ClusterResults = ({
  clearFilters,
  clusterResults,
  filterSkills,
  selectedSkills,
  setMobileSidebarOpen,
  isProfileLoading,
  validSkills
}) => {
  const [displayedMatchCount, setDisplayedMatchCount] = useState(16);
  const [displayedOtherCount, setDisplayedOtherCount] = useState(8);
  const { matches, others, error, isLoading: isLoadingMatches } = clusterResults;

  // Reset the displayed counts if the filters change.
  useEffect(() => {
    setDisplayedMatchCount(16);
    setDisplayedOtherCount(8);
  }, [filterSkills]);

  // Filter clusters by selected filter skills
  let displayableMatches = [...matches],
    displayableOther = [...others];
  if (filterSkills.length) {
    displayableMatches = matches.filter(c =>
      c.primarySkills.some(skill => filterSkills.includes(skill.id))
    );
    displayableOther = others.filter(c =>
      c.primarySkills.some(skill => filterSkills.includes(skill.id))
    );
  }

  // Pagination Logic
  const paginate = () => {
    if (displayedMatchCount < displayableMatches.length) {
      setDisplayedMatchCount(count => count + 8);
    } else {
      setDisplayedOtherCount(count => count + 24);
    }
  };

  // Display the correct number of clusters
  const matchedCards = displayableMatches
    .slice(0, displayedMatchCount)
    .map(cluster => (
      <ClusterCardBody validSkills={validSkills} key={cluster.clusterId} cluster={cluster} />
    ));
  let otherCards; // otherCards should only be defined if there are <= 8 matches
  if (displayableMatches.length <= 8) {
    otherCards = displayableOther
      .slice(0, displayedOtherCount)
      .map(cluster => (
        <ClusterCardBody
          validSkills={validSkills}
          key={cluster.clusterId}
          cluster={cluster}
          showMatchScore={false}
        />
      ));
  }

  // Determine when the Load More button should be disabled
  const endOfList =
    displayedMatchCount >= displayableMatches.length &&
    (!otherCards || displayedOtherCount >= displayableOther.length);

  const isLoading = isLoadingMatches || isProfileLoading || !Object.keys(validSkills).length;

  return (
    <>
      <ResultsDisplay
        contentName="Career Areas"
        matchedResults={matchedCards}
        otherResults={otherCards}
        selectedSkills={selectedSkills}
        openMobileSidebar={() => setMobileSidebarOpen(true)}
        isLoading={isLoading}
        error={error}
        clearFilters={clearFilters}
      />
      {!isLoading && !error && <LoadMoreButton loadMore={() => paginate()} endOfList={endOfList} />}
    </>
  );
};

ClusterResults.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  clusterResults: PropTypes.shape({
    matches: PropTypes.arrayOf(PropTypes.object).isRequired,
    others: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  filterSkills: PropTypes.arrayOf(PropTypes.string),
  isProfileLoading: PropTypes.bool.isRequired,
  selectedSkills: PropTypes.object.isRequired,
  setMobileSidebarOpen: PropTypes.func.isRequired,
  validSkills: PropTypes.object.isRequired
};

export default ClusterResults;
