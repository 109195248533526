import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from 'components/atoms/Card';
import HelpIconTooltip from 'components/atoms/HelpIconTooltip';

const StyledCard = styled(Card)`
  margin: 3rem;
  padding: 1rem;
  padding-top: 2rem;

  @media screen and (min-width: 1024px) {
    flex-grow: 1;
    padding: 2rem;
  }
`;

const CardHeadingWrapper = styled.div`
  display: flex;
  ${props => props.centered && 'justify-content: center;'}
  @media screen and (max-width: 799px) {
    flex-direction: column;
    > div {
      justify-content: center;
    }
  }
`;

const HeadingWrapperLeft = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledHeader = styled.h2`
  font-size: 1.6rem;
  margin: 0;
`;

const CardContentWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (min-width: 1024px) {
    align-items: flex-start;
  }
`;

const TitledCard = ({
  centerHeader = false,
  children,
  className,
  helpText,
  icon,
  title,
  titleLink
}) => {
  return (
    <StyledCard className={className}>
      <CardHeadingWrapper centered={centerHeader}>
        <HeadingWrapperLeft>
          <StyledHeader>
            <span aria-hidden="true">{icon}</span>
            {title}
          </StyledHeader>
          {helpText && <HelpIconTooltip helpText={helpText} />}
        </HeadingWrapperLeft>
        {titleLink}
      </CardHeadingWrapper>
      <CardContentWrap>{children}</CardContentWrap>
    </StyledCard>
  );
};

TitledCard.propTypes = {
  centerHeader: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  helpText: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  titleLink: PropTypes.node
};

export default TitledCard;
