import React from 'react';
import PropTypes from 'prop-types';

import SearchAndFilterInput from 'components/molecules/SearchAndFilterInput';

import { searchPrograms } from 'services/courses';
import withKeen from 'hocs/withKeen';

const ProgramFilter = ({ view, updateView, sites, trackEvent }) => {
  const { programNames, filterProgramIds } = view;

  const makeSelection = suggestion => {
    trackEvent('Results Page Program Searched', {
      programId: suggestion.id
    });
    updateView({
      programNames: { [suggestion.id]: suggestion.title, ...programNames },
      filterProgramIds: [suggestion.id, ...filterProgramIds]
    });
  };

  const removeSuggestion = async programId => {
    const newFilterProgramIds = filterProgramIds.filter(itemId => itemId !== programId);
    const newProgramNames = { ...programNames };
    delete newProgramNames[programId];
    updateView({
      filterProgramIds: newFilterProgramIds,
      programNames: newProgramNames
    });
    return newProgramNames;
  };

  const fetchRequested = async e => {
    const resp = e.value.length === 0 ? [] : await searchPrograms(null, e.value, sites, 20);
    return resp.data
      ? resp.data.map(item => ({
          ...item,
          name: `${item.title} (${item.groupType.groupTypeClass.name})`
        }))
      : [];
  };

  return (
    <SearchAndFilterInput
      type="Program"
      removeSuggestion={removeSuggestion}
      makeSelection={makeSelection}
      fetchRequested={fetchRequested}
      pillData={Object.entries(programNames)}
    />
  );
};

export default withKeen(ProgramFilter);

ProgramFilter.propTypes = {
  view: PropTypes.shape({
    filterProgramIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    programNames: PropTypes.objectOf(PropTypes.string).isRequired
  }).isRequired,
  updateView: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.string).isRequired,
  trackEvent: PropTypes.func.isRequired
};
