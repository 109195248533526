import { connect } from 'react-redux';
import ProgramCoursePage from 'components/pages/ProgramCoursePage';
import { fetchValidSkillsActions } from 'store/reducers/validSkills';

const mapStateToProps = ({ profile, site }) => ({
  selectedSkills: profile.selectedSkills,
  institutions: site.data.skillabiSiteNames,
  hideEdType: site.data.hideEdType
});

export default connect(mapStateToProps, { fetchValidSkills: fetchValidSkillsActions.requestOnce })(
  ProgramCoursePage
);
