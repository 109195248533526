import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from 'components/atoms/Card';
import Flex from 'components/atoms/Flex';
import Margin from 'components/atoms/Margin';
import Link from 'components/atoms/Link';

import { blue, grayLight } from 'helpers/colors';
import { ReactComponent as Timer } from 'images/timer.svg';
import { ReactComponent as Arrow } from 'images/arrow-right.svg';

const StyledCard = styled(Card)`
  width: 27.5rem;
  box-shadow: 0 0 20px ${grayLight};
  font-size: 1.4rem;
  margin: 2rem 0 4rem;
  :not(:last-child) {
    margin-right: 7rem;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
`;

const ArrowIcon = styled(Arrow)`
  margin-left: 0.5rem;
  fill: ${blue};
  height: 1rem;
  width: 1.4rem;
`;

const StyledHr = styled.hr`
  width: 100%;
  border: solid 1px ${grayLight};
  margin: 1.5rem 0;
`;

const StyledClock = styled(Timer)`
  margin-right: 1rem;
`;

const IntroCtaCard = ({ title, time, image, url }) => (
  <StyledCard>
    <Link tabIndex="-1" aria-hidden to={url}>
      <Image src={image} alt={`go to ${url}`} />
    </Link>
    <Margin margin="1rem 2rem 2rem 2rem">
      <StyledLink to={url}>
        {title}
        <ArrowIcon />
      </StyledLink>
      <StyledHr />
      <Flex justifyContent="center">
        <StyledClock />
        {time}
      </Flex>
    </Margin>
  </StyledCard>
);

IntroCtaCard.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default IntroCtaCard;
