import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Flex from 'components/atoms/Flex';
import Link from 'components/atoms/Link';

import { blue, grayLight, grayMedium, blueText } from 'helpers/colors';
import { ReactComponent as Arrow } from 'images/arrow-right.svg';

const StyledCard = styled.div`
  background: ${grayLight};
  margin: 3rem 4rem;
  padding: 0 6rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  @media (max-width: 1023px) {
    margin: 0 2rem 3rem 2rem;
    padding: 1rem;
  }
`;

const ImageLink = styled(Link)`
  width: 28rem;
  // the images have more blank space at the bottom, so margin needs to compensate for that
  margin: 1.4rem 4rem 0 4rem;
  @media (max-width: 1023px) {
    display: none;
  }
  img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  max-width: 38rem;
  @media (max-width: 1023px) {
    margin: 0 1rem 1rem 1rem;
  }
`;

const Header = styled.h3`
  margin: 0;
  @media (min-width: 1024px) {
    margin-bottom: 1rem;
  }
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  display: block;
  margin-top: 1.5rem;
  width: fit-content;
`;

const ArrowIcon = styled(Arrow)`
  height: 1rem;
  width: 1.4rem;
  margin-left: 0.5rem;
  fill: ${blue};
`;

const IconCircle = styled.div`
  flex: 0 0 4rem;
  height: 4rem;
  margin: 2rem 2rem 2rem 0;
  background: ${grayMedium};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }

  * {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${blueText};
  }
`;

const IntroCtaRow = ({ title, text, image, imagePosition, icon: Icon, linkText, linkUrl }) => (
  <StyledCard>
    {imagePosition === 'left' && (
      <ImageLink tabIndex="-1" aria-hidden to={linkUrl}>
        <img src={image} alt={`go to ${linkUrl}`} />
      </ImageLink>
    )}
    <Wrapper>
      <Flex alignItems="center">
        <IconCircle>
          <Icon />
        </IconCircle>
        <Header>{title}</Header>
      </Flex>
      {text}
      <StyledLink to={linkUrl}>
        {linkText}
        <ArrowIcon />
      </StyledLink>
    </Wrapper>
    {imagePosition === 'right' && (
      <ImageLink tabIndex="-1" aria-hidden to={linkUrl}>
        <img src={image} alt={`go to ${linkUrl}`} />
      </ImageLink>
    )}
  </StyledCard>
);

IntroCtaRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imagePosition: PropTypes.oneOf(['left', 'right']),
  icon: PropTypes.elementType.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired
};

export default IntroCtaRow;
