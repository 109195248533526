import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { blueText } from 'helpers/colors';

const StyledDiv = styled.div`
  width: 100%;
  :hover {
    .toolTipBody {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const ToolTipBody = styled.div`
  position: relative;
  pointer-events: none;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s linear;
  margin-left: -3rem;
`;

const ToolTipText = styled.div`
  position: absolute;
  ${({ appearBelow }) => (appearBelow ? `top: 0.5rem` : `bottom: 2.8rem`)}
  left: 2rem;
  padding: 1rem;
  border-radius: 0.4rem;
  min-width: 16rem;
  font-size: 1.4rem;
  background-color: ${blueText};
  color: white;
`;

const ToolTipArrow = styled.div`
  position: absolute;
  ${({ appearBelow }) =>
    appearBelow
      ? `
      bottom: -0.5rem;
      border-bottom: 0.8rem solid ${blueText};
      `
      : `
      top: -2.8rem;
      border-top: 0.8rem solid ${blueText};
      `}
  border-left: 0.8rem solid transparent;
  border-right: 0.8rem solid transparent;
  left: 50%;
`;

const AdminToolTip = ({ text, hideToolTip, appearBelow, children }) => {
  return (
    <StyledDiv>
      {children}
      {!hideToolTip && (
        <ToolTipBody className="toolTipBody">
          <ToolTipText appearBelow={appearBelow}>{text}</ToolTipText>
          <ToolTipArrow appearBelow={appearBelow} />
        </ToolTipBody>
      )}
    </StyledDiv>
  );
};

AdminToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideToolTip: PropTypes.bool,
  appearBelow: PropTypes.bool
};

export default AdminToolTip;
