import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { blueText } from 'helpers/colors';
import { ReactComponent as ShapesIcon } from 'images/circle-and-square.svg';
import { ReactComponent as Close } from 'images/close.svg';

const ParentDiv = styled.div`
  position: absolute;
  transition: all 1s linear;
  opacity: ${props => (props.openState ? 1 : 0)};
  visibility: ${props => (props.openState ? 'visible' : 'hidden')};
  @media (min-width: 800px) {
    right: 2.75rem;
    top: 6.5rem;
  }
  @media (max-width: 799px) {
    top: 4rem;
    left: 0.5rem;
  }
`;

const ToolTipTopArrow = styled.div`
  position: absolute;
  bottom: 9rem;
  border-left: 0.8rem solid transparent;
  border-right: 0.8rem solid transparent;
  border-bottom: 0.8rem solid white;
  @media (min-width: 800px) {
    left: 25rem;
  }
  @media (max-width: 799px) {
    left: 1.25rem;
  }
`;

const ToolTipBody = styled.div`
  display: flex;
  margin-top: 0.5rem;
  width: 25rem;
  height: 5rem;
  background: #ffffff;
  border-radius: 0.8rem;
  box-shadow: 0rem 0.4rem 0.6rem rgba(0, 0, 0, 0.0976289);
  font-size: 1.4rem;
  padding: 2.5rem 3rem 1.5rem;
  font-weight: bold;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
`;

const Shapes = styled(ShapesIcon)`
  margin-right: 1rem;
  min-height: 2.9rem;
  min-width: 2.9rem;
  transform: rotate(-12.61deg);
`;

const FloatingTooltip = ({ helpText, closeSignUpPrompt, hasSignUpPromptClosed }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 2000);

    setTimeout(() => {
      setIsOpen(false);
    }, 14000);
  }, []);

  // hasSignUpPromptClosed is a variable in redux state to track if the user clicks 'x' to exit the prompt
  // isOpen is a local variable that is set whenever the prompt appears in or disappears from the view
  const openState = isOpen && !hasSignUpPromptClosed;

  return (
    <ParentDiv
      openState={openState}
      aria-hidden={openState ? 'false' : 'true'}
      role="dialog"
      aria-labelledby="helpText"
      aria-live={openState ? 'polite' : 'off'}
    >
      <ToolTipTopArrow />
      <ToolTipBody>
        <Shapes />
        <span id="helpText">{helpText}</span>
        <CloseButton aria-label="Click to close this dialog." onClick={closeSignUpPrompt}>
          <Close fill={blueText} />
        </CloseButton>
      </ToolTipBody>
    </ParentDiv>
  );
};

FloatingTooltip.propTypes = {
  helpText: PropTypes.string,
  hasSignUpPromptClosed: PropTypes.bool.isRequired,
  closeSignUpPrompt: PropTypes.func.isRequired
};
export default FloatingTooltip;
