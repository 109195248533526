import { connect } from 'react-redux';
import { currentViewActions } from 'store/reducers/currentViews';
import App from 'components/pages/App';
const { updateView } = currentViewActions;

const mapStateToProps = ({ site }) => ({
  nation: site.data.nation
});

export default connect(mapStateToProps, {
  updateView
})(App);
