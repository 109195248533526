import React from 'react';
import styled from 'styled-components';

import BackgroundImage from 'containers/BackgroundImage';
import Card from 'components/atoms/Card';
import Link from 'components/atoms/Link';
import SignupForm from 'containers/SignupForm';

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 799px) {
    align-items: flex-start;
  }
`;

const FormCard = styled(Card)`
  width: 27rem;
  padding: 2.7rem 2.5rem 3.5rem 2.5rem;

  @media (max-width: 799px) {
    margin: 1.5rem 1rem 1rem 1rem;
  }
`;

const Header = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const Subheader = styled.span`
  font-size: 1.4rem;
  margin-bottom: 2.6rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.4rem;
`;

const SignUp = () => {
  return (
    <BackgroundImage hideImageOnMobile>
      <ContentWrapper>
        <FormCard>
          <Header>Sign Up</Header>
          <Subheader>
            Already have an account?{' '}
            <StyledLink to={`/login${window.location.search}`}>Login</StyledLink>
          </Subheader>
          <SignupForm />
        </FormCard>
      </ContentWrapper>
    </BackgroundImage>
  );
};

export default SignUp;
