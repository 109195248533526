import React from 'react';
import styled from 'styled-components';

import SvgIcon from 'components/atoms/SvgIcon';
import JobTitleWrapper from 'components/molecules/JobTitleWrapper';
import TitledCard from 'components/organisms/TitledCard';
import { ReactComponent as JobPostingsIcon } from 'images/job-postings.svg';

const Icon = styled(SvgIcon)`
  width: 1.5rem;
  margin-right: 1rem;
  height: auto;
  fill: black;
`;

const SkillsByJob = () => {
  return (
    <TitledCard
      helpText="Select skills by entering a past or current job title."
      icon={<Icon Svg={JobPostingsIcon} />}
      title="Skills By Job Title"
    >
      <JobTitleWrapper />
    </TitledCard>
  );
};

export default SkillsByJob;
