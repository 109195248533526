import camelCase from 'camelcase-keys-deep';
import fetchWithRetry from './helpers/fetchWithRetry';
import getModelVersion from 'helpers/getModelVersion';

/** ******************* Clusters-Meta API ************************/

export const getClustersMeta = async clusterIds => {
  const clusterParam = clusterIds && clusterIds.length ? `&clusterIds=${clusterIds}` : '';
  const response = await fetchWithRetry(
    `/api/emsi-services/clusters-meta/clusters?modelVersion=${getModelVersion()}${clusterParam}`
  );
  if (!response.ok) {
    throw new Error('Error retrieving clusters from clusters-meta API.');
  }
  const json = await response.json();
  return camelCase(json.data).map(cluster => cluster.attributes);
};

export const getClusterNames = async subgroup => {
  const response = await fetchWithRetry(
    `/api/emsi-services/clusters-meta/clusters?modelVersion=${getModelVersion()}&subgroupName=${subgroup}`
  );
  const json = await response.json();
  return json.data;
};

export const getCareerAreaNames = async () => {
  const response = await fetchWithRetry(
    `/api/emsi-services/clusters-meta/career-area-names?modelVersion=${getModelVersion()}`
  );
  const json = await response.json();
  return json.data;
};

export const getSubgroupNames = async careerArea => {
  const response = await fetchWithRetry(
    `/api/emsi-services/clusters-meta/subgroup-names?modelVersion=${getModelVersion()}&careerAreaName=${careerArea}`
  );
  const json = await response.json();
  return json.data;
};

/**
 * Call the clusters-meta/degrees endpoint with a set of degrees to be added as a query param.
 * @param {Array} degreeIds An array of strings that are each degreeIds (e.g. `"16|P5108"`)to be
 *                          sent to the API.
 * @returns {Array} An array of objects resulting from the call to the clusters-meta API.
 */
export const getClustersByEducation = async degreeIds => {
  const response = await fetchWithRetry(
    `/api/emsi-services/clusters-meta/degrees?degreeIds=${degreeIds
      .map(id => encodeURIComponent(id))
      .join(',')}`
  );
  if (!response.ok) {
    throw new Error(
      `Error retrieving clusters by education with the following degrees: ${degreeIds.join(',')}.`
    );
  }
  const { data } = await response.json();
  const clusters = camelCase(data).map(degree => degree.attributes.clusters);
  return clusters.reduce((acc, val) => acc.concat(val), []);
};
