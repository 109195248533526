import { connect } from 'react-redux';

import SkillSelectionModal from 'components/molecules/SkillSelectionModal';
import { profileActions } from 'store/reducers/profile';
import withKeen from 'hocs/withKeen';

const { addSelectedSkill, removeSelectedSkill } = profileActions;

const mapDispatchToProps = (dispatch, { trackEvent, skill }) => ({
  addSelectedSkill: payload => {
    trackEvent('Skill Added', {
      skillId: payload.skillId,
      skillName: skill.name,
      selectionType: payload.selectionType
    });
    dispatch(addSelectedSkill(payload));
  },
  removeSelectedSkill: payload => {
    trackEvent('Skill Removed', { skillId: payload.skillId, skillName: skill.name });
    dispatch(removeSelectedSkill(payload));
  }
});

export default withKeen(connect(null, mapDispatchToProps)(SkillSelectionModal));
