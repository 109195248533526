import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { blueText } from 'helpers/colors';
import { ReactComponent as CloseIcon } from 'images/close.svg';

const Button = styled.button`
  position: absolute;
  z-index: 2;
  right: 1.5rem;
  top: 2.4rem;
  width: 2rem;
  border: none;
  background: none;

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled(CloseIcon)`
  position: relative;
  right: 0.155rem;
  top: 0.125rem;
  width: 1rem;
  height: 1rem;
  fill: ${blueText};
`;

const CloseButton = ({ onClick, ariaLabel, className }) => (
  <Button onClick={onClick} aria-label={ariaLabel} className={className}>
    <Icon />
  </Button>
);

CloseButton.propTypes = {
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default CloseButton;
