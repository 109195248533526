import { connect } from 'react-redux';

import Results from 'components/pages/Results';

import { currentViewActions } from 'store/reducers/currentViews';
import { profileActions } from 'store/reducers/profile';

const { updateView } = currentViewActions;
const { closeSignUpPrompt } = profileActions;

const mapStateToProps = ({
  profile: {
    selectedSkills,
    goal,
    isLoading,
    hasSignUpPromptClosed,
    isLoggedIn,
    subdomain,
    activeViewId
  },
  currentViews,
  validSkills,
  site: {
    data: {
      defaultCity,
      defaultEduTab,
      hideEdType,
      skillabiSiteNames,
      groupTypeClasses,
      plcMenuLabel,
      plcTitle,
      plcDescription,
      plcLinks,
      jobPostingOrgIds
    }
  }
}) => ({
  currentViews,
  profile: {
    selectedSkills,
    selectedGoal: goal,
    isProfileLoading: isLoading,
    hasSignUpPromptClosed,
    isLoggedIn,
    subdomain,
    activeViewId
  },
  validSkills: validSkills.data,
  site: {
    defaultCity,
    defaultEduType: defaultEduTab,
    hideEdType,
    institutions: skillabiSiteNames,
    groupTypeClasses,
    plcMenuLabel,
    plcTitle,
    plcDescription,
    plcLinks,
    jobPostingOrgIds
  }
});

export default connect(mapStateToProps, {
  updateView,
  closeSignUpPrompt
})(Results);
