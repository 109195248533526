import React from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'services/keen';

const mapStateToProps = ({ profile }) => ({
  profileId: profile.profileId
});

const withKeen = WrappedComponent =>
  connect(mapStateToProps)(({ profileId, ...props }) => (
    <WrappedComponent
      {...props}
      trackEvent={(eventName, eventBody) => trackEvent(eventName, { profileId, ...eventBody })}
    />
  ));

export default withKeen;
