import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProgressBar from 'components/atoms/ProgressBar';
import Strong from 'components/atoms/Strong';
import withSelectedSkills from 'hocs/withSelectedSkills';
import withValidSkills from 'containers/withValidSkills';
import { blue } from 'helpers/colors';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 1rem 2rem;
  @media screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const Header = styled.h2`
  font-size: 1.6rem;
  margin-right: 2rem;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 800px) {
    width: auto;
    text-align: left;
  }
`;

const Progress = styled.div`
  font-size: 1.4rem;
  text-align: center;
  @media screen and (min-width: 800px) {
    margin-top: 1rem;
    flex-grow: 1;
    text-align: right;
  }
  @media (min-width: 800px) and (max-width: 1023px) {
    max-width: 60%;
  }
`;

const Blue = styled.span`
  color: ${blue};
`;

const SkillsProgress = ({ selectedSkills, validSkills }) => {
  const numberSelected = Object.keys(selectedSkills).filter(skillId => validSkills[skillId]).length;
  let note;
  if (numberSelected < 12) {
    note = '';
  } else if (numberSelected < 24) {
    note = ': Good';
  } else if (numberSelected < 36) {
    note = ': Very Good';
  } else if (numberSelected < 48) {
    note = ': Excellent';
  } else {
    note = ': Rockstar';
  }

  return (
    <Wrapper>
      <Header>Selected Skills</Header>
      <Progress>
        <ProgressBar numberSelected={numberSelected} />
        <Strong>
          <Blue>
            {numberSelected} Skill{numberSelected !== 1 && 's'}
            {numberSelected === 0 && ' Selected'}
            {note}
          </Blue>
        </Strong>
      </Progress>
    </Wrapper>
  );
};

SkillsProgress.propTypes = {
  selectedSkills: PropTypes.object.isRequired,
  validSkills: PropTypes.object.isRequired
};

export default withValidSkills(withSelectedSkills(SkillsProgress));
