import { updateProfile } from 'services/profiles';
import { nonMetaProfileSelector } from '../reducers/profile';
import { flashError, flashSuccess } from 'helpers/flashMessage';
import isEqual from 'lodash.isequal';

let patchables = {};
let shouldShowAnErrorIfRequestFails = true;
export const updateProfileMiddleware = store => next => action => {
  const oldState = store.getState();
  const result = next(action);
  const state = store.getState();
  const isLogin = !oldState.profile.profileId && state.profile.profileId;
  let merged;
  if (
    /**
     * We don't need to deep compare the profile and oldProfile because
     * we completely swap out profile for any changes in the reducer.
     * A change to the profile will always result in a change in memory location
     * which is what we are checking in the first conditional.
     *
     * Then we check to make sure that there was a previous subdomain and profileId
     * in the profile reducer to prevent a rogue update request when the profile is initially fetched
     * and the profile reducer changes.
     */
    oldState.profile !== state.profile &&
    oldState.profile.subdomain &&
    oldState.profile.profileId &&
    state.profile.profileId
  ) {
    Object.keys(nonMetaProfileSelector(state)).forEach(key => {
      if (oldState.profile[key] !== state.profile[key]) {
        patchables[key] = state.profile[key];
      }
    });
  } else if (isLogin) {
    /**
     * Check to see if there are any disparities between the parts of
     * the fetched profile and the state profile that were merged. If there are differences,
     * an update is triggered.
     */
    if (!isEqual(state.profile.education, action.payload.education)) {
      patchables.education = state.profile.education;
      merged = true;
    }

    if (!isEqual(state.profile.selectedSkills, action.payload.selectedSkills)) {
      patchables.selectedSkills = state.profile.selectedSkills;
      merged = true;
    }

    if (!isEqual(state.profile.visitedSkillsPages, action.payload.visitedSkillsPages)) {
      patchables.visitedSkillsPages = state.profile.visitedSkillsPages;
      merged = true;
    }

    if (state.profile.goal !== action.payload.goal) {
      patchables.goal = state.profile.goal;
      merged = true;
    }
  }

  if (Object.keys(patchables).length) {
    updateProfile(state.profile.subdomain, state.profile.profileId, patchables)
      .then(() => {
        patchables = {};
        if (merged) {
          flashSuccess('The changes you made while logged out have been added to your profile.');
        }
      })
      .catch(() =>
        // If the request fails the first time, we try to resend it. This way, if the API had a random failing we will still catch their changes
        updateProfile(state.profile.subdomain, state.profile.profileId, patchables)
          .then(() => {
            patchables = {};
            if (merged) {
              flashSuccess(
                'The changes you made while logged out have been added to your profile.'
              );
            }
          })
          .catch(() => {
            // if our request fails a second time we send them this message but only once per session.
            if (shouldShowAnErrorIfRequestFails) {
              flashError(
                'We are having trouble updating your profile. You may continue using SkillsMatch, but may experience data loss.'
              );
              shouldShowAnErrorIfRequestFails = false;
            }
          })
      );
  }
  return result;
};
