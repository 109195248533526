import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  signUpCognito,
  loginCognito,
  confirmSignUpCognito,
  resendVerificationCognito,
  logoutCognito,
  deleteCognitoUser
} from 'services/auth';
import {
  createProfile as createProfileAPI,
  getProfile as fetchProfileAPI,
  deleteProfile as deleteProfileAPI,
  updateProfile as updateProfileAPI
} from 'services/profiles';
import { updateUser as updateCognitoUser } from 'services/cognitoUser';
import {
  SIGNUP,
  VALIDATE_PROFILE,
  PROFILE_FETCH,
  PROFILE_CREATE,
  PROFILE_DELETE,
  LOGIN,
  LOGOUT,
  INITIATE_FORGOT_PASSWORD,
  SUBMIT_FORGOT_PASSWORD,
  CHANGE_EMAIL,
  profileActions,
  nonMetaProfileSelector,
  emailAndPasswordSelector
} from 'store/reducers/profile';
import { forgotCognitoPasswordRequest, forgotCognitoPasswordSubmit } from 'services/auth';
import { flashSuccess, flashError } from 'helpers/flashMessage';
import isTrackingEnabled from 'helpers/isTrackingEnabled';
import queryString from 'query-string';

export function* signUpSaga(action) {
  const { password, confirmPassword, ...rest } = action.payload;
  const params = queryString.parse(window.location.search);
  const { uid, audience } = params;

  try {
    const subdomain = yield select(state => state.site.data.subdomain);
    const user = yield call(signUpCognito, rest.email, password, subdomain);
    yield put(profileActions.signUp.success());
    const nonMetaProfileData = yield select(nonMetaProfileSelector);

    yield put(
      profileActions.createProfile.request({
        ...rest,
        ...nonMetaProfileData,
        profileId: user.userSub,
        subdomain
      })
    );

    if (uid) {
      yield call(updateProfileAPI, subdomain, user.userSub, {
        uids: [uid]
      });
    }

    if (audience) {
      yield call(updateProfileAPI, subdomain, user.userSub, {
        audiences: [audience]
      });
    }
  } catch (e) {
    if (e.message.includes('regular expression')) {
      yield put(profileActions.signUp.failure({ description: 'Password must not contain spaces' }));
    } else {
      yield put(profileActions.signUp.failure({ description: e.message }));
    }
  }
}

export function* loginSaga(action) {
  const { email, password } = action.payload;
  const subdomain = yield select(state => state.site.data.subdomain);
  try {
    const user = yield call(loginCognito, email, password);
    const { sub, 'custom:subdomain': userSubdomain, ...rest } = user.attributes;

    if (subdomain === userSubdomain || userSubdomain === 'universal') {
      yield put(
        profileActions.fetchProfile.request({
          profileId: sub,
          subdomain: userSubdomain,
          ...rest
        })
      );
    } else {
      yield put(
        profileActions.login.failure({
          description: `Your email is already registered on https://${userSubdomain}.emsiskills.com`
        })
      );
      yield put(profileActions.logout());
    }
  } catch (e) {
    // This error is triggered when either the profile was imported into cognito or it was manually set to require a password reset
    if (e.code === 'PasswordResetRequiredException') {
      yield put(profileActions.initiateForgotPassword.request(email));
    }
    if (e.code === 'UserNotConfirmedException') {
      yield put(profileActions.forceVerification());
      yield call(resendVerificationCognito, email);
    } else {
      yield put(profileActions.login.failure({ description: e.message }));
    }
  }
}

export function* verifyUserSaga(action) {
  const { code } = action.payload;

  try {
    const { email, tempPassword } = yield select(emailAndPasswordSelector);
    yield call(confirmSignUpCognito, email, code);
    yield put(profileActions.validateProfile.success());
    if (tempPassword) {
      // If verify occurred during login/signup flow
      yield put(
        profileActions.login.request({
          email,
          password: tempPassword
        })
      );
    } else {
      // If verify occurred during forgot password flow
      yield put(profileActions.initiateForgotPassword.request(email));
    }
  } catch (e) {
    if (e.message.includes('regular expression')) {
      yield put(
        profileActions.validateProfile.failure({
          description: 'Verification code must not contain spaces'
        })
      );
    } else if (e.code === 'CodeMismatchException') {
      yield put(profileActions.validateProfile.failure({ description: e.message }));
    } else {
      yield put(profileActions.login.failure({ description: e.message }));
    }
  }
}

export function* fetchProfileSaga(action) {
  const { profileId, subdomain } = action.payload;
  try {
    const profile = yield call(fetchProfileAPI, subdomain, profileId);
    yield put(profileActions.fetchProfile.success(profile.data.attributes));

    let { userRole, createdAt, uids, audiences } = profile.data.attributes;
    if (isTrackingEnabled()) {
      pendo.updateOptions({
        visitor: {
          profileId,
          role: userRole,
          creationDate: (new Date(createdAt).getTime() / 1000).toFixed()
        }
      });
    }

    const params = queryString.parse(window.location.search);
    const { uid, audience } = params;
    if (uid) {
      if (uids && !uids.includes(uid)) {
        uids.push(uid);
      } else if (!uids) {
        uids = [uid];
      }
    }
    if (audience) {
      if (audiences && !audiences.includes(audience)) {
        audiences.push(audience);
      } else if (!audiences) {
        audiences = [audience];
      }
    }

    // These attributes will only be updated in Profiles API, but not in redux state
    // (they are only used for profile meta analytics, not in the SM app. A refresh will sync)
    yield call(updateProfileAPI, subdomain, profileId, {
      lastLogin: new Date().toISOString(),
      cognitoStatus: 'CONFIRMED',
      uids,
      audiences
    });
  } catch (e) {
    yield put(profileActions.logout());
    yield put(profileActions.fetchProfile.failure({ description: e.message }));
  }
}

export function* createProfileSaga(action) {
  const { subdomain, profileId, ...rest } = action.payload;
  try {
    yield call(createProfileAPI, subdomain, profileId, rest);
    yield put(profileActions.createProfile.success());
  } catch (e) {
    yield put(profileActions.createProfile.failure({ description: e.message }));
  }
}

export function* deleteProfileSaga() {
  const { profileId, subdomain } = yield select(state => state.profile);

  try {
    yield call(deleteCognitoUser);
    yield put(profileActions.deleteProfile.success());
    yield put(profileActions.logout());
    yield call(deleteProfileAPI, { subdomain, profileId });
  } catch (e) {
    yield put(profileActions.deleteProfile.failure(e));
    flashError('We are having trouble deleting your profile. Please try again later');
  }
}

export function* sendForgotPasswordEmail(action) {
  const email = action.payload;
  try {
    yield call(forgotCognitoPasswordRequest, email);
    yield put(profileActions.initiateForgotPassword.success());
  } catch (e) {
    let { message, code } = e;
    if (code === 'InvalidParameterException') {
      // This occurs when user still hasn't verified after signing up
      yield put(profileActions.forceVerification());
      yield call(resendVerificationCognito, email);
    } else if (code === 'UserNotFoundException') {
      yield put(
        profileActions.initiateForgotPassword.failure(
          'Could not find user associated with that email address.'
        )
      );
    } else {
      yield put(profileActions.initiateForgotPassword.failure(message));
    }
  }
}

export function* submitCognitoPassword(action) {
  const { email } = yield select(state => state.profile);
  const { verificationCode, newPassword } = action.payload;

  try {
    yield call(forgotCognitoPasswordSubmit, { email, verificationCode, newPassword });
    yield put(profileActions.submitForgotPassword.success());
    yield put(
      profileActions.login.request({
        email,
        password: newPassword
      })
    );
  } catch (e) {
    yield put(profileActions.submitForgotPassword.failure(e.message));
  }
}

export function* changeEmailSaga(action) {
  const { profileId, subdomain } = yield select(state => state.profile);

  try {
    yield call(updateCognitoUser, {
      userId: profileId,
      newEmail: action.payload
    });
    yield call(updateProfileAPI, subdomain, profileId, {
      email: action.payload
    });
    yield put(profileActions.changeEmail.success(action.payload));
    flashSuccess('Email Changed!');
  } catch (e) {
    yield put(profileActions.changeEmail.failure());
    flashError('There was a problem changing your email. Please try again with a different email.');
  }
}

export default function* watch() {
  yield takeLatest(SIGNUP.REQUEST, signUpSaga);
  yield takeLatest(LOGIN.REQUEST, loginSaga);
  yield takeLatest(LOGOUT, logoutCognito);
  yield takeLatest(VALIDATE_PROFILE.REQUEST, verifyUserSaga);
  yield takeLatest(PROFILE_FETCH.REQUEST, fetchProfileSaga);
  yield takeLatest(PROFILE_CREATE.REQUEST, createProfileSaga);
  yield takeLatest(PROFILE_DELETE.REQUEST, deleteProfileSaga);
  yield takeLatest(INITIATE_FORGOT_PASSWORD.REQUEST, sendForgotPasswordEmail);
  yield takeLatest(SUBMIT_FORGOT_PASSWORD.REQUEST, submitCognitoPassword);
  yield takeLatest(CHANGE_EMAIL.REQUEST, changeEmailSaga);
}
