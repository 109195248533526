import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HelpSVG from 'images/help.svg';

const HelpIcon = styled.img`
  width: 1.6rem;
  height: 2.1rem;
  @media screen and (max-width: 799px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  /* align icon to the center vertically */
  display: flex;
  /* background is important for non-chrome browsers */
  background: none repeat scroll 0 0 transparent;
  border: none;
  border-spacing: 0;
  margin: 0 1rem;
  padding: 0;
  cursor: help;
  @media screen and (max-width: 799px) {
    display: none;
  }
`;

const ParentDiv = styled.div`
  position: relative;
  bottom: 1rem;
`;

const ToolTipBody = styled.div`
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.0976289);
  bottom: 1.5rem;
  font-size: 1.4rem;
  left: -18rem;
  line-height: 1.5;
  padding: 1.5rem 2rem;
  position: absolute;
  text-align: center;
  width: 30rem;
  z-index: 6;
`;

const ToolTipDownArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #ffffff;
  z-index: 7;
  left: -2.6rem;
  bottom: 0.75rem;
`;

const HelpIconTooltip = ({ helpText }) => {
  const [showTip, setShowTip] = useState(false);

  return (
    <>
      <StyledButton
        onClick={() => setShowTip(!showTip)}
        onBlur={() => setShowTip(false)}
        onMouseOut={() => setShowTip(false)}
        aria-label="Click to expand tooltip."
        aria-expanded={showTip}
      >
        <HelpIcon src={HelpSVG} alt="help" />
      </StyledButton>
      {showTip && (
        <ParentDiv>
          <ToolTipBody role="tooltip" aria-live="assertive">
            {helpText}
          </ToolTipBody>
          <ToolTipDownArrow />
        </ParentDiv>
      )}
    </>
  );
};

HelpIconTooltip.propTypes = {
  helpText: PropTypes.string.isRequired
};

export default HelpIconTooltip;
