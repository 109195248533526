import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { blue } from 'helpers/colors';

const StyledButton = styled.button`
  height: 28rem;
  width: 25rem;
  margin: 2rem;
  padding: 0;
  border: none;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 0.8rem 1.6rem rgba(82, 97, 115, 0.08);
  background: white;
  color:${blue}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImg = styled.img`
  // there is a white rim on the right side of the smartphone photo. 102% width can get rid of it
  width: 102%;
`;

const SubTextDiv = styled.div`
  flex-grow: 1;
  padding: 0 4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;

  // align text vertically in the middle of white space
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SquareImgButton = ({ children, imgSrc, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <StyledImg src={imgSrc} alt="" />
      <SubTextDiv>
        <span>{children}</span>
      </SubTextDiv>
    </StyledButton>
  );
};

SquareImgButton.propTypes = {
  children: PropTypes.any.isRequired,
  imgSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default SquareImgButton;
