import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { blue } from 'helpers/colors';

const StyledToggle = styled(({ solidBackground, ...props }) => <Toggle {...props} />)`
  ${({ scale }) => (scale ? ` transform: scale(${scale});` : '')}

  ${({ solidBackground }) => `
    > .react-toggle-track {
      background-color: ${solidBackground ? blue : '#CCC'}
    }
    &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: ${solidBackground ? blue : '#CCC'}
  }`}
  &.react-toggle--checked .react-toggle-track {
    background-color: ${blue};
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${blue};
  }
  > .react-toggle-thumb,
  .react-toggle--checked > .react-toggle-thumb {
    border-color: transparent;
  }
  > .react-toggle-screenreader-only {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 6px 6px #0099e0;
    box-shadow: 0px 0px 6px 6px -webkit-focus-ring-color;
  }
`;

class ToggleSwitch extends React.Component {
  state = {
    isFocused: false
  };
  render() {
    const { isOn, onChange, solidBackground, ariaLabel, disabled, scale } = this.props;
    return (
      <StyledToggle
        solidBackground={solidBackground}
        icons={false}
        checked={isOn}
        aria-label={ariaLabel}
        disabled={disabled}
        onChange={onChange}
        scale={scale}
      />
    );
  }
}

ToggleSwitch.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isOn: PropTypes.bool.isRequired,
  solidBackground: PropTypes.bool,
  disabled: PropTypes.bool,
  scale: PropTypes.number
};

export default ToggleSwitch;
