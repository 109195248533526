import { connect } from 'react-redux';
import SignupForm from 'components/molecules/SignupForm';
import { profileActions } from 'store/reducers/profile';

const mapStateToProps = ({ profile }) => ({
  error: profile.error,
  isLoading: profile.isLoading
});

export default connect(mapStateToProps, {
  signUp: profileActions.signUp.request,
  clearProfileError: profileActions.clearProfileError
})(SignupForm);
