import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Amplify from '@aws-amplify/core';
import cognitoAuth from '@aws-amplify/auth';

import ExcelSiteWorkbook from 'Admin/components/ExcelSiteWorkbook';
import Card from 'components/atoms/Card';

import loading from 'images/loading.gif';
import { flashError } from 'helpers/flashMessage';
import { getAllSkills } from 'services/skillsClassification';
import { getAllProfiles } from 'services/profiles';
import getSubdomain from 'helpers/getSubdomain';

import config from 'config';

Amplify.configure({
  Auth: config.awsCognitoAuth
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainSection = styled(Card)`
  padding: 2rem;
  width: 30rem;
  align-items: center;
  height: 30%;
`;

const LoadingIcon = styled.img`
  width: 4rem;
`;

const CardBody = styled.div`
  padding-top: 3rem;
  text-align: center;
`;

const NoProfilesMessage = styled.span`
  font-size: 1.4rem;
`;

const AdminProfiles = ({ history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [subdomain, setSubdomain] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [skills, setSkills] = useState(null);

  useEffect(() => {
    (async () => {
      const authenticatedUser = await cognitoAuth.currentUserInfo();
      if (!authenticatedUser) {
        history.push('/login?redirect=Admin/profiles');
      } else if (
        !['Admin', 'View Everything', 'View Profiles'].includes(
          authenticatedUser.attributes['custom:userRole']
        )
      ) {
        history.push('/');
        flashError('Sorry, you must be a site admin to access this page');
      }
      setIsLoading(false);
    })();
  }, [history]);

  useEffect(() => {
    let sub = getSubdomain();
    const fetchAll = async () => {
      let profilesResponse = await getAllProfiles(sub);
      setSubdomain(sub);
      setProfiles(profilesResponse.data);
      await getAllSkills().then(skillz => setSkills(skillz));
    };
    fetchAll();
  }, []);

  if (!isLoading) {
    if (!profiles || !skills || !subdomain) {
      return (
        <Wrapper>
          <MainSection>
            <h1>Preparing Your Profiles</h1>
            <CardBody>
              <LoadingIcon src={loading} alt="" />
            </CardBody>
          </MainSection>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <MainSection>
            <h1>{profiles.length === 0 ? 'No Profiles Found' : 'Profiles Ready for Export'}</h1>
            <CardBody>
              {profiles.length === 0 ? (
                <NoProfilesMessage>
                  This site has no profiles. Select a different site.
                </NoProfilesMessage>
              ) : (
                <ExcelSiteWorkbook profiles={profiles} skills={skills} subdomain={subdomain} />
              )}
            </CardBody>
          </MainSection>
        </Wrapper>
      );
    }
  } else {
    return null;
  }
};

export default AdminProfiles;
