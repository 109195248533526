import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import uuid from 'uuid';
import 'index.css';
import App from 'containers/App';
import * as Sentry from '@sentry/react';

import configureStore from 'store/configureStore';

if (process.env.REACT_APP_SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV
  });
}

const store = configureStore();

const currentDate = Date.now();
// If the visitId was created more than an hour ago ago (the value held in "expiration") then it's expired and we need to create a new visitId.
// Since Date uses milliseconds, 60 * 60 * 1000 gives us the number of milliseconds in an hour.
const expiration = currentDate - 60 * 60 * 1000;

const getVisitorId = () => {
  if (navigator.cookieEnabled) {
    let visitorId = localStorage.getItem('visitorId');
    if (!visitorId) {
      visitorId = uuid.v4();
      localStorage.setItem('visitorId', visitorId);
      localStorage.setItem('visitorIdCreated', currentDate);
    }
    return visitorId;
  }
};

if (navigator.cookieEnabled) {
  // set visitorId during initial load
  getVisitorId();

  const visitId = localStorage.getItem('visitId');
  const visitIdCreated = localStorage.getItem('visitIdCreated');
  if (!visitId || visitIdCreated < expiration) {
    localStorage.setItem('visitId', uuid.v4());
    localStorage.setItem('visitIdCreated', currentDate);
  }
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

export { getVisitorId };
