import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import ReduxToastr from 'react-redux-toastr';
import queryString from 'query-string';
import { OptimizelyProvider, setLogLevel } from '@optimizely/react-sdk';
import { optimizelyInstance } from 'helpers/optimizely';
import { getVisitorId } from 'index';
import { createBrowserHistory } from 'history';

import SkipToContent from 'components/atoms/SkipToContent';
import ScrollToTop from 'components/molecules/ScrollToTop';

import Disclaimer from 'components/pages/Disclaimer';
import NotFound from 'components/pages/NotFound';
import SignUp from 'components/pages/SignUp';
import Login from 'components/pages/Login';
import EmailVerification from 'components/pages/EmailVerification';

import NavBar from 'components/organisms/NavBar';
import AdminAnalytics from 'containers/AdminAnalytics';
import AdminTeamSettings from 'containers/AdminTeamSettings';
import AdminProfiles from 'components/pages/AdminProfiles';
import Degrees from 'containers/Degrees';
import ForgotPassword from 'containers/ForgotPassword';
import GlobalFooter from 'containers/GlobalFooter';
import GlobalHeader from 'containers/GlobalHeader';
import Goals from 'containers/Goals';
import Intro from 'containers/Intro';
import LoadSite from 'containers/LoadSite';
import MatchCluster from 'containers/MatchCluster';
import ProgramCoursePage from 'containers/ProgramCoursePage';
import Results from 'containers/Results';
import Skills from 'containers/Skills';
import Settings from 'containers/Settings';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { trackEvent } from 'services/keen';
import { searchCityNames, searchCompaniesbyId } from 'services/jpa';

const Flex = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* for the dark overlay in MobileNav*/
  position: relative;
`;

const BodyWrap = styled.div`
  display: flex;
  flex-grow: 1;
  height: auto;
`;

const getValidNextPath = fullpath => {
  const validQueryParams = ['uid', 'audience'];
  if (typeof fullpath === 'object') {
    // Sometimes fullpath.search will not start with a '?', so conditionally add it
    if (fullpath.search && !fullpath.search.startsWith('?')) {
      fullpath.search = `?${fullpath.search}`;
    }
    fullpath = `${fullpath.pathname}${fullpath.search}`;
  }
  const oldParams = queryString.parse(window.location.search);
  const [path, newQuery] = fullpath.split('?');
  const newParams = queryString.parse(newQuery);
  for (let param of validQueryParams) {
    if (oldParams[param]) {
      newParams[param] = oldParams[param];
    }
  }
  return `${path}?${queryString.stringify(newParams)}`;
};

const history = createBrowserHistory();

const oldPush = history.push,
  oldReplace = history.replace;
history.push = (path, state) => {
  path = getValidNextPath(path);
  oldPush(path, state);
};
history.replace = (path, state) => {
  path = getValidNextPath(path);
  oldReplace(path, state);
};

// fire keen event during initial load
trackEvent('Page Views');
// fire subsequent keen events every time route changes
history.listen(() => {
  trackEvent('Page Views');
});

const formatTitle = string => {
  const defaultTitle = 'Lightcast SkillsMatch';
  const title = string
    .substring(string.lastIndexOf('/') + 1)
    .toLowerCase()
    .split('-')
    .map(word => {
      return word && word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
  const pageTitle = title ? `${defaultTitle} - ${title}` : defaultTitle;
  return pageTitle;
};

document.title = formatTitle(window.location.pathname);

history.listen(location => {
  document.title = formatTitle(location.pathname);
});

// set Optimizely to only log warnings and errors
setLogLevel('warn');

const App = ({ nation, updateView }) => {
  const mainContent = useRef(null);
  const locationHelper = locationHelperBuilder({});

  useEffect(() => {
    (async () => {
      const queries = queryString.parse(window.location.search);
      // When a query parameter is repeated, e.g. ?x=1&x=2, you get an array surprisingly. Then app breaks.
      if (queries.companies && typeof queries.companies === 'string') {
        const response = await searchCompaniesbyId(queries.companies.split(','));
        if (response.data && response.data.length) {
          let matchedCompanies = {};
          response.data.forEach(item => (matchedCompanies[item.id] = item.name));
          updateView('jobs', {
            filterCompanyIds: Object.keys(matchedCompanies),
            companyNames: matchedCompanies
          });
        }
      }
      if (queries.city && typeof queries.city === 'string') {
        const response = await searchCityNames(queries.city);
        if (response) {
          const matchedCity = response.find(
            item => item.name.toLowerCase() === queries.city.toLowerCase()
          );
          if (matchedCity) {
            updateView('jobs', { filterCities: [matchedCity.name] });
          }
        }
      }
    })();
  }, [updateView]);

  const notAuthenticated = connectedRouterRedirect({
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
    authenticatedSelector: state => !state.profile.isLoggedIn,
    allowRedirectBack: false
  });

  const needsVerify = connectedRouterRedirect({
    redirectPath: (state, ownProps) => {
      let redirectPath = 'login';
      let { search } = ownProps.location;
      if (search.includes('flow=forgot-password')) {
        search = search.replace('flow=forgot-password', 'just-verified=true');
        redirectPath = 'forgot-password';
      }
      return `/${redirectPath}${search}`;
    },
    authenticatedSelector: state => state.profile.needsVerification,
    allowRedirectBack: false
  });

  const needsNoVerify = connectedRouterRedirect({
    redirectPath: (state, ownProps) => {
      const { pathname, search } = ownProps.location;
      let flow = '';
      if (pathname === '/signup' || pathname === '/forgot-password') {
        flow = `${search ? '&' : '?'}flow=${pathname.slice(1)}`;
      }
      return `/verification${search}${flow}`;
    },
    authenticatedSelector: state => !state.profile.needsVerification,
    allowRedirectBack: false
  });

  const isAuthenticated = connectedRouterRedirect({
    redirectPath: '/login',
    authenticatedSelector: state => !!state.profile.isLoggedIn
  });

  return (
    <OptimizelyProvider optimizely={optimizelyInstance} user={{ id: getVisitorId() }}>
      <Flex>
        <Router history={history}>
          <LoadSite>
            <ReduxToastr
              timeOut={10000}
              closeOnToastrClick
              newestOnTop
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
            />
            <ScrollToTop />
            <SkipToContent afterAnimate={() => mainContent && mainContent.current.focus()} />
            <GlobalHeader />
            <NavBar />
            <BodyWrap id="main-content" tabIndex="-1" ref={mainContent}>
              <Switch>
                <Route path="/" exact component={Intro} />
                <Route path="/goals" exact component={Goals} />
                {// cannot use `window.nation` here, because site hasn't been loaded yet
                nation === 'US' && <Route path="/degrees" exact component={Degrees} />}
                <Route path="/admin/analytics" exact component={AdminAnalytics} />
                <Route path="/admin/team" exact component={AdminTeamSettings} />
                <Route path="/admin/profiles" exact component={AdminProfiles} />
                <Route path="/skills" component={Skills} />
                <Route path="/results/cluster/:clusterId" exact component={MatchCluster} />
                <Route path="/results/:site/group/:groupId" exact component={ProgramCoursePage} />
                <Route path="/results/:site/course/:courseId" exact component={ProgramCoursePage} />
                <Route path="/results" component={Results} />
                <Route path="/account/settings" exact component={isAuthenticated(Settings)} />
                <Route path="/signup" exact component={notAuthenticated(needsNoVerify(SignUp))} />
                <Route path="/login" exact component={notAuthenticated(needsNoVerify(Login))} />
                <Route
                  path="/verification"
                  exact
                  component={notAuthenticated(needsVerify(EmailVerification))}
                />
                <Route
                  path="/forgot-password"
                  exact
                  component={notAuthenticated(needsNoVerify(ForgotPassword))}
                />
                <Route path="/disclaimer" exact component={Disclaimer} />
                <Route component={NotFound} />
              </Switch>
            </BodyWrap>
            <GlobalFooter />
          </LoadSite>
        </Router>
      </Flex>
    </OptimizelyProvider>
  );
};

App.propTypes = {
  nation: PropTypes.string,
  updateView: PropTypes.func.isRequired
};

export default App;
