import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingButton from 'components/molecules/LoadingButton';
import { ReactComponent as RefreshSvg } from 'images/refresh.svg';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;

  * {
    font-weight: 400;
  }
`;

const RefreshIcon = styled(RefreshSvg)`
  fill: white;
  height: auto;
  margin-right: 0.7rem;
  margin-bottom: -0.3rem;
  width: 1.4rem;
`;

const LoadMoreButton = ({ isLoading, endOfList, loadMore }) => (
  <ButtonWrapper>
    <LoadingButton onClick={loadMore} isLoading={isLoading} disabled={endOfList}>
      {endOfList ? (
        <>You&apos;ve reached the end of the list</>
      ) : (
        <>
          <RefreshIcon />
          Load More Results
        </>
      )}
    </LoadingButton>
  </ButtonWrapper>
);

LoadMoreButton.propTypes = {
  isLoading: PropTypes.bool,
  endOfList: PropTypes.bool,
  loadMore: PropTypes.func.isRequired
};

export default LoadMoreButton;
