import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { blueText, getBestContrast } from 'helpers/colors.js';

const Wrapper = styled.div`
  background: ${props => props.menuBackgroundColor || blueText};
  box-sizing: border-box;
  color: ${props => props.menuTextColor || 'white'};
  display: flex;
  flex-direction: column;
  min-height: 7rem;
  padding-left: 1.5rem;
  width: 100%;

  @media (min-width: 1024px) {
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 2.5rem;
  }
`;

const HeaderText = styled.h1`
  color: ${({ menuTextColor, menuBackgroundColor }) =>
    getBestContrast(menuBackgroundColor, [menuTextColor, '#fff', '#000'])};
  font-size: 2.8rem;
  font-weight: normal;

  @media (min-width: 1024px) {
    margin: 2.3rem 1.7rem 1.5rem 0;
    min-width: 27.5rem;
    width: calc(20% - 2.5rem);
  }
`;

const LinkBar = styled.nav`
  height: 2.75rem;
  display: flex;
`;

const LinkBox = styled.div`
  min-width: 10rem;
  text-align: center;
  margin-right: 2rem;
  display: flex;
  align-items: flex-bottom; // Prevents a tiny bottom gap on mobile
`;

const Link = styled(({ menuBackgroundColor, menuTextColor, ...props }) => <NavLink {...props} />)`
  padding-bottom: 0.3rem;
  color: ${({ menuTextColor, menuBackgroundColor }) =>
    getBestContrast(menuBackgroundColor, [menuTextColor, '#fff', '#000'])};
  min-width: 10rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;

  :hover,
  &.active {
    border-bottom: 3px solid
      ${({ menuBackgroundColor }) => getBestContrast(menuBackgroundColor, ['#fff', '#000'])};
    font-weight: 800;
    margin-bottom: 0.1rem;
`;

const ResultsHeader = ({ menuBackgroundColor, menuTextColor, routes }) => {
  return (
    <Wrapper menuBackgroundColor={menuBackgroundColor} menuTextColor={menuTextColor}>
      <HeaderText menuBackgroundColor={menuBackgroundColor} menuTextColor={menuTextColor}>
        My Matches
      </HeaderText>
      <LinkBar>
        {routes.map(route => (
          <LinkBox key={route.text}>
            <Link
              to={route.path}
              menuBackgroundColor={menuBackgroundColor}
              menuTextColor={menuTextColor}
            >
              {route.text}
            </Link>
          </LinkBox>
        ))}
      </LinkBar>
    </Wrapper>
  );
};

ResultsHeader.propTypes = {
  menuBackgroundColor: PropTypes.string,
  menuTextColor: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ResultsHeader;
