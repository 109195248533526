import React from 'react';
import styled from 'styled-components';

import SvgIcon from 'components/atoms/SvgIcon';
import AriaAlert from 'components/atoms/AriaAlert';
import Select from 'components/molecules/Select';
import SkillsChooser from 'components/molecules/SkillsChooser';
import TitledCard from 'components/organisms/TitledCard';

import { ReactComponent as CategoryIcon } from 'images/checkmark-in-box.svg';
import { getSkillsByCluster } from '../../../services/skillsClusters';
import {
  getCareerAreaNames,
  getClusterNames,
  getSubgroupNames
} from '../../../services/clustersMeta';
import { red, blueText } from 'helpers/colors';
import sortItemsInArrays from '../../../helpers/sortItemsInArrays';

const Icon = styled(SvgIcon)`
  width: 1.45rem;
  margin-right: 1rem;
  fill: ${blueText};
`;

const Selects = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 1rem;

  @media (min-width: 800px) {
    width: 49.5%;
  }
`;

const ErrorMessage = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${red};
`;

class SkillsByCategory extends React.Component {
  state = {
    careerAreas: [],
    subgroups: [],
    skills: [],
    selectedCareerArea: '',
    selectedSubgroup: '',
    areCareerAreasLoading: false,
    areSubgroupsLoading: false,
    areSkillsLoading: false,
    errorMessage: ''
  };

  componentDidMount() {
    this.getCareerAreas();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCareerArea, selectedSubgroup } = this.state;
    if (selectedCareerArea && selectedCareerArea !== prevState.selectedCareerArea) {
      this.getSubgroups(selectedCareerArea);
    }

    if (selectedSubgroup && selectedSubgroup !== prevState.selectedSubgroup) {
      this.getSkills(selectedSubgroup);
    }
  }

  async getCareerAreas() {
    this.setState({ areCareerAreasLoading: true });
    const unsortedCareerAreas = await getCareerAreaNames();
    if (unsortedCareerAreas) {
      const careerAreas = unsortedCareerAreas.sort();
      this.setState({ careerAreas, areCareerAreasLoading: false });
    } else {
      this.setState({ errorMessage: 'Error retrieving categories', areCareerAreasLoading: false });
    }
  }

  async getSubgroups(careerArea) {
    this.setState({ selectedSubgroup: undefined, areSubgroupsLoading: true });
    const unsortedSubgroups = await getSubgroupNames(careerArea);

    if (unsortedSubgroups) {
      const subgroups = unsortedSubgroups.sort();
      this.setState({ subgroups, areSubgroupsLoading: false });
      if (subgroups.length === 1) {
        this.setState({ selectedSubgroup: subgroups[0] });
        this.getSkills(subgroups[0]);
      }
    } else {
      this.setState({
        errorMessage: 'Error retrieving subcategories',
        areSubgroupsLoading: false
      });
    }
  }

  async getSkills(subgroup) {
    this.setState({ areSkillsLoading: true });
    const clusters = await getClusterNames(subgroup);
    if (clusters) {
      const clusterPromises = clusters.map(cluster =>
        getSkillsByCluster(cluster.attributes['cluster-id'])
      );
      const skillsByCluster = await Promise.all(clusterPromises);
      const skills = sortItemsInArrays(skillsByCluster).map(skill => skill.id);
      this.setState({ skills, areSkillsLoading: false });
    } else {
      this.setState({
        errorMessage: 'Error retrieving skills by category',
        areSkillsLoading: false
      });
    }
  }

  render() {
    const {
      careerAreas,
      subgroups,
      skills,
      selectedCareerArea,
      selectedSubgroup,
      areCareerAreasLoading,
      areSubgroupsLoading,
      areSkillsLoading,
      errorMessage
    } = this.state;

    return (
      <TitledCard
        helpText="Select skills by narrowing down industry categories."
        icon={<Icon Svg={CategoryIcon} />}
        title="Skills By Category"
      >
        <Selects>
          <StyledSelect
            name="category-select"
            placeholder="Select Category"
            ariaLabel="Use up and down arrows to select a category."
            options={careerAreas}
            onChange={e => this.setState({ selectedCareerArea: e.target.value })}
            selection={selectedCareerArea}
            isLoading={areCareerAreasLoading}
            id="skill-page-first-select"
          />

          <StyledSelect
            name="subcategory-select"
            placeholder={subgroups.length < 2 ? 'No Subcategory Available' : 'Select Subcategory'}
            ariaLabel="Use up and down arrows to select a subcategory."
            options={subgroups}
            onChange={e => this.setState({ selectedSubgroup: e.target.value })}
            selection={selectedSubgroup}
            disabled={subgroups.length < 2 || areSubgroupsLoading}
            isLoading={areSubgroupsLoading}
          />
        </Selects>
        {errorMessage && <ErrorMessage aria-live="polite">{errorMessage}</ErrorMessage>}
        <AriaAlert>
          {!areSkillsLoading && skills.length > 0 && 'Tab to select skills matching your search.'}
        </AriaAlert>
        <SkillsChooser
          skillIds={skills}
          isLoading={areSkillsLoading}
          emptyText="Select skills by narrowing down industry categories."
        />
      </TitledCard>
    );
  }
}

export default SkillsByCategory;
