import algoliasearch from 'algoliasearch';
import config from 'config';

const algoliaClient = algoliasearch(config.algoliaProjectId, config.algoliaKey);
const programsIndex = algoliaClient.initIndex(`SM_PROGRAMS_${config.modelVersion}`);

/**
 * Query Algolia to search for a program by the specified parameters.
 * @param {string} query The Algolia query containing search criteria.
 * @returns {object} An object of programs returned from the algolia search engine.
 */
export async function searchPrograms(query) {
  const response = await programsIndex.search({ query });

  return response.hits.map(hit => {
    // remove these fields from the object, aren't needed in state
    const { objectID, _highlightResult, ...rest } = hit;
    return rest;
  });
}
