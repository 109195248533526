import { connect } from 'react-redux';
import LoginForm from 'components/molecules/LoginForm';
import { profileActions } from 'store/reducers/profile';

const mapStateToProps = ({ profile }) => ({
  error: profile.error,
  isLoading: profile.isLoading
});

export default connect(mapStateToProps, {
  login: profileActions.login.request,
  clearProfileError: profileActions.clearProfileError
})(LoginForm);
