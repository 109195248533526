import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ExternalLink from 'components/atoms/ExternalLink';
import HorizontalCardScroll from 'components/molecules/HorizontalCardScroll';
import ProgramCourseCard from 'components/molecules/ProgramCourseCard';
import SkillCountCard from 'components/molecules/SkillCountCard';
import withValidSkills from 'containers/withValidSkills';

import { ReactComponent as ArrowRight } from 'images/arrow-right.svg';
import loading from 'images/loading.gif';
import satelliteEarth from 'images/satellite-earth.svg';

import { blue, grayMedium } from 'helpers/colors';
import { getGroupById, getCourseById } from 'services/courses';
import withKeen from 'hocs/withKeen';

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  width: 102rem;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (min-width: 800px) {
    padding: 2rem 4rem;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
`;

const Label = styled.span`
  background: ${grayMedium};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.3rem;
  margin-right: 1rem;
  margin-top: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const Header = styled.h1`
  font-size: 2.8rem;
  font-weight: normal;
  margin: 1rem 0 0 0;
`;

const ButtonLink = styled(({ inverseColor, ...rest }) => <ExternalLink {...rest} />)`
  display: block;
  border: 1px solid ${blue};
  border-radius: 0.5rem;
  font-size: 1.5rem;
  padding: 1rem 3rem;
  margin: 2rem 0 0 0;
  background: ${props => !props.inverseColor && blue};
  color: ${props => !props.inverseColor && 'white'};
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

const WhiteBackground = styled.div`
  width: 100%;
  background: white;
  margin-top: 2rem;
  padding-bottom: 2rem;
`;

const SkillCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1rem;
  width: calc(100% + 2rem);
  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

const Subheader = styled.h2`
  font-weight: bold;
  font-size: 1.6rem;
`;

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${blue};
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const ArrowIcon = styled(ArrowRight)`
  fill: ${blue};
  width: 1.5rem;
  height: 1rem;
  margin-right: 0.8rem;
  transform: rotate(180deg);
`;

const Loading = styled.img`
  margin: auto;
  width: 6rem;
`;

const ErrorMessage = styled.div`
  max-width: 50rem;
  font-size: 2rem;
  text-align: center;
  margin: auto;
  padding: 5rem 5rem 10rem;
`;

const ErrorImage = styled.img`
  display: block;
  width: 20rem;
  margin: auto;
`;

const StyledScroll = styled(HorizontalCardScroll)`
  @media (min-width: 800px) {
    margin-left: -4.5rem;
    width: calc(100% + 8rem);
  }
  @media (max-width: 799px) {
    margin-left: -3.5rem;
    width: calc(100% + 5.5rem);
  }
  @media (max-width: 549px) {
    margin-left: -2rem;
    width: calc(100% + 4rem);
  }
`;

const ProgramCoursePage = ({
  match,
  selectedSkills,
  institutions,
  hideEdType,
  validSkills,
  fetchValidSkills,
  validSkillsIsLoading,
  validSkillsError,
  trackEvent
}) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);

  useEffect(() => {
    setData(undefined);
    (match.params.groupId
      ? getGroupById(match.params.groupId, match.params.site)
      : getCourseById(match.params.courseId, match.params.site)
    )
      .then(result => {
        if (!result.isPublished) {
          setError(true);
        }
        trackEvent('Program Course Page Visited', {
          title: result.title,
          url: result.url,
          type: match.params.groupId ? 'Programs' : 'Courses',
          id: result.id,
          courseId: result.courseId,
          groupType: result.groupType,
          site: result.subdomain
        });
        setData(result);
        document.title = `Lightcast SkillsMatch - ${result.title}`;
      })
      .catch(() => {
        setError(true);
      });
  }, [match.params]);

  useEffect(() => {
    // validSkills won't be loaded if the user came to this page directly
    if (!Object.keys(validSkills).length) {
      fetchValidSkills();
    }
  }, [validSkills, fetchValidSkills]);

  if (error) {
    return (
      <ErrorMessage aria-live="polite">
        <ErrorImage src={satelliteEarth} alt="" />
        An error occurred while loading the page - the link may have expired. Please refresh or try
        again later.
      </ErrorMessage>
    );
  }

  if (!data || validSkillsIsLoading) {
    return <Loading src={loading} alt="Loading" />;
  }

  // in case skills in the education are not valid
  data.skills = data.skills.map(skillId => validSkills[skillId]).filter(Boolean);

  if (data.courses) {
    data.courses = data.courses
      .map(course => ({
        ...course,
        wantSkills: course.skills.filter(skill => selectedSkills[skill] === 'want').length
      }))
      .sort((a, b) => b.wantSkills - a.wantSkills);
  }

  if (data.groups) {
    data.groups = data.groups
      .map(group => ({
        ...group,
        wantSkills: group.skills.filter(skill => selectedSkills[skill] === 'want').length
      }))
      .sort((a, b) => b.wantSkills - a.wantSkills);
  }

  return (
    <Wrapper>
      <Margin>
        <LabelWrapper>
          {data.groupType && <Label>{data.groupType.label}</Label>}
          {data.courseId && <Label>{data.courseId}</Label>}
          {data.credits > 0 && (
            <Label>
              {data.credits} {data.credits === 1 ? 'Credit' : 'Credits'}
            </Label>
          )}
        </LabelWrapper>
        <Header>{data.title}</Header>
        {// TODO: switch to `site` when changing to use CS Skills API
        Object.keys(institutions).length > 1 && <div>{institutions[data.subdomain]}</div>}
        {data.url && (
          <ButtonLink
            url={data.url}
            onClick={() =>
              trackEvent('Education Link Clicked', {
                title: data.title,
                url: data.url,
                type: data.courseId ? 'Courses' : 'Programs'
              })
            }
          >
            View on School&apos;s Site
          </ButtonLink>
        )}
      </Margin>
      <WhiteBackground>
        <Margin>
          <BackLink to="/results/education">
            <ArrowIcon />
            Back to Results Overview
          </BackLink>
          {validSkillsError ? (
            <ErrorMessage>
              An error occurred loading skills. Please refresh or try again later.
            </ErrorMessage>
          ) : (
            <SkillCardsContainer>
              <SkillCountCard
                type="Want"
                skills={data.skills.filter(skill => selectedSkills[skill.id] === 'want')}
              />
              <SkillCountCard
                type="Have"
                skills={data.skills.filter(skill => selectedSkills[skill.id] === 'have')}
              />
              <SkillCountCard type="All" skills={data.skills} />
            </SkillCardsContainer>
          )}

          {data.description && (
            <>
              <Subheader>About This Offering</Subheader>
              <div>{data.description}</div>
              {data.url && (
                <ButtonLink
                  inverseColor
                  url={data.url}
                  onClick={() =>
                    trackEvent('Education Link Clicked', {
                      title: data.title,
                      url: data.url,
                      type: data.courseId ? 'Courses' : 'Programs'
                    })
                  }
                >
                  View More Info
                </ButtonLink>
              )}
            </>
          )}
        </Margin>
      </WhiteBackground>
      {!hideEdType && data.courses && data.courses.length ? (
        <Margin>
          <Subheader>Courses in This Program</Subheader>
          <StyledScroll type="education">
            {data.courses.map(edItem => (
              <ProgramCourseCard
                key={edItem.id}
                edItem={edItem}
                edType="Courses"
                institutionNames={institutions}
                shouldShowInstitution={Object.keys(institutions).length > 1}
              />
            ))}
          </StyledScroll>
        </Margin>
      ) : null}
      {!hideEdType && data.groups && data.groups.length ? (
        <Margin>
          <Subheader>Programs This Course Appears in</Subheader>
          <StyledScroll type="education">
            {data.groups.map(edItem => (
              <ProgramCourseCard
                key={edItem.id}
                edItem={edItem}
                edType="Programs"
                institutionNames={institutions}
                shouldShowInstitution={Object.keys(institutions).length > 1}
              />
            ))}
          </StyledScroll>
        </Margin>
      ) : null}
    </Wrapper>
  );
};

ProgramCoursePage.propTypes = {
  selectedSkills: PropTypes.objectOf(PropTypes.string).isRequired,
  institutions: PropTypes.objectOf(PropTypes.string).isRequired,
  hideEdType: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      site: PropTypes.string.isRequired,
      groupId: PropTypes.string,
      courseId: PropTypes.string
    }).isRequired
  }).isRequired,
  validSkills: PropTypes.objectOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired })
  ).isRequired,
  fetchValidSkills: PropTypes.func.isRequired,
  validSkillsIsLoading: PropTypes.bool.isRequired,
  validSkillsError: PropTypes.bool,
  trackEvent: PropTypes.func.isRequired
};

export default withKeen(withValidSkills(ProgramCoursePage));
