import React from 'react';
import styled from 'styled-components';

const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;

const Header = styled.h1`
  margin: 6rem 0 3rem 0;
  font-size: 2.8rem;
`;

const Text = styled.div`
  max-width: 100rem;
  margin: 0 2rem 2rem 2rem;
  font-size: 1.4rem;
  line-height: 2rem;
`;

const Disclaimer = () => (
  <ContentWrap>
    <Text>
      <Header>Lightcast Disclaimer</Header>
      Lightcast&apos;s products and services use data, reports, and forecasts which are generated
      using government and private data sources and proprietary analytical processes. Lightcast uses
      estimates when there are suppressed or missing data points, and such estimates are subject to
      error. Data, reports, and forecasts included in Lightcast&apos;s products and services may
      differ significantly from actual circumstances or outcomes. Lightcast&apos;s products and
      services are provided &#34;as is,&#34; without warranty for a particular purpose or project.
      Lightcast is not liable for their misuse, or for the results of any planning errors based
      thereon. Licensee is fully responsible for the decisions that are made based on
      Lightcast&apos;s products or services and the outcomes of those decisions. Lightcast&apos;s
      liability for damages to Licensee shall not exceed the amount Licensee paid to Lightcast for
      the product or service in question.
    </Text>
  </ContentWrap>
);

export default Disclaimer;
