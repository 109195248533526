import React from 'react';
import PropTypes from 'prop-types';
import VisuallyHidden from 'components/atoms/VisuallyHidden';

// Provides aria-live feedback to the user regarding their results as they edit their filters
function ResultsScreenReader({ contentName, hasSelectedSkills, matchedCount, othersCount }) {
  return (
    <VisuallyHidden>
      {hasSelectedSkills && (
        <div aria-atomic="true" aria-live="polite">
          {matchedCount
            ? `displaying ${matchedCount} ${contentName} that match your skills`
            : `all ${contentName} matched to your skills have been filtered out`}
        </div>
      )}
      {othersCount !== undefined && (
        <div aria-atomic="true" aria-live="polite">
          {othersCount
            ? `displaying ${othersCount} other ${contentName}`
            : `all other ${contentName} have been filtered out`}
        </div>
      )}
    </VisuallyHidden>
  );
}

ResultsScreenReader.propTypes = {
  contentName: PropTypes.string.isRequired,
  hasSelectedSkills: PropTypes.bool.isRequired,
  matchedCount: PropTypes.number.isRequired,
  othersCount: PropTypes.number
};

export default ResultsScreenReader;
