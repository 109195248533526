import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AdminToolTip from 'Admin/components/AdminToolTip';
import RoleDropdown from 'Admin/components/RoleDropdown';
import RoleConfirmationModal from 'Admin/components/RoleConfirmationModal';

import { ReactComponent as MailSvg } from 'images/mail-icon.svg';
import { blueText, grayText, grayLight } from 'helpers/colors';

const StyledTable = styled.table`
  width: 100%;
  padding-top: 1rem;
  table-layout: fixed;
`;

const TableHeader = styled.th`
  font-size: 1.4rem;
  color: ${grayText};
  font-weight: 400;
  text-align: left;
  ${({ isEmail }) => isEmail && `padding-right: 3rem;`}
  width: ${({ width }) => width};
`;

const StyledHr = styled.hr`
  width: 100%;
  color: ${grayLight};
`;

const TableItem = styled.td`
  padding: 0.5rem 0;
  ${({ isIcon }) =>
    !isIcon &&
    `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.5rem 3rem 0.5rem 0;
  `}
`;

const Email = styled.span`
  color: ${blueText};
  font-size: 1.4rem;
`;

const DropdownTableItem = styled.td`
  overflow: visible;
`;

const AdminSettingsTable = ({ adminProfiles, options, reloadAdminProfiles }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({
    subdomain: '',
    profileId: '',
    email: '',
    selectedUserRole: ''
  });

  return (
    <StyledTable>
      <thead>
        <tr>
          {!!adminProfiles.filter(profile => profile.cognitoStatus === 'RESET_REQUIRED').length && (
            <TableHeader aria-hidden width="3rem" />
          )}
          <TableHeader isEmail width="56%">
            Email <StyledHr />
          </TableHeader>
          <TableHeader width="40%">
            Permissions <StyledHr />
          </TableHeader>
        </tr>
      </thead>
      <tbody>
        {adminProfiles.map(profile => {
          return (
            <tr key={profile.profileId}>
              {!!adminProfiles.filter(user => user.cognitoStatus === 'RESET_REQUIRED').length && (
                <TableItem isIcon aria-hidden>
                  {profile.cognitoStatus === 'RESET_REQUIRED' && (
                    <AdminToolTip text="Invite Not Yet Accepted">
                      <MailSvg />
                    </AdminToolTip>
                  )}
                </TableItem>
              )}
              <TableItem>
                <Email
                  aria-label={
                    profile.cognitoStatus === 'RESET_REQUIRED'
                      ? `${profile.email} has not accepted their invite`
                      : profile.email
                  }
                >
                  {profile.email}
                </Email>
              </TableItem>
              <DropdownTableItem>
                <RoleDropdown
                  onSelect={value => {
                    setIsConfirmModalOpen(true);
                    setSelectedProfile({
                      subdomain: profile.subdomain,
                      profileId: profile.profileId,
                      email: profile.email,
                      selectedUserRole: value
                    });
                  }}
                  ariaLabel={`Click to change permissions for ${profile.email}. ${profile.userRole} is the current permission`}
                  options={options}
                  selectedRole={profile.userRole}
                  reloadAdminProfiles={reloadAdminProfiles}
                />
              </DropdownTableItem>
            </tr>
          );
        })}
        <RoleConfirmationModal
          subdomain={selectedProfile.subdomain}
          profileId={selectedProfile.profileId}
          email={selectedProfile.email}
          selectedUserRole={selectedProfile.selectedUserRole}
          isOpen={isConfirmModalOpen}
          close={() => setIsConfirmModalOpen(false)}
          reloadAdminProfiles={reloadAdminProfiles}
        />
      </tbody>
    </StyledTable>
  );
};

AdminSettingsTable.propTypes = {
  adminProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      cognitoStatus: PropTypes.string,
      email: PropTypes.string.isRequired,
      profileId: PropTypes.string.isRequired,
      userRole: PropTypes.string.isRequired
    })
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  reloadAdminProfiles: PropTypes.func.isRequired
};

export default AdminSettingsTable;
