import React from 'react';
import PropTypes from 'prop-types';

import ProgramCourseCard from 'containers/ProgramCourseCard';
import LoadMoreButton from 'components/molecules/LoadMoreButton';
import ResultsDisplay from 'components/organisms/ResultsDisplay';

const EducationResults = ({
  clearFilters,
  edType,
  educationResults,
  selectedSkills,
  setMobileSidebarOpen,
  loadMoreEducation,
  institutions
}) => {
  const { matches, others, error, isLoading, isLoadingMore, endOfList } = educationResults;

  const matchedCards = matches
    // TODO: Remove filtering once we fix courses API returning dups
    .filter(
      (currentItem, currentIndex) =>
        // if currentItem is the first item with the same id, then keep it. Filter out the rest
        matches.findIndex(duplicate => duplicate.id === currentItem.id) === currentIndex
    )
    .map(edItem => (
      <ProgramCourseCard
        key={edItem.id}
        edItem={edItem}
        edType={edType}
        shouldShowInstitution={Object.keys(institutions).length > 1}
      />
    ));

  let otherCards =
    others &&
    // TODO: Remove filtering once we fix courses API returning dups
    others
      .filter(
        (currentItem, currentIndex) =>
          // if currentItem is the first item with the same id, then keep it. Filter out the rest
          others.findIndex(duplicate => duplicate.id === currentItem.id) === currentIndex
      )
      .filter(other => matches.every(match => match.id !== other.id))
      .map(edItem => (
        <ProgramCourseCard
          key={edItem.id}
          edItem={edItem}
          edType={edType}
          shouldShowInstitution={Object.keys(institutions).length > 1}
        />
      ));

  return (
    <>
      <ResultsDisplay
        contentName={edType}
        matchedResults={matchedCards}
        otherResults={otherCards}
        selectedSkills={selectedSkills}
        openMobileSidebar={() => setMobileSidebarOpen(true)}
        isLoading={isLoading}
        error={error}
        clearFilters={clearFilters}
      />
      {!isLoading && !error && (
        <LoadMoreButton
          isLoading={isLoadingMore}
          loadMore={loadMoreEducation}
          endOfList={endOfList}
        />
      )}
    </>
  );
};

EducationResults.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  edType: PropTypes.oneOf(['Courses', 'Programs']).isRequired,
  educationResults: PropTypes.shape({
    matches: PropTypes.arrayOf(PropTypes.object).isRequired,
    others: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool.isRequired,
    isLoadingMore: PropTypes.bool.isRequired,
    endOfList: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  selectedSkills: PropTypes.objectOf(PropTypes.string).isRequired,
  institutions: PropTypes.objectOf(PropTypes.string).isRequired,
  setMobileSidebarOpen: PropTypes.func.isRequired,
  loadMoreEducation: PropTypes.func.isRequired
};

export default EducationResults;
