import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Card from 'components/atoms/Card';
import SvgIcon from 'components/atoms/SvgIcon';
import { blue, grayMedium, innerCardBoxShadow } from 'helpers/colors';

import { ReactComponent as DeleteIcon } from 'images/delete.svg';

const StyledCard = styled(Card)`
  box-shadow: ${innerCardBoxShadow};
  flex-direction: row;
  font-size: 1.4rem;
  margin-bottom: 2rem;
`;

const DetailsDiv = styled.div`
  border-right: 1px solid ${grayMedium};
  min-width: 15rem;
  padding: 1.5rem;
  width: calc(100% - 6rem);
`;

const InvisibleButton = styled.button`
  border: none;
  background: none;
  height: 1.5rem;
  padding: 0.5rem;
  box-sizing: content-box;
`;

const TrashDiv = styled.div`
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bold = styled.div`
  margin-top: 0.3rem;
  font-weight: bold;
`;

const Icon = styled(SvgIcon)`
  height: 1.5rem;
  width: auto;
  fill: ${blue};
  :hover {
    cursor: pointer;
  }
`;

const EducationCard = ({ edItem, removeEducation, index }) => {
  return (
    <StyledCard>
      <DetailsDiv>
        {edItem.level.label} {edItem.area && <Bold>{edItem.area.name}</Bold>}
      </DetailsDiv>
      <TrashDiv>
        <InvisibleButton
          data-cy={`delete-ed-${index}`}
          aria-label={`Delete the Education Item ${edItem.level.label}${
            edItem.area ? `: ${edItem.area.name}` : ''
          }`}
          onClick={() => removeEducation(edItem)}
        >
          <Icon Svg={DeleteIcon} />
        </InvisibleButton>
      </TrashDiv>
    </StyledCard>
  );
};

EducationCard.propTypes = {
  edItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  removeEducation: PropTypes.func.isRequired
};

export default EducationCard;
