import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checked from 'images/Checked.svg';
import Unchecked from 'images/Unchecked.svg';

const Check = styled.img`
  height: 1.4rem;
  width: 1.4rem;
  cursor: pointer;
  margin-top: 0.3rem;
  z-index: 1;
`;

const Label = styled.label`
  margin-left: 1rem;
  line-height: 2rem;
  font-size: 1.4rem;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CheckWrapper = styled.div`
  position: relative;
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  top: -0.1rem;
  left: -0.5rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  /* This sets the opacity on ios devices to 0 so the checkbox does not show through the svg checkbox */
  ${/iPhone|iPad|iPod/i.test(navigator.userAgent) && 'opacity: 0;'};

  :invalid {
    box-shadow: none;
  }

  &:focus {
    outline: 0.1rem solid #67a2fa;
  }
`;

const Checkbox = ({
  dataCy,
  isChecked,
  onChange,
  label,
  labelComponent,
  ariaLabel,
  name,
  required = false,
  className
}) => {
  return (
    <CheckWrapper className={className}>
      <HiddenCheckbox
        aria-label={ariaLabel}
        name={name}
        id={name}
        type="checkbox"
        checked={isChecked}
        required={required}
        onChange={onChange}
        // e.key needs a space here, the space is the key.
        onKeyDown={e => e.key === ' ' && onChange}
      />

      <Flex>
        <Check
          data-cy={dataCy}
          src={isChecked ? Checked : Unchecked}
          alt=""
          onClick={onChange}
          aria-hidden
        />
        {label && !labelComponent ? <Label htmlFor={name}>{label}</Label> : labelComponent}
      </Flex>
    </CheckWrapper>
  );
};

Checkbox.propTypes = {
  dataCy: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  labelComponent: PropTypes.element,
  ariaLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string
};

export default Checkbox;
