import { connect } from 'react-redux';
import SavedViews from 'components/organisms/SavedViews';
import { profileActions } from 'store/reducers/profile';

const mapStateToProps = ({ profile }) => ({
  isLoggedIn: profile.isLoggedIn,
  savedViews: profile.views,
  selectedViewId: profile.activeViewId
});

export default connect(mapStateToProps, {
  addProfileView: profileActions.addProfileView.request,
  getProfileView: profileActions.getProfileView.request,
  deleteProfileView: profileActions.deleteProfileView.request,
  updateProfileView: profileActions.updateProfileView.request
})(SavedViews);
