import { connect } from 'react-redux';

import GlobalFooter from 'components/molecules/GlobalFooter';

const mapStateToProps = state => ({
  menuBackgroundColor: state.site.data.menuBackgroundColor,
  menuTextColor: state.site.data.menuTextColor
});

export default connect(mapStateToProps)(GlobalFooter);
