import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import { fetchKeenData } from 'Admin/helpers/keen';
import useMountedAsync from 'helpers/useMountedAsync';
import { blue, grayText, grayMedium } from 'helpers/colors';

dayjs.extend(utc);

const ChartWrapper = styled.div`
  width: ${({ shrinkChart }) => shrinkChart && 'calc(100% - 13rem)'};
`;

const ToolTipBody = styled.div`
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.0976289);
  padding: 0.5rem 1rem;
  position: relative;
  top: -3rem;
  text-align: center;
  z-index: 6;
  color: ${grayText};
  font-size: 1.2rem;
`;

const Value = styled.div`
  font-size: 1.4rem;
  margin-top: 0.2rem;
`;

const CustomTooltip = ({ active, payload, interval }) => {
  if (active && payload) {
    const date = dayjs(payload[0].payload.timeframe.start).utc();
    return (
      <ToolTipBody>
        {interval === 'weekly' && 'Week of '}
        <b>{date.format('MMM D, YYYY')}</b>
        <Value>{payload[0].value.toLocaleString('en-US')}</Value>
      </ToolTipBody>
    );
  }
  return null;
};

const KeenChart = ({ shrinkChart, setError, ...rest }) => {
  const [data, setData] = useState(null);

  // The fetchKeenData query here cannot be combined with the one in KeenCard
  // because the sum from daily/weekly does not equal the total when there are duplicates
  useMountedAsync(
    () => fetchKeenData(rest),
    ({ result }) =>
      setData(
        result.map(item => {
          return {
            ...item,
            date: dayjs(item.timeframe.start)
              .utc()
              .format('MM/DD')
          };
        })
      ),
    () => setError(true),
    [rest.timeframe]
  );

  return data ? (
    <ChartWrapper shrinkChart={shrinkChart} aria-hidden>
      <ResponsiveContainer width="100%" height={shrinkChart ? 90 : 160}>
        <AreaChart
          data={data}
          // TODO: change these margins as needed
          margin={{
            top: 3,
            right: 20,
            left: shrinkChart ? 3 : -12,
            bottom: 3
          }}
        >
          <defs>
            <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
              <stop offset="50%" stopColor={blue} stopOpacity={0.2} />
              <stop offset="95%" stopColor="white" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            hide={shrinkChart}
            dataKey="date"
            stroke={grayText}
            axisLine={{ stroke: grayMedium }}
            tickLine={false}
            tick={{ fontSize: 12 }}
            minTickGap={30}
            tickMargin={10}
          />
          <YAxis
            hide={shrinkChart}
            axisLine={false}
            stroke={grayText}
            tick={{ fontSize: 12 }}
            tickLine={false}
            tickFormatter={value => (value === 0 ? '' : value.toLocaleString('en-US'))}
          />
          <Tooltip content={<CustomTooltip interval={rest.interval} />} />
          <Area
            dataKey="value"
            type="monotone"
            stroke={blue}
            fill="url(#gradientFill)"
            activeDot={{ strokeWidth: 3, r: 4 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </ChartWrapper>
  ) : null;
};

KeenChart.propTypes = {
  shrinkChart: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  timeframe: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired,
  interval: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  analysisType: PropTypes.string,
  targetProperty: PropTypes.string,
  eventCollection: PropTypes.string.isRequired
};

export default KeenChart;
