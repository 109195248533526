import { useEffect } from 'react';

/**
 * Handles Asyncronous calls and mounting conditions when a user tries to do something before the component fully finishes loading.
 * See https://stackoverflow.com/a/60907638/12032510 'Can't perform a React state update on an unmounted component' for more details
 * @param {function} asyncFn The main function to be called, typically an external api call
 * @param {function} onSuccess A function to be called on Success
 * @param {function} onFailure A function to be called on Fail
 * @param {String[]} listeners An array of parameters that would change the outcome of data for the useEffect (i.e. ['thing',1,{name: 'stuff'}])
 * @returns {Void} This function does not return anything. Ensure your onSuccess Function handles the data.
 */

const useMountedAsync = (asyncFn, onSuccess, onFailure, listeners) => {
  useEffect(() => {
    let isMounted = true;
    asyncFn()
      .then(result => {
        if (isMounted) {
          onSuccess(result);
        }
      })
      .catch(() => onFailure());
    return () => {
      isMounted = false;
    };
  }, listeners);
};

export default useMountedAsync;
