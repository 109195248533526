import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { grayMedium, blue } from 'helpers/colors';

const ButtonLink = styled(({ inverseColor, ...rest }) => <Link {...rest} />)`
  background: ${props => (props.disabled ? grayMedium : blue)};
  border: 0;
  border-radius: 0.3rem;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem 5rem;
  text-align: center;
  text-decoration: none;
  ${props =>
    props.inverseColor &&
    `
    font-weight: 400;
    background: white;
    border: 1px solid ${blue};
    color: ${blue};
  `}
`;

export default ButtonLink;
