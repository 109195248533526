import { connect } from 'react-redux';

import { fetchSiteActions } from 'store/reducers/site';
import { profileActions } from 'store/reducers/profile';

import LoadSite from 'components/pages/LoadSite';

export default connect(
  ({
    site: {
      isLoaded,
      notFound,
      data: { isDisabled, salesforceId }
    }
  }) => ({
    siteIsLoaded: isLoaded,
    siteNotFound: notFound,
    siteIsDisabled: isDisabled,
    salesforceId
  }),
  {
    fetchSite: fetchSiteActions.request,
    logout: profileActions.logout,
    fetchProfile: profileActions.fetchProfile.request
  }
)(LoadSite);
