import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from 'components/atoms/Card';
import Modal from 'components/atoms/Modal';
import Button from 'components/atoms/Button';

import { updateUserRole } from 'services/cognitoUser';

const StyledCard = styled(Card)`
  padding: 3rem 1.5rem;
  @media (min-width: 800px) {
    padding: 3rem;
    width: 57.5rem;
  }
`;

const Title = styled.h1`
  font-size: 1.6rem;
  text-align: center;
`;

const Email = styled.span`
  ${({ isTruncated }) => isTruncated && 'display: inline-block'};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledSpan = styled.span`
  font-size: 1.4rem;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const StyledButton = styled(Button)`
  width: 20rem;
  :nth-child(2) {
    margin-left: 2rem;
  }
`;

const RoleConfirmationModal = ({
  isOpen,
  close,
  email,
  subdomain,
  profileId,
  selectedUserRole,
  reloadAdminProfiles
}) => {
  const isTruncated = email && email.length > 46;
  const emailField = <Email isTruncated={isTruncated}>&quot;{email}&quot;</Email>;

  const changePermission = async () => {
    close();
    if (selectedUserRole === 'Remove Access') {
      selectedUserRole = '';
    }
    await updateUserRole(subdomain, profileId, selectedUserRole);
    await reloadAdminProfiles();
  };

  return (
    <Modal isOpen={isOpen} close={close}>
      <StyledCard>
        {selectedUserRole === 'Remove Access' ? (
          <>
            <Title>Remove Access?</Title>
            <StyledSpan>{emailField} will no longer have admin accesss.</StyledSpan>
          </>
        ) : (
          <Title>
            Change permissions for {emailField} to &quot;{selectedUserRole}&quot;?
          </Title>
        )}
        <ButtonWrapper>
          <StyledButton variant="secondary" onClick={close}>
            Cancel
          </StyledButton>
          <StyledButton
            variant={selectedUserRole === 'Remove Access' ? 'danger' : 'primary'}
            onClick={changePermission}
          >
            Confirm
          </StyledButton>
        </ButtonWrapper>
      </StyledCard>
    </Modal>
  );
};

RoleConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  selectedUserRole: PropTypes.string.isRequired,
  reloadAdminProfiles: PropTypes.func.isRequired
};

export default RoleConfirmationModal;
