import { connect } from 'react-redux';
import GlobalHeader from 'components/molecules/GlobalHeader';
import { profileActions } from 'store/reducers/profile';

const { logout } = profileActions;

const mapStateToProps = state => ({
  institutionName: state.site.data.institutionName,
  isLoggedIn: state.profile.isLoggedIn,
  userRole: state.profile.userRole,
  logoImage: state.site.data.logoImage,
  menuBackgroundColor: state.site.data.menuBackgroundColor,
  menuTextColor: state.site.data.menuTextColor
});

export default connect(mapStateToProps, { logout })(GlobalHeader);
