import fetchWithRetry from './helpers/fetchWithRetry';
import getModelVersion from 'helpers/getModelVersion';

export const getSkillsByCluster = async clusterId => {
  let response = await fetchWithRetry(
    `/api/skills-clusters/models/${getModelVersion()}/clusters/${clusterId}`
  );
  if (!response.ok) {
    throw new Error('Error retrieving skills from skillsClusters.');
  }
  // clean up all the attributes we don't use (all except id, name and score)
  let result = await response.json();
  result = result.skills;
  return result
    .sort((a, b) => b.score - a.score)
    .map(skill => {
      const { id, name, score } = skill;
      return { id, name, score };
    });
};

export const getAllClusters = async () => {
  let response = await fetchWithRetry(`/api/skills-clusters/models/${getModelVersion()}/clusters`);
  if (!response.ok) {
    throw new Error('Error retrieving clusters from skillsClusters.');
  }
  return response.json();
};

export const getValidSkills = async () => {
  let response = await fetchWithRetry(`/api/skills-clusters/models/${getModelVersion()}/skills`);
  if (!response.ok) {
    throw new Error('Error retrieving clusters from skillsClusters.');
  }
  return response.json();
};

export const getClustersBySkills = async skills => {
  // Skills API and SkillsClusters API have very different sets of valid skills
  // /matchwithoutmetadata endpoint throws an error if invalid skills are passed in
  let validSkills = await getValidSkills();
  validSkills = validSkills.map(skill => skill.id);

  skills = skills.filter(skillId => validSkills.includes(skillId));
  // SkillsClusters API return 400 if empty array is passed in
  if (!skills.length) {
    return [];
  }

  let response = await fetchWithRetry(
    `/api/skills-clusters/models/${getModelVersion()}/matchwithoutmetadata`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        skills
      })
    }
  );

  if (!response.ok) {
    throw new Error('Error retrieving clusters from skillsClusters.');
  }

  let result = await response.json();

  return result.map(cluster => {
    // rename `skills` to `primarySkills` to be more specific
    let { skills: primarySkills, overlapSkills, ...rest } = cluster;

    // For primarySkills and overlapSkills, first sort by score, and then clean up all the attributes we don't use (all except id and name)
    primarySkills = primarySkills
      .sort((a, b) => b.score - a.score)
      .map(item => {
        const { id, name } = item;
        return { id, name };
      });
    overlapSkills = overlapSkills
      .sort((a, b) => b.score - a.score)
      .map(item => {
        const { id, name } = item;
        return { id, name };
      });

    return { ...rest, primarySkills, overlapSkills };
  });
};
