let subdomain = 'testsite';

export default function getSubdomain() {
  if (typeof window !== 'undefined') {
    const hostZones = window.location.hostname.split('.');
    subdomain = hostZones.length > 1 ? hostZones[0] : subdomain;
  }

  return subdomain;
}
